import React from 'react';
import { Grid, Typography } from '@mui/material';
import { LinkButton } from '@/clientCore/cart/components/common/LinkButton';
import { SERVICE_FEE } from '@/clientCore/cart/constants';
import { FeeListProps } from '../types';
import styles from '../CartCheckout.styles';
import formattedPrice from '@/common/formattedPrice';

export const FeeList = ({ handleFeeClick, fees }: FeeListProps) => {
  const checkoutClasses = styles.cartCheckout();
  return (
    <>
      {fees.map((fee, key) => (
        <Grid container justifyContent="space-between" key={key}>
          <Grid>
            {fee.name?.toLowerCase() !== 'service tax' ? (
              <Typography
                data-testid={`${fee.name
                  .toLowerCase()
                  .split(' ')
                  .join('-')}-item`}
              >
                {fee.name}
              </Typography>
            ) : (
              <LinkButton
                className={checkoutClasses.serviceFeeLink}
                ariaLabel={SERVICE_FEE}
                onClick={handleFeeClick}
                testId={`${SERVICE_FEE.toLowerCase()}-link-item`}
              >
                {SERVICE_FEE}
              </LinkButton>
            )}
          </Grid>
          <Grid>
            <Typography
              data-testid={`${fee.name
                .toLowerCase()
                .split(' ')
                .join('-')}-amount`}
            >
              {formattedPrice(fee.amount)}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </>
  );
};
