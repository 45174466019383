import * as React from 'react';
import { useDecision } from '@optimizely/react-sdk';
import { Grid, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { DEAL_LOVERS_CHECKOUT_WARNING } from '@/clientCore/cart/components/CartRail/components/Checkout7DLWarning/constants';
import { Props } from '@/clientCore/cart/components/CartRail/components/Checkout7DLWarning/types';
import { closeCartRail } from '@/clientCore/redux/rail/CartRailSlice';
import Routes from '@/router/routes';
import ProductId from '@/common/ProductId';
import { useCartDeleteItem } from '@/clientCore/cart/hooks/useDeleteItem';
import { VluProductsList } from '@/clientCore/cart/components/CartRail/types';
import styles from '@/clientCore/cart/components/CartRail/components/Checkout7DLWarning/styles';
import CtaButton from '@/clientCore/components/CtaButton';

const Checkout7DLWarning = (props: Props) => {
  const { items, setShow7DLCheckoutWarning } = props;

  const router = useRouter();
  const dispatch = useDispatch();
  const classes = styles();

  const { handleDeleteCartItem } = useCartDeleteItem();

  const [vluEnforcePick2Decision] = useDecision(
    'fr-web-1339-vlu-enforcing-pick-2'
  );

  const vluYumItemList = vluEnforcePick2Decision.variables?.vlu_list
    ? (vluEnforcePick2Decision.variables as VluProductsList).vlu_list.yum_global_ids
    : undefined;

  const removeCtaLabel = items.length > 1
    ? DEAL_LOVERS_CHECKOUT_WARNING.cta.removeAndCheckout
    : DEAL_LOVERS_CHECKOUT_WARNING.cta.remove;

  const addDealLoversItem = () => {
    dispatch(closeCartRail());
    setShow7DLCheckoutWarning(false);
    if (router.pathname !== Routes.LINEUP) {
      router.push(Routes.LINEUP);
    }
  };

  const removeDealLoversItemFromCart = () => {
    const itemToRemove = items.find((cartItem) => {
      const { globalId } = new ProductId(cartItem.id);
      return vluYumItemList?.find((item) => globalId === item.id && item.variant_codes.includes(cartItem.variantCode ?? ''));
    });
    if (itemToRemove) handleDeleteCartItem(itemToRemove);
    if (items.length > 1) {
      dispatch(closeCartRail());
      router.push(Routes.CHECKOUTW2);
    }
    setShow7DLCheckoutWarning(false);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        {DEAL_LOVERS_CHECKOUT_WARNING.title}
      </Typography>
      <Typography className={classes.content}>
        {DEAL_LOVERS_CHECKOUT_WARNING.content}
      </Typography>
      <Grid className={classes.add7DLItemButton}>
        <CtaButton
          color="primary"
          clickHandler={addDealLoversItem}
          labelText={DEAL_LOVERS_CHECKOUT_WARNING.cta.add}
          fullWidth
          size="large"
        />
      </Grid>
      <CtaButton
        color="secondary"
        clickHandler={removeDealLoversItemFromCart}
        labelText={removeCtaLabel}
        fullWidth
        size="large"
      />
    </div>
  );
};

export default Checkout7DLWarning;
