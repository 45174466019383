import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { mobileStartBreakpoint } from '../../materialUi/theme';
import { railHeaderHeight, railHeaderHeightMobileView } from './RailHeader.styles';
import colors from '../../common/colors';

const styles = makeStyles((theme: Theme) => createStyles({
  container: {
    marginTop: '0px'
  },
  content: {
    paddingLeft: '24px',
    paddingRight: '24px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      paddingLeft: '16px',
      paddingRight: '16px'
    }
  },
  storesNear: {
    fontFamily: 'open_sans_semi',
    fontSize: '18px',
    fontWeight: 600,
    margin: '24px 0 16px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      fontSize: '16px',
      marginTop: '16px'
    }
  },
  storeGroup: {
    marginTop: '24px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      marginTop: '16px'
    }
  },
  line: {
    color: colors.gray400,
    marginLeft: '8px',
    marginRight: '8px'
  },
  mapContainer: {
    position: 'sticky',
    top: railHeaderHeight,
    zIndex: 1,
    marginTop: '24px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.3)',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      marginTop: '16px',
      top: railHeaderHeightMobileView
    }
  },
  storeTile: {
    paddingBottom: '16px',
    flexBasis: 'auto',
    flexShrink: 0
  }
}));

export default styles;
