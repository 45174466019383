/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import { connect, useSelector } from 'react-redux';
import {
  Drawer
} from '@mui/material';
import RailHeader, { RailHeaderProps } from './RailHeader';
import LocalizationRailContentView from './LocalizationRailContentView';
import { closeLocalizationRail, usingSavedAddresses } from '../actions';
import CarryoutIcon from '../icons/CarryoutIcon';
import DeliveryIcon from '../icons/DeliveryIcon';
import { Occasion, ValidOccasion, zIndex } from '../constants';
import { railStyles } from '@/rail/Rail.styles';
import { RootState } from '@/rootStateTypes';
import { selectors } from '@/account/profile/profile.slice';
import LocalizationRailProvider from '@/clientCore/localization/localizationRail/LocalizationRailProvider';

interface LocalizationRailPropTypes {
  visible: boolean;
  occasion: Occasion;
  closeLocalizationRail: () => void;
  usingSavedAddresses: (usingSavedAddresses: boolean) => void;
  searchInRail: boolean;
  useSavedAddresses: boolean;
}

function LocalizationRail({
  visible,
  occasion,
  closeLocalizationRail,
  searchInRail,
  useSavedAddresses,
  usingSavedAddresses
}: LocalizationRailPropTypes) {
  const railClasses = railStyles();
  const savedAddresses = useSelector(selectors.addresses);
  const includeSavedAddressInAnalytics = useSavedAddresses && savedAddresses.length > 0;
  
  const switchToSavedAddresses = () => {
    usingSavedAddresses(true);
  };

  const switchToFormFields = () => {
    usingSavedAddresses(false);
  };

  const headerProps: { [key in ValidOccasion]: Omit<RailHeaderProps, 'onClickClose'> }  = {
    [Occasion.DELIVERY]: {
      label: searchInRail ? 'Start here' : 'Delivery',
      testId: 'delivery',
      icon: <DeliveryIcon size={35} aria-hidden />,
      includeSavedAddressInAnalytics
    },
    [Occasion.CARRYOUT]: {
      label: searchInRail ? 'Start here' : 'Carryout',
      testId: 'carryout',
      icon: <CarryoutIcon size={35} aria-hidden />,
      includeSavedAddressInAnalytics
    }
  };

  return (
    <Drawer
      classes={{ paper: railClasses.paper }}
      style={{ zIndex: zIndex.LOWER_THAN_GOOGLE_AUTO_COMPLETE }}
      anchor="right"
      onClose={closeLocalizationRail}
      open={visible}
      data-testid="localization-rail"
    >
      <RailHeader
        onClickClose={closeLocalizationRail}
        {...headerProps[occasion as ValidOccasion]}
      />
        <LocalizationRailProvider>
          <LocalizationRailContentView
            useSavedAddresses={useSavedAddresses}
            switchToSavedAddresses={switchToSavedAddresses}
            switchToFormFields={switchToFormFields}
          />
        </LocalizationRailProvider>
    </Drawer>
  );
}

const mapStateToProps = (state: RootState) => ({
  visible: state.presentational.localization.rail.options.isOpen,
  occasion: state.presentational.localization.occasion,
  searchInRail: state.presentational.localization.searchInRail,
  savedAddresses: state.presentational.localization.savedAddresses,
  useSavedAddresses: state.presentational.localization.useSavedAddresses
});

const mapDispatchToProps = {
  closeLocalizationRail,
  usingSavedAddresses
};

export default connect(mapStateToProps, mapDispatchToProps)(LocalizationRail);
