var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { removeBundleOnlyVariants } from './filterUtilities';
import { GlutenFreeCodes, LineupNameOverridesMap, ModifierKeys, OptionKeys, Placement, Portion, SlotKeys, WeightKeys, WeightMap, } from '../types';
import transformYumNutrition from './transformYumNutrition';
import { includeVariantForPromotionDefinition, metafieldsHaveSodiumWarning, isMelt as productIsMelt, } from './menuUtilities';
import { sortByOutOfStock } from './sortUtilities';
import transformYumAvailability from './transformYumAvailabilty';
import getLineupItemsVariantCodes from './getLineupItemsVariantCodes';
export const transformYumProductPizzasAndMelts = (yumProduct, allOptions, allSlots, removeBundleVariants = true, variantUpcharges = {}, promotionDefinition) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    // determine if the product is a melt
    const isMelt = productIsMelt(yumProduct.options);
    // filter out bundlesOnly variants if the incoming flag is true
    const product = removeBundleVariants
        ? Object.assign(Object.assign({}, yumProduct), { variants: removeBundleOnlyVariants(yumProduct.variants) }) : Object.assign({}, yumProduct);
    // destructure the Yum metadata for the CC product
    const { availability: productAvailability, blackout: outOfStock = false, defaultVariantCode, description, images, legalText, metafields, name, privateMetafields = [], productCode, variants, } = product;
    const lineUpVariantCodes = getLineupItemsVariantCodes([product]);
    const isLineup = variants.every((variant) => lineUpVariantCodes.includes(variant.variantCode));
    const isLineupCYO = isLineup && LineupNameOverridesMap[productCode];
    const transformName = isLineupCYO ? LineupNameOverridesMap[productCode] : name;
    // get the option keys for this product
    const crustKey = isMelt ? OptionKeys.meltCrust : OptionKeys.pizzaCrust;
    const sizeKey = isMelt ? OptionKeys.meltSize : OptionKeys.pizzaSize;
    // get all sizes and crusts from the options
    const allCrusts = ((_a = allOptions.find((option) => option.id === crustKey)) === null || _a === void 0 ? void 0 : _a.modifiers) || [];
    const allSizes = ((_b = allOptions.find((option) => option.id === sizeKey)) === null || _b === void 0 ? void 0 : _b.modifiers) || [];
    // get all of the slot codes of the incoming slots
    const allSlotCodes = new Set(allSlots.map((slot) => slot.id));
    // storage for the ids for the crusts, sizes and slots that participate in the product's variants
    // the crustIds will be used so that only crusts that participate in the products variants will be included
    // under the sizes for this product
    const crustIds = new Set();
    // the sizeIds will be used so that only sizes that participate in the products variants will be included
    // under the options for this product
    const sizeIds = new Set();
    // the slotIds will be used so that only slots that participate in the products variants AND
    // only slots that have not been filtered out by private metafields will be included under the options for this product
    const slotIds = new Set();
    // storage for the modifier codes that are excluded from each of the product's variants
    // the excluded variants will be added to each modifier if its code is in the map
    const modifierWeightsExcludedByVariant = new Map();
    // storage for the option value codes for each of the product's variants
    // the option value codes are used when building the nutrition object for each modifier
    const variantOptionValues = new Map();
    // storage for all of the prices of modifiers by weight
    // the price will be added to each modifier
    const weightPrices = new Map();
    // storage for the weight price ranges by slot
    // these will be added to the crusts later, and are be used by the computeProductPrice function
    const variantSlotPriceRanges = new Map();
    // store slot optionRequired boolean
    const variantSlotOptionRequired = new Map();
    // determine which variants to use for building the pizza product
    // if a promotion definition is present, the variants will be futher filtered
    const productVariants = promotionDefinition
        ? variants.filter((variant) => includeVariantForPromotionDefinition(productCode, promotionDefinition, variant))
        : variants;
    // iterate over the product's variants to populate all of the storage objects declared above
    productVariants.forEach((variant) => {
        var _a, _b, _c, _d;
        const { selectedOptionValues, slots, variantCode } = variant;
        // get the crustId and sizeId from the option values on this variant
        const crustId = (_b = (_a = selectedOptionValues.find((option) => option.optionTypeCode === crustKey)) === null || _a === void 0 ? void 0 : _a.optionValueCode) !== null && _b !== void 0 ? _b : '';
        const sizeId = (_d = (_c = selectedOptionValues.find((option) => option.optionTypeCode === sizeKey)) === null || _c === void 0 ? void 0 : _c.optionValueCode) !== null && _d !== void 0 ? _d : '';
        // add the ids to the storage objects declared above
        crustIds.add(crustId);
        sizeIds.add(sizeId);
        // declare an object to store the weight price ranges for each slot on the variant
        let priceRanges = {};
        // iterate over the slots
        slots.forEach(({ minModifierWeight, modifiers, slotCode, weightPriceRanges }) => {
            // only process this slot if the slotCode is in the incoming slots
            // (they could have been filtered out by metafields)
            if (allSlotCodes.has(slotCode)) {
                // add the slot code to the storage declared above
                slotIds.add(slotCode);
                variantSlotOptionRequired.set(slotCode, !!minModifierWeight);
                // if this slot has weight price ranges on it, then add them to the priceRanges object declared above
                if (weightPriceRanges.length > 0) {
                    priceRanges = Object.assign(Object.assign({}, priceRanges), { [slotCode]: weightPriceRanges });
                }
                // if the slot does not have weight price ranges, add any upcharge for the modifiers on the slot
                // to the storage declared above, these prices will be added to the modifier later
                if (!weightPriceRanges.length) {
                    modifiers.forEach((modifier) => {
                        weightPrices.set(modifier.modifierCode, new Map());
                        modifier.weights.forEach((weight) => {
                            var _a, _b, _c;
                            const weightMap = (_a = weightPrices.get(modifier.modifierCode)) !== null && _a !== void 0 ? _a : new Map();
                            weightMap.set(weight.modifierWeightCode, (_c = (_b = weight.price) === null || _b === void 0 ? void 0 : _b.amount) !== null && _c !== void 0 ? _c : 0);
                        });
                    });
                }
                modifiers.forEach((modifier) => {
                    const { modifierCode } = modifier;
                    const excludedWeights = modifier.weights
                        .filter((weight) => weight.isExcludedFromVariant)
                        .map((weight) => weight.modifierWeightCode);
                    // for each modifier on this slot, if a weight is excluded from this variant,
                    // then add the variant code to the storage declared above
                    excludedWeights.forEach((weightCode) => {
                        var _a, _b;
                        const excludedVariantsByWeight = (_a = modifierWeightsExcludedByVariant.get(modifierCode)) !== null && _a !== void 0 ? _a : new Map();
                        const excludedVariants = (_b = excludedVariantsByWeight.get(weightCode)) !== null && _b !== void 0 ? _b : [];
                        excludedVariants.push(variantCode);
                        excludedVariantsByWeight.set(weightCode, excludedVariants);
                        modifierWeightsExcludedByVariant.set(modifierCode, excludedVariantsByWeight);
                    });
                });
            }
        });
        // add the price ranges for this variant's slots to the storage declared above
        variantSlotPriceRanges.set(variantCode, priceRanges);
        // add the option value codes for this variant to the storage declared above
        variantOptionValues.set(variantCode, selectedOptionValues.map((option) => option.optionValueCode));
    });
    // get the size modifier group from the incoming allOptions
    const sizeModifierGroup = allOptions.find((option) => option.id === sizeKey);
    // create the selectedOptions array, it will hold all of the default modifiers for the default variant
    // and the selected flag will be set for each one
    const selectedOptions = [];
    // get the default variant for the product, if there is not one use the first variant
    // in the product's variants
    const defaultVariant = (_c = productVariants.find((variant) => variant.variantCode === defaultVariantCode)) !== null && _c !== void 0 ? _c : productVariants[0];
    // determine if default variant is gluten free
    const isGlutenFree = defaultVariant === null || defaultVariant === void 0 ? void 0 : defaultVariant.selectedOptionValues.some((option) => option.optionTypeCode === OptionKeys.pizzaCrust && GlutenFreeCodes.has(option.optionValueCode));
    // destructure the default modifiers and selected option values from the default variant
    // and guard against the default variant being undefined
    const { defaultModifiers = [], selectedOptionValues = [] } = Object.assign({}, defaultVariant);
    // get all of the ids for the default modifiers
    const defaultModifierIds = defaultModifiers.map((modifier) => modifier.modifierCode);
    // get the default crust id and size id from the default variant's selected option values
    const defaultCrustId = (_e = (_d = selectedOptionValues.find((option) => option.optionTypeCode === crustKey)) === null || _d === void 0 ? void 0 : _d.optionValueCode) !== null && _e !== void 0 ? _e : '';
    const defaultSizeId = (_g = (_f = selectedOptionValues.find((option) => option.optionTypeCode === sizeKey)) === null || _f === void 0 ? void 0 : _f.optionValueCode) !== null && _g !== void 0 ? _g : '';
    // filter all of the crusts that participate in the variants from all crusts, using the storage declared above
    const crusts = sortByOutOfStock(allCrusts
        .filter((modifier) => crustIds.has(modifier.id))
        .map((crust) => {
        return Object.assign(Object.assign({}, crust), { selected: crust.id === defaultCrustId, subtype: 'crusts' });
    }) || []);
    // filter all of the sizes that participate in the variants from all sizes, using the storage declared above
    // and create a temporary object containing the participating sizes indexed by the id of the size
    const sizes = allSizes
        .filter((modifier) => sizeIds.has(modifier.id))
        .reduce((acc, size) => {
        return Object.assign(Object.assign({}, acc), { [size.id]: Object.assign(Object.assign({}, size), { modifiers: [], selected: size.id === defaultSizeId, subtype: 'sizes' }) });
    }, {}) || {};
    // iterate over the variants again, adding the crust for each variant to the modifiers array of the size
    productVariants.forEach((variant) => {
        var _a, _b, _c, _d, _e, _f, _g;
        // get all of the metadata needed that will be added to the crust that is built from this variant
        const { blackout = false, metafields: variantMetafields, nutritionInformation, price: { amount }, privateMetafields: variantPrivateMetafields = [], selectedOptionValues: variantSelectedOptionValues, slots, variantCode, } = variant;
        // get the crust and size modifier that comprise this variant
        const crustId = (_b = (_a = variantSelectedOptionValues.find((option) => option.optionTypeCode === crustKey)) === null || _a === void 0 ? void 0 : _a.optionValueCode) !== null && _b !== void 0 ? _b : '';
        const sizeId = (_d = (_c = variantSelectedOptionValues.find((option) => option.optionTypeCode === sizeKey)) === null || _c === void 0 ? void 0 : _c.optionValueCode) !== null && _d !== void 0 ? _d : '';
        const crust = crusts.find((modifier) => modifier.id === crustId);
        const size = sizes[sizeId];
        // get the max modifier weight from the toppings slot on this variant,
        // it will become the maxAllowed on the crust
        const maxModifierWeight = (_e = variant.slots.find((slot) => slot.slotCode === SlotKeys.pizzaToppings)) === null || _e === void 0 ? void 0 : _e.maxModifierWeight;
        // determine if this variant has a sodium warning
        const variantSodiumWarning = metafieldsHaveSodiumWarning(variantPrivateMetafields);
        // add the crust to the size's modifiers and add the variant code and price to the crust,
        // but only if we have a size and crust for this variant
        if (size && crust) {
            // build the nutrition object for this variant and a user friendly display name of the nutrition
            const nutrition = nutritionInformation.map((n) => (Object.assign(Object.assign({}, n), { qualifiers: [crustId, sizeId, variantCode] })));
            const [crustNutrition] = nutrition;
            const { servings = '', unit = '' } = Object.assign({}, crustNutrition);
            let sliceCount = '';
            if (servings.length && unit.length) {
                sliceCount = `${servings} ${unit}s`;
            }
            // get any upcharges for this variant from the slots that participate
            // this will be used in the computeProductPRice function
            const upcharges = slots.filter((slot) => slotIds.has(slot.slotCode));
            // add this crust to the size, spreading in all of the metadata
            (_f = size.modifiers) === null || _f === void 0 ? void 0 : _f.push(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, crust), { maxAllowed: maxModifierWeight, metafields: variantMetafields, nutrition, outOfStock: blackout, price: amount, privateMetafields: variantPrivateMetafields, selected: crust.id === defaultCrustId && size.selected }), (sliceCount.length > 0 && { sliceCount })), { slotPriceRanges: variantSlotPriceRanges.get(variantCode), subtype: 'crusts' }), (variantSodiumWarning !== undefined && { sodiumWarning: variantSodiumWarning })), { upcharge: (_g = variantUpcharges[variantCode]) !== null && _g !== void 0 ? _g : 0, upcharges,
                variantCode }));
        }
    });
    // build prices for sizes and crust
    // we get the price for each size by finding the lowest price of crusts
    // we get the price of the crust by subtracting the price of the size from the price of the crust
    // example: crust = 2199, size = 1899
    // 2199 - 1899 = 300
    const sizeKeys = Object.keys(sizes);
    sizeKeys.forEach((size) => {
        var _a, _b, _c;
        const prices = (_b = (_a = sizes[size].modifiers) === null || _a === void 0 ? void 0 : _a.map((mod) => { var _a; return (_a = mod.price) !== null && _a !== void 0 ? _a : 0; })) !== null && _b !== void 0 ? _b : [0];
        // this is the lowest price of crusts
        const sizePrice = Math.min(...prices);
        sizes[size].price = sizePrice;
        (_c = sizes[size].modifiers) === null || _c === void 0 ? void 0 : _c.forEach((crust) => {
            const crustPrice = crust.price ? crust.price - sizePrice : 0;
            // if a crustPrice is 0 and we have an upcharge, we know it's a deal
            // and set the price equal to the upcharge on the deal
            if (crustPrice === 0 && crust.upcharge) {
                crust.price = crust.upcharge;
            }
            else {
                crust.price = crustPrice;
            }
        });
    });
    // filter all of the slots that participate in the variants from all slots
    const filteredSlots = allSlots.filter((modifier) => slotIds.has(modifier.id)) || [];
    // map over the slots, building all of the modifier groups that will live under options for this product
    // there are specific cases for cheeses, sauces and toppings
    // the selected flag will be set to true for all of the default modifiers on the default variant
    const slots = filteredSlots.reduce((acc, slot) => {
        var _a, _b;
        const isSelected = (defaultModifier, id, weight) => {
            const selected = defaultModifierIds.includes(id) && (defaultModifier === null || defaultModifier === void 0 ? void 0 : defaultModifier.modifierWeightCode) === weight;
            return selected;
        };
        // get the id and modifiers from the slot
        const { id, modifiers: slotModifiers = [], slotCode = '' } = slot;
        const optionRequired = !!variantSlotOptionRequired.get(slotCode);
        // add nutrition to the modifiers
        const modifiers = slotModifiers.map((modifier) => {
            const nutrition = transformYumNutrition(modifier, variantOptionValues);
            const { nutritionInformation } = modifier, rest = __rest(modifier, ["nutritionInformation"]);
            return Object.assign(Object.assign({}, rest), { nutrition });
        });
        if (id === SlotKeys.pizzaCheese || id === SlotKeys.meltCheese) {
            // Yum menu treats cheese differently than PH. Cheese has a single id, and the weightCode is
            // used to specify none/light/regular/extra. Whereas in PH, each weighted cheese has a separate id.
            // We are recreating that cheese group structure here so FEs do not break
            const [cheese] = modifiers;
            const defaultCheese = defaultModifiers.find((modifier) => modifier.slotCode === id);
            // get the excluded variants map for this modifier
            const excludedVariants = Object.fromEntries((_a = modifierWeightsExcludedByVariant.get(cheese.id)) !== null && _a !== void 0 ? _a : new Map());
            const cheeseGroup = {
                displayOrder: 0,
                id,
                modifiers: [],
                name: 'Cheeses',
                type: 'GROUP',
            };
            const noCheeseGroup = {
                displayOrder: 1,
                id,
                modifiers: [],
                name: 'No Cheese',
                type: 'GROUP',
            };
            const cheeseModifiers = [];
            const noCheeseModifiers = [];
            const prices = (_b = weightPrices.get(cheese.id)) !== null && _b !== void 0 ? _b : new Map();
            const regularCheese = Object.assign(Object.assign(Object.assign(Object.assign({}, cheese), { displayOrder: 0 }), (Object.keys(excludedVariants).length > 0 && { excludedVariants })), { name: 'Regular Cheese', placement: Placement.WHOLE, portion: Portion.REGULAR, price: prices.has(WeightKeys.regularCheese) ? prices.get(WeightKeys.regularCheese) : 0, selected: isSelected(defaultCheese, cheese.id, WeightKeys.regularCheese), subtype: 'cheeses', weightCode: WeightKeys.regularCheese, weights: [WeightKeys.regularCheese] });
            cheeseModifiers.push(regularCheese);
            const lightCheese = Object.assign(Object.assign(Object.assign(Object.assign({}, cheese), { displayOrder: 1 }), (Object.keys(excludedVariants).length > 0 && { excludedVariants })), { name: 'Light Cheese', placement: Placement.WHOLE, portion: Portion.LIGHT, price: prices.has(WeightKeys.lightCheese) ? prices.get(WeightKeys.lightCheese) : 0, selected: isSelected(defaultCheese, cheese.id, WeightKeys.lightCheese), subtype: 'cheeses', weightCode: WeightKeys.lightCheese, weights: [WeightKeys.lightCheese] });
            cheeseModifiers.push(lightCheese);
            const extraCheese = Object.assign(Object.assign(Object.assign(Object.assign({}, cheese), { displayOrder: 2 }), (Object.keys(excludedVariants).length > 0 && { excludedVariants })), { name: 'Extra Cheese', placement: Placement.WHOLE, portion: Portion.EXTRA, price: prices.has(WeightKeys.extraCheese) ? prices.get(WeightKeys.extraCheese) : 0, selected: isSelected(defaultCheese, cheese.id, WeightKeys.extraCheese), subtype: 'cheeses', weightCode: WeightKeys.extraCheese, weights: [WeightKeys.extraCheese] });
            cheeseModifiers.push(extraCheese);
            const noCheese = Object.assign(Object.assign(Object.assign(Object.assign({}, cheese), { displayOrder: 0 }), (Object.keys(excludedVariants).length > 0 && { excludedVariants })), { name: 'No Cheese', placement: Placement.WHOLE, portion: Portion.NONE, price: prices.has(WeightKeys.noCheese) ? prices.get(WeightKeys.noCheese) : 0, selected: isSelected(defaultCheese, cheese.id, WeightKeys.noCheese), subtype: 'cheeses', weightCode: WeightKeys.noCheese, weights: [WeightKeys.noCheese] });
            noCheeseModifiers.push(noCheese);
            const allCheeses = [...cheeseModifiers, ...noCheeseModifiers];
            const selectedCheese = allCheeses.find((c) => c.weightCode === (defaultCheese === null || defaultCheese === void 0 ? void 0 : defaultCheese.modifierWeightCode));
            if (selectedCheese) {
                selectedOptions.push(selectedCheese);
            }
            cheeseGroup.modifiers = cheeseModifiers;
            noCheeseGroup.modifiers = noCheeseModifiers;
            acc.push({
                displayOrder: 0,
                id,
                modifiers: [cheeseGroup, noCheeseGroup],
                name: 'Cheeses',
                optionRequired,
                type: 'GROUP',
            });
        }
        else if (id === SlotKeys.pizzaSauce || id === SlotKeys.meltSauce) {
            // Sauces are much like cheeses, each sauces has a single id, and the weightCode is
            // used to specify none/light/extra. Whereas in PH, each weighted sauce has a separate id.
            // We are recreating that sauce group structure here so FEs do not break
            // Get the default sauce modifier
            const defaultSauce = defaultModifiers.find((modifier) => modifier.slotCode === id);
            // build all of the sauce groupings, each weight code on the modifier will become a separate modifier
            const sauceGroups = modifiers
                .map((modifier) => {
                var _a, _b, _c, _d;
                const prices = (_a = weightPrices.get(modifier.id)) !== null && _a !== void 0 ? _a : new Map();
                // get the excluded variants map for this modifier
                const excludedVariants = Object.fromEntries((_b = modifierWeightsExcludedByVariant.get(modifier.id)) !== null && _b !== void 0 ? _b : new Map());
                // classic marinara
                if (modifier.id === ModifierKeys.classicMarinara) {
                    const sauces = (_c = modifier.weights) === null || _c === void 0 ? void 0 : _c.map((weight) => {
                        var _a;
                        const placementPortion = (_a = WeightMap[weight]) !== null && _a !== void 0 ? _a : {
                            placement: Placement.WHOLE,
                            portion: Portion.REGULAR,
                        };
                        return Object.assign(Object.assign(Object.assign(Object.assign({}, modifier), placementPortion), (Object.keys(excludedVariants).length > 0 && { excludedVariants })), { price: prices.has(weight) ? prices.get(weight) : 0, selected: isSelected(defaultSauce, modifier.id, weight), subtype: 'sauces', weightCode: weight, weights: [weight] });
                    }).filter((sauce) => sauce);
                    return Object.assign(Object.assign({}, modifier), { modifiers: sauces, type: 'GROUP' });
                }
                else {
                    const sauceGroup = Object.assign(Object.assign({}, modifier), { modifiers: [], type: 'GROUP' });
                    (_d = modifier.weights) === null || _d === void 0 ? void 0 : _d.forEach((weight) => {
                        var _a, _b;
                        const placementPortion = (_a = WeightMap[weight]) !== null && _a !== void 0 ? _a : {
                            placement: Placement.WHOLE,
                            portion: Portion.REGULAR,
                        };
                        const subtype = 'sauces';
                        const sauceModifier = Object.assign(Object.assign(Object.assign(Object.assign({}, modifier), placementPortion), (Object.keys(excludedVariants).length > 0 && { excludedVariants })), { price: prices.has(weight) ? prices.get(weight) : 0, selected: isSelected(defaultSauce, modifier.id, weight), subtype, weightCode: weight, weights: [weight] });
                        (_b = sauceGroup === null || sauceGroup === void 0 ? void 0 : sauceGroup.modifiers) === null || _b === void 0 ? void 0 : _b.push(sauceModifier);
                    });
                    return sauceGroup;
                }
            })
                .filter((sauce) => { var _a; return (_a = sauce.modifiers) === null || _a === void 0 ? void 0 : _a.length; });
            // Add the selected sauce to the selected options
            const flattenedSauceGroups = sauceGroups.reduce((groupAcc, sauce) => {
                var _a;
                (_a = sauce.modifiers) === null || _a === void 0 ? void 0 : _a.forEach((s) => groupAcc.push(s));
                return groupAcc;
            }, []);
            const selectedSauce = flattenedSauceGroups.find((s) => (s === null || s === void 0 ? void 0 : s.id) === (defaultSauce === null || defaultSauce === void 0 ? void 0 : defaultSauce.modifierCode) && (s === null || s === void 0 ? void 0 : s.weightCode) === (defaultSauce === null || defaultSauce === void 0 ? void 0 : defaultSauce.modifierWeightCode));
            if (selectedSauce) {
                selectedOptions.push(selectedSauce);
            }
            acc.push({
                displayOrder: 0,
                id,
                modifiers: sauceGroups,
                name: 'Sauces',
                optionRequired,
                type: 'GROUP',
            });
        }
        else if (id === SlotKeys.pizzaToppings) {
            // get the ids of the default toppings
            const defaultToppingIds = defaultModifiers
                .filter((modifier) => modifier.slotCode === id)
                .map((modifier) => modifier.modifierCode);
            // separate the toppings into meats and veggies and
            // set the selected flag, placement and portion
            const toppingsByType = modifiers
                .reduce((modAcc, modifier) => {
                var _a, _b, _c;
                // get the excluded variants map for this modifier
                const excludedVariants = Object.fromEntries((_a = modifierWeightsExcludedByVariant.get(modifier.id)) !== null && _a !== void 0 ? _a : new Map());
                const type = (_c = (_b = modifier.privateMetafields) === null || _b === void 0 ? void 0 : _b.find((field) => field.key === 'toppingType')) === null || _c === void 0 ? void 0 : _c.value;
                if ((type === null || type === void 0 ? void 0 : type.toLowerCase()) === 'meats') {
                    modAcc[0].push(Object.assign(Object.assign(Object.assign({}, modifier), (Object.keys(excludedVariants).length > 0 && { excludedVariants })), { subtype: 'meats' }));
                }
                else {
                    modAcc[1].push(Object.assign(Object.assign(Object.assign({}, modifier), (Object.keys(excludedVariants).length > 0 && { excludedVariants })), { subtype: 'veggies' }));
                }
                return modAcc;
            }, [[], []])
                .map((groups) => {
                return groups.map((modifier) => {
                    var _a, _b;
                    // get the excluded variants map for this modifier
                    const excludedVariants = Object.fromEntries((_a = modifierWeightsExcludedByVariant.get(modifier.id)) !== null && _a !== void 0 ? _a : new Map());
                    const prices = (_b = weightPrices.get(modifier.id)) !== null && _b !== void 0 ? _b : new Map();
                    const selected = defaultToppingIds.includes(modifier.id);
                    const m = Object.assign(Object.assign(Object.assign({}, modifier), (Object.keys(excludedVariants).length > 0 && { excludedVariants })), { placement: Placement.WHOLE, portion: Portion.REGULAR, price: prices.has(WeightKeys.wholeRegular) ? prices.get(WeightKeys.wholeRegular) : 0, selected, weightCode: WeightKeys.wholeRegular });
                    if (selected) {
                        selectedOptions.push(m);
                    }
                    return m;
                });
            });
            const [meats, veggies] = toppingsByType;
            const meatGroup = {
                displayOrder: 0,
                id,
                modifiers: sortByOutOfStock(meats),
                name: 'Meats',
                optionRequired,
                type: 'GROUP',
            };
            const veggieGroup = {
                displayOrder: 1,
                id,
                modifiers: sortByOutOfStock(veggies),
                name: 'Veggies',
                optionRequired,
                type: 'GROUP',
            };
            acc.push(meatGroup, veggieGroup);
        }
        else {
            acc.push(Object.assign(Object.assign({}, slot), { displayOrder: 0, modifiers: modifiers.map((modifier) => {
                    const selected = defaultModifierIds.includes(modifier.id);
                    if (selected) {
                        selectedOptions.push(modifier);
                    }
                    return Object.assign(Object.assign({}, modifier), { selected });
                }) }));
        }
        return acc;
    }, []);
    // build the options array for the pizza by concatenating the size group with the slots
    const sizesAndCrusts = Object.assign(Object.assign({}, sizeModifierGroup), { modifiers: Object.values(sizes) });
    const options = [sizesAndCrusts, ...slots];
    // set the default size and crust in the selected options array
    const selectedSize = sizesAndCrusts.modifiers.find((s) => s.id === defaultSizeId);
    const selectedCrust = (_h = selectedSize === null || selectedSize === void 0 ? void 0 : selectedSize.modifiers) === null || _h === void 0 ? void 0 : _h.find((c) => c.id === defaultCrustId);
    // transform the Yum availablility to CC availablility
    const availability = transformYumAvailability(productAvailability === null || productAvailability === void 0 ? void 0 : productAvailability.schedule);
    // check if this product has a sodium warning private metafield
    const sodiumWarning = metafieldsHaveSodiumWarning(privateMetafields);
    const pizza = Object.assign(Object.assign({ availability, description: description !== null && description !== void 0 ? description : '', displayOrder: 0, glutenFree: isGlutenFree, id: productCode, legalText: legalText !== null && legalText !== void 0 ? legalText : '', metafields, name: transformName, options,
        outOfStock, price: (_j = defaultVariant === null || defaultVariant === void 0 ? void 0 : defaultVariant.price.amount) !== null && _j !== void 0 ? _j : 0, privateMetafields, qoId: productCode, selectedOptions: [selectedSize, selectedCrust, ...selectedOptions] }, (sodiumWarning !== undefined && { sodiumWarning })), { type: isMelt ? 'MELT' : 'PIZZA' });
    // add images to the product
    images.forEach((image) => {
        if (image.key) {
            pizza[image.key] = image.url;
        }
    });
    return pizza;
};
export default transformYumProductPizzasAndMelts;
