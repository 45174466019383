var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { EmptyPizzaBuilder, RuleType, } from '../types';
import { applyPizzaBuilderIncludeRules, applyPizzaBuilderRules, computeBuilderQuantity, computeNutrition, computePizzaPrice, decrement, increment, removeCheeseWeightsExcludedByVariant, removeSauceWeightsExcludedByVariant, removeToppingWeightsExcludedByVariant, transformCartItemToPizzaBuilderState, transformPizzaBuilderToCartItem, transformPizzaBuilderToCartLineItemUpdate, transformPizzaBuilderToYumCartItem, } from '../utils';
import { menuApiPizzaBuilder } from '../api/menu/MenuApiPizzaBuilder';
import { orderApiCart } from '../api/order/OrderApiCart';
import { OrderActions } from './OrderSlice';
const initialState = {
    builder: EmptyPizzaBuilder,
    cheeses: [],
    glutenFree: false,
    isCartLoading: false,
    isPriceLoading: false,
    maxToppingsAllowed: null,
    nutrition: '',
    price: 0,
    quantity: 1,
    sauces: [],
    selectedCheese: null,
    selectedCrust: null,
    selectedSauce: null,
    selectedSize: null,
    selectedToppings: [],
    sizesAndCrusts: [],
    specialInstructions: '',
    toppings: [],
    type: 'PIZZA',
};
export const addPizzaToCart = createAsyncThunk('pizzaBuilder/addToCart', (_, { dispatch, rejectWithValue, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { pizzaBuilder, order: { cart }, coreConfig: { isYumEcomm }, } = state;
    const cartPayload = isYumEcomm
        ? transformPizzaBuilderToYumCartItem(state.pizzaBuilder)
        : transformPizzaBuilderToCartItem(state);
    try {
        const data = yield dispatch(orderApiCart.endpoints.addBuilderItemToCart.initiate({ cartItem: { item: cartPayload.items[0] } }));
        if ('error' in data)
            return rejectWithValue(data.error);
        const oldCartIds = (cart === null || cart === void 0 ? void 0 : cart.items.map((item) => item.cartItemId)) || [];
        const [newCartItem] = data.data.items.filter((item) => !oldCartIds.includes(item.cartItemId));
        let cartItemId = newCartItem === null || newCartItem === void 0 ? void 0 : newCartItem.cartItemId;
        let quantity = pizzaBuilder.quantity;
        if (!cartItemId) {
            const { cartItemId: id, quantity: quant } = computeBuilderQuantity(cart === null || cart === void 0 ? void 0 : cart.items, data.data.items);
            cartItemId = id;
            quantity = quant;
        }
        dispatch(OrderActions.setPizzaHistory({
            cartItemId,
            state: Object.assign(Object.assign({}, pizzaBuilder), { isCartLoading: false, isPriceLoading: false, quantity }),
        }));
        return data;
    }
    catch (err) {
        return rejectWithValue(err.message);
    }
}));
export const editPizza = createAsyncThunk('pizzaBuilder/editPizza', (cartItem, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const state = getState();
    const { order: { cart, pizzaHistory }, } = state;
    const { cartItemId, id } = cartItem;
    if (pizzaHistory[cartItemId]) {
        return pizzaHistory[cartItemId];
    }
    const payload = { itemId: id, storeNumber: (_a = cart === null || cart === void 0 ? void 0 : cart.storeNumber) !== null && _a !== void 0 ? _a : '' };
    const { data: pizzaBuilder } = yield dispatch(menuApiPizzaBuilder.endpoints.getPizzaBuilder.initiate(payload));
    const pizzaBuilderState = transformCartItemToPizzaBuilderState(cartItem, pizzaBuilder);
    return pizzaBuilderState;
}));
export const updatePizza = createAsyncThunk('pizzaBuilder/updatePizza', (itemId, { dispatch, rejectWithValue, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    let requestBody;
    if (state.coreConfig.isYumEcomm) {
        requestBody = transformPizzaBuilderToCartLineItemUpdate(itemId, state.pizzaBuilder);
    }
    else {
        const cartPayload = transformPizzaBuilderToCartItem(state);
        requestBody = {
            cartId: cartPayload.cartId,
            cartItem: { item: cartPayload.items[0] },
            itemId,
        };
    }
    try {
        const data = yield dispatch(orderApiCart.endpoints.updateCartItem.initiate(requestBody));
        dispatch(OrderActions.setPizzaHistory({
            cartItemId: itemId,
            state: Object.assign(Object.assign({}, state.pizzaBuilder), { isCartLoading: false, isPriceLoading: false }),
        }));
        return data;
    }
    catch (err) {
        return rejectWithValue(err.message);
    }
}));
export const pricePizza = createAsyncThunk('pizzaBuilder/pricePizza', (_, { dispatch, rejectWithValue, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { pizzaBuilder } = state;
    const isYumEcomm = state.coreConfig.isYumEcomm;
    if (isYumEcomm) {
        return computePizzaPrice(pizzaBuilder) * pizzaBuilder.quantity;
    }
    const cartPayload = transformPizzaBuilderToCartItem(state);
    try {
        if (state.pizzaBuilder.selectedSize && state.pizzaBuilder.selectedCrust) {
            const data = (yield dispatch(orderApiCart.endpoints.priceCart.initiate({ cartId: cartPayload.cartId, items: cartPayload.items })));
            return data.data.price;
        }
        else {
            return state.pizzaBuilder.price;
        }
    }
    catch (err) {
        return rejectWithValue(err.message);
    }
}));
export const pizzaBuilderSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(addPizzaToCart.fulfilled, () => {
            return initialState;
        });
        builder.addCase(addPizzaToCart.pending, (state) => {
            state.isCartLoading = true;
        });
        builder.addCase(addPizzaToCart.rejected, (state) => {
            state.isCartLoading = false;
        });
        builder.addCase(updatePizza.fulfilled, () => {
            return initialState;
        });
        builder.addCase(updatePizza.pending, (state) => {
            state.isCartLoading = true;
        });
        builder.addCase(updatePizza.rejected, (state) => {
            state.isCartLoading = false;
        });
        builder.addCase(pricePizza.fulfilled, (state, action) => {
            state.isPriceLoading = false;
            state.price = action.payload;
        });
        builder.addCase(pricePizza.pending, (state) => {
            state.isPriceLoading = true;
        });
        builder.addCase(pricePizza.rejected, (state) => {
            state.isPriceLoading = false;
        });
        builder.addCase(editPizza.fulfilled, (_, action) => {
            return action.payload;
        });
        builder.addCase(editPizza.pending, (state) => {
            state.isCartLoading = true;
        });
        builder.addCase(editPizza.rejected, (state) => {
            state.isCartLoading = false;
        });
    },
    initialState,
    name: 'pizzaBuilder',
    reducers: {
        addTopping: (state, action) => {
            const maxLimitReached = state.selectedToppings.length >= 10;
            const toppingIds = state.selectedToppings.map((topping) => topping.id);
            const toppingIsIncluded = toppingIds.includes(action.payload.id);
            if (maxLimitReached && !toppingIsIncluded) {
                return;
            }
            else if (toppingIsIncluded) {
                const oldToppings = state.selectedToppings;
                const newToppings = oldToppings.reduce((acc, topping) => {
                    if (topping.id === action.payload.id) {
                        acc = [...acc, action.payload];
                    }
                    else {
                        acc = [...acc, topping];
                    }
                    return acc;
                }, []);
                state.selectedToppings = newToppings;
                state.nutrition = computeNutrition(state.selectedSize, state.selectedCrust, state.selectedCheese, state.selectedSauce, newToppings);
            }
            else {
                const newToppings = [...state.selectedToppings, action.payload];
                state.selectedToppings = newToppings;
                state.nutrition = computeNutrition(state.selectedSize, state.selectedCrust, state.selectedCheese, state.selectedSauce, newToppings);
            }
        },
        clearBuilder: () => initialState,
        decrementQuantity: (state) => {
            state.quantity = decrement(state.quantity);
        },
        editDealPizza: (_state, action) => {
            const { cartItemId, state } = action.payload;
            return Object.assign(Object.assign({}, state), { cartItemId });
        },
        incrementQuantity: (state) => {
            state.quantity = increment(state.quantity);
        },
        removeTopping: (state, action) => {
            const removedTopping = state.selectedToppings.filter((topping) => topping.id !== action.payload.id);
            state.selectedToppings = removedTopping;
            state.nutrition = computeNutrition(state.selectedSize, state.selectedCrust, state.selectedCheese, state.selectedSauce, removedTopping);
        },
        resetBuilder: ( /* state: PizzaBuilderState */) => { },
        setCheese: (state, action) => {
            const cheese = action.payload;
            state.selectedCheese = cheese;
            state.nutrition = computeNutrition(state.selectedSize, state.selectedCrust, cheese, state.selectedSauce, state.selectedToppings);
        },
        setCrust: (state, action) => {
            var _a, _b, _c, _d;
            const crust = action.payload;
            state.selectedCrust = crust;
            state.maxToppingsAllowed = (_a = crust === null || crust === void 0 ? void 0 : crust.maxAllowed) !== null && _a !== void 0 ? _a : null;
            state.nutrition = computeNutrition(state.selectedSize, crust, state.selectedCheese, state.selectedSauce, state.selectedToppings);
            // Apply rules based on the selectedCrust, but only if a size has also been selected
            if (state.selectedSize && state.selectedCrust) {
                const modsWithRestrictions = applyPizzaBuilderRules(state.selectedCrust.id, state.selectedSize.id, state.builder);
                Object.keys(modsWithRestrictions).forEach((key) => {
                    const modKey = key;
                    state[modKey] = modsWithRestrictions[modKey];
                });
                // filter out cheese, sauce and topping weights excluded by the crust variant
                state.cheeses = removeCheeseWeightsExcludedByVariant((_b = crust.variantCode) !== null && _b !== void 0 ? _b : '', state.builder);
                state.sauces = removeSauceWeightsExcludedByVariant((_c = crust.variantCode) !== null && _c !== void 0 ? _c : '', state.builder);
                state.toppings = removeToppingWeightsExcludedByVariant((_d = crust.variantCode) !== null && _d !== void 0 ? _d : '', state.builder);
            }
        },
        setPizza: (state, action) => {
            var _a, _b, _c, _d;
            if (action.payload.pizzaBuilder.type !== 'PIZZA' && action.payload.pizzaBuilder.type !== 'MELT')
                return;
            const { includeRules } = action.payload;
            let { pizzaBuilder } = action.payload;
            let { cheeses, sauces, sizesAndCrusts, toppings } = pizzaBuilder;
            // If we have INCLUDE rules, we need to apply those to the available sizesAndCrusts in the pizza builder state
            if (includeRules && (includeRules === null || includeRules === void 0 ? void 0 : includeRules.length) > 0) {
                pizzaBuilder = Object.assign(Object.assign({}, pizzaBuilder), { rules: Object.assign(Object.assign({}, pizzaBuilder.rules), { [RuleType.INCLUDE]: includeRules }) });
                sizesAndCrusts = applyPizzaBuilderIncludeRules(pizzaBuilder, includeRules);
            }
            // Reduce all of the modifiers for each modifier into a single array
            const flatMap = (modifier) => modifier.modifiers || [];
            const selectedCheese = cheeses.flatMap(flatMap).find((cheese) => cheese.selected && !cheese.outOfStock);
            const selectedSauce = sauces.flatMap(flatMap).find((sauce) => sauce.selected && !sauce.outOfStock);
            const selectedSize = sizesAndCrusts.length === 1
                ? sizesAndCrusts[0]
                : sizesAndCrusts.find((size) => size.selected && !size.outOfStock);
            const allCrusts = sizesAndCrusts.flatMap(flatMap);
            const getCrustFromSize = (_a = selectedSize === null || selectedSize === void 0 ? void 0 : selectedSize.modifiers) === null || _a === void 0 ? void 0 : _a.find((crust) => crust.selected && !crust.outOfStock);
            const getSelectedCrust = getCrustFromSize !== null && getCrustFromSize !== void 0 ? getCrustFromSize : allCrusts.find((crust) => (crust === null || crust === void 0 ? void 0 : crust.selected) && !crust.outOfStock);
            const selectedCrust = allCrusts.length === 1 ? allCrusts[0] : getSelectedCrust;
            const selectedToppings = toppings
                .flatMap(flatMap)
                .filter((topping) => topping.selected && !topping.outOfStock);
            // If the pizza builder specifies a size and crust, we need to apply RESTRICT rules
            // The RESTRICT rules affect cheeses, sauces and toppings
            if (selectedCrust && selectedSize) {
                const restrictions = applyPizzaBuilderRules(selectedCrust.id, selectedSize.id, pizzaBuilder);
                cheeses = restrictions.cheeses;
                sauces = restrictions.sauces;
                toppings = restrictions.toppings;
            }
            // Set all of the pizza builder state
            state.builder = pizzaBuilder;
            state.glutenFree = pizzaBuilder.glutenFree;
            state.maxToppingsAllowed = (selectedCrust === null || selectedCrust === void 0 ? void 0 : selectedCrust.maxAllowed) || null;
            state.nutrition = computeNutrition(selectedSize, selectedCrust, selectedCheese, selectedSauce, selectedToppings);
            state.price = pizzaBuilder.price;
            state.quantity = 1;
            state.selectedCheese = selectedCheese || null;
            state.selectedCrust = selectedCrust || null;
            state.selectedSauce = selectedSauce || null;
            state.selectedSize = selectedSize || null;
            state.selectedToppings = selectedToppings;
            state.sizesAndCrusts = sizesAndCrusts;
            // filter out cheese, sauce and topping weights excluded by the selected crust variant
            state.cheeses = removeCheeseWeightsExcludedByVariant((_b = selectedCrust === null || selectedCrust === void 0 ? void 0 : selectedCrust.variantCode) !== null && _b !== void 0 ? _b : '', pizzaBuilder);
            state.sauces = removeSauceWeightsExcludedByVariant((_c = selectedCrust === null || selectedCrust === void 0 ? void 0 : selectedCrust.variantCode) !== null && _c !== void 0 ? _c : '', pizzaBuilder);
            state.toppings = removeToppingWeightsExcludedByVariant((_d = selectedCrust === null || selectedCrust === void 0 ? void 0 : selectedCrust.variantCode) !== null && _d !== void 0 ? _d : '', pizzaBuilder);
        },
        setSauce: (state, action) => {
            const sauce = action.payload;
            state.selectedSauce = sauce;
            state.nutrition = computeNutrition(state.selectedSize, state.selectedCrust, state.selectedCheese, sauce, state.selectedToppings);
        },
        setSize: (state, action) => {
            const size = action.payload;
            state.selectedSize = size;
            state.nutrition = computeNutrition(size, state.selectedCrust, state.selectedCheese, state.selectedSauce, state.selectedToppings);
            // Apply rules based on the selectedSize, but only if a crust has also been selected
            if (state.selectedSize && state.selectedCrust) {
                const modsWithRestrictions = applyPizzaBuilderRules(state.selectedCrust.id, state.selectedSize.id, state.builder);
                Object.keys(modsWithRestrictions).forEach((key) => {
                    const modKey = key;
                    state[modKey] = modsWithRestrictions[modKey];
                });
            }
        },
        setSpecialInstructions: (state, action) => {
            state.specialInstructions = action.payload;
        },
    },
});
const { addTopping, clearBuilder, decrementQuantity, editDealPizza, incrementQuantity, removeTopping, resetBuilder, setCheese, setCrust, setPizza, setSauce, setSize, setSpecialInstructions, } = pizzaBuilderSlice.actions;
export const PizzaBuilderActions = {
    addTopping,
    clearBuilder,
    decrementQuantity,
    editDealPizza,
    incrementQuantity,
    removeTopping,
    resetBuilder,
    setCheese,
    setCrust,
    setPizza,
    setSauce,
    setSize,
    setSpecialInstructions,
};
export const PizzaBuilderReducer = pizzaBuilderSlice.reducer;
