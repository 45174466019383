/** Use these to reference domain level terms instead of using magic strings */
export const PIZZA = 'PIZZA';
export const MELT = 'MELT';
export const PRODUCT = 'PRODUCT';
export const DISCOUNT = 'DISCOUNT';
export const SIZE = 'SIZE';
export const MODIFIER = 'MODIFIER';
export const STEP = 'STEP';
export const VEGGIES = 'Veggies';
export const MEATS = 'Meats';
export const CHEESE = 'Cheeses';
export const SAUCE = 'Sauces';
export const SIZES = 'Sizes';
export const WING = 'wing';

// DATA CONTROL VARS
export const NO_TRANSFORM_TYPES = [MELT];
