import React from 'react';
import { Typography, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { getJoinedText } from '../../common/string-formatter';
import fontStyles from '../../common/fontStyles';
import { mobileStartBreakpoint } from '../../materialUi/theme';

interface FooterSectionHeader {
  sectionName: string;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  heading: {
    ...fontStyles.h2,
    marginTop: '18px',
    marginBottom: '17px',
    letterSpacing: '1px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      ...fontStyles.h2Mobile,
      lineHeight: 1.2
    }
  }
}));

const FooterSectionHeaderComponent = ({ sectionName, className }: FooterSectionHeader) => {
  const classes = useStyles();

  return (
    <Typography
      className={className || classes.heading}
      data-testid={`footer-${getJoinedText(sectionName)}_title-text`}
    >
      {sectionName}
    </Typography>
  );
};

export default FooterSectionHeaderComponent;
