import React from 'react';
import { Grid, Skeleton } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '2px'
  },
  fullWidthContainer: {
    margin: '0 20px',
    maxWidth: '100%'
  },
  header: {
    height: '46px',
    width: '300px',
    maxWidth: '100%',
    margin: '15px 0 8px 0'
  },
  label: {
    height: '28px',
    marginBottom:'-12px',
    maxWidth: '100%'
  },
  input: {
    height: '64px',
    maxWidth: '100%',
    borderRadius: '10px'
  },
  checkboxContainer: {
    display: 'flex',
    gap: '5px'
  },
  checkbox: {
    minWidth: '28px',
    height: '28px',
    borderRadius: '2px'
  },
  recaptcha: {
    margin: '20px 0',
    height: '70px',
    width: '310px',
    borderRadius: '5px'
  },
  button: {
    height: '48px',
    borderRadius: '15px'
  },
  centeredCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '12px',
    gap: '12px'
  }
}));

const YumSignInIframeSkeleton = () => {
  const classes = useStyles();
  return (
    <Grid
      data-testid="signin-iframe-skeleton"
      className={classes.container}
    >
      <Grid item className={classes.fullWidthContainer}>
        <Skeleton animation="wave" variant="text" className={classes.header} />
      </Grid>
      <Grid item className={classes.fullWidthContainer}>
        <Skeleton animation="wave" variant="text" className={classes.label} width="45px" />
        <Skeleton animation="wave" variant="text" className={classes.input} />
      </Grid>
      <Grid item className={classes.fullWidthContainer}>
        <Skeleton animation="wave" variant="text" className={classes.label} width="165px" />
        <Skeleton animation="wave" variant="text" className={classes.input} />
      </Grid>
      <Grid item className={`${classes.checkboxContainer} ${classes.fullWidthContainer}`}>
        <Skeleton animation="wave" variant="rectangular" className={classes.checkbox} />
        <Grid container justifyContent='space-between'>
          <Skeleton animation="wave" variant="text" width="105px"/>
          <Skeleton animation="wave" variant="text" width="120px"/>
        </Grid>
      </Grid>
      <Grid item className={classes.fullWidthContainer}>
        <Skeleton animation="wave" variant="rectangular" className={classes.recaptcha} />
      </Grid>
      <Grid item className={classes.fullWidthContainer}>
        <Skeleton animation="wave" variant="rectangular" className={classes.button} />
      </Grid>
      <Grid item className={`${classes.fullWidthContainer} ${classes.centeredCol}`}>
        <Skeleton animation="wave" variant="text" width="20px"/>
        <Skeleton animation="wave" variant="text" width="120px"/>
      </Grid>
    </Grid>
)};

export default YumSignInIframeSkeleton;
