import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import colors from '@/common/colors';

const useStyles = makeStyles(() => createStyles({
  deliveryFeeAdvisory: {
    backgroundColor: colors.gray100,
    borderRadius: '8px',
    marginBottom: '16px',
    padding: '16px'
  },
  deliveryFeeAdvisoryText: {
    marginLeft: '8px'
  }
}));

export { useStyles };
