import { CurrencyCode, OrderPaymentMethod, } from '../types';
export const transformPlaceOrderPayloadToSubmitCustomerOrderInput = (payload) => {
    // map over each form of payment and create a payment method
    const paymentMethods = payload.formsOfPayment.map((form) => {
        const type = form.type === 'cash' ? OrderPaymentMethod.CASH : OrderPaymentMethod.CARD;
        const amount = form.amount;
        // if the form is creditcard, get the token from the metadata
        let paymentToken = '';
        switch (form.type) {
            case 'creditcard': {
                paymentToken = form.metadata.token;
                break;
            }
            case 'applepay': {
                paymentToken = form.metadata.token;
                break;
            }
            default:
                break;
        }
        return Object.assign(Object.assign({ amount: {
                amount,
                currencyCode: CurrencyCode.USD,
            } }, (type == OrderPaymentMethod.CARD && { paymentToken })), { type });
    });
    const transformedData = {
        agreements: {
            coppaAgreement: true,
        },
        contactless: payload.contactless,
        emailOptin: payload.emailOptin,
        paymentMethods,
        storeNumber: payload.storeNumber,
    };
    if (payload.smsOrderUpdate) {
        const { phone, smsConsent } = payload.smsOrderUpdate;
        transformedData.smsPhoneNumber = phone;
        transformedData.smsOptin = smsConsent;
    }
    return transformedData;
};
// transform v3 response to v2 response shape and default missing values to empty values
export const transformOrderResponse = (response, emailOptin) => {
    return {
        channel: response.channel,
        deliveryAddress: {
            address1: '',
            address2: '',
            address3: '',
            address4: '',
            city: '',
            company: '',
            country: '',
            latitude: '',
            longitude: '',
            postalCode: '',
            state: '',
        },
        emailOptin,
        foodPlacementLocation: '',
        futureOrderTime: '',
        occasionId: response.occasion,
        orderId: response.orderID,
        orderNumber: response.orderNumber,
        orderStatus: response.orderStatus,
        orderTime: response.orderTime,
        phone: '',
        promisedTime: response.promisedTime,
        promisedTimeMax: 0,
        promisedTimeMin: 0,
        storeNumber: response.storeNumber,
        submissionId: response.submissionID,
        susId: response.submissionID,
        vehicleColor: '',
        vehicleDescription: '',
    };
};
