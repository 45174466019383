import {
  CartItem, Product as CCProduct, transformProductToCartItemInput
} from '@pizza-hut-us-development/client-core';
import { useDispatch } from 'react-redux';
import { SerializedError } from '@reduxjs/toolkit';
import { useCartAddItem as useCCCartAddItem } from '@/clientCore/cart/hooks/useCartAddItem';
import { cartErrorModalDetails } from '@/common/Modal/modalDetails';
import { openModal } from '@/localization/actions';

export type AddToCart = (
  product: CCProduct,
  quantity: number,
  onSuccess?: (item: CartItem) => void,
  onError?: (error: SerializedError) => void,
) => Promise<void>;

export function useAddToCart() {
  const dispatch = useDispatch();
  const { handleAddCartItem, isAddingCartItem: isLoading } = useCCCartAddItem();

  const addToCart: AddToCart = async function (product, quantity, onSuccess?, onError?) {
    const payload = transformProductToCartItemInput({ ...product, quantity }, true);
    return handleAddCartItem({
      item: payload,
      onSuccess: (_, cartItem) => onSuccess?.(cartItem),
      onError: (err) => {
        dispatch(openModal(cartErrorModalDetails));
        onError?.(err);
      }
    });
  };

  return {
    addToCart,
    isLoading
  };
}

export default useAddToCart;
