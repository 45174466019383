import { NonSizeKeys, Placement, PlacementMultipliers, Portion, PortionMultipliers, SizeKeys, SlotKeys, } from '../types';
const AllSlotKeys = new Set(Object.values(SlotKeys));
export const computeProductPrice = (selectedOptions) => {
    var _a, _b, _c;
    // get the modifiers that are a Yum option
    const [sizeModifier] = selectedOptions.filter((m) => { var _a; return SizeKeys.has((_a = m.optionTypeCode) !== null && _a !== void 0 ? _a : ''); });
    const [nonSizeModifier] = selectedOptions.filter((m) => { var _a; return NonSizeKeys.has((_a = m.optionTypeCode) !== null && _a !== void 0 ? _a : ''); });
    if (!sizeModifier) {
        throw new Error('size modifier was not provided');
    }
    // sum up the prices of the incoming size and option to get the base price
    let basePrice = ((_a = sizeModifier.price) !== null && _a !== void 0 ? _a : 0) + ((_b = nonSizeModifier === null || nonSizeModifier === void 0 ? void 0 : nonSizeModifier.price) !== null && _b !== void 0 ? _b : 0);
    // get the upcharges for the variant
    // if there is no nonSizeModifier, they are on the size, otherwise they are on the nonSizeModifier
    const upcharges = (_c = nonSizeModifier === null || nonSizeModifier === void 0 ? void 0 : nonSizeModifier.upcharges) !== null && _c !== void 0 ? _c : sizeModifier === null || sizeModifier === void 0 ? void 0 : sizeModifier.upcharges;
    // get all modifiers that are in Yum slots and organize them by slot
    const slotModifiers = selectedOptions.filter((m) => { var _a; return AllSlotKeys.has((_a = m.slotCode) !== null && _a !== void 0 ? _a : ''); });
    const modifiersBySlot = new Map();
    slotModifiers.reduce((acc, modifier) => {
        var _a;
        const { slotCode = '' } = modifier;
        const modifiers = (_a = acc.get(slotCode)) !== null && _a !== void 0 ? _a : [];
        modifiers.push(modifier);
        acc.set(slotCode, modifiers);
        return acc;
    }, modifiersBySlot);
    // iterate over the modifiersBySlot and add any additional upcharges to the base price
    const slotEntries = Array.from(modifiersBySlot.entries());
    const price = slotEntries.reduce((acc, entry) => {
        var _a, _b;
        let priceUpdate = acc;
        const [slotCode, selectedModifiers] = entry;
        const slotUpcharges = upcharges === null || upcharges === void 0 ? void 0 : upcharges.find((u) => u.slotCode === slotCode);
        // if there are no upcharges associated with this slotCode, just return
        if (!slotUpcharges) {
            return priceUpdate;
        }
        // get the modifiers and weightPriceRanges for this slotCode
        const { modifiers, weightPriceRanges } = slotUpcharges;
        // iterate over the selectedModifiers and get any upcharge associated with it
        selectedModifiers.forEach((modifier) => {
            var _a, _b, _c;
            const upcharge = (_a = modifiers
                .find((m) => m.modifierCode)) === null || _a === void 0 ? void 0 : _a.weights.find((w) => w.modifierWeightCode === modifier.weightCode);
            if (!(upcharge === null || upcharge === void 0 ? void 0 : upcharge.isExcludedFromVariant)) {
                priceUpdate += (_c = (_b = upcharge === null || upcharge === void 0 ? void 0 : upcharge.price) === null || _b === void 0 ? void 0 : _b.amount) !== null && _c !== void 0 ? _c : 0;
            }
        });
        // check to see if this slotCode had a weight price range, and if it does compute any upcharges
        if (weightPriceRanges.length > 0) {
            const [range] = weightPriceRanges.slice(-1);
            const modifierCount = selectedModifiers.reduce((count, modifier) => {
                const { placement = Placement.WHOLE, portion = Portion.REGULAR } = modifier;
                const placementMult = PlacementMultipliers[placement];
                const portionMult = PortionMultipliers[portion];
                return count + placementMult * portionMult;
            }, 0);
            const start = (_a = range === null || range === void 0 ? void 0 : range.rangeStart) !== null && _a !== void 0 ? _a : Number.MAX_SAFE_INTEGER;
            if (modifierCount > start) {
                const amount = (_b = range === null || range === void 0 ? void 0 : range.price.amount) !== null && _b !== void 0 ? _b : 0;
                const upcharge = amount * (modifierCount - start);
                priceUpdate += upcharge;
            }
        }
        return priceUpdate;
    }, basePrice);
    return price;
};
export default computeProductPrice;
