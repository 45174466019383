import { removeNulls } from './filterUtilities';
import snakeToCamel from './snakeToCamel';
import { sortByDisplayOrder } from './sortUtilities';
const transformYumCategoriesToCategoryData = (categories) => {
    const categoriesToCamel = snakeToCamel(categories);
    const newCategories = categoriesToCamel
        .filter((cat) => cat.items.length > 0)
        .map((category) => {
        var _a, _b, _c, _d, _e, _f;
        return {
            displayName: (_b = (_a = category.displayName) !== null && _a !== void 0 ? _a : category.name) !== null && _b !== void 0 ? _b : category.categoryCode,
            displayOrder: (_c = category.index) !== null && _c !== void 0 ? _c : null,
            heading: (_d = category.heading) !== null && _d !== void 0 ? _d : null,
            isNational: !!category.isNational,
            legalDisclaimer: (_e = category.legalDisclaimer) !== null && _e !== void 0 ? _e : null,
            link: category.link,
            mainImage: null,
            name: category.displayName,
            subheading: (_f = category.subHeading) !== null && _f !== void 0 ? _f : null,
        };
    })
        .sort(sortByDisplayOrder);
    return removeNulls(newCategories);
};
export default transformYumCategoriesToCategoryData;
