import { uniqBy } from 'remeda';
import {
  CrustOption,
  PizzaIngredientOption,
  PizzaOptions,
  Pizza,
  PizzaIngredient
} from '../../dataTransformers/builderTypes';

// #Crust selectors
const getGlobalId = (id: string) => id?.split('/').pop();

const getUniqCrusts = (crusts: PizzaOptions['crusts']): PizzaOptions['crusts'] => (crusts ? uniqBy(crusts, (crust) => crust.name) : []);

const selectPizzaCrust = (pizza: Pizza): PizzaIngredient | null => pizza.crust;

const hasSameCrustOptions = (pizza: Pizza): boolean => {
  const crusts = pizza.pizzaOptions?.crusts;
  if (crusts && crusts.length > 1) {
    const names = crusts?.map((crust) => crust.name);
    return names?.every((n) => n === names[0]);
  }
  return false;
};

const selectCrustOptionBySizeId = (
  crustOptions: CrustOption[] | undefined,
  sizeId: PizzaIngredientOption['id']
): CrustOption[] => crustOptions
  ?.filter((crust) => crust.sizeId === sizeId)
  || [];

const selectCrustOptionByName = (
  crustOptions: CrustOption[] | undefined,
  selectedSize: PizzaIngredientOption,
  selectedCrust: CrustOption
): CrustOption | undefined => selectCrustOptionBySizeId(crustOptions, selectedSize?.id)?.find(
  (crust) => crust.name === selectedCrust?.name
);

const selectCrustOptionsByName = (
  crusts: CrustOption[] | undefined,
  selectedCrust: CrustOption | PizzaIngredient | undefined | null
): CrustOption[] => crusts
  ?.filter((crust) => crust.name === selectedCrust?.name) || [];

const selectCrustOptions = (
  { pizzaOptions, size }: Pizza,
  sizeId?: string
): CrustOption[] | undefined => {
  const selectedSizeID = sizeId || size?.id;

  return selectedSizeID
    ? selectCrustOptionBySizeId(pizzaOptions?.crusts, selectedSizeID)
    : getUniqCrusts(pizzaOptions?.crusts);
};

const selectPizzaCrustById = (
  crusts: CrustOption[],
  crustId: string
): CrustOption | undefined => crusts.find(({ id }) => id?.includes(crustId));

const selectPizzaCrustGlobalId = (pizza: Pizza): string | undefined => getGlobalId(pizza?.crust?.id || '');

const selectPizzaCrustOption = (pizza: Pizza): CrustOption | undefined => {
  const pizzaCrustOptions = pizza.size
    && selectCrustOptionBySizeId(pizza.pizzaOptions?.crusts, pizza.size.id);

  if (pizzaCrustOptions) {
    return selectPizzaCrustById(
      pizzaCrustOptions,
      selectPizzaCrust(pizza)?.id || ''
    );
  }
  return undefined;
};

function selectCrustOptionForSize(
  pizzaOptions: PizzaOptions | undefined,
  selectedSize: PizzaIngredientOption | PizzaIngredient | null,
  selectedCrust: CrustOption | PizzaIngredient | undefined
): CrustOption {
  const pizza = { pizzaOptions } as Pizza;
  const allCrustForSelectedSize = selectCrustOptions(pizza, selectedSize?.id) || [];
  const [addCrustOption] = selectCrustOptionsByName(allCrustForSelectedSize, selectedCrust);

  if (allCrustForSelectedSize.length === 1) {
    return allCrustForSelectedSize[0];
  }

  return addCrustOption || null;
}

export {
  hasSameCrustOptions,
  getUniqCrusts,
  selectPizzaCrustGlobalId,
  selectPizzaCrust,
  selectPizzaCrustOption,
  selectPizzaCrustById,
  selectCrustOptions,
  selectCrustOptionForSize,
  selectCrustOptionsByName,
  selectCrustOptionByName,
  selectCrustOptionBySizeId
};
