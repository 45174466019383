import { hideProductsAPI } from './hideProducts';
import { contentfulApi } from '@/api/reduxToolkit/contentfulData';
import { newProductsAPI } from '@/api/reduxToolkit/newProducts';

export default {
  reducers: {
    [hideProductsAPI.reducerPath]: hideProductsAPI.reducer,
    [contentfulApi.reducerPath]: contentfulApi.reducer,
    [newProductsAPI.reducerPath]: newProductsAPI.reducer
  },

  middlewares: [
    ...(hideProductsAPI.middleware ? [hideProductsAPI.middleware] : []),
    ...(contentfulApi.middleware ? [contentfulApi.middleware] : []),
    ...(newProductsAPI.middleware ? [newProductsAPI.middleware] : [])
  ]
};
