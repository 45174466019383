import { PopperPlacementType } from '@mui/material';
import React, { MutableRefObject, useRef } from 'react';
import useWindowSize from '../../common/useWindowSize';
import { breakPointsValues, mobileStartBreakpoint } from '../../materialUi/theme';
import MobileDrawer from './mobileDrawer';
import useStyles from '../styles';
import { Anchor } from '../headerTypes';
import DesktopPopper from './desktopPopper';

interface DropdownProps {
  items: JSX.Element[];
  gridRef: MutableRefObject<any>;
  buttonRef: MutableRefObject<any>;
  open: boolean;
  handleClose: () => void;
  testId: string;
  placement: PopperPlacementType;
  width?: string;
  anchor?: Anchor;
}

const Dropdown = ({
  items,
  gridRef,
  buttonRef,
  placement,
  open,
  handleClose,
  testId,
  width,
  anchor = 'left'
}: DropdownProps) => {
  const classes = useStyles();
  const categoryListRef = useRef(null);
  const windowSize = useWindowSize();
  const isMobile = windowSize.width && windowSize.width < breakPointsValues[mobileStartBreakpoint];

  return (
    isMobile
      ? (
        <MobileDrawer
          open={open}
          classes={classes}
          anchor={anchor}
          testId={testId}
          items={items}
        />
      )
      : (
        <DesktopPopper
          open={open}
          classes={classes}
          handleClose={handleClose}
          buttonRef={buttonRef}
          width={width}
          placement={placement}
          categoryListRef={categoryListRef}
          gridRef={gridRef}
          testId={testId}
          items={items}
        />
      )
  );
};

export default Dropdown;
