import React from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import { Grid } from '@mui/material';

import NavigationFooter, { NavigationFooterSection } from './NavigationFooter';
import MobileAppSocialMediaFooter from './mobileAppAndSocialMedia';
import LegalDisclaimerFooter from './LegalDisclaimerFooter';
import useStyles from './styles';
import {
  getNavigationFooterList,
  getMobileAppAndSocialMediaList,
  getLegalDisclaimerList
} from './gqlDataTransformer';
import { handleLogout } from '@/common/logout/actions';
import { toggleRail } from '@/rail/slices/Rail.slice';
import { RootState } from '@/rootStateTypes';
import { RailType } from '@/rail/slices/Rail.slice.types';
import useFooter from '@/footer/hooks/useFooter';
import { localizationSelectors } from "@/localization/localizationSelectors";
import { setIsAuthenticated } from '@/../optimizely/utils/attributeHelpers';
import { actions as profileActions } from '@/account/profile/profile.slice';
import authService from '@/services/authService';
import telemetry from '@/telemetry';
import { onSignInSignOutClick } from '@/dataAnalytics/dataAnalyticsHelper';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';

interface FooterProps {
  signedIn: boolean;
  criticalGqlErrors: boolean;
  handleLogout: (localizationToken: string | undefined) => void;
}

const Footer = ({
  signedIn, criticalGqlErrors, handleLogout
}: FooterProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const localizationToken = useSelector(localizationSelectors.localizationToken);
  const {
    data,
    loading,
    error
  } = useFooter();

  if (loading || !data || criticalGqlErrors || error || !data?.categories?.length) {
    return null;
  }

  const handleToggleRail = () => dispatch(toggleRail(RailType.SIGN_IN));

  const logoutYum = async () => {
    try {
      await authService.logoutYum();
      handleLogoutHelper();
    } catch (err) {
      const error = err instanceof Error ? err : new Error(typeof err === 'string' ? err : 'Unknown error');
      telemetry.addNoticeError(error);
    }
  }

  const handleLogoutHelper = () => {
    dispatch(handleLogout(localizationToken));
    dispatch(profileActions.logout());
    setIsAuthenticated(false);
    analytics.push(() => onSignInSignOutClick('Sign Out'));
  }

  const navigationSections: NavigationFooterSection[] = getNavigationFooterList(
    data, signedIn, logoutYum, handleToggleRail
  );
  const mobileAppAndSocialMediaSections = getMobileAppAndSocialMediaList(data);
  const legalDisclaimerSections = getLegalDisclaimerList(data);

  return (
    <Grid id="footer" className={classes.footerRoot}>
      <Grid
        container
        className={classes.footerContainer}
        data-testid="footer-container"
      >
        <Grid item xs={12} md={4}>
          <MobileAppSocialMediaFooter {...mobileAppAndSocialMediaSections} />
        </Grid>
        <Grid item xs={12} md={1} />
        <Grid item xs={12} md={7} className={classes.navigationFooter}>
          <NavigationFooter sections={navigationSections} />
        </Grid>
      </Grid>
      <LegalDisclaimerFooter {...legalDisclaimerSections} />
    </Grid>
  );
};

const mapStateToProps = (state: RootState) => {
  const {
    headerError,
    heroError,
    sidekickError
  } = state.presentational.gqlErrors;

  const signedIn = state.presentational.header.customerDetails !== null;
  const criticalGqlErrors = headerError && heroError && sidekickError;

  return {
    signedIn,
    criticalGqlErrors
  };
};

const mapDispatchToProps = { handleLogout };

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
