import { Modifier, Placement, Portion } from '@pizza-hut-us-development/client-core';
import {
  PizzaIngredientOption,
  PortionChoice,
  PlacementChoice
} from '@/builders/pizza/dataTransformers/builderTypes';
import checkAvailability from '@/graphql/helpers/checkAvailability';
import { OccasionApi } from '@/localization/constants';
import { Availability } from '@/menu/pizza/pizzaMenuTypes';
import { YUM_PAN_CRUST_ID } from '@/clientCore/temporaryTransformationalHooks/useCCGetPizzaBuilderQuery/constants';

const extractPortionFromWeightCode = (weightCode: string): PortionChoice | undefined => {
  // eslint-disable-next-line no-restricted-syntax
  for (const value of weightCode.split('_')) {
    if (Object.values(Portion).includes(value as Portion)) {
      return value.toLowerCase() as PortionChoice;
    }
  }

  return undefined;
};

const extractPlacementFromWeightCode = (weightCode: string): PlacementChoice | undefined => {
  // eslint-disable-next-line no-restricted-syntax
  for (const value of weightCode.split('_')) {
    if (Object.values(Placement).includes(value as Placement)) {
      return value.toLowerCase() as PlacementChoice;
    }
  }

  return undefined;
};

const getPortion = (modifier: Modifier): PortionChoice | undefined => {
  const { portion, weightCode } = modifier;

  if (portion) {
    return portion.toLowerCase() as PortionChoice;
  }

  if (weightCode) {
    return extractPortionFromWeightCode(weightCode);
  }

  return undefined;
};

const getPlacement = (modifier: Modifier): PlacementChoice | undefined => {
  const { placement, weightCode } = modifier;

  if (placement) {
    return placement.toLowerCase() as PlacementChoice;
  }

  if (weightCode) {
    return extractPlacementFromWeightCode(weightCode);
  }

  return undefined;
};

const transformModifierToPortion = (modifier: Modifier): PizzaIngredientOption => {
  const {
    id,
    name,
    required,
    type,
    price,
    selected,
    displayOrder,
    glutenFree,
    outOfStock,
    maxAllowed,
    splittable,
    description,
    defaultSelectedOption,
    sodiumWarning,
    isPanCrust,
    nutrition,
    imageURL,
    weightCode,
    slotCode
  } = modifier;

  return {
    id,
    name,
    required,
    type: type ?? '',
    price,
    selected: Boolean(selected),
    priority: displayOrder,
    glutenFree,
    outOfStock,
    maxAllowed: maxAllowed || undefined,
    splittable,
    description,
    defaultSelectedOption,
    sodiumWarning,
    isPanCrust,
    nutrition: nutrition ?? null,
    portion: getPortion(modifier),
    placement: getPlacement(modifier),
    image: imageURL,
    slotCode,
    weightCode
  };
};

// Used in Cheeses
export const transfomCCModifiersToPortions = (
  modifiers: Modifier[]
): PizzaIngredientOption[] => {
  const options: PizzaIngredientOption[] = [];

  modifiers.forEach((modifier) => {
    // WEB-3621 - This modifier array can contain null values, which causes things to blow up
    if (!modifier) return;
    if (modifier && modifier.modifiers && modifier.modifiers.length > 0) {
      modifier.modifiers.forEach((subModifier) => {
        subModifier?.weights?.forEach((weightCode) => {
          options.push(transformModifierToPortion({ ...subModifier, weightCode }));
        });
      });
      return;
    }
    options.push(transformModifierToPortion(modifier));
  });

  return options;
};

export const crustBuilder = (crustModifiers: Modifier[], sizeId: string) => crustModifiers.map((mod) => {
  const {
    id,
    name,
    required,
    type,
    price,
    selected,
    displayOrder,
    glutenFree,
    outOfStock,
    maxAllowed,
    splittable,
    description,
    defaultSelectedOption,
    sodiumWarning,
    isPanCrust,
    nutrition,
    imageURL,
    upcharges,
    slotPriceRanges,
    images
  } = mod;

  const image = imageURL ?? images?.[0]?.url;

  return {
    id,
    name,
    required,
    type: type ?? '',
    price,
    selected: Boolean(selected),
    priority: displayOrder,
    glutenFree,
    outOfStock,
    maxAllowed: maxAllowed || undefined,
    splittable,
    description,
    defaultSelectedOption,
    sodiumWarning,
    isPanCrust: isPanCrust ?? id.includes(YUM_PAN_CRUST_ID),
    nutrition: nutrition || null,
    sizeId,
    image,
    variantCode: mod.variantCode,
    upcharges,
    slotPriceRanges
  };
});

const formatModId = (modId: string) => modId.slice(
  modId.lastIndexOf('/groups/'),
  modId.lastIndexOf('/modifiers')
);

export const transformRulesLookupTable = (
  rulesLookupTable: Record<string, PizzaIngredientOption[]>
): Record<string, string> => {
  const keys = Object.keys(rulesLookupTable);
  return keys.reduce((acc, currentKey) => {
    const currentDataset = rulesLookupTable[currentKey];
    return {
      ...acc,
      ...currentDataset.reduce((tempObj, currentMod) => {
        const { id } = currentMod;
        const groupId = id ? formatModId(id) : '';
        return { ...tempObj, [groupId]: currentKey };
      }, {})
    };
  }, {});
};

export const lookupInternalName = (
  modifierId: string,
  internalnameByModifierTable: Record<string, string>
): string => {
  const indexOfModifier = modifierId.indexOf('/modifier');
  const formattedModId = formatModId(modifierId.substring(0, indexOfModifier + 1));

  return internalnameByModifierTable[formattedModId];
};

export const processCCPizzaAvailability = (
  availability: Availability[],
  occasion: OccasionApi,
  storeTimezone: string
) => {
  const item = {
    availability
  };
  const { available, availableInOtherOccasion } = checkAvailability(
    item,
    occasion,
    storeTimezone
  );

  return { available, availableInOtherOccasion };
};
