import makeStyles from '@mui/styles/makeStyles';
import colors from '../../../common/colors';

const expansionPanelStyles = makeStyles(() => ({
  root: {
    padding: '0px',
    display: 'inline-flex',
    minHeight: '1px',
    '&.Mui-expanded': {
      minHeight: '1px'
    }
  },
  expandIconStyle: {
    padding: '0px'
  },
  expandIconSvgStyle: {
    position: 'relative',
    top: '2px',
    width: '20px',
    height: '20px',
    color: colors.gray900
  },
  content: {
    margin: '1px 0px',
    '&.Mui-expanded': {
      margin: 'auto'
    }
  },
  detailRoot: {
    display: 'inline',
    padding: '0px'
  },
  expansionPanelRoot: {
    paddingLeft: '4px',
    position: 'static',
    margin: '0px',
    '&.Mui-expanded': {
      margin: '0px'
    }
  },
  expansionPanelRounded: {
    boxShadow: 'none'
  }
}));

export default expansionPanelStyles;
