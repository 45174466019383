import React, { MouseEvent } from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { StoreTileButtonType } from './constants';
import { OccasionString, Occasion } from '../../constants';
import { storeTileCtaAnalytics } from '@/dataAnalytics/dataAnalyticsHelper';
import TextLinkWithCarat from '../../../common/TextLinkWithCarat';
import { useRouter } from 'next/router';
import Routes from '@/router/routes';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocalizedStore } from '@/localization/selectors';
import { occasionChanged, rememberOccasion, storeChanged, storeReset } from '@/localization/actions';
import { localizationSelectors } from '@/localization/localizationSelectors';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { isEmpty } from '@/utils';

const useStyles = makeStyles({
  flexGrow: {
    flexGrow: 1
  }
});

interface StoreTileCtaProps {
  store: StoreDetail;
  index: number;
  onContinue: (
    storeNumber: string,
    storeToken: string,
    address: DeliveryAddress,
    removeAlcohol: boolean
  ) => void;
  onSwitchOccasion: () => void;
  buttonType: StoreTileButtonType;
  deliveryAddress?: DeliveryAddress;
  occasion: Occasion.DELIVERY | Occasion.CARRYOUT;
}

const StoreTileCta = ({
  store,
  index,
  onContinue,
  onSwitchOccasion,
  buttonType,
  deliveryAddress,
  occasion
}: StoreTileCtaProps) => {
  const classes = useStyles();
  const router = useRouter();
  const dispatch = useDispatch();
  const currentStore = useSelector(selectLocalizedStore);
  const currentOccasion = useSelector(localizationSelectors.occasion);
  const analytics = useAnalytics();

  const getCtaText = () => {
    switch (buttonType) {
      case StoreTileButtonType.CONTINUE:
        return 'Continue';
      case StoreTileButtonType.PREORDER:
        return 'Pre-Order';
      case StoreTileButtonType.SWITCH_OCCASION:
        return `Order ${occasion === Occasion.CARRYOUT ? OccasionString.D : OccasionString.C}`;
      case StoreTileButtonType.DELIVER_WITHOUT_BEER:
        return 'Deliver Without Beer';
      default:
        return '';
    }
  };

  const onClick = (event: MouseEvent) => {
    const isOccasionCarryout = occasion === Occasion.CARRYOUT;
    if (buttonType === StoreTileButtonType.SWITCH_OCCASION) {
      // show delivery form in rail on 'Order Delivery'
      onSwitchOccasion();
    } else {
      const currentRoute = router.pathname;
      const removeAlcohol = buttonType === StoreTileButtonType.DELIVER_WITHOUT_BEER;

      const carryoutStoreAddress = {
        address: store.address,
        city: store.city,
        state: store.state,
        zipcode: store.zipcode
      };

      const occasionSearchAddress = isOccasionCarryout ? carryoutStoreAddress : deliveryAddress;

      onContinue(store.storeNumber, store.storeToken as string, occasionSearchAddress as DeliveryAddress, removeAlcohol);

      if (currentRoute.includes(Routes.CONFIRMATION)) {
        router.push(Routes.HOME)
        dispatch(storeReset());
      }

      if (store.storeNumber !== currentStore?.storeNumber) {
        dispatch(storeChanged());
      }

      if (occasion !== currentOccasion) {
        dispatch(rememberOccasion(occasion));
        dispatch(occasionChanged());
      }
    }

    const occasionString = isOccasionCarryout ? OccasionString.C : OccasionString.D;

    analytics.push(() => storeTileCtaAnalytics(occasionString, getCtaText()));

    event.stopPropagation();
  };

  const ctaText = getCtaText();

  return (
    <Grid className={classes.flexGrow}>
      {!isEmpty(ctaText)
        && (
          <Grid>
            <TextLinkWithCarat
              testId={`store-tile-btn-${index}`}
              ariaLabel={ctaText}
              text={ctaText}
              onClick={onClick}
            />
          </Grid>
        )}
    </Grid>
  );
};

export default StoreTileCta;
