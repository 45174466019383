// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as getConfig from 'next/config';

export type NewProducts = {
  ids: string[];
};

type QuickAddDeals = NewProducts;

export const newProductsAPI = createApi({
  reducerPath: 'rtkq/newProducts',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://storage.googleapis.com' }),
  endpoints: (builder) => ({
    getNewProducts: builder.query<Record<string, boolean>, void>({
      query: () => {
        const { publicRuntimeConfig } = getConfig.default();
        const env = publicRuntimeConfig.DEPLOY_ENVIRONMENT === 'local' ? 'leda' : publicRuntimeConfig.DEPLOY_ENVIRONMENT;
        
        return `phus-web-filter-lists-${env}/newProducts.json`;
      },
      transformResponse: (response: NewProducts) => response.ids?.reduce((acc, id) => {
          acc[id] = true;
          return acc;
        }, {} as Record<string, boolean>) ?? {}
    }),
    getQuickAddDeals: builder.query<Record<string, boolean>, void>({
      query: () => {
        const { publicRuntimeConfig } = getConfig.default();
        const env = publicRuntimeConfig.DEPLOY_ENVIRONMENT === 'local' ? 'leda' : publicRuntimeConfig.DEPLOY_ENVIRONMENT;
        
        return `phus-web-filter-lists-${env}/quickAddDeals.json`;
      },
      transformResponse: (response: QuickAddDeals) => response.ids?.reduce((acc, id) => {
          acc[id] = true;
          return acc;
        }, {} as Record<string, boolean>) ?? {}
    })
  })
});

export const { useGetNewProductsQuery, useGetQuickAddDealsQuery } = newProductsAPI;
