import React from 'react';
import { Typography, Theme, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import AnchorLink from '../common/AnchorLink';
import { getJoinedText } from '../common/string-formatter';
import ResponsiveImageRender from '../common/ResponsiveImageRender';
import fontStyles from '../common/fontStyles';
import { mobileStartBreakpoint } from '../materialUi/theme';
import telemetry from '../telemetry';

interface AppStoreLink {
  appStore: { image: Image; link: LinkContent };
  externalLink: Image;
  classes: { [key: string]: string; };
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  appStoreAnchorLink: {
    ...fontStyles.redTextLink,
    textDecoration: 'none',
    display: 'flex',
    marginTop: '7px'
  },
  appStoreIconContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'center'
  },
  appStoreIcon: {
    height: '13px'
  },
  linkDisplayTextHeading: {
    ...fontStyles.redTextLink,
    textAlign: 'right',
    paddingLeft: '5px',
    paddingRight: '5px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      fontSize: '12px'
    }
  }
}));

const AppStoreLink = ({ appStore, externalLink, classes }: AppStoreLink) => (
  <Grid onClick={telemetry.addCustomEventOnClick('footer-app-store-link-click')}>
    <AnchorLink
      {...appStore.link}
      tabIndex={0}
      testId={`footer-${getJoinedText(appStore.link.linkDisplayText)}-link`}
      className={classes.appStoreAnchorLink}
      dataAnalyticsCategory="global_footer"
      dataAnalyticsAction={appStore.link.linkDisplayText}
    >
      <>
        <ResponsiveImageRender
          testId={`footer-${getJoinedText(appStore.link.linkTitle)}-icon`}
          mobile={appStore.image.mobile}
          desktop={appStore.image.desktop}
          altText="Logo"
          title={appStore.image.title}
          pictureClassName={classes.appStoreIconContainer}
          className={classes.appStoreIcon}
        />
        <Typography
          className={classes.linkDisplayTextHeading}
          data-testid={`footer-${getJoinedText(appStore.link.linkTitle)}-text`}
        >
          {appStore.link.linkDisplayText}
        </Typography>
        <ResponsiveImageRender
          testId={`footer-${getJoinedText(appStore.link.linkTitle)}_outside_link-icon`}
          mobile={externalLink.mobile}
          desktop={externalLink.desktop}
          altText="External link to app store"
          title={externalLink.title}
          pictureClassName={classes.appStoreIconContainer}
          className={classes.appStoreIcon}
        />
      </>
    </AnchorLink>
  </Grid>
);

const AppStoreSection = ({ apple, google, externalLink }: any) => {
  const classes = useStyles();

  return (
    <div>
      <AppStoreLink
        appStore={apple}
        externalLink={externalLink}
        classes={classes}
      />
      <AppStoreLink
        appStore={google}
        externalLink={externalLink}
        classes={classes}
      />
    </div>
  );
};

export default AppStoreSection;
