import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { mobileStartBreakpoint, smallMobileStartBreakpoint } from '@/materialUi/theme';
import borders from '@/common/borders';
import colors from '@/common/colors';

const cartItem = makeStyles((theme) => createStyles({
  root: {
    padding: '19px 0 0 0'
  },
  item: {
    borderBottom: borders.gray400Border,
    margin: '0 16px 0 16px',
    paddingBottom: '26px'
  },
  itemContainer: {
    gap: '10px'
  },
  headerItemContainer: {
    flexWrap: 'nowrap',
    alignItems: 'baseline',
    gap: '10px'
  },
  lastItem: {
    borderBottom: 'none',
    margin: '0 16px 0 16px',
    paddingBottom: '26px'
  },
  titleContainer: {
    display: 'flex'
  },
  title: {
    textTransform: 'none',
    letterSpacing: '0px',
    lineHeight: '21px'
  },
  span: {
    fontSize: '12px',
    lineHeight: '18px'
  },
  quantityItem: {
    flexBasis: 'auto'
  },
  container: {
    width: '50%',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      width: '58%'
    },
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      width: '66%'
    },
    '& > *:first-child': {
      minWidth: 0
    }
  },
  spacing: {
    padding: '0px 0px 0px 6px'
  },
  specialInstructionsSpacing: {
    margin: '6px 0px 10px'
  },
  dealItemSpacing: {
    marginBottom: '16px'
  },
  dealItemsContainer: {
    padding: '16px 8px 0'
  },
  infoIcon: {
    backgroundColor: 'transparent',
    padding: '0 0 0 16px',
    '&:hover': {
      backgroundColor: 'transparent',
      '& svg > g g': {
        fill: '#131313'
      }
    }
  },
  price: {
    whiteSpace: 'nowrap'
  }
}));

const quantityPicker = makeStyles(() => createStyles({
  quantityPicker: {
    display: 'flex'
  },
  button: {
    lineHeight: 1.2,
    fontSize: '20px',
    padding: '0px',
    minWidth: '27px',
    margin: 'auto 0',
    border: `1px solid ${colors.gray400}`,
    color: 'inherit',
    '&:hover': {
      background: 'none'
    }
  },
  quantityButton: {
    display: 'flex',
    justifyContent: 'center',
    lineHeight: 2,
    fontSize: '12px',
    padding: '0px',
    minWidth: '27px',
    border: `1px solid ${colors.gray400}`,
    '&:hover': {
      background: 'none'
    }
  },
  outerDiv: {
    cursor: 'pointer',
    padding: '0px',
    fontSize: '20px',
    minWidth: '27px',
    lineHeight: 1.2,
    margin: '-15px 0px',
    display: 'flex',
    '&:first-of-type button': {
      borderRight: 'none',
      borderTopRightRadius: '0',
      borderBottomRightRadius: '0'
    },
    '&:last-of-type button': {
      borderLeft: 'none',
      borderTopLeftRadius: '0',
      borderBottomLeftRadius: '0'
    }
  },
  defaultCursor: {
    cursor: 'default'
  }
}));

const cartItemSkeleton = makeStyles(createStyles({
  root: {
    padding: '19px 0 0 0'
  },
  item: {
    borderBottom: borders.gray400Border,
    margin: '0 16px 0 16px',
    paddingBottom: '26px'
  }
}));

const styles = { cartItem, quantityPicker, cartItemSkeleton };

export default styles;
