/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { SortedItemPointer } from '../selectors/clientCorePresentational/rail/presentationalRailSelectors';
import { getSortedCartItemIds } from './helpers';

export type CartAlert = {
  displayAlert: boolean;
  quantityChanged: boolean;
  previousPrice?: number;
  currentPrice?: number;
  itemsRemoved: string[];
};

export type OOSAlertType = {
  lineItemId: string;
  name: string;
};

type CartRailTypes = {
  isCartRailOpen: boolean;
  editCartItemId: string | null;
  cartChangedAlert: CartAlert | null;
  sortedItemPointers: SortedItemPointer[];
  oosAlertItemList: OOSAlertType[];
};

type RailTypes = CartRailTypes;

export type PresentationTypes = CartRailTypes;

const initialState: PresentationTypes = {
  isCartRailOpen: false,
  editCartItemId: null,
  cartChangedAlert: null,
  sortedItemPointers: [],
  oosAlertItemList: []
};

export const CartRailSlice = createSlice({
  initialState,
  name: 'Rail',
  reducers: {
    openCartRail: (state: RailTypes) => {
      state.isCartRailOpen = true;
    },
    closeCartRail: (state: RailTypes) => {
      state.isCartRailOpen = false;
    },
    toggleCartRail: (state: RailTypes) => {
      state.isCartRailOpen = !state.isCartRailOpen;
    },
    setEditCartItemId: (state: RailTypes, action) => {
      state.editCartItemId = action.payload;
    },
    clearSortedItemPointers: (state: RailTypes) => {
      state.sortedItemPointers = [];
    },
    addSortedItemPointer: (state: RailTypes, action) => {
      if (getSortedCartItemIds(state.sortedItemPointers).includes(action.payload.cartItemId)) {
        return;
      }
      state.sortedItemPointers = [...state.sortedItemPointers, action.payload];
    },
    removeSortedItemPointer: (state: RailTypes, action) => {
      const indexToRemove = getSortedCartItemIds(state.sortedItemPointers).indexOf(
        action.payload.cartItemId
      );
      state.sortedItemPointers.splice(indexToRemove, 1);
      return state;
    },
    setCartChangedAlert: (
      state: RailTypes,
      action: { payload: CartAlert | null }
    ) => {
      state.cartChangedAlert = action.payload;
    },
    // This is the main functionality to work with.
    setCartItemIdOfSortedPointer: (
      state: RailTypes,
      action: {
        payload: { currentPointer: SortedItemPointer; newCartId: string };
      }
    ) => {
      const { currentPointer, newCartId } = action.payload;
      const newPointer: SortedItemPointer = {
        cartItemId: newCartId,
        itemId: currentPointer.itemId
      };

      const currentIndex = getSortedCartItemIds(state.sortedItemPointers).indexOf(
        action.payload.currentPointer.cartItemId
      );

      if (newCartId) state.sortedItemPointers[currentIndex] = newPointer;
    },
    addOOSAlertItem: (
      state: RailTypes,
      action: { payload: OOSAlertType }
    ) => {
      state.oosAlertItemList.push(action.payload);
    },
    removeOOSAlertItem: (
      state: RailTypes,
      action: { payload: OOSAlertType }
    ) => {
      const indexToRemove = state.oosAlertItemList.findIndex(
        (item) => item.lineItemId === action.payload.lineItemId
      );
      state.oosAlertItemList.splice(indexToRemove, 1);
      return state;
    }
  }
});

export const presentationalRailReducer = CartRailSlice.reducer;
export const {
  openCartRail,
  closeCartRail,
  toggleCartRail,
  setEditCartItemId,
  clearSortedItemPointers,
  setCartChangedAlert,
  addSortedItemPointer,
  removeSortedItemPointer,
  setCartItemIdOfSortedPointer,
  addOOSAlertItem,
  removeOOSAlertItem
} = CartRailSlice.actions;
