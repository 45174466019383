var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { SizeKeys, } from '../types';
import { removeBundleOnlyVariants } from './filterUtilities';
import { includeVariantForPromotionDefinition, isDip, metafieldsHaveSodiumWarning } from './menuUtilities';
import { sortByOutOfStock } from './sortUtilities';
import transformYumAvailability from './transformYumAvailabilty';
import transformYumNutrition from './transformYumNutrition';
export const transformYumProduct = (yumProduct, allOptions, allSlots, removeBundleVariants = true, variantUpcharges = {}, promotionDefinition) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    // filter out bundlesOnly variants if the removeBundleVariants flag is true
    const product = removeBundleVariants
        ? Object.assign(Object.assign({}, yumProduct), { variants: removeBundleOnlyVariants(yumProduct.variants) }) : Object.assign({}, yumProduct);
    // destructure all of the Yum product keys we need to transform
    const { availability: productAvailability, blackout: outOfStock = false, defaultVariantCode, description, images, legalText, metafields, name, options: yumProductOptions, privateMetafields = [], productCode, variants, } = product;
    // if the product is a dip, we build the slot modifiers differently
    const productIsADip = isDip(productCode);
    // get the size key and other option key for this product
    const otherOptionKey = (_a = yumProductOptions.find((o) => !SizeKeys.has(o))) !== null && _a !== void 0 ? _a : '';
    const sizeKey = (_b = yumProductOptions.find((o) => SizeKeys.has(o))) !== null && _b !== void 0 ? _b : '';
    // get all sizes and other options from the options
    const allOtherOptions = ((_c = allOptions.find((option) => option.id === otherOptionKey)) === null || _c === void 0 ? void 0 : _c.modifiers) || [];
    const allSizes = ((_d = allOptions.find((option) => option.id === sizeKey)) === null || _d === void 0 ? void 0 : _d.modifiers) || [];
    // get all of the slot codes of the incoming slots
    const allSlotCodes = new Set(allSlots.map((slot) => slot.id));
    // storage for the ids for the other options, sizes and slots that participate in the product's variants
    // the otherOptionsIds will be used so that only options that participate in the products variants will be included
    // under the sizes for this product
    const otherOptionsIds = new Set();
    // the sizeIds will be used so that only sizes that participate in the products variants will be included
    // under the options for this product
    const sizeIds = new Set();
    // the slotIds will be used so that only slots that participate in the products variants AND
    // only slots that have not been filtered out by private metafields will be included under the options for this product
    const slotIds = new Set();
    // storage for the modifier codes that are excluded from each of the product's variants
    // the excluded variants will be added to each modifier if its code is in the map
    const modifierWeightsExcludedByVariant = new Map();
    // storage for the option value codes for each of the product's variants
    // the option value codes are used when building the nutrition object for each modifier
    const variantOptionValues = new Map();
    // storage for the weight price ranges by slot
    // these will be added to the crusts later, and are be used by the computeProductPrice function
    const variantSlotPriceRanges = new Map();
    // store slot optionRequired boolean
    const variantSlotOptionRequired = new Map();
    // determine which variants to use for building this product
    // these will be added to the size or other options later, and are used by the computeProductPrice function
    const productVariants = promotionDefinition
        ? variants.filter((variant) => includeVariantForPromotionDefinition(productCode, promotionDefinition, variant))
        : variants;
    // iterate over the product's variants to populate all of the storage objects declared above
    productVariants.forEach((variant) => {
        var _a, _b, _c, _d;
        const { selectedOptionValues, slots, variantCode } = variant;
        // get the id of the other option and the sizeId
        const otherId = (_b = (_a = selectedOptionValues.find((option) => option.optionTypeCode === otherOptionKey)) === null || _a === void 0 ? void 0 : _a.optionValueCode) !== null && _b !== void 0 ? _b : '';
        const sizeId = (_d = (_c = selectedOptionValues.find((option) => option.optionTypeCode === sizeKey)) === null || _c === void 0 ? void 0 : _c.optionValueCode) !== null && _d !== void 0 ? _d : '';
        // add the ids to the storage declared above
        sizeIds.add(sizeId);
        otherOptionsIds.add(otherId);
        // declare an object to store the weight price ranges for each slot on the variant
        let priceRanges = {};
        // iterate over the slots
        slots.forEach(({ minModifierWeight, modifiers, slotCode, weightPriceRanges }) => {
            if (!variantSlotOptionRequired.get(slotCode) && slotCode) {
                variantSlotOptionRequired.set(slotCode, !!minModifierWeight);
            }
            // only process this slot if the slotCode is in the incoming slots
            // (they could have been filtered out by metafields)
            if (allSlotCodes.has(slotCode)) {
                // add the slot code to the storage declared above
                slotIds.add(slotCode);
                // if this slot has weight price ranges on it, then add them to the priceRanges object declared above
                if (weightPriceRanges.length > 0) {
                    priceRanges = Object.assign(Object.assign({}, priceRanges), { [slotCode]: weightPriceRanges });
                }
                modifiers.forEach((modifier) => {
                    const { modifierCode } = modifier;
                    const excludedWeights = modifier.weights
                        .filter((weight) => weight.isExcludedFromVariant)
                        .map((weight) => weight.modifierWeightCode);
                    // for each modifier on this slot, if a weight is excluded from this variant,
                    // then add the variant code to the storage declared above
                    excludedWeights.forEach((weightCode) => {
                        var _a, _b;
                        const excludedVariantsByWeight = (_a = modifierWeightsExcludedByVariant.get(modifierCode)) !== null && _a !== void 0 ? _a : new Map();
                        const excludedVariants = (_b = excludedVariantsByWeight.get(weightCode)) !== null && _b !== void 0 ? _b : [];
                        excludedVariants.push(variantCode);
                        excludedVariantsByWeight.set(weightCode, excludedVariants);
                        modifierWeightsExcludedByVariant.set(modifierCode, excludedVariantsByWeight);
                    });
                });
            }
        });
        // add the price ranges for this variant's slots to the storage declared above
        variantSlotPriceRanges.set(variantCode, priceRanges);
        // add the option value codes for this variant to the storage declared above
        variantOptionValues.set(variantCode, selectedOptionValues.map((option) => option.optionValueCode));
    });
    // get the size group option
    const sizeModifierGroup = allOptions.find((option) => option.id === sizeKey);
    const sizeModifierGroupClone = allOptions.find((option) => option.id === sizeKey);
    // create the selectedOptions array it will hold all of the default modifiers for the default variant
    // and the selected flag will be set for each one
    const selectedOptions = [];
    // get the default variant, selected modifiers and selected option values
    const defaultVariant = (_e = productVariants.find((variant) => variant.variantCode === defaultVariantCode)) !== null && _e !== void 0 ? _e : productVariants[0];
    // destructure the default modifiers and selected option values
    // and guard against the default variant being undefined
    const { defaultModifiers = [], selectedOptionValues = [] } = Object.assign({}, defaultVariant);
    // get all of the ids for the default modifiers
    const defaultModifierIds = defaultModifiers.map((modifier) => modifier.modifierCode);
    // get the default other option and size for the default variant
    const defaultOtherOptionId = (_g = (_f = selectedOptionValues.find((option) => option.optionTypeCode === otherOptionKey)) === null || _f === void 0 ? void 0 : _f.optionValueCode) !== null && _g !== void 0 ? _g : '';
    const defaultSizeId = (_j = (_h = selectedOptionValues.find((option) => option.optionTypeCode === sizeKey)) === null || _h === void 0 ? void 0 : _h.optionValueCode) !== null && _j !== void 0 ? _j : '';
    // filter all of the non-size options that participate in the variants from all options
    const otherOptions = sortByOutOfStock(allOtherOptions
        .filter((option) => otherOptionsIds.has(option.id))
        .map((option) => {
        return Object.assign(Object.assign({}, option), { selected: option.id === defaultOtherOptionId, subtype: 'flavors' });
    }) || []);
    // filter all of the sizes that participate in the variants from all sizes
    // and create a temporary object containing the participating sizes indexed by the id of the size
    const sizes = allSizes
        .filter((modifier) => sizeIds.has(modifier.id))
        .reduce((acc, size) => {
        return Object.assign(Object.assign({}, acc), { [size.id]: Object.assign(Object.assign({}, size), { modifiers: [], selected: size.id === defaultSizeId, subtype: 'sizes' }) });
    }, {}) || {};
    // if we have an other option type on the variants on this product
    // we need to nest them under the sizes for this product
    if (otherOptionKey) {
        // iterate over the variants again, adding the other option for each variant to the modifiers array of the size
        productVariants.forEach((variant) => {
            var _a, _b, _c, _d, _e, _f;
            const { blackout = false, metafields: variantMetafields, nutritionInformation, price: { amount }, privateMetafields: variantPrivateMetafields, selectedOptionValues: variantSelectedOptionValues = [], slots, variantCode, } = variant;
            const otherOptionId = (_b = (_a = variantSelectedOptionValues.find((option) => option.optionTypeCode === otherOptionKey)) === null || _a === void 0 ? void 0 : _a.optionValueCode) !== null && _b !== void 0 ? _b : '';
            const sizeId = (_d = (_c = variantSelectedOptionValues.find((option) => option.optionTypeCode === sizeKey)) === null || _c === void 0 ? void 0 : _c.optionValueCode) !== null && _d !== void 0 ? _d : '';
            const otherOption = otherOptions.find((modifier) => modifier.id === otherOptionId);
            const size = sizes[sizeId];
            const variantSodiumWarning = metafieldsHaveSodiumWarning(variantPrivateMetafields);
            // add the option to the size's modifiers and add the variant code to the option
            if (size && otherOption) {
                const nutrition = nutritionInformation.map((n) => (Object.assign(Object.assign({}, n), { qualifiers: [otherOptionId, sizeId, variantCode] })));
                const upcharges = slots.filter((slot) => slotIds.has(slot.slotCode));
                (_e = size.modifiers) === null || _e === void 0 ? void 0 : _e.push(Object.assign(Object.assign(Object.assign(Object.assign({}, otherOption), { metafields: variantMetafields, nutrition, outOfStock: blackout, price: amount, privateMetafields: variantPrivateMetafields, slotPriceRanges: variantSlotPriceRanges.get(variantCode) }), (variantSodiumWarning !== undefined && { sodiumWarning: variantSodiumWarning })), { subtype: 'other', upcharge: (_f = variantUpcharges[variantCode]) !== null && _f !== void 0 ? _f : 0, upcharges,
                    variantCode }));
            }
        });
    }
    else {
        // iterate over the variants again, adding the sizes to the modifiers array of the size group
        productVariants.forEach((variant) => {
            var _a, _b, _c;
            const { blackout = false, metafields: variantMetafields, nutritionInformation, price: { amount }, privateMetafields: variantPrivateMetafields, selectedOptionValues: variantSelectedOptionValues, slots, variantCode, } = variant;
            const sizeId = (_b = (_a = variantSelectedOptionValues.find((option) => option.optionTypeCode === sizeKey)) === null || _a === void 0 ? void 0 : _a.optionValueCode) !== null && _b !== void 0 ? _b : '';
            const size = sizes[sizeId];
            const variantSodiumWarning = metafieldsHaveSodiumWarning(variantPrivateMetafields);
            // add the flavor to the size's modifiers and add the variant code and nutrition to the flavor
            if (size) {
                const nutrition = nutritionInformation.map((n) => (Object.assign(Object.assign({}, n), { qualifiers: [sizeId, variantCode] })));
                const upcharges = slots.filter((slot) => slotIds.has(slot.slotCode));
                const newSize = Object.assign(Object.assign(Object.assign(Object.assign({}, size), { metafields: variantMetafields, nutrition, outOfStock: blackout, price: amount, privateMetafields: variantPrivateMetafields, slotPriceRanges: variantSlotPriceRanges.get(variantCode) }), (variantSodiumWarning !== undefined && { sodiumWarning: variantSodiumWarning })), { subtype: 'sizes', upcharge: (_c = variantUpcharges[variantCode]) !== null && _c !== void 0 ? _c : 0, upcharges,
                    variantCode });
                sizes[sizeId] = newSize;
            }
        });
    }
    // filter all of the slots that participate in the variants from all slots
    const filteredSlots = allSlots.filter((modifier) => slotIds.has(modifier.id)) || [];
    // map over the slots, building all of the modifier groups that will live under options for this product
    // the selected flag will be set to true for all of the default modifiers on the default variant
    const slots = filteredSlots.reduce((acc, slot) => {
        // get the id and modifiers from the slot
        let { modifiers: slotModifiers = [], slotCode = '' } = slot;
        // if this is a dip product, we only want the modifiers from the slot that are on the variant
        if (productIsADip) {
            const [variant] = productVariants;
            const { slots: dipSlots } = variant;
            const [dipSlot] = dipSlots;
            const modifierCodes = dipSlot.modifiers.map((m) => m.modifierCode);
            slotModifiers = slotModifiers.filter((m) => modifierCodes.includes(m.id));
        }
        // add nutrition to the modifiers
        const modifiers = slotModifiers.map((modifier) => {
            const nutrition = transformYumNutrition(modifier, variantOptionValues);
            const { nutritionInformation } = modifier, rest = __rest(modifier, ["nutritionInformation"]);
            if (productIsADip) {
                sizeModifierGroupClone.nutrition = nutrition;
            }
            return Object.assign(Object.assign({}, rest), { nutrition });
        });
        const newSlot = Object.assign(Object.assign({}, slot), { modifiers: [], optionRequired: !!variantSlotOptionRequired.get(slotCode) });
        modifiers.forEach((modifier) => {
            var _a, _b, _c;
            const selected = defaultModifierIds.includes(modifier.id);
            // get the excluded variants map for this modifier
            const excludedVariants = Object.fromEntries((_a = modifierWeightsExcludedByVariant.get(modifier.id)) !== null && _a !== void 0 ? _a : new Map());
            const [weightCode] = (_b = modifier.weights) !== null && _b !== void 0 ? _b : [];
            if (selected) {
                selectedOptions.push(Object.assign(Object.assign(Object.assign({}, modifier), (Object.keys(excludedVariants).length > 0 && { excludedVariants })), { selected, weightCode: weightCode }));
            }
            (_c = newSlot.modifiers) === null || _c === void 0 ? void 0 : _c.push(Object.assign(Object.assign(Object.assign({}, modifier), (Object.keys(excludedVariants).length > 0 && { excludedVariants })), { selected, weightCode: weightCode }));
        });
        acc.push(newSlot);
        return acc;
    }, []);
    // build the options array for the product by concatenating the size group with the slots
    const sizeModifiers = productIsADip
        ? Object.values(sizes).map((size) => (Object.assign(Object.assign({}, size), { nutrition: sizeModifierGroupClone.nutrition })))
        : Object.values(sizes);
    const productSizes = Object.assign(Object.assign({}, sizeModifierGroup), { modifiers: sizeModifiers });
    const options = [productSizes, ...slots];
    // set the default size and crust in the selected options array
    const selectedSize = productSizes.modifiers.find((s) => s.id === defaultSizeId);
    const selectedOtherOption = (_k = selectedSize === null || selectedSize === void 0 ? void 0 : selectedSize.modifiers) === null || _k === void 0 ? void 0 : _k.find((c) => c.id === defaultOtherOptionId);
    // transform the availability
    const availability = transformYumAvailability(productAvailability === null || productAvailability === void 0 ? void 0 : productAvailability.schedule);
    // get the defaultSelectedOption for this product if it exists in its private metafields
    const defaultSelectedOption = privateMetafields.reduce((acc, metafield) => {
        if (metafield.key === 'defaultSelectedOption') {
            acc += metafield.value;
        }
        return acc;
    }, '');
    // build the selectedOptions array
    const optionsSelected = [selectedSize];
    if (selectedOtherOption) {
        optionsSelected.push(selectedOtherOption);
    }
    const sodiumWarning = metafieldsHaveSodiumWarning(privateMetafields);
    const item = Object.assign(Object.assign(Object.assign(Object.assign({ availability }, (defaultSelectedOption && { defaultSelectedOption })), { description: description !== null && description !== void 0 ? description : '', displayOrder: 0, id: productCode, legalText: legalText !== null && legalText !== void 0 ? legalText : '', metafields, name: name, options,
        outOfStock, price: (_l = defaultVariant === null || defaultVariant === void 0 ? void 0 : defaultVariant.price.amount) !== null && _l !== void 0 ? _l : 0, privateMetafields, qoId: productCode, selectedOptions: [...optionsSelected, ...selectedOptions] }), (sodiumWarning !== undefined && { sodiumWarning })), { type: 'PRODUCT' });
    // add images to the product
    images.forEach((image) => {
        if (image.key) {
            item[image.key] = image.url;
        }
    });
    return item;
};
export default transformYumProduct;
