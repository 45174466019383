export enum Occasion {
  CARRYOUT = 'C',
  DELIVERY = 'D',
  UNKNOWN = 'U'
}

export type ValidOccasion = Exclude<Occasion, Occasion.UNKNOWN>;

export enum OccasionString {
  C = 'Carryout',
  D = 'Delivery',
  U = 'Unknown'
}

export type OccasionStringValues = `${OccasionString}`;

export enum OccasionApi {
  C = 'CARRYOUT',
  D = 'DELIVERY',
  U = 'UNKNOWN_OCCASION'
}

export const NATIONAL = 'national';
export const NATIONAL_STORE_ID_V2 = ''; // v2 accepts empty string for national store id
export const NATIONAL_STORE_ID_V3 = '002';
export const NATIONAL_STORE_ID_FULL = '000002';

export const zIndex = {
  LOWER_THAN_GOOGLE_AUTO_COMPLETE: 1049,
  HIGHEST: 9999
};

export enum States {
  'Alabama' = 'AL',
  'Alaska' = 'AK',
  'Arizona' = 'AZ',
  'Arkansas' = 'AR',
  'California' = 'CA',
  'Colorado' = 'CO',
  'Connecticut' = 'CT',
  'Delaware' = 'DE',
  'Florida' = 'FL',
  'Georgia' = 'GA',
  'Hawaii' = 'HI',
  'Idaho' = 'ID',
  'Illinois' = 'IL',
  'Indiana' = 'IN',
  'Iowa' = 'IA',
  'Kansas' = 'KS',
  'Kentucky' = 'KY',
  'Louisiana' = 'LA',
  'Maine' = 'ME',
  'Maryland' = 'MD',
  'Massachusetts' = 'MA',
  'Michigan' = 'MI',
  'Minnesota' = 'MN',
  'Mississippi' = 'MS',
  'Missouri' = 'MO',
  'Montana' = 'MT',
  'Nebraska' = 'NE',
  'Nevada' = 'NV',
  'New Hampshire' = 'NH',
  'New Jersey' = 'NJ',
  'New Mexico' = 'NM',
  'New York' = 'NY',
  'North Carolina' = 'NC',
  'North Dakota' = 'ND',
  'Ohio' = 'OH',
  'Oklahoma' = 'OK',
  'Oregon' = 'OR',
  'Pennsylvania' = 'PA',
  'Rhode Island' = 'RI',
  'South Carolina' = 'SC',
  'South Dakota' = 'SD',
  'Tennessee' = 'TN',
  'Texas' = 'TX',
  'Utah' = 'UT',
  'Vermont' = 'VT',
  'Virginia' = 'VA',
  'Washington' = 'WA',
  'West Virginia' = 'WV',
  'Wisconsin' = 'WI',
  'Wyoming' = 'WY',
  'American Samoa' = 'AS',
  'District of Columbia' = 'DC',
  'Federated States of Micronesia' = 'FM',
  'Guam' = 'GU',
  'Marshall Islands' = 'MH',
  'Northern Mariana Islands' = 'MP',
  'Palau' = 'PW',
  'Puerto Rico' = 'PR',
  'Virgin Islands' = 'VI'
}

export const LOCALIZATION_SERVICE_X_VARIANT = 'localization_service_x_variant';
export const X_VARIANT = 'x-variant';
