import React, { useState } from 'react';
import { Button, CircularProgress, FormControl, Grid, InputLabel, Select } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DiningOccasion } from '@pizza-hut-us-development/client-core';

import LinkButton from '../../common/LinkButton';
import { Occasion } from '../constants';
import fontStyles from '../../common/fontStyles';
import colors from '../../common/colors';
import { ProfileAddress } from '@/domain/Profile';
import useLocalizationRail from '@/clientCore/localization/localizationRail/useLocalizationRail';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';

const styles = makeStyles(() => createStyles({
  label: {
    ...fontStyles.formFieldLabel,
    width: '100%',
    marginBottom: '5px',
    lineHeight: 1.43,
    display: 'inline'
  },
  selectBox: {
    border: `1px solid ${colors.gray900}`,
    borderRadius: '8px',
    padding: '10px',
    display: 'block',
    width: '100%',
    lineHeight: '14px',
    '&:focus': {
      borderRadius: '8px'
    },
    marginBottom: '10px',
    fontSize: '14px'
  },
  selectBoxIcon: {
    top: 'calc(50% - 20px)',
    right: '5px',
    height: '30px',
    width: '30px',
    position: 'absolute',
    pointerEvents: 'none'
  },
  search: {
    paddingTop: '10px'
  }
}));

interface SavedAddressPickerProps {
  savedAddresses: ProfileAddress[];
  switchToFormFields: () => void;
  occasion: Occasion | undefined;
  searchDelivery: (options: DeliveryAddress, isSavedAddress: boolean) => void;
  searchCarryout: ({location, isSavedAddress}: CarryoutSearchByLocation) => void;
  isSearchingIndicator: boolean;
}

const SavedAddressOption = (savedAddress: ProfileAddress) => (
  <option
    key={savedAddress.addressId}
    value={savedAddress.addressId}
  >
    {savedAddress.address1} {savedAddress.name && `(${savedAddress.name})`}
  </option>
);

const SavedAddressPicker = (props: SavedAddressPickerProps) => {
  const classes = styles();
  const { savedAddresses, switchToFormFields, occasion, isSearchingIndicator } = props;

  const savedAddressOptions = savedAddresses
    .filter((address) => address.address1)
    .map(SavedAddressOption);

  const firstSavedAddressId = savedAddresses.length > 0 && savedAddresses[0].addressId;

  const [selectedAddressId, setSelectedAddressId] = useState(firstSavedAddressId);

  const { isLoading, handleStoreSearch } = useLocalizationRail();
  const analytics = useAnalytics();


  const search = () => {
    const address = savedAddresses
      .find((savedAddress) => {
        return selectedAddressId === savedAddress.addressId;
      });

    if (occasion === Occasion.DELIVERY && address) {
      handleStoreSearch({
        occasionId: DiningOccasion.DELIVERY,
        ...address
      },
        analytics,
        true
      );
    } else if (address) {
      handleStoreSearch({
        occasionId: DiningOccasion.CARRYOUT,
        ...address
      },
        analytics,
        true
      );
    }
  };

  const labelText = occasion === Occasion.DELIVERY
    ? 'Saved address'
    : 'Find a store near a saved address';

  return (
    <>
      <InputLabel htmlFor="savedAddressPicker" classes={{ root: classes.label }}>
        {labelText}
      </InputLabel>
      <FormControl style={{ width: '100%' }}>
        <Select
          data-testid="saved-address-selectbox"
          native
          label="Saved Address Picker"
          inputProps={{
            name: 'savedAddress',
            id: 'savedAddressPicker',
            className: classes.selectBox
          }}
          IconComponent={() => (
            <ExpandMoreIcon
              classes={{ root: classes.selectBoxIcon }}
              data-testid="expand-more-icon"
            />
          )}
          onChange={(event: any) => {
            setSelectedAddressId(event.target.value);
          }}
        >
          {savedAddressOptions}
        </Select>
      </FormControl>
      <Grid>
        <LinkButton
          testId="use-new-location-button"
          onClick={switchToFormFields}
        >
          Use a new location
        </LinkButton>
      </Grid>
      <Grid className={classes.search} container item xs={12} alignItems="flex-end">
        <Button data-testid="saved-address-search" type="submit" variant="contained" color="primary" disableRipple fullWidth onClick={search}>
          {isLoading || isSearchingIndicator ? <CircularProgress size={24} /> : 'Search'}
        </Button>
      </Grid>
    </>
  );
};

export default SavedAddressPicker;
