import React from 'react';

import { Button, Grid } from '@mui/material';

type LinkButtonProps = {
  className: string;
  testId?: string;
  ariaLabel: string;
  onClick: () => void;
  children: React.ReactNode;
  isExpandable?: boolean;
  isExpanded?: boolean;
};

const LinkButton = ({
  className,
  testId,
  ariaLabel,
  onClick,
  children,
  isExpandable = false,
  isExpanded = false
} : LinkButtonProps): JSX.Element => (
  <Grid container justifyContent="space-between">
    <Button
      data-testid={testId}
      disableRipple
      className={className}
      onClick={onClick}
      aria-labelledby={ariaLabel}
      aria-expanded={isExpandable ? isExpanded : undefined}
    >
      {children}
    </Button>
  </Grid>
);

export default LinkButton;
