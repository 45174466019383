import React from 'react';
import { Grid, Typography } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Label from '@/common/Label';
import {
  COUPON_BUTTON_ACCORDION_LABEL,
  COUPON_BUTTON_LABEL,
  COUPON_CODE_ID,
  COUPON_CODE_LABEL
} from './constants';
import OutlineTextInput from '@/common/OutlineTextInput';
import styles from './Coupon.styles';
import { CtaButton } from '@/coreComponents/buttons/CtaButton/CtaButton';
import LinkButton from '@/cart/cartRail/LinkButton';
import useCoupon from './hooks/useCoupon';
import { WarningIcon } from '@/clientCore/cart/components/CartRail/common/WarningIcon';

const Coupon = (): JSX.Element => {
  const classes = styles.coupon();

  const [data, handlers] = useCoupon();
  const {
    couponId, errorMessage, isExpanded, yumDisplayableCoupons, couponLoading
  } = data;
  const { applyButtonSubmit, sanitizeAndSetCouponId, setIsExpanded } = handlers;

  return (
    <>
      <div className={classes.borderTop}>
        <LinkButton
          className={classes.link}
          onClick={() => setIsExpanded((prevState) => !prevState)}
          ariaLabel={COUPON_BUTTON_ACCORDION_LABEL}
          isExpandable
          isExpanded={isExpanded}
        >
          {COUPON_BUTTON_ACCORDION_LABEL}
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </LinkButton>
      </div>
      {isExpanded && (
        <>
          <Grid container alignItems="flex-end" className={classes.container}>
            <Grid item xs={12} sm={8}>
              <Label
                htmlFor={COUPON_CODE_ID}
                className={classes.couponFieldLabel}
              >
                <span>{COUPON_CODE_LABEL}</span>
              </Label>
              <OutlineTextInput
                id={COUPON_CODE_ID}
                testId={COUPON_CODE_ID}
                onChange={sanitizeAndSetCouponId}
                value={couponId}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.couponButton}>
              <CtaButton
                labelText={COUPON_BUTTON_LABEL}
                clickHandler={applyButtonSubmit}
                loading={couponLoading}
                color="secondary"
                disabled={false}
              />
            </Grid>
            {errorMessage.length > 0 && (
              <Grid
                container
                alignItems="flex-start"
                className={classes.warningContainer}
              >
                <div className={classes.warningIcon}>
                  <WarningIcon />
                </div>
                <Typography className={classes.warningText}>
                  {errorMessage}
                </Typography>
              </Grid>
            )}
            <Grid>
              {yumDisplayableCoupons}
            </Grid>
          </Grid>
        </>
      )}
      <div className={classes.borderBottom} />
    </>
  );
};

export default Coupon;
