import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Skeleton, Card, CardHeader } from '@mui/material';
import { extraSmallMobileStartBreakpoint, mobileStartBreakpoint } from '../../materialUi/theme';
import { ShownOnDevice } from '../../common/ResponsiveContext';

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: '150px',
    alignSelf: 'stretch',
    display: 'flex',
    boxShadow: '0px 0 6px 0 rgba(0, 0, 0, 0.3)',
    padding: '0 24px',
    borderRadius: '0',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      padding: '0 12px',
      minWidth: '120px'
    },
    [theme.breakpoints.down(extraSmallMobileStartBreakpoint)]: {
      minWidth: '70px'
    }
  },
  header: {
    padding: '0px',
    width: '100%',
    '& .MuiCardHeader-avatar': {
      marginRight: '5px'
    }
  }
}));

const ProfileSkeleton = (): JSX.Element => {
  const classes = useStyles();
  const avatar = (<Skeleton animation="wave" variant="circular" width={35} height={35} />);
  const title = (
    <ShownOnDevice xsUp>
      <Skeleton animation="wave" height={13} width="100%" />
      <Skeleton animation="wave" height={13} width="70%" />
    </ShownOnDevice>
  );

  return (
    <Card className={classes.card} data-testid="profile-skeleton">
      <CardHeader className={classes.header} avatar={avatar} title={title} />
    </Card>
  );
};

export default ProfileSkeleton;
