import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import fontStyles from '../common/fontStyles';
import colors from '../common/colors';
import {
  mobileStartBreakpoint,
  extraSmallMobileStartBreakpoint
} from '../materialUi/theme';
import { zIndex } from '../localization/constants';
import boxShadows from '../common/boxShadows';
import borders from '../common/borders';
import transitions from '../common/transitions';

export default makeStyles((theme: Theme) => createStyles({
  /* GRIDS */
  root: {
    backgroundColor: colors.white,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.3)',
    position: 'relative',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      position: 'sticky'
    },
    zIndex: zIndex.LOWER_THAN_GOOGLE_AUTO_COMPLETE - 1
  },
  containerGrid: {
    ...fontStyles.centeredContainerWrapper,
    ...fontStyles.centeredContainer,
    height: '70px',
    margin: 'auto',
    zIndex: 1,
    alignItems: 'center',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      ...fontStyles.centeredContainerMobile,
      height: '50px'
    },
    overflow: 'hidden'
  },
  menuItems: {
    flex: 13,
    display: 'flex',
    height: 'inherit',
    alignItems: 'center'
  },
  profileWrapper: {
    alignSelf: 'stretch',
    display: 'flex',
    boxShadow: '0px 0 6px 0 rgba(0, 0, 0, 0.3)',
    padding: '0 24px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      padding: '0 12px',
      boxShadow: '0px -1px 4px 0 rgba(0, 0, 0, 0.3)'
    }
  },
  profileGlow: {
    boxShadow: '0px 0 6px 0 rgba(0, 0, 0, 0.3)',
    animationName: '$glow',
    animationIterationCount: '5',
    animationDuration: '2200ms',
    animationTimingFunction: 'ease-out'
  },
  '@keyframes glow': {
    '0%, 5%, 95%, 100%': {
      boxShadow: '0px 0 6px 0 rgba(0, 0, 0, 0.3)'
    },
    '45%, 65%': {
      boxShadow: `0px 0 8px ${colors.red}`
    }
  },
  accountSection: {
    marginLeft: '10px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      marginLeft: '5px',
      display: 'contents'
    }
  },
  menuItem: {
    marginLeft: '24px',
    display: 'flex',
    height: 'inherit',
    alignItems: 'center',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      marginLeft: '12px',
      '&:first-child': {
        marginLeft: '8px'
      }
    }
  },
  menuLink: {
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    padding: '6px 16px',
    alignItems: 'center',
    ...fontStyles.headerSubNavLinks,
    '&:hover': {
      textDecoration: 'none'
    }
  },
  itemGridFlex: {
    display: 'flex',
    height: 'inherit',
    maxWidth: 'fit-content',
    marginLeft: '15px',
    [theme.breakpoints.down(extraSmallMobileStartBreakpoint)]: {
      marginLeft: '5px'
    }
  },
  profileGrid: {
    display: 'flex',
    alignSelf: 'center'
  },
  profileDropdown: {
    '&&': {
      ...fontStyles.headerComponentLink,
      paddingBottom: '4px',
      borderRadius: '0px',
      textDecoration: 'none',
      [theme.breakpoints.down(mobileStartBreakpoint)]: {
        padding: '4px 0 4px 0',
        lineHeight: '20px'
      },
      '&:hover': {
        cursor: 'pointer',
        scrollMarginBottom: '10px',
        backgroundColor: 'transparent',
        boxShadow: boxShadows.redUnderline
      }
    }
  },
  signInProfileLink: {
    ...fontStyles.headerComponentLink,
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: boxShadows.redUnderline,
      scrollMarginBottom: '10px',
      transition: transitions.redUnderline
    },
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '4px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      padding: '4px 0 4px 0'
    }
  },
  signInProfileGrid: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
  /* LINKS AND TEXTS */
  mainNavLink: {
    ...fontStyles.headerMainNavLink,
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      ...fontStyles.headerMainNavLinkMobile,
      paddingBottom: '3px',
      paddingTop: '2px'
    }
  },
  menuDropdown: {
    '&&': {
      ...fontStyles.headerMainNavLink,
      padding: '0',
      'text-transform': 'capitalize',
      minWidth: 'fit-content',
      borderRadius: '0',
      [theme.breakpoints.down(mobileStartBreakpoint)]: {
        ...fontStyles.headerMainNavLinkMobile,
        paddingBottom: '3px',
        paddingTop: '2px'
      }
    }
  },
  menuDropdownMobileWrapper: {
    top: '50px',
    width: '85%'
  },
  menuDropdownMobile: {
    borderTop: borders.gray400Border,
    borderBottom: borders.gray400Border
  },
  menuBackdrop: {
    top: '50px'
  },
  menuListWrapper: {
    borderRadius: '0 0 8px 8px',
    boxShadow: '0 6px 7px 0 rgba(0, 0, 0, 0.4)',
    overflow: 'hidden'
  },
  menuList: {
    '&.MuiList-padding': {
      padding: '0px'
    }
  },
  menuListItem: {
    ...fontStyles.headerSubNavLinks,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    textDecoration: 'none',
    padding: 0,
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      fontSize: '12px'
    },
    '&.MuiMenuItem-gutters': {
      height: '46px',
      borderBottom: borders.gray400Border
    },
    '&:last-child': {
      borderBottom: 'none'
    },
    '&:hover, &:focus': {
      backgroundColor: colors.gray200,
      boxShadow: `3px 0px 0px ${colors.red} inset`
    },
    '&:focus': {
      outline: 'none'
    }
  },
  menuListExpandLessItem: {
    height: '31px',
    minHeight: 'auto'
  },
  mobExpandLessIcon: {
    margin: '0 auto'
  },
  hutRewards: {
    ...fontStyles.h6,
    color: colors.red,
    'text-transform': 'uppercase',
    lineHeight: '10px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      display: 'none'
    }
  },
  hutPoints: {
    ...fontStyles.h6,
    color: colors.red,
    'text-transform': 'uppercase',
    lineHeight: '10px',
    letterSpacing: 'normal',
    textAlign: 'left',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      color: colors.gray900,
      'text-transform': 'lowercase',
      lineHeight: '20px',
      fontSize: '12px'
    }
  },
  signInLink: {
    ...fontStyles.headerPersonalizationLink,
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      ...fontStyles.headerPersonalizationLinkMobile,
      lineHeight: '20px'
    }
  },
  accountLink: {
    display: 'flex'
  },
  /* ICONS */
  expandIcon: {
    ...fontStyles.caretIcon,
    margin: 0,
    marginTop: 4,
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      marginLeft: 0,
      marginTop: 2,
      fontSize: '16px'
    }
  },
  profileIcon: {
    height: '28px',
    width: '28px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      height: '16px',
      width: '16px',
      marginRight: '5px'
    }
  },
  caratIcon: {
    ...fontStyles.caretIcon,
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      ...fontStyles.caretIconMobile,
      marginLeft: 0,
      fontSize: '16px'
    }
  },
  localization: {
    flex: 5,
    alignItems: 'center',
    alignSelf: 'stretch',
    display: 'flex',
    justifyContent: 'flex-end',
    flexBasis: '145px'
  },
  localizationDetails: {
    margin: '0 auto',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      display: 'flex',
      alignItems: 'center',
      margin: 'auto 5px'
    }
  },
  localizationPromiseTime: {
    ...fontStyles.h6,
    color: colors.red,
    'text-transform': 'uppercase',
    lineHeight: '10px',
    marginBottom: '4px',
    flex: '0 0 auto',
    display: 'flex',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      fontSize: '10px',
      lineHeight: '20px'
    }
  },
  localizationAddress: {
    ...fontStyles.headerPersonalizationLink,
    lineHeight: '14px',
    alignItems: 'center',
    flex: '0 auto',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      lineHeight: '14px',
      fontSize: '12px',
      borderLeft: borders.gray400Border,
      marginLeft: '8px',
      marginBottom: '6px',
      paddingLeft: '8px'
    }
  },
  desktopWrapper: {
    ...fontStyles.headerComponentLink,
    display: 'flex',
    margin: 'auto 24px auto 5px',
    cursor: 'pointer'
  },
  mobileWrapper: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '100%',
    boxShadow: '0 4px 8px 0 rgba(19, 19, 19, 0.2)',
    height: '36px',
    zIndex: 1000,
    position: 'relative',
    backgroundColor: '#fff'
  },
  localizationDetailsMobile: {
    ...fontStyles.headerComponentLink,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: '2px 0 0 0',
    margin: 'auto 8px'
  },
  caretIcon: {
    ...fontStyles.caretIcon,
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      ...fontStyles.caretIconMobile,
      marginBottom: '8px'
    }
  },
  locationIcon: {
    width: '20px',
    height: '28px',
    marginRight: '5px'
  }
}));
