export const CHECKOUT_HEADER_TEXT = 'Checkout';

export const PHONE_MASK = '(___) ___-____';
export const INVALID_PHONE_NUMBER = 'Invalid phone number';

export const PHONE_ERRORS = {
  required: INVALID_PHONE_NUMBER,
  minLength: INVALID_PHONE_NUMBER
};
export const ORDER_FAILED_MODAL_TITLE = 'Order placement failed';

export const CLEAR_FORM_MODAL_TITLE = 'Personal info cleared';
export const CLEAR_FORM_MODAL_BODY = 'For your security, we cleared your personal information due to idle time. Please re-enter it when you\'re ready to order.';
export const CLEAR_FORM_MODAL_CTA = 'OK';

export const CHANGE_OCCASION_TITLE = 'Change Occasion';
