import { CartItem, Modifier, Product } from '@pizza-hut-us-development/client-core';
import { useCCGetUpsellQuery } from '@/clientCore/temporaryTransformationalHooks/useCCGetUpsellQuery';
import { useCartRail } from '../../../hooks/useCartRail';
import {
  transformOneClickMods
} from '@/clientCore/temporaryTransformationalHooks/useCCGetProducts/helpers/menuProductsData';

type UseOneClickUpsellCartResponse = {
  products: Product[];
  isUpsellQueryLoading: boolean | null | undefined;
};

export type UseOneClickUpsellDecisionVariables = {
  product_id_to_default_size_map: { [key: string]: string };
};

const getOneClickOptionPrice = (product: Product, selectedOptions: (Modifier | undefined)[]) => product.price || selectedOptions?.find((option) => option?.variantCode)?.price;

const getDefaultSelections = (yumProduct: Product) => yumProduct.selectedOptions?.filter(
  (selectedOption) => (selectedOption.slotCode && selectedOption.weightCode) || selectedOption.variantCode
) || [];

function transformProducts(products: Product[]): Product[] {
  return products.map((product) => {
    // Handle Melts Products
    if (product.name?.toLowerCase().includes('melt')) {
      const oneClickSelections = getDefaultSelections(product);
      const transformedOneClickDefaults = transformOneClickMods(oneClickSelections);

      return {
        ...product,
        selectedOptions: transformedOneClickDefaults
      } as Product;
    }

    const oneClickOptionPrice = getOneClickOptionPrice(product, product.selectedOptions);

    return {
      ...product,
      price: oneClickOptionPrice,
      selectedOptions: product.selectedOptions
    } as Product;
  });
}

function getProductsNotInCart(itemsInCart: CartItem[], products: Product[]): Product[] {
  const cartItemsIds = itemsInCart.map((cartItem) => cartItem.id);
  return products.filter(
    (product) => cartItemsIds?.includes(product.id) === false
  );
}

function getPaidProducts(products: Product[]): Product[] {
  return products?.filter((product) => (product.price ?? -1) > 0) ?? [];
}

export const useOneClickUpsellCart = (): UseOneClickUpsellCartResponse => {
  const [{ cart }] = useCartRail();
  const cartItems = cart?.items ?? [];

  const {
    data: upsellProducts,
    isLoading: isUpsellQueryLoading
  } = useCCGetUpsellQuery({ skipTransform: true });

  if (!upsellProducts) {
    return {
      products: [],
      isUpsellQueryLoading
    };
  }

  const productsNotInCart = getProductsNotInCart(cartItems, upsellProducts as Product[]);
  const transformedProducts = transformProducts(productsNotInCart);
  const paidProducts = getPaidProducts(transformedProducts);

  const firstThreeProducts = paidProducts.slice(0, 3);

  return {
    products: firstThreeProducts,
    isUpsellQueryLoading
  };
};
