enum StandaloneEndpoints {
  ENCRYPT_SC_LIST = '/api/encrypt-sc-list',

  CREATE_YUM_GUEST_TOKEN = '/api/create-yum-guest-token',
  CREATE_CC_LOCALIZATION_COOKIE = '/api/create-cc-localization-cookie',

  DELETE_LOCALIZATION_COOKIE = '/api/delete-localization-cookie',

  GET_ACCESS_TOKEN_EXP_IN = '/api/get-access-token-expiration',
  GET_ACCESS_TOKEN_USER_ID = '/api/get-access-token-user-id',

  PREPARE_YUM_GUEST_TO_CUSTOMER = '/api/prepare-yum-guest-to-customer',

  REFRESH_ACCESS_TOKEN = '/api/refresh-yum-access-token',

  REMOVE_YUM_TOKENS = '/api/remove-yum-tokens',

  SAVE_ACCESS_TOKEN = '/api/save-yum-access-token',
  SAVE_LOCALIZATION_TOKEN = '/api/save-localization-token',

  UPDATE_LOCALIZATION_COOKIE = '/api/update-localization-cookie',

  LOGOUT_YUM = '/api/logout-yum',

  RECAPTCHA_VERIFICATION_SERVICE = '/api/verify-gift-card-form-recaptcha'
}

export default StandaloneEndpoints;
