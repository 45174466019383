import getConfig from 'next/config';
import { GraphQLClient } from 'graphql-request';

import { CONTENTFUL_URL, YUM_STOREFRONT_API_URL_PROD, YUM_STOREFRONT_API_URL_STG } from '@noqo/constants';
import { getAuthToken } from './auth';

const { publicRuntimeConfig } = getConfig();
const { GRAPHQL_ENVIRONMENT } = publicRuntimeConfig;

export async function getYumGraphQLClient() {
  return new GraphQLClient(GRAPHQL_ENVIRONMENT === 'production' ? YUM_STOREFRONT_API_URL_PROD : YUM_STOREFRONT_API_URL_STG, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${await getAuthToken()}`
    }
  });
}

export function getContentfulClient() {
  return new GraphQLClient(CONTENTFUL_URL, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer Lr6oGRtv4JkLhJ9nQWUWqqreu5TmOGuSZ7zX9PFpjho'
    }
  });
}
