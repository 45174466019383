export var Categories;
(function (Categories) {
    Categories["DEALS"] = "deals";
    Categories["DESSERTS"] = "desserts";
    Categories["DIPS"] = "dips";
    Categories["DRINKS"] = "drinks";
    Categories["LINEUP"] = "lineup";
    Categories["MELTS"] = "melts";
    Categories["PARTYOFONE"] = "partyofone";
    Categories["PASTA"] = "pasta";
    Categories["PIZZA"] = "pizza";
    Categories["PZONE"] = "pzone";
    Categories["SALADS"] = "salads";
    Categories["SANDWICHES"] = "sandwiches";
    Categories["SIDES"] = "sides";
    Categories["SOUPS"] = "soups";
    Categories["WINGS"] = "wings";
})(Categories || (Categories = {}));
;
export var ItemTypes;
(function (ItemTypes) {
    ItemTypes["STEP"] = "STEP";
    ItemTypes["DISCOUNT"] = "DISCOUNT";
    ItemTypes["GROUP"] = "GROUP";
    ItemTypes["MASTER_DISCOUNT"] = "MASTER_DISCOUNT";
    ItemTypes["MELT"] = "MELT";
    ItemTypes["MODIFIER"] = "MODIFIER";
    ItemTypes["MODIFIER_WEIGHT"] = "MODIFIER_WEIGHT";
    ItemTypes["PIZZA"] = "PIZZA";
    ItemTypes["PRODUCT"] = "PRODUCT";
    ItemTypes["PROMOTION"] = "PROMOTION";
    ItemTypes["SIZE"] = "SIZE";
    ItemTypes["SLOT"] = "SLOT";
    ItemTypes["WINGS"] = "WINGS";
})(ItemTypes || (ItemTypes = {}));
export var LineupNameOverrides;
(function (LineupNameOverrides) {
    LineupNameOverrides["MEDIUM_CYO"] = "Medium 1-Topping Pizza";
})(LineupNameOverrides || (LineupNameOverrides = {}));
export var Placement;
(function (Placement) {
    Placement["LEFT"] = "LEFT";
    Placement["RIGHT"] = "RIGHT";
    Placement["WHOLE"] = "WHOLE";
})(Placement || (Placement = {}));
export var Portion;
(function (Portion) {
    Portion["EXTRA"] = "EXTRA";
    Portion["LIGHT"] = "LIGHT";
    Portion["NONE"] = "NONE";
    Portion["REGULAR"] = "REGULAR";
})(Portion || (Portion = {}));
export var RuleType;
(function (RuleType) {
    RuleType["ADD"] = "ADD";
    RuleType["DELETE"] = "DELETE";
    RuleType["INCLUDE"] = "INCLUDE";
    RuleType["PRICING"] = "PRICING";
    RuleType["RESTRICT"] = "RESTRICT";
})(RuleType || (RuleType = {}));
export var SelectedBy;
(function (SelectedBy) {
    SelectedBy["SYSTEM"] = "SYSTEM";
    SelectedBy["USER"] = "USER";
})(SelectedBy || (SelectedBy = {}));
