import React, { createContext, useContext, useEffect, useState } from "react";
import { getYumGraphQLClient } from "@noqo/utils/graphql-client";


type GraphQLClientContextType = {
  client: Awaited<ReturnType<typeof getYumGraphQLClient>> | null;
  isLoading: boolean;
};

const GraphQLClientContext = createContext<GraphQLClientContextType>({
  client: null,
  isLoading: true
});

export const useGraphQLClient = () => useContext(GraphQLClientContext);

export const GraphQLClientProvider = ({ children }: { children: React.ReactNode }) => {
  const [client, setClient] = useState<Awaited<ReturnType<typeof getYumGraphQLClient>> | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getYumGraphQLClient()
      .then(setClient)
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <GraphQLClientContext.Provider value={{ client, isLoading }}>
      {children}
    </GraphQLClientContext.Provider>
  );
};
