import React from 'react';
import { chunk } from 'remeda';
import { Grid } from '@mui/material';
import {  ShownOnDevice } from '../common/ResponsiveContext';
import { CompactView, ExpandedView } from './footerSection';
import useStyles from './styles';
import { computeComponentKey } from '../common/string-formatter';

const SECTIONS_PER_COLUMN = 2;

export interface NavigationFooterSection {
  sectionName: string;
  links: [Links];
}

const NavigationFooter = ({ sections }: { sections: NavigationFooterSection[] }) => {
  const classes = useStyles();

  return (
    <>
      <ShownOnDevice mdUp>
        <div className={classes.expactedRoot} data-testid='navigation-footer-sections'>
          {chunk(sections, SECTIONS_PER_COLUMN).map(
            // @ts-expect-error - existing code
            (section: [NavigationFooterSection], index: number) => (
              <Grid
                item
                md={4}
                key={`section-${index}`}
                className={classes.footerSectionGrid}
              >
                {section.map(
                  (
                    { sectionName, links }: NavigationFooterSection,
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    index: number
                  ) => (
                    <ExpandedView
                      key={computeComponentKey(sectionName, index)}
                      sectionName={sectionName}
                      links={links}
                      classes={classes}
                    />
                  )
                )}
              </Grid>
            )
          )}
        </div>
      </ShownOnDevice>
      <ShownOnDevice mdDown>
        <>
          {sections.map(
            (
              { sectionName, links }: NavigationFooterSection,
              index: number
            ) => (
              <CompactView
                key={computeComponentKey(sectionName, index)}
                sectionName={sectionName}
                links={links}
                classes={classes}
              />
            )
          )}
        </>
      </ShownOnDevice>
    </>
  );
};

export default NavigationFooter;
