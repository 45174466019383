import { ApplyableGiftCard } from '@/checkout/types';
import { AddableCartItemKinds } from '@/api/phdApiV2Client/request.types';

export interface CartItemModifierWithInternalName extends Omit<CartItemModifier, 'modifiers'> {
  internalName?: string;
  modifiers?: CartItemModifierWithInternalName[];
}

export interface CartItemModifier {
  id: string;
  name: string;
  type: string;
  quantities?: number[];
  modifiers?: CartItemModifier[] | CartItem[] | CartItemModifierWithInternalName[];
  sodiumWarning?: boolean;
  variantCode?: string;
  specialInstructions?: string;
}

export interface CartItem {
  kind: AddableCartItemKinds.CART_ITEM;
  id: string;
  name: string;
  type: string;
  displayablePrice: string;
  quantity: number;
  modifiers: CartItemModifier[];
  description: string;
  sodiumWarning?: boolean;
  combinable?: boolean;
  specialInstructions?: string;
  legalText?: string;
  rewardsRedemptionCode?: number;
  rewardsPoints?: number;
  pizzaModifiers?: CartPizzaModifiers;
  cartItemId?: string;
  variantCode?: string;
}

export interface CartPizzaModifiers {
  sauce: string;
  cheese: string;
  crust: string;
  size: string;
  finisher: string;
  veggies: string;
  meats: string;
}

export interface CartItemModifierInfo extends Pick<CartItem, 'id' | 'type' | 'name' | 'sodiumWarning'> {
  modifiers: CartItemModifierInfo[];
}

export interface CartItemInfo extends Pick<CartItem, 'id' | 'type' | 'name' | 'sodiumWarning' | 'combinable' | 'legalText' | 'pizzaModifiers'> {
  modifiers: CartItemModifierInfo[];
}

export interface Fee {
  amount: number;
  name: string;
  displayableAmount: string;
}

export const EMPTY_CART = {
  id: '',
  quantity: 0,
  subtotal: 0,
  totalPriceInCents: 0,
  items: [],
  invalidItems: [],
  fees: [],
  tax: '$0.00',
  tip: null,
  percentage: '',
  loading: false,
  giftCards: [],
  discountedAmount: 0,
  lineupCount: 0,
  isLineupReady: true
};

export interface Cart {
  id: string;
  totalPriceInCents: number;
  items: CartItem[];
  invalidItems: CartItem[];
  fees: Fee[];
  quantity: number;
  subtotal: number;
  tax: string;
  tip: number | null;
  percentage?: string | null;
  loading: boolean;
  giftCards: ApplyableGiftCard[];
  discountedAmount: number;
  actionCartError?: ErrorMessage;
  lineupCount: number;
  isLineupReady: boolean;
}

export interface CartUi {
  rail: RailCartUi;
  shouldSaveNewCreditCard: boolean;
}

export interface RailCartUi {
  state: CartRailState;
  cartChangedAlert: CartAlert;
  openCartRailAfterRedirect: boolean;
}

export interface CartAlert {
  displayAlert: boolean;
  quantityChanged: boolean;
  previousPrice: number;
  currentPrice: number;
  itemsRemoved: string[]; // W2D3PH-123
}

export enum GiftCardTypes {
  None = 'None',
  ThirdParty = 'ThirdParty',
  Legacy = 'Legacy'
}

export enum GiftCardState {
  VALID = 'Valid',
  NOT_APPLIED = 'Not Applied',
  ALREADY_APPLIED = 'Already Applied',
  ZERO_BALANCE = 'Zero Balance'
}

export enum CartRailState {
  CLOSED = 'Closed',
  OPEN = 'Open',
  OPEN_WITH_ITEM_EDITOR = 'Open with Item editor'
}
