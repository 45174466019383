import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { mobileStartBreakpoint } from '@/materialUi/theme';
import fontStyles from '@/common/fontStyles';

const useOneClickUpsellCartRailRowStyles = makeStyles((theme) => createStyles({
  item: {
    display: 'flex',
    justifyContent: 'Center',
    paddingTop: '12px'
  },
  hutFavorite: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  hutPictureContainer: {
    height: 'inherit'
  },
  hutFavoriteImage: {
    height: '75px',
    width: '75px',
    borderRadius: '50%',
    marginTop: '10px',
    marginRight: '10px'
  },
  hutFavoriteTextContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    paddingLeft: '10px'
  },
  hutFavoriteTitle: {
    width: 'fit-content',
    display: 'flex',
    textAlign: 'left',
    ...fontStyles.productTitle,
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      ...fontStyles.productTitleMobile
    }
  },
  hutFavoriteSubTitle: {
    textAlign: 'left'
  },
  addToCartText: {
    fontWeight: 'bolder'
  },
  plusParent: {
    width: '100%',
    justifyContent: 'flex-start'
  },
  spinnerRow: {
    padding: '2.3em'
  }
}));

export default useOneClickUpsellCartRailRowStyles;
