import {
  CartItem, CartModifier, Placement, Portion
} from '@pizza-hut-us-development/client-core';
import { CartWithLineItems } from '../types';
import { getOrInitializeOptimizely } from '../../../../../../../../../../optimizely/optimizely';

const getModifierString = (modifierGroup: CartModifier): string => {
  if (!modifierGroup.modifiers?.length) {
    return modifierGroup.description?.length
      ? modifierGroup.description
      : modifierGroup.name;
  }

  return modifierGroup.modifiers?.map(getModifierString).join(', ');
};

const parseItemDescription = (item: CartItem, cart?: CartWithLineItems) => {
  if (item.description) return item.description;
  const optimizely = getOrInitializeOptimizely();
  const noSauceMeltFixEnabled = optimizely?.isFeatureEnabled('fr-web-4098-no-sauce-melt-fix');
  const generalDescriptors: string[] = [];
  const wholeToppings: string[] = [];
  const leftToppings: string[] = [];
  const rightToppings: string[] = [];
  // TODO: fix hardcode WEB-2725 pasta for breadsticks modifier. Also WEB-3380
  if (item.id?.includes('CLSS060PS') && item.modifiers?.length === 0) {
    const requiredVariant = (cart?.lineItems ?? []).find(
      (currentLineItem) => currentLineItem.lineItemId === item.cartItemId
    )?.configuredProduct?.variant;

    const hasNoBreadsticks = Boolean(
      (requiredVariant?.selectedOptionValues ?? []).find((option) => option?.optionValue?.name === 'No Breadsticks')
    );
    // TODO: WEB-3380 Remove hardcode of pasta description no breadsticks.
    if (hasNoBreadsticks) {
      generalDescriptors.push('No Breadsticks');
    } else {
      generalDescriptors.push('Breadsticks');
    }
  }

  item.modifiers?.forEach((modifierGroup: CartModifier) => {
    if (
      modifierGroup.name === 'Meats'
      || modifierGroup.name === 'Veggies'
      || modifierGroup.id === 'slot_pizza_toppings'
    ) {
      modifierGroup.modifiers?.forEach((modifier) => {
        const modifierString = `${modifier.name}${modifier.portion === Portion.EXTRA || modifier?.weightCode?.includes(Portion.EXTRA) ? ' (extra)' : ''}`;
        switch (modifier.placement) {
          case Placement.RIGHT:
            rightToppings.push(modifierString);
            break;
          case Placement.LEFT:
            leftToppings.push(modifierString);
            break;
          default:
            wholeToppings.push(modifierString);
            break;
        }
      });
    } else {
      const baseDescription = modifierGroup.name;
      const additionalDescription = modifierGroup.modifiers?.length === 1
        ? getModifierString(modifierGroup.modifiers[0])
        : getModifierString(modifierGroup);
      generalDescriptors.push(
        modifierGroup.type === 'SIZE' && baseDescription !== additionalDescription
          ? `${baseDescription}${additionalDescription.length ? ', ' : ''}${additionalDescription}`
          : additionalDescription ?? baseDescription
      );
    }
  });

  if (
    // Pizza no sauce
    (item.type === 'PIZZA' && !item.modifiers?.find((mod) => mod.id === 'slot_pizza_sauce'))
    // Melt in a deal step, no sauce
    || noSauceMeltFixEnabled && item.modifiers?.[0]?.type === 'MELT' && !item.modifiers?.[0]?.modifiers?.find((mod) => mod.id === 'slot_pizza_melt_sauce')
  ) {
    generalDescriptors.push('No Sauce');
  }

  return `\
      ${generalDescriptors.join(', ')}\
      ${/* eslint-disable-next-line no-nested-ternary */ ''}\
      ${leftToppings.length || rightToppings.length ? '\nWhole: ' : wholeToppings.length ? '\n' : ''}${wholeToppings.join(', ')}\
      ${leftToppings.length ? `\nLeft: ${leftToppings.join(', ')}` : ''}\
      ${rightToppings.length ? `\nRight: ${rightToppings.join(', ')}` : ''}\
    `;
};

export default parseItemDescription;
