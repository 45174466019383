import { CustomerState } from '@pizza-hut-us-development/client-core';
import { compose } from '@reduxjs/toolkit';
import { RootState } from '@pizza-hut-us-development/client-core/ReduxStore';

const domain = (state: RootState): CustomerState => state.customer;

// All Comented out Selectors will not be valid until we fix localization with a pure CC solution

const searchAddress = (customer: CustomerState) => customer.searchAddress;
// const authToken = (customer: CustomerState) => customer.authToken;
// const tokenType = (customer: CustomerState) => customer.tokenType;
// const favorites = (customer: CustomerState) => customer.favorites;
// const isGuest = (customer: CustomerState) => customer.searchAddress;

export const customerSelectors = {
  searchAddress: compose(searchAddress, domain)
  //   authToken: compose(authToken, domain),
  //   tokenType: compose(tokenType, domain),
  //   favorites: compose(favorites, domain),
  //   isGuest: compose(isGuest, domain)
};
