import { metafieldsHaveSodiumWarning } from './menuUtilities';
import { transformStepsWithBlackout } from './transformStepsWithBlackout';
import transformYumAvailability from './transformYumAvailabilty';
export const transformYumBundleToDealProduct = (bundle, bundleChoices, menu) => {
    var _a;
    const { products } = menu;
    const availability = transformYumAvailability((_a = bundle.availability) === null || _a === void 0 ? void 0 : _a.schedule);
    const { blackout: outOfStock = false, bundleCode: id, choices, description, images, legalText = '', metafields, name, price: { amount: price }, privateMetafields = [], redeemableOnline, visibleOnline, } = bundle;
    // create an array of choices that are part of the deal
    const dealBundleChoices = choices.flatMap((choice) => bundleChoices.filter((bundleChoice) => bundleChoice.choiceCode === choice.choiceCode));
    // restructure bundleChoices to have minAllowedSelections, outOfStock, and required values for checking out of stock scenarios
    const stepsWithBlackout = transformStepsWithBlackout(bundle, bundleChoices, products);
    const allStepsOutOfStock = stepsWithBlackout.every((step) => step.outOfStock);
    const anyRequiredStepOutOfStock = stepsWithBlackout.some((step) => step.required && step.outOfStock);
    const sodiumWarning = metafieldsHaveSodiumWarning(privateMetafields);
    // extracts the upcharge data for all the dealBundleChoices
    const upcharges = dealBundleChoices.flatMap((dealBundleChoice) => dealBundleChoice.variants);
    const deal = Object.assign(Object.assign({ availability, description: description || '', displayOrder: 0, displayPrice: `$${(price / 100).toFixed(2)}`, hidden: !visibleOnline, id, isBundle: true, isPromotion: false, legalText,
        metafields,
        name, options: [], outOfStock: allStepsOutOfStock || anyRequiredStepOutOfStock || outOfStock, price,
        privateMetafields, qoId: '', redeemableOnline, selectedOptions: [] }, (sodiumWarning !== undefined && { sodiumWarning })), { type: 'DISCOUNT', upcharges });
    // add images to the product
    images.forEach((image) => {
        if (image.key) {
            deal[image.key] = image.url;
        }
    });
    return deal;
};
export default transformYumBundleToDealProduct;
