import { useDispatch, useSelector } from 'react-redux';
import {
  CartModifier,
  CartItem,
  useUpdateCartItemMutation,
  CartItemUpdate,
  CartModifierInput
} from '@pizza-hut-us-development/client-core';
import { useDecision } from '@optimizely/react-sdk';
import triggerMutationWrapper from '@/clientCore/helper/triggerMutationWrapper';
import { UpdateCartPayload } from '../types';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import { toggleCartLoadingStatus } from '@/clientCore/redux/cart/CartSlice';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { onRailEditLinkClick } from '@/checkout/checkout.analytics';
import { cartItemToLegacy } from '../helpers';
import { removeSortedItemPointer, setCartItemIdOfSortedPointer } from '@/clientCore/redux/rail/CartRailSlice';
import { presentationalRailSelectors } from '@/clientCore/redux/selectors/clientCorePresentational/rail/presentationalRailSelectors';
import { findNewCartItemId } from '@/clientCore/redux/rail/helpers';
import { useCartDeleteItem } from './useDeleteItem';

export const useCartUpdateItem = () => {
  const analytics = useAnalytics();
  const dispatch = useDispatch();
  // CC Selectors
  const cartId = useSelector(orderSelectors.cartId);

  // Update Cart
  const [updateCartItem, { isLoading: isUpdatingProduct }] = useUpdateCartItemMutation();
  const { handleDeleteCartItem } = useCartDeleteItem();
  const [{enabled: cartToastFixEnabled}] = useDecision('fr-web-4557-cart-toast-fix');

  const idToName = (modifier: CartModifier): CartModifierInput => ({
    ...modifier,
    name: modifier.id,
    modifiers: modifier.modifiers?.map(idToName) ?? []
  });

  // This function transforms the nested Yum modifiers to one dimensional modifiers.
  // Where all the nested child modifiers are assigned slot code of the parent modifier.
  const transformModifiers = (modifiers: CartModifier[], slotCode?: string): CartModifierInput[] => modifiers?.flatMap(
    (modifier) => {
      if ((modifier.slotCode) && !modifier.weightCode && modifier.modifiers) {
        return transformModifiers(modifier.modifiers, modifier.slotCode);
      }
      return slotCode
        ? {
          ...idToName(modifier),
          slotCode
        }
        : idToName(modifier);
    }
  );

  const updateQuantity = (
    item: CartItem,
    isIncreasing = true
  ): CartItemUpdate => ({
    ...item,
    name: item.id,
    modifiers: (item.modifiers && transformModifiers(item.modifiers)) ?? [],
    quantity: isIncreasing
      ? (item?.quantity ?? 1) + 1
      : (item?.quantity ?? 1) - 1
  });

  const sortedItemPointers = useSelector(
    presentationalRailSelectors.sortedItemPointers
  );

  const handleUpdateCartItemQuantity = async (
    item: CartItem,
    isIncreasing = true
  ) => {
    analytics.push(() => onRailEditLinkClick('Change Quantity', [cartItemToLegacy(item)]));
    dispatch(toggleCartLoadingStatus({ loadingState: true }));

    if (item.quantity === 1 && !isIncreasing) {
      if(cartToastFixEnabled) {
        handleDeleteCartItem(item);
      } else {
        handleDeleteCartItem(item, () => {
          dispatch(removeSortedItemPointer({ cartItemId: item.cartItemId }));
        });
      }

    } else {
      await triggerMutationWrapper(
        updateCartItem({
          cartId,
          item: updateQuantity(item, isIncreasing),
          itemId: item.cartItemId
        }),
        {
          onSuccess: (response) => {
            const foundNewCartItemId = findNewCartItemId(sortedItemPointers, response.items);
            if (foundNewCartItemId) {
              dispatch(
                setCartItemIdOfSortedPointer({
                  currentPointer: {
                    cartItemId: item.cartItemId,
                    itemId: item.id
                  },
                  newCartId: foundNewCartItemId
                })
              );
            }
          },
          onError: (e) => {
            // TODO: Do we have some existing error modal we dispatch?
            console.error('Issue with updating cart', e);
          }
        }
      ).finally(() => dispatch(toggleCartLoadingStatus({ loadingState: false })));
    }
  };

  const handleUpdateCartItem = async ({
    item,
    cartItemId
  }: UpdateCartPayload) => {
    const updatedCartItem = {
      cartId,
      cartItem: {
        item: {
          ...item,
          displayName: item.name,
          modifiers: item.modifiers ?? []
        }
      },
      itemId: cartItemId
    };
    dispatch(toggleCartLoadingStatus({ loadingState: true }));

    await triggerMutationWrapper(updateCartItem(updatedCartItem), {
      onSuccess: () => {
        // TODO: What do we do upon success? Can pass in onSuccess/onError ()
      },
      onError: () => {
        // TODO: Do we have some existing error modal we dispatch?
      }
    }).finally(() => dispatch(toggleCartLoadingStatus({ loadingState: false })));
  };

  return {
    handleUpdateCartItem,
    handleUpdateCartItemQuantity,
    isUpdatingProduct
  };
};
