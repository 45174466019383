import React, {forwardRef} from 'react';
import Grid from '@mui/material/Grid';
import { InputLabel, Select, SelectChangeEvent } from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { useDecision } from '@optimizely/react-sdk';
import useStyles from '../styles';
import { DisplayableModifier } from '@/domain/product/types';
import nutritionForProduct from '../../../../builders/pizza/dataTransformers/nutritionForProduct';
import decodeEntities from '@/clientCore/helper/decodeEntities';
import formattedPrice from '@/common/formattedPrice';

interface ProductOptionsDropdownProps {
  id: string;
  productNameWithoutSpaces: string;
  formattedUnitPrice: string;
  selectedProductOption?: DisplayableModifier;
  productOptions: DisplayableModifier[] | undefined;
  updateProductOption: (
    event: SelectChangeEvent,
    child: React.ReactNode
  ) => void;
  label: 'Sizes' | 'Additional Options';
  type: 'sizes' | 'additional-options';
  productName: string;
}

const ArrowDown = forwardRef<SVGSVGElement, React.ComponentProps<typeof KeyboardArrowDownOutlinedIcon>>((props, ref) => (
  <KeyboardArrowDownOutlinedIcon {...props} ref={ref} style={{ cursor: "pointer" }} />
));

const ProductOptionsDropdown = (props: ProductOptionsDropdownProps): JSX.Element => {
  const classes = useStyles();
  const {
    id,
    formattedUnitPrice,
    selectedProductOption,
    productOptions,
    updateProductOption,
    label,
    type,
    productName,
    productNameWithoutSpaces
  } = props;
  const [{ enabled: isAdditionalSizeUpchargeEnabled }] = useDecision('fr-dtg-1306-dispaly-menu-product-size-upcharge');
  const selectBoxId = `${type}-dropdown-${productNameWithoutSpaces}`;
  const inputLabel = `${label} for ${productName}`;
  const inputDescId = `${type}-dropdown-desc-${id}`;

  const nutritionString = (selectedProductOption?.nutrition ?? []).map(
    (nutrition) => nutritionForProduct(nutrition, true)
  );

  const productOptionsDropdownDescription = `Price ${formattedUnitPrice} each, ${nutritionString}`;

  return (
    <Grid className={classes.modifierDropdownWrapper}>
      <InputLabel
        className={classes.modifierDropdownLabel}
        htmlFor={selectBoxId}
      >
        {label}
      </InputLabel>
      <div
        id={inputDescId}
        className={classes.visuallyHidden}
        aria-hidden
      >
        {productOptionsDropdownDescription}
      </div>
      <Select
        native
        value={selectedProductOption?.id ?? ''}
        onChange={updateProductOption}
        IconComponent={ArrowDown}
        inputProps={{
          name: type,
          id: selectBoxId,
          className: `${classes.modifierDropdownSelectElement}`,
          tabIndex: 0,
          'aria-label': `${inputLabel}`
        }}
        className={classes.modifierDropdownSelectComponent}
      >
        {productOptions?.map(
          (productOption) => {
            const shouldShowUpcharge = isAdditionalSizeUpchargeEnabled && productOption.upcharge && productOption.upcharge > 0;
            const productOptionName = shouldShowUpcharge ? `${productOption.name} (+${formattedPrice(productOption.upcharge)})` : productOption.name;
            const optionLabel = productOption.isOutOfStock
              ? `${productOptionName} (sold out)`
              : productOptionName;
            return (
              <option
                data-testid={`${type}-dropdown-${optionLabel}`}
                key={productOption.id}
                value={productOption.id}
                aria-selected={productOption.id === selectedProductOption?.id}
                disabled={productOption.isOutOfStock}
              >
                {decodeEntities(optionLabel)}
              </option>
            );
          }
        )}
      </Select>
    </Grid>
  );
};

export default ProductOptionsDropdown;
