import colors from '../colors';
import boxShadows from '../boxShadows';
import transitions from '../transitions';

export const sharedFontStyle = {
  fontFamily: 'open_sans_semi',
  fontSize: '14px',
  fontStretch: 'normal',
  fontStyle: 'normal',
  letterSpacing: 'normal',
  color: colors.gray900,
  fontWeight: 400
};

const outlines = {
  commonFocusOutline: `3px dotted ${colors.red}`,
  commonFocusTextDecoration: 'none'
};

const fontStyles = {
  h1: {
    ...sharedFontStyle,
    fontFamily: 'PizzaHutFont',
    fontSize: '48px',
    lineHeight: '54px'
  },
  h1Mobile: {
    ...sharedFontStyle,
    fontFamily: 'PizzaHutFont',
    fontSize: '32px',
    lineHeight: '36px'
  },
  h2: {
    ...sharedFontStyle,
    fontFamily: 'PizzaHutFont',
    fontSize: '28px',
    lineHeight: '32px'
  },
  h2Mobile: {
    ...sharedFontStyle,
    fontFamily: 'PizzaHutFont',
    fontSize: '24px',
    lineHeight: '28px'
  },
  h3: {
    ...sharedFontStyle,
    fontFamily: 'open_sans_bold',
    fontSize: '20px',
    lineHeight: '24px'
  },
  h3Mobile: {
    ...sharedFontStyle,
    fontFamily: 'open_sans_bold',
    fontWeight: 800,
    fontSize: '18px',
    lineHeight: '22px'
  },
  h4: {
    ...sharedFontStyle,
    lineHeight: '20px',
    letterSpacing: '1px',
    'text-transform': 'uppercase'
  },
  h5: {
    ...sharedFontStyle,
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '1px',
    'text-transform': 'uppercase'
  },
  h6: {
    ...sharedFontStyle,
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '1px',
    'text-transform': 'uppercase'
  },
  body: {
    ...sharedFontStyle,
    fontFamily: 'open_sans',
    lineHeight: '24px'
  },
  bodyLarge: {
    ...sharedFontStyle,
    fontFamily: 'open_sans',
    fontSize: '16px',
    lineHeight: '24px'
  },
  bodySmall: {
    ...sharedFontStyle,
    fontFamily: 'open_sans',
    fontSize: '12px',
    lineHeight: '20px'
  },
  bodyPriceAndCalorie: {
    ...sharedFontStyle,
    fontFamily: 'open_sans',
    lineHeight: '18px'
  },
  textLink: {
    ...sharedFontStyle,
    color: colors.blue,
    textDecorationColor: colors.blue,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&:active': {
      color: colors.blueLinkHover
    }
  },
  textLinkSmall: {
    ...sharedFontStyle,
    fontSize: '12px',
    color: colors.blue
  },
  redTextLink: {
    ...sharedFontStyle,
    color: colors.red,
    letterSpacing: '1px',
    'text-transform': 'uppercase',
    '&:hover': {
      textDecoration: 'underline'
    },
    '&:focus': {
      textDecoration: 'underline'
    }
  },
  headerMainNavLink: {
    ...sharedFontStyle,
    fontSize: '18px',
    lineHeight: '30px',
    textDecoration: 'none',
    paddingBottom: '8px',
    '&:focus': {
      boxShadow: boxShadows.redUnderline
    },
    '&:hover': {
      boxShadow: boxShadows.redUnderline,
      backgroundColor: 'transparent',
      transition: transitions.redUnderline
    }
  },
  headerMainNavLinkMobile: {
    ...sharedFontStyle,
    fontSize: '12px',
    lineHeight: '24px',
    textDecoration: 'none',
    paddingBottom: '8px',
    '&:focus': {
      boxShadow: boxShadows.redUnderline,
      outline: outlines.commonFocusOutline
    },
    '&:hover': {
      boxShadow: boxShadows.redUnderline,
      backgroundColor: 'transparent'
    }
  },
  headerPersonalizationLink: {
    ...sharedFontStyle,
    textDecoration: 'none',
    'text-transform': 'capitalize'
  },
  headerPersonalizationLinkMobile: {
    ...sharedFontStyle,
    fontSize: '12px',
    textDecoration: 'none'
  },
  headerSubNavLinks: {
    ...sharedFontStyle,
    '&:hover, &:focus': {
      textDecoration: 'underline'
    },
    '&:focus': {
      textDecoration: 'underline'
    },
    '&:active': {
      color: colors.red,
      textDecoration: 'none'
    }
  },
  formFieldLabel: {
    ...sharedFontStyle
  },
  formFieldHintText: {
    ...sharedFontStyle,
    fontFamily: 'open_sans',
    color: colors.gray601,
    fontStyle: 'italic'
  },
  infoText: {
    ...sharedFontStyle,
    fontFamily: 'open_sans',
    fontSize: '12px',
    lineHeight: '18px',
    color: colors.gray600
  },
  productTitle: {
    ...sharedFontStyle,
    'text-transform': 'none',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 600
  },
  productTitleMobile: {
    ...sharedFontStyle,
    lineHeight: '18px',
    fontWeight: 600
  },
  productPrice: {
    ...sharedFontStyle,
    fontFamily: 'open_sans',
    lineHeight: '1.29'
  },
  productNutrition: {
    ...sharedFontStyle,
    fontFamily: 'open_sans',
    lineHeight: '1.29'
  },
  feesText: {
    ...sharedFontStyle,
    fontSize: '16px',
    lineHeight: '26px'
  },
  feesTextMobile: {
    ...sharedFontStyle,
    lineHeight: '24px'
  },
  errorText: {
    ...sharedFontStyle,
    fontFamily: 'open_sans',
    lineHeight: '24px',
    color: colors.red
  },
  errorTextSmall: {
    ...sharedFontStyle,
    fontFamily: 'open_sans',
    fontSize: '12px',
    lineHeight: '20px',
    color: colors.red
  },
  badgeText: {
    ...sharedFontStyle,
    color: colors.gray601,
    fontFamily: 'open_sans_semi',
    fontSize: '10px',
    lineHeight: 'normal',
    'text-transform': 'uppercase',
    letterSpacing: '1px'
  },
  modalHeading: {
    ...sharedFontStyle,
    fontFamily: 'open_sans_bold',
    fontSize: '24px',
    lineHeight: '28.8px',
    color: colors.gray900
  },
  centeredContainerWrapper: {
    maxWidth: '1152px',
    margin: '0 auto'
  },
  centeredContainer: {
    paddingLeft: '24px',
    paddingRight: '24px'
  },
  centeredContainerMobile: {
    paddingLeft: '16px',
    paddingRight: '16px'
  },
  headerMainText: {
    ...sharedFontStyle,
    color: colors.gray900,
    fontSize: '18px',
    lineHeight: '24px'
  },
  headerComponentLink: {
    padding: '8px 0 8px 0',
    '&:focus': {
      boxShadow: boxShadows.redUnderline
    },
    '&:hover': {
      boxShadow: boxShadows.redUnderline,
      scrollMarginBottom: '8px',
      transition: transitions.redUnderline
    }
  },
  commonComponentSelectedState: {
    '&:active': {
      outline: `2px solid ${colors.red}`
    },
    '&:focus': {
      padding: '5px'
    }
  },
  footerTextLink: {
    ...sharedFontStyle,
    fontFamily: 'open_sans',
    fontSize: '12px',
    lineHeight: '20px',
    '&:hover, &:focus': {
      textDecoration: 'underline'
    },
    '&:focus': {
      textDecoration: 'underline'
    },
    '&:active': {
      color: colors.red,
      textDecoration: 'none'
    }
  },
  caretIcon: {
    color: colors.gray900,
    margin: 'auto',
    marginLeft: '2px',
    '&.MuiSvgIcon-fontSizeSmall': {
      fontSize: '12px'
    }
  },
  caretIconMobile: {
    color: colors.gray900,
    margin: 'auto',
    marginLeft: '2px',
    '&.MuiSvgIcon-fontSizeSmall': {
      fontSize: '10px'
    }
  },
  primarySmallCtaLabel: {
    ...sharedFontStyle,
    fontFamily: 'open_sans',
    fontWeight: 600,
    lineHeight: '1.71em',
    letterSpacing: '1px',
    color: colors.white
  },
  primaryCtaLabel: {
    ...sharedFontStyle,
    fontFamily: 'open_sans_semi',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: '1px',
    color: colors.white
  },
  secondaryCtaLabel: {
    ...sharedFontStyle,
    fontFamily: 'open_sans_semi',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: '1px',
    color: colors.red
  },
  secondarySmallCtaLabel: {
    ...sharedFontStyle,
    fontFamily: 'open_sans',
    fontWeight: 600,
    lineHeight: '1.71em',
    letterSpacing: '1px',
    color: colors.red
  },
  expandMoreIcon: {
    color: colors.gray900
  }
};

export default fontStyles;
