import { createTheme } from '@mui/material/styles';
import { createBreakpoints } from '@mui/system';
import fontStyles from '../common/fontStyles';
import colors from '../common/colors';

interface ThemeOptions {
  htmlFontSize: number;
}

export const extraLargeStartBreakpoint = 'xl';
export const largeStartBreakpoint = 'lg';
export const mobileStartBreakpoint = 'md';
export const smallMobileStartBreakpoint = 'sm';
export const extraSmallMobileStartBreakpoint = 'xs';

export const breakPointsValues = {
  xs: 0,
  sm: 361,
  md: 651,
  lg: 851,
  xl: 1100
};

const breakpoints = createBreakpoints({ values: breakPointsValues });

export default function createAppTheme({ htmlFontSize }: ThemeOptions) {
  return createTheme({
    breakpoints, // Custom breakpoints
    shape: {
      borderRadius: 8,
    },
    typography: {
      htmlFontSize,
      h1: {
        [breakpoints.down(largeStartBreakpoint)]: fontStyles.h1Mobile,
        [breakpoints.up(largeStartBreakpoint)]: fontStyles.h1,
      },
      h2: {
        [breakpoints.down(largeStartBreakpoint)]: fontStyles.h2Mobile,
        [breakpoints.up(largeStartBreakpoint)]: fontStyles.h2,
      },
      h3: {
        [breakpoints.down(largeStartBreakpoint)]: fontStyles.h3Mobile,
        [breakpoints.up(largeStartBreakpoint)]: fontStyles.h3,
      },
      h4: fontStyles.h4,
      h5: fontStyles.h5,
      h6: fontStyles.h6,
      body1: fontStyles.body,
    },
    palette: {
      primary: {
        main: colors.red,
      },
      error: {
        main: colors.red,
      },
    },
    components: {
      MuiLink: {
        styleOverrides: {
          root: fontStyles.textLink,
        },
      },
      MuiTypography: {
        styleOverrides: {
          body1: {
            whiteSpace: 'pre-line',
            fontFamily: 'open_sans',
          },
          caption: {
            fontFamily: 'open_sans',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: fontStyles.body,
        },
      },
      MuiButton: {
        styleOverrides: {
          contained: {
            fontFamily: 'open_sans_semi',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: 1.5,
            letterSpacing: '1px',
            textAlign: 'center',
            boxShadow: 'none',
            '&:active': {
              boxShadow: 'none',
            },
          },
          containedPrimary: {
            '&:hover': {
              backgroundColor: '#e21216',
              boxShadow: '0 3px 8px 0 rgba(0, 0, 0, 0.3)',
            },
            '&:active': {
              backgroundColor: '#d10a14',
              boxShadow: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.3)',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: fontStyles.body,
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            fontSize: '10px',
          },
          body: {
            fontFamily: '"open_sans", sans-serif',
            lineHeight: '21px',
            backgroundColor: colors.white,
          },
          '.badgeText': fontStyles.badgeText,
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            padding: 'unset',
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          colorPrimary: {
            color: '#ffffff',
          },
        },
      },
    },
  });
}
