import { useDispatch, useSelector } from 'react-redux';
import { CartItem } from '@pizza-hut-us-development/client-core';

import { ENTER_KEY_EVENT_CODE } from '@/common/constants';
import formattedPrice from '@/common/formattedPrice';

import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import { toggleCartRail } from '@/clientCore/redux/rail/CartRailSlice';
import { presentationalCartSelectors } from '@/clientCore/redux/selectors/clientCorePresentational/cart/presentationalCartSelectors';

import { CartNavBarProps } from '../types';

export const useCartNavBarButton = (): CartNavBarProps => {
  const dispatch = useDispatch();

  const isCartLoading = useSelector(presentationalCartSelectors.isCartLoading);

  const cart = useSelector(orderSelectors.cart);
  const handleToggleRail = () => {
    dispatch(toggleCartRail());
  };
  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (toggleCartRail && event.key === ENTER_KEY_EVENT_CODE) {
      dispatch(toggleCartRail());
    }
  };

  if (!cart && isCartLoading) {
    return [
      {
        isCartLoading
      },
      {
        handleToggleRail,
        onKeyDown
      }
    ];
  }

  const { items, subtotal = 0 } = cart || {};
  const totalItems = items?.reduce(
    (total: number, currentItem: CartItem): number => {
      if (currentItem.quantity) {
        return total + currentItem?.quantity;
      }
      return total;
    },
    0
  ) ?? 0;
  const displayableTotal = formattedPrice(subtotal);

  return [
    {
      displayableTotal,
      isCartLoading,
      totalItems
    },
    {
      handleToggleRail,
      onKeyDown
    }
  ];
};
