import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import colors from '@/common/colors';
import { smallMobileStartBreakpoint } from '@/materialUi/theme';

const useRailLayoutStyles = makeStyles((theme) => createStyles({
  railContent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  bodyContainer: {
    flexGrow: 1,
    margin: '19px 8px',
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      margin: '12px 8px'
    }
  },
  bodyContainerGrid: {
    width: '100%',
    margin: 0
  },
  ctasContainer: {
    padding: '24px 16px 24px 16px',
    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.3)',
    position: 'sticky',
    bottom: 0,
    backgroundColor: colors.white
  }
}));

export default useRailLayoutStyles;
