import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQueryV1 from '../../utils/axiosBaseQueryV1';
/**
 * Creates the base `customerApi` slice.
 *
 * See the [RTK Query docs](https://redux-toolkit.js.org/rtk-query/api/createApi) for information on `createApi`.
 */
export const customerApi = createApi({
    baseQuery: axiosBaseQueryV1(),
    endpoints: () => ({}),
    reducerPath: 'rtkq/customer',
    tagTypes: ['Addresses', 'Customer', 'Favorites', 'Loyalty', 'Promotions'],
});
