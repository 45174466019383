import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import colors from '@/common/colors';
import fontStyles from '@/common/fontStyles';

const cartCheckout = makeStyles(() => createStyles({
  root: {
    padding: '19px 16px 19px 16px',
    position: 'sticky',
    bottom: '0',
    width: '100%',
    justifyContent: 'center',
    boxShadow: '0 0 4px 2px rgb(0 0 0 / 30%)',
    backgroundColor: colors.white
  },
  summaryRoot: {
    paddingBottom: '18px'
  },
  container: {
    paddingBottom: '13px'
  },
  link: {
    color: colors.blue,
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
    textTransform: 'none',

    '&:hover': {
      background: 'none'
    },

    '@media (max-width: 320px)': {
      padding: 0
    }
  },
  serviceFeeLink: {
    ...fontStyles.textLink,
    color: colors.blue,
    textTransform: 'initial',
    padding: 0
  },
  total: {
    display: 'flex',

    '@media (max-width: 320px)': {
      flexDirection: 'column'
    }
  },
  displayableTotal: {
    fontSize: '18px'
  },
  appliedPromo: {
    backgroundColor: colors.gold,
    borderRadius: '4px',
    padding: '2px 4px'
  }
}));

const styles = { cartCheckout };
export default styles;
