import {
  StoreSearchPayload, DeliveryAddress, CCStoreResponse, DiningOccasion, Hour, OccasionDetail
} from '@pizza-hut-us-development/client-core';
import { Dispatch } from 'redux';
import { format } from 'date-fns';

import {
  getAcceptedCCTypesForOccasion, getDaysAvailableForSchedule, getPaymentTypesForOccasion, OnlineStatus, PosType
} from '@/api/phdApiClient/transformPHDData';
import { carryoutToString } from '@/localization/common/utils';
import { openModal, showSearchInRail, switchToCarryout } from '@/localization/actions';
import { PHDApiStoreHourAvailability } from '@/api/phdApiClient/types';
import convertTo12HourClock from '@/localization/common/convertTo12HourClock';
import { padNumber } from '@/localization/common/determineCloseTime';
import { StoreStatus } from '@/localization/localizationRail/StoreTile/constants';
import getContactlessStatus from '@/localization/common/getContactlessStatus';
import { Occasion, OccasionApi } from '@/localization/constants';
import { Analytics } from '@/dataAnalytics/hooks/useAnalytics';
import { storeSearchNoResultsActionAnalytics } from '@/dataAnalytics/dataAnalyticsHelper';

const deliveryAddressFromPayload = (payload: StoreSearchPayload, store?: CCStoreResponse) => ({
    address1: payload.address1,
    address2: payload.address2,
    city: payload.city,
    state: payload.state,
    postalCode: payload.postalCode,
    ...(store?.deliveryLatitude && store?.deliveryLongitude && {
      position: {
        coordinates: [
          parseFloat(store.deliveryLongitude),
          parseFloat(store.deliveryLatitude)
        ]
      }
    })
  });

const carryoutOptionsFromPayload = (payload: StoreSearchPayload) => {
  const options: CarryoutSearchDetails = {
    city: payload.city,
    state: payload.state,
    zipcode: payload.postalCode
  };

  if (payload.latitude && payload.longitude) {
    options.lat = parseInt(payload.latitude, 10);
    options.lng = parseInt(payload.longitude, 10);
  }

  return options;
};

const showDeliveryNoStoresFoundModal = (dispatch: Dispatch, address: DeliveryAddress, analytics: Analytics, isSavedAddress: boolean) => {
  const title = "We're sorry!";
  const body = 'There are no available Pizza Huts that can deliver to: '
      + `${address.address1}, ${address.city}, ${address.state} ${address.postalCode}.`;

  dispatch(
    openModal({
      title,
      body,
      cta: {
        text: 'ENTER NEW ADDRESS',
        callback: () => {
          analytics.push(() => storeSearchNoResultsActionAnalytics(
            'Delivery',
            'Enter New Address',
            isSavedAddress
          ))
          dispatch(showSearchInRail());
        }
      },
      altCta: {
        text: 'SWITCH TO CARRYOUT',
        callback: () => {
          analytics.push(() => storeSearchNoResultsActionAnalytics(
            'Delivery',
            'Switch To Carryout',
            isSavedAddress
          ))
          dispatch(showSearchInRail());
          dispatch(switchToCarryout());
        }
      }
    })
  );
};

const showCarryoutNoStoresFoundModal = (dispatch: Dispatch, options: CarryoutSearchDetails, analytics: Analytics, isSavedAddress: boolean) => {
  const locationString = options ? `: ${carryoutToString(options)}` : ' you';
  const title = "We're sorry!";
  const body = `There are no available Pizza Huts near${locationString}.`;
  const text = 'ENTER NEW LOCATION';

  dispatch(openModal({
    title,
    body,
    cta: {
    text,
    callback: () => {
      analytics.push(() => storeSearchNoResultsActionAnalytics(
        'Carryout',
        text,
        isSavedAddress
      ))
    }
    } }));
};

function determineCloseTime(storeHours: any) {
  if (!storeHours.days) return undefined;

  const startTime = storeHours.intervalStartTime
    .split(':')
    .map((value: string) => parseInt(value, 10));

  const duration = storeHours.duration
    .split(':')
    .map((value: string) => parseInt(value, 10));

  const closeTime = new Date(0, 0, 0,
    startTime[0] + duration[0],
    startTime[1] + duration[1],
    startTime[2] + duration[2]);

  return `${padNumber(closeTime.getHours())}:${padNumber(closeTime.getMinutes())}:${padNumber(closeTime.getSeconds())}`;
}

const getMaxOrderLimit = (occasions: OccasionDetail[]) => occasions.map((occasion) => ({
  name: occasion.name,
  maxOrder: occasion.maxOrder
}));

const isAllowTip = (occasions: OccasionDetail[]) => occasions.map((occasion) => ({
  occasionName: occasion.name,
  isAllowed: (occasion as any).allowTip // TODO: Type missing allowTip
}));

const transformCCStoreToStoreDetail = (store: CCStoreResponse, occasion: DiningOccasion): StoreDetail => {
  const occasionFilter = occasion === DiningOccasion.CARRYOUT ? 'CARRYOUT' : 'DELIVERY';
  const [shift1, shift2] = store.hours?.filter(
    (hour: Hour) => hour.occasionId === occasionFilter
  ) ?? [];

  const carryoutPromiseTime = store.occasions?.find(
    (oc: OccasionDetail) => oc.occasionId === 'CARRYOUT'
  )?.serviceTime;
  const deliveryPromiseTime = store.occasions?.find(
    (oc: OccasionDetail) => oc.occasionId === 'DELIVERY'
  )?.serviceTime;
  const promiseTime = occasion === DiningOccasion.CARRYOUT ? carryoutPromiseTime : deliveryPromiseTime;

  const getOpenCloseTime = (shift: Hour) => {
    const dayOfWeekOrdinal = parseInt(format(Date.now(), 'i'), 10);
    let shiftOpenTime: boolean | string = false;
    let shiftCloseTime: boolean | string = false;

    if (shift?.days?.includes(dayOfWeekOrdinal)) {
      shiftOpenTime = convertTo12HourClock(shift?.intervalStartTime);
      shiftCloseTime = convertTo12HourClock(determineCloseTime(shift) || '');
    }

    return { openTime: shiftOpenTime, closeTime: shiftCloseTime };
  };

  const shift1OpenCloseTime = getOpenCloseTime(shift1);
  const shift2OpenCloseTime = getOpenCloseTime(shift2);

  const getStoreStatus = (): string => {
    if (
      store.status === StoreStatus.TEMP_CLOSED
        && store.onlineStatus === OnlineStatus.INACTIVE
    ) {
      return StoreStatus.INACTIVE;
    }

    return store.status || StoreStatus.ONLINE_BUT_OUTSIDE_STORE_HOURS;
  };

  let posType;
  if (store.metadata?.posType === PosType.NPC) {
    posType = 'NPC';
  } else if (store.metadata?.posType === PosType.SUS) {
    posType = 'SUS';
  }

  const deliveryOccasions = store.occasions?.filter(
    (oc: OccasionDetail) => oc.occasionId === DiningOccasion.DELIVERY
  );
  const deliveryOccasion = deliveryOccasions.length > 0 && deliveryOccasions[0];

  const paymentTypesForOccasion = store?.paymentTypes
    ? getPaymentTypesForOccasion(
      store.paymentTypes as PHDApiStorePaymentType[],
      occasionFilter as OccasionApi
    )
    : [];

  const acceptedCCTypesForOccasion = store?.paymentTypes
    ? getAcceptedCCTypesForOccasion(
      store.paymentTypes as PHDApiStorePaymentType[],
      occasionFilter as OccasionApi
    )
    : [];

  const daysAvailableForSchedule = store?.hours
    ? getDaysAvailableForSchedule(
      store.hours?.map((hour): PHDApiStoreHourAvailability => ({
        days: hour.days,
        occasion_id: hour.occasionId as PHDApiStoreOccasion,
        interval_start_time: hour.intervalStartTime,
        duration: hour.duration
      })),
      occasionFilter as OccasionApi,
      promiseTime,
      store.timezone
    )
    : [];

  return ({
    storeNumber: store.storeNumber,
    address: store.address1,
    address2: store.address2,
    city: store.city,
    state: store.state,
    zipcode: store.postalCode,
    promiseTime,
    openTime: shift1OpenCloseTime.openTime,
    closeTime: shift1OpenCloseTime.closeTime,
    splitOpenTime: shift2OpenCloseTime.openTime,
    splitCloseTime: shift2OpenCloseTime.closeTime,
    landmark: store.landmark,
    phoneNumber: store.phoneNumber,
    storeStatus: getStoreStatus(),
    acceptFutureOrders: store.allowsFutureOrders,
    storeClosureReason: '',
    lat: store.latitude,
    long: store.longitude,
    storeToken: store.token,
    contactless: getContactlessStatus(store.metadata, occasion === DiningOccasion.CARRYOUT ? Occasion.CARRYOUT : Occasion.DELIVERY),
    posType,
    storeTimezone: store.timezone,
    carryoutPromiseTime: carryoutPromiseTime && carryoutPromiseTime * 60,
    deliveryPromiseTime: deliveryPromiseTime && deliveryPromiseTime * 60,
    deliveryMinOrder: deliveryOccasion ? deliveryOccasion.minOrder : 0,
    paymentTypesForOccasion,
    acceptedCCTypesForOccasion: acceptedCCTypesForOccasion as AcceptedCCTypes[],
    daysAvailableForSchedule,
    orderMaxForOccasion: getMaxOrderLimit(store.occasions),
    storeTipEligibility: isAllowTip(store.occasions),
    baseDeliveryCharge: store.baseDeliveryCharge,
    showSodiumAlerts: store.metadata.showSodiumAlerts,
    dragonTail: (store as any).dragontail ?? false
  });
};

export {
  deliveryAddressFromPayload,
  carryoutOptionsFromPayload,
  showDeliveryNoStoresFoundModal,
  showCarryoutNoStoresFoundModal,
  transformCCStoreToStoreDetail
};
