import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import colors from '@/common/colors';

const cartInfoAndWariningView = makeStyles(() => createStyles({
  root: {
    padding: '19px 16px 19px 16px',
    bottom: '0',
    width: '100%',
    justifyContent: 'center'
  },
  title: {
    textTransform: 'none',
    paddingBottom: '12px'
  },
  content: {
    paddingBottom: '31px'
  },
  link: {
    padding: '0px 0px 15px 0px',
    marginLeft: '-3px',
    color: colors.blue,
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    '&:hover': {
      background: 'none'
    },
    '& svg': {
      marginRight: '5px'
    }
  }
}));

const styles = { cartInfoAndWariningView };

export default styles;
