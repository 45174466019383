import { filterVariantsSlotsModifiersByAccessPointWeb } from './filterUtilities';
import { metafieldsHaveSodiumWarning } from './menuUtilities';
import transformYumAvailability from './transformYumAvailabilty';
import transformYumBundleChoiceToDealStep from './transformYumBundleChoiceToDealStep';
import transformYumOptionTypesToModifierArray from './transformYumOptionTypesToModifierArray';
import transformYumSlotsToModifierArray from './transformYumSlotsToModifierArray';
export const transformYumBundleToDealBuilder = (bundleCodeOrBundleNavigationCode, menu, removeBundleVariants = false, promotionDefinition, isPreviewMode = false) => {
    const { bundles, bundleChoices, modifiers, optionTypes, optionValues, products: allProducts, slots } = menu;
    const { products: productsFilteredByPrivateMetafield, slots: slotsFilteredByPrivateMetafield, modifiers: modifiersFilteredByPrivateMetafield, } = filterVariantsSlotsModifiersByAccessPointWeb(allProducts, slots, modifiers, isPreviewMode);
    const allOptions = transformYumOptionTypesToModifierArray(optionTypes, optionValues);
    const allSlots = transformYumSlotsToModifierArray(slotsFilteredByPrivateMetafield, modifiersFilteredByPrivateMetafield);
    const bundle = bundles.find(({ bundleCode, bundleNavigationCode }) => {
        const bundleNavigationCodes = bundleNavigationCode === null || bundleNavigationCode === void 0 ? void 0 : bundleNavigationCode.split(',');
        return (bundleCode === bundleCodeOrBundleNavigationCode ||
            bundleNavigationCode === bundleCodeOrBundleNavigationCode ||
            (bundleNavigationCodes === null || bundleNavigationCodes === void 0 ? void 0 : bundleNavigationCodes.includes(bundleCodeOrBundleNavigationCode)));
    });
    if (!bundle) {
        throw new Error(`Bundle ${bundleCodeOrBundleNavigationCode} not found in menu`);
    }
    const choiceIds = new Set(bundle === null || bundle === void 0 ? void 0 : bundle.choices.map((choice) => choice.choiceCode));
    const bundleChoicePrices = bundle.choices.map((choice) => ({
        allocatedPrice: choice.allocatedPrice,
        choiceCode: choice.choiceCode,
    }));
    const filteredBundleChoices = bundleChoices.filter((choice) => choiceIds.has(choice.choiceCode));
    const filteredChoicePrice = bundleChoicePrices.filter((choice) => choiceIds.has(choice.choiceCode));
    const { availability: bundleAvailability, blackout: outOfStock = false, bundleCode, choices, description, images, legalText = '', metafields, name = '', price, privateMetafields = [], redeemableOnline, visibleOnline, } = bundle;
    const availability = transformYumAvailability(bundleAvailability === null || bundleAvailability === void 0 ? void 0 : bundleAvailability.schedule);
    const steps = [];
    choices.forEach((choice) => {
        var _a;
        const { defaultVariantCodes, maxAllowedSelections, minAllowedSelections } = choice;
        const bundleChoice = filteredBundleChoices.find((bChoice) => bChoice.choiceCode === choice.choiceCode);
        const bundleChoicePrice = filteredChoicePrice.find((bChoice) => bChoice.choiceCode === choice.choiceCode);
        const variantCodes = new Set();
        let variantUpcharges = {};
        bundleChoice.variants.forEach((variant) => {
            var _a;
            variantCodes.add(variant.variantCode);
            variantUpcharges = Object.assign(Object.assign({}, variantUpcharges), { [variant.variantCode]: (_a = variant.upcharge.amount) !== null && _a !== void 0 ? _a : 0 });
        });
        // filter out the products where one of the bundle choice variants is present
        // then remove any products that would result in no variants after filtering by private metafields
        const yumProducts = productsFilteredByPrivateMetafield.reduce((acc, product) => {
            const { variants: productVariants } = product;
            const productVariantCodes = productVariants.map((variant) => variant.variantCode);
            const includeProduct = productVariantCodes.some((variantCode) => variantCodes.has(variantCode));
            if (includeProduct) {
                acc.push(product);
            }
            return acc;
        }, []);
        const step = transformYumBundleChoiceToDealStep(yumProducts, allOptions, allSlots, bundleChoice, minAllowedSelections, (_a = bundleChoicePrice === null || bundleChoicePrice === void 0 ? void 0 : bundleChoicePrice.allocatedPrice) !== null && _a !== void 0 ? _a : null, removeBundleVariants, promotionDefinition);
        const productVariantsInStock = allProducts
            .flatMap(({ variants }) => variants)
            .filter((variant) => variantCodes.has(variant.variantCode) && !variant.blackout)
            .map(({ variantCode }) => variantCode);
        const inStockDefaultVariantCodes = defaultVariantCodes.filter((variantCode) => productVariantsInStock.includes(variantCode));
        if (maxAllowedSelections === 1) {
            const defaultVariantCode = inStockDefaultVariantCodes.length > 0 ? inStockDefaultVariantCodes[0] : '';
            steps.push(Object.assign(Object.assign({}, step), { defaultVariantCode }));
        }
        else {
            for (let i = 0; i < maxAllowedSelections; i++) {
                const defaultVariantCode = inStockDefaultVariantCodes.length > i ? inStockDefaultVariantCodes[i] : '';
                steps.push(JSON.parse(JSON.stringify(Object.assign(Object.assign({}, step), { defaultVariantCode, displayText: bundleChoice.displayText }))));
            }
        }
    });
    // check if this bundle has a sodium warning private metafield
    const sodiumWarning = metafieldsHaveSodiumWarning(privateMetafields);
    const everyStepOutOfStock = steps.every((step) => step.outOfStock);
    const requiredStepOutOfStock = steps.filter((step) => step.required).some((step) => step.outOfStock);
    const builder = Object.assign(Object.assign({ availability, description: description || '', displayOrder: 0, displayPrice: `$${(price.amount / 100).toFixed(2)}`, hidden: !visibleOnline, id: bundleCode, isBundle: true, isPromotion: false, legalText,
        metafields,
        name, options: [], outOfStock: everyStepOutOfStock || requiredStepOutOfStock || outOfStock, price: price.amount, privateMetafields, qoId: '', redeemableOnline, selectedOptions: [] }, (sodiumWarning !== undefined && { sodiumWarning })), { steps, type: 'DISCOUNT' });
    // add images to the product
    images.forEach((image) => {
        if (image.key) {
            builder[image.key] = image.url;
        }
    });
    return builder;
};
export default transformYumBundleToDealBuilder;
