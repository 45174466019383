type ExpirationDate = `${number}/${number}`;
type CardNumber = `${number}`;

export enum PaymentType {
  creditcard = 'creditcard',
  giftcard = 'giftcard',
  tpgc = 'tpgc'
}

export interface CreditCard {
  name: string;
  cardNumber: CardNumber;
  expirationDate: ExpirationDate;
  cardMask: string;
  postalCode: `${number}`;
}

export interface SavedCreditCard extends CreditCard {
  paymentId: string;
  primary: boolean;
}

export interface GiftCardFormFields {
  cardNumber: CardNumber;
  pin: `${number}`;
}

export interface ThirdPartyGiftCard {
  cardNumber: CardNumber;
  expirationDate: ExpirationDate;
  cvv: `${number}`;
  cardMask: string;
}

interface CardInfo {
  paymentId: string;
  isDefault: boolean;
  paymentType: string;
  cardInfo: Record<string, unknown>;
}

export interface CreditCardInfo extends CardInfo {
  name: string;
  paymentType: 'creditcard';
  cardInfo: {
    lastFour: string;
    cardType: string;
    expiration: string;
    postalCode: string;
  };
}

export interface CreditCardData{
  primary?: false;
  creditDebitInfo: {
    nameOnCard: string;
    cardNumber: string;
    expiration: string;
    billingZip: string;
  };
}

export interface EditedCreditCard{
  isDefault: boolean;
  paymentId: string;
  name: string;
  paymentType: 'creditcard';
  cardInfo: {
    cardType: string;
    lastFour: string;
    expiration: `${number}/${number}`;
    postalCode: `${number}`;
  };
}

export interface GiftCardInfo extends CardInfo {
  paymentType: PaymentType.giftcard | PaymentType.tpgc;
  cardInfo: {
    number: string;
    balance: number;
  };
}

export const giftCardInfoFromTpgc = (
  paymentId: string, cardNum: string, balance: number
): GiftCardInfo => ({
  paymentId,
  paymentType: PaymentType.tpgc,
  isDefault: false,
  cardInfo: { number: cardNum, balance }
});

export const giftCardInfoFromSavedGiftCard = (
  paymentId: string, cardNum: string, balance: number
): GiftCardInfo => ({
  paymentId,
  paymentType: PaymentType.giftcard,
  isDefault: false,
  cardInfo: { number: cardNum, balance }
});

export interface CardIdentifier {
  id: string;
  type: string;
}

export const isCreditCard = (
  card: CreditCardInfo | GiftCardInfo
): card is CreditCardInfo => card.paymentType === PaymentType.creditcard;
export const isGiftCard = (
  card: GiftCardInfo | CreditCardInfo
): card is GiftCardInfo => card.paymentType === PaymentType.giftcard
    || card.paymentType === PaymentType.tpgc;
