import { compose } from '@reduxjs/toolkit';

import {
  NATIONAL,
  NATIONAL_STORE_ID_V2,
  NATIONAL_STORE_ID_V3,
  Occasion
} from './constants';
import { RootState } from '@/rootStateTypes';

const domain = (state: RootState): LocalizationDetails => state.domain.localization;
const presentational = (state: RootState): LocalizationUi => state.presentational.localization;

const isLocalized = (localization: LocalizationDetails) => {
  const nationalStores = [NATIONAL, NATIONAL_STORE_ID_V2, NATIONAL_STORE_ID_V3];
  return !nationalStores.includes(localization.localizedStore);
};
const isStoreChanged = (localization:LocalizationDetails) => localization.isStoreChanged;

const isOccasionChanged = (localization: LocalizationDetails) => localization.isOccasionChanged;

const isStoreOpen = (localization: LocalizationDetails) => localization
  .localizedStoreDetails?.storeStatus;

const acceptFutureOrders = (localization: LocalizationDetails) => localization
  .localizedStoreDetails?.acceptFutureOrders;

const occasion = (localization: LocalizationDetails): Occasion => localization
  .localizedOccasion;

const storeDetails = (localization: LocalizationDetails): StoreDetail => localization
  .localizedStoreDetails;

const storeIdEarly = (localization: LocalizationDetails): string => localization.localizedStore;

const localizationToken = (
  localization: LocalizationDetails
) => localization.localizedStoreDetails?.storeToken;

const paymentTypesForOccasion = (
  localization: LocalizationDetails
) => localization.localizedStoreDetails?.paymentTypesForOccasion;

const currentlyLocalizedAddress = (
  localization: LocalizationUi
): DeliveryAddress => localization.currentlyLocalizedAddress;

const acceptedCCTypesForOccasion = (
  localization: LocalizationDetails
) => localization.localizedStoreDetails?.acceptedCCTypesForOccasion;

const hasLoadingBar = (localization: LocalizationUi) => localization.showLoadingBar;

const hasLoadingQuery = (localization: LocalizationUi) => localization.hasLoadingQuery;

const shouldPrefillInput = (localization: LocalizationUi) => localization.shouldPrefillInput;

const railOptions = (localization: LocalizationUi) => localization.rail.options;

const storeNumber = (localization: LocalizationDetails) => localization.localizedStoreDetails?.storeNumber;

export const localizationSelectors = {
  isLocalized: compose(isLocalized, domain),
  isStoreOpen: compose(isStoreOpen, domain),
  acceptFutureOrders: compose(acceptFutureOrders, domain),
  occasion: compose(occasion, domain),
  storeDetails: compose(storeDetails, domain),
  storeIdEarly: compose(storeIdEarly, domain), // Prefer to use storeDetails above
  storeNumber: compose(storeNumber, domain),
  localizationToken: compose(localizationToken, domain),
  paymentTypesForOccasion: compose(paymentTypesForOccasion, domain),
  currentlyLocalizedAddress: compose(currentlyLocalizedAddress, presentational),
  isStoreChanged: compose(isStoreChanged, domain),
  isOccasionChanged: compose(isOccasionChanged, domain),
  acceptedCCTypesForOccasion: compose(acceptedCCTypesForOccasion, domain),
  hasLoadingBar: compose(hasLoadingBar, presentational),
  hasLoadingQuery: compose(hasLoadingQuery, presentational),
  shouldPrefillInput: compose(shouldPrefillInput, presentational),
  railOptions: compose(railOptions, presentational)
};
