var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { EmptyDealBuilder, } from '../types';
import { computeBuilderQuantity, transformDealBuilderToCartItem, transformDealBuilderToYumCartLineItemInput, } from '../utils';
import { orderApiCart } from '../api/order/OrderApiCart';
import transformDealBuilderToYumCartLineItemUpdate from '../utils/transformDealBuilderToYumCartLineItemUpdate';
import { OrderActions } from './OrderSlice';
import areDealStepsCompletedWithRecipes from '../utils/dealBuilder/areDealStepsCompletedWithRecipes';
import computeDealPrice from '../utils/dealBuilder/computeDealPrice';
const initialState = {
    builder: EmptyDealBuilder,
    isCartLoading: false,
    isPriceLoading: false,
    price: 0,
    quantity: 1,
    stepSelections: {},
    steps: [],
    type: 'DISCOUNT',
};
export const addDealToCart = createAsyncThunk('dealBuilder/addDealToCart', (_, { dispatch, getState, rejectWithValue }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { dealBuilder, order: { cartId = '', cart }, coreConfig: { isYumEcomm }, } = state;
    const cartItem = isYumEcomm
        ? transformDealBuilderToYumCartLineItemInput(state)
        : transformDealBuilderToCartItem(dealBuilder);
    try {
        const data = yield dispatch(orderApiCart.endpoints.addBuilderItemToCart.initiate({
            cartId,
            cartItem: { item: cartItem },
            isDeal: true,
        }));
        if ('error' in data)
            return rejectWithValue(data.error);
        const oldCartIds = (cart === null || cart === void 0 ? void 0 : cart.items.map((item) => item.cartItemId)) || [];
        const [newCartItem] = data.data.items.filter((item) => !oldCartIds.includes(item.cartItemId));
        let cartItemId = newCartItem === null || newCartItem === void 0 ? void 0 : newCartItem.cartItemId;
        let quantity = dealBuilder.quantity;
        if (!cartItemId) {
            const { cartItemId: id, quantity: quant } = computeBuilderQuantity(cart === null || cart === void 0 ? void 0 : cart.items, data.data.items);
            cartItemId = id;
            quantity = quant;
        }
        dispatch(OrderActions.setDealHistory({
            cartItemId,
            state: Object.assign(Object.assign({}, dealBuilder), { isCartLoading: false, isPriceLoading: false, quantity }),
        }));
        return data;
    }
    catch (error) {
        return rejectWithValue(error.message);
    }
}));
export const editDeal = createAsyncThunk('dealBuilder/editDeal', (cartItem, { getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { order: { dealHistory }, } = state;
    const { cartItemId } = cartItem;
    const history = dealHistory[cartItem.cartItemId];
    return Object.assign(Object.assign({}, history), { cartItemId });
}));
export const updateDeal = createAsyncThunk('dealBuilder/updateDeal', (itemId, { dispatch, getState, rejectWithValue }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { coreConfig: { isYumEcomm }, dealBuilder, order: { cartId = '' }, } = state;
    let requestBody;
    if (isYumEcomm) {
        requestBody = transformDealBuilderToYumCartLineItemUpdate(itemId, state.dealBuilder);
    }
    else {
        const cartItem = transformDealBuilderToCartItem(dealBuilder);
        requestBody = {
            cartId,
            cartItem: { item: cartItem },
            itemId,
        };
    }
    try {
        const data = yield dispatch(orderApiCart.endpoints.updateCartItem.initiate(requestBody));
        dispatch(OrderActions.setDealHistory({
            cartItemId: itemId,
            state: Object.assign(Object.assign({}, state.dealBuilder), { isCartLoading: false, isPriceLoading: false }),
        }));
        return data;
    }
    catch (error) {
        return rejectWithValue(error.message);
    }
}));
export const priceDeal = createAsyncThunk('dealBuilder/priceDeal', (_, { dispatch, getState, rejectWithValue }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { coreConfig: { isYumEcomm }, dealBuilder, order: { cartId = '' }, } = state;
    if (isYumEcomm) {
        return computeDealPrice(dealBuilder);
    }
    if (!dealBuilder.stepSelections) {
        return dealBuilder.price;
    }
    if (!areDealStepsCompletedWithRecipes(dealBuilder)) {
        throw new Error('Some deal steps are not completed');
    }
    try {
        const item = transformDealBuilderToCartItem(dealBuilder);
        const data = (yield dispatch(orderApiCart.endpoints.priceCart.initiate({ cartId, items: [item] })));
        return data.data.price;
    }
    catch (err) {
        return rejectWithValue(err.message);
    }
}));
export const dealBuilderSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(addDealToCart.fulfilled, () => {
            return initialState;
        });
        builder.addCase(addDealToCart.pending, (state) => {
            state.isCartLoading = true;
        });
        builder.addCase(addDealToCart.rejected, (state) => {
            state.isCartLoading = false;
        });
        builder.addCase(priceDeal.fulfilled, (state, action) => {
            state.isPriceLoading = false;
            state.price = action.payload;
        });
        builder.addCase(priceDeal.pending, (state) => {
            state.isPriceLoading = true;
        });
        builder.addCase(priceDeal.rejected, (state) => {
            state.isPriceLoading = false;
        });
        builder.addCase(updateDeal.fulfilled, () => {
            return initialState;
        });
        builder.addCase(updateDeal.pending, (state) => {
            state.isCartLoading = true;
        });
        builder.addCase(updateDeal.rejected, (state) => {
            state.isCartLoading = false;
        });
        builder.addCase(editDeal.fulfilled, (_, action) => {
            return action.payload;
        });
        builder.addCase(editDeal.pending, (state) => {
            state.isCartLoading = true;
        });
        builder.addCase(editDeal.rejected, (state) => {
            state.isCartLoading = false;
        });
    },
    initialState,
    name: 'dealBuilder',
    reducers: {
        clearBuilder: () => initialState,
        setDeal: (state, action) => {
            state.builder = action.payload;
            state.steps = action.payload.steps;
        },
        setDealStep: (state, action) => {
            state.stepSelections[action.payload.step] = action.payload.selection;
        },
    },
});
const { clearBuilder, setDeal, setDealStep } = dealBuilderSlice.actions;
export const DealBuilderActions = {
    clearBuilder,
    setDeal,
    setDealStep,
};
export const DealBuilderReducer = dealBuilderSlice.reducer;
