import { useDispatch, useSelector } from 'react-redux';
import { CartItem as CCCartItem, useUpdateCartItemMutation, YumCart } from '@pizza-hut-us-development/client-core';
import { useRouter } from 'next/router';
import { useDecision } from '@optimizely/react-sdk';
import { PromotionNotAppliedReasonType } from '@pizza-hut-us-development/client-core/types/order/orderingGatewayTypes';
import { cartLoaded, cartLoading, openCartRail } from '../actions';
import { AddableCartItem, AddableCartItemKinds } from '@/api/phdApiV2Client/request.types';
import { openCartToast } from '@/common/CartToast/slices/Toast.slice';
import { CartItem } from '@/domain/cart/types';
import { ItemAnalytics } from '@/dataAnalytics/analyticsTypes';
import useCartToast from './useCartToast';
import { useCartAddItem as useCCCartAddItem } from '@/clientCore/cart/hooks/useCartAddItem';
import formattedPrice from '@/common/formattedPrice';
import {
  useCCTransformLegacyCartItem
} from '@/clientCore/temporaryTransformationalHooks/useCCTransformLegacyCartItem/useCCTransformLegacyCartItem';
import useCCCartState from '@/clientCore/cart/hooks/useCCCartState';
import logger from '@/common/logger';
import { setCartItemIdOfSortedPointer } from '@/clientCore/redux/rail/CartRailSlice';
import {
  presentationalRailSelectors
} from '@/clientCore/redux/selectors/clientCorePresentational/rail/presentationalRailSelectors';
import { findNewCartItemId } from '@/clientCore/redux/rail/helpers';
import { transformationDebugLogging } from '@/clientCore/debuggerLogging';
import { debugModeSelectors } from '@/clientCore/redux/selectors/debugSelectors';
import { DebugModeOptions } from '@/clientCore/redux/debug/types';
import { translateNoSauceFromDealOrPizza } from '@/clientCore/hardCodingHelpers/WEB-2491-yumPizzaNoSauce/helpers';
import Routes from '@/router/routes';
import { RedemptionQueryParams, RedemptionRedirectQueryParams } from '@/clientCore/redemptionMenu/constants';
import {
  findRedemptionWarningFromPromoOrCode
} from '@/clientCore/cart/components/CartRail/components/CartContent/components/YumAddedCoupons/helpers';

export const transformCCCartItemToLegacy = (item: CCCartItem): CartItem => ({
  ...item,
  kind: AddableCartItemKinds.CART_ITEM,
  displayablePrice: formattedPrice(item.price),
  quantity: item.quantity ?? 1,
  modifiers: item.modifiers ?? [],
  description: item.description ?? ''
});

const useAddToCart = () => {
  const { handleAddCartItem, isAddingCartItem, addToCartStatus } = useCCCartAddItem();
  // Debug
  const debugEnabled = useSelector(debugModeSelectors.enabled);
  const debugMode = useSelector(debugModeSelectors.mode);

  const { shapeAndDisplayToast } = useCartToast();
  const dispatch = useDispatch();
  const {
    selectedItem, isEditingFlow, cart: ccCart
  } = useCCCartState();
  const [updateCartItem] = useUpdateCartItemMutation();
  const { handleLegacyCartItemTransformer } = useCCTransformLegacyCartItem();
  const router = useRouter();
  const [{enabled: redirectToRewardsPageAfterRedemptionEnabled}] = useDecision('fr-web-4540-redirect-to-rewards-page-after-redemption');
  const [{enabled: completeStepsForPromoRedemptionEnabled}] = useDecision('fr-web-4551-complete-steps-for-promo-redemtion');

  const postRedirectAction = (
    newItem: AddableCartItem,
    isSuccess: CartItem,
    eventKeyboardClick?: boolean,
    itemAnalytics?: ItemAnalytics,
    isLineup?: boolean,
    cartResponse?: YumCart
  ): void => {
    if (isEditingFlow) {
      dispatch(openCartRail());
    } else {
      shapeAndDisplayToast(newItem, isSuccess, eventKeyboardClick, itemAnalytics, isLineup);
      dispatch(openCartToast());
    }
    if (redirectToRewardsPageAfterRedemptionEnabled && router.pathname.includes('redeem') && router?.query?.rd === RedemptionRedirectQueryParams.REWARD) {
      router.push( Routes.ACCOUNT.REWARDS);
      return;
    }

    if (completeStepsForPromoRedemptionEnabled && router?.query?.red) {
      const redemptionCode = router.query.red?.toString();
      const associatedWarning = findRedemptionWarningFromPromoOrCode(cartResponse as YumCart, redemptionCode, undefined);
      const warningReasonTypes = associatedWarning?.reasons?.map((reason) => reason.__typename);
      let flowId;
      if (warningReasonTypes?.includes(PromotionNotAppliedReasonType.PromotionEffectTargetNotInCart)) {
        flowId = RedemptionQueryParams.EFFECTS;
      } else if(warningReasonTypes?.includes(PromotionNotAppliedReasonType.ItemPresenceRequirementNotMet)) {
        flowId = RedemptionQueryParams.REQUIREMENTS;
      }
      const path = associatedWarning && flowId ? `/redeem?red=${redemptionCode}&fl=${flowId}` : Routes.UPSELL;
      router.push(path);
    }
  };

  const addToCCCart = async (
    newItem: AddableCartItem,
    onFinishedRequest: () => void,
    onFailedRequest: (err?: Error) => void,
    itemAnalytics?: ItemAnalytics,
    eventKeyboardClick?: boolean,
    isLineup?: boolean
  ) => {
    const addableItem = translateNoSauceFromDealOrPizza(newItem);

    return handleAddCartItem({
      item: addableItem,
      isLegacyBuilder: true,
      onSuccess: (cartResponse, cartItem) => {
        onFinishedRequest();
        postRedirectAction(
          addableItem,
          transformCCCartItemToLegacy(cartItem),
          eventKeyboardClick,
          itemAnalytics,
          isLineup,
          cartResponse as YumCart
        );
      },
      onError: (err) => {
        onFailedRequest(new Error(err.message));
      }
    });
  };

  const sortedItemPointers = useSelector(presentationalRailSelectors.sortedItemPointers);

  const addToCart = async (
    newItem: AddableCartItem,
    onFinishedRequest: () => void,
    onFailedRequest: (err?: Error) => void,
    itemAnalytics?: ItemAnalytics,
    eventKeyboardClick?: boolean,
    isLineup?: boolean
  ) => {
    dispatch(cartLoading());

    if (isEditingFlow) {
      const transformedItem = handleLegacyCartItemTransformer(newItem);
      try {
        const update = await updateCartItem({
          cartId: ccCart?.cartId,
          item: {
            ...transformedItem,
            cartItemId: selectedItem?.cartItemId || ''
          }
        });

        if ('error' in update) throw update.error;

        if (debugEnabled) {
          transformationDebugLogging(
            'transformCCCartItemToLegacy | addToCart/isEditingFlow',
            `handleLegacyCartItemTransformer type:${'userSelections' in newItem ? 'DEAL' : newItem.type}`,
            newItem,
            transformedItem,
            debugMode,
            [DebugModeOptions.CART, DebugModeOptions.EDIT, DebugModeOptions.TRANSFORMATIONS]
          );
        }

        const foundNewCartItemId = findNewCartItemId(sortedItemPointers, update.data.items);
        if (selectedItem && selectedItem.cartItemId && foundNewCartItemId) {
          dispatch(
            setCartItemIdOfSortedPointer({
              currentPointer: {
                cartItemId: selectedItem.cartItemId,
                itemId: selectedItem.id
              },
              newCartId: foundNewCartItemId
            })
          );
        }

        dispatch(cartLoaded());
        onFinishedRequest();
        return update;
      } catch (error) {
        if (error instanceof Error) {
          logger.error(error);
        }
        onFailedRequest();
      }
    }

    return addToCCCart(newItem, onFinishedRequest, onFailedRequest, itemAnalytics, eventKeyboardClick, isLineup);
  };

  return {
    addToCart,
    isAddingCartItem,
    addToCartStatus
  };
};

export default useAddToCart;
