import React from 'react';
import { InputLabel, Typography } from '@mui/material';
import clsx from 'clsx';
import { useStyles } from './styles';

interface LabelInputProps {
  htmlFor: string;
  tip?: string;
  children?: React.ReactNode;
  tipClassName?: string;
  className?: string;
}

const Label = ({
  htmlFor, tip, tipClassName, children, className
}: LabelInputProps): JSX.Element => {
  const classes = useStyles();
  return (
    <InputLabel className={clsx(classes.inputLabel, className)} htmlFor={htmlFor}>
      {children}
      {tip && (
        <Typography component="span" className={clsx(classes.tip, tipClassName)}>
          {tip}
        </Typography>
      )}
    </InputLabel>
  );
};

export default Label;
