import transformYumProductPizzasAndMelts from './transformYumProductPizzasAndMelts';
import transformYumProduct from './transformYumProduct';
import transformYumProductWings from './transformYumProductWings';
import { isMelt, isPizza, isWings, productType } from './menuUtilities';
const transformYumBundleChoiceToDealStep = (yumProducts, allOptions, allSlots, choice, minAllowedSelections, bundleChoicePrice = null, removeBundleVariants = false, promotionDefinition) => {
    // get the choiceCode name and products from the bundle choice
    const { choiceCode, displayText, name, variants } = choice;
    // get all of the variant codes available for the choice
    const variantCodes = new Set();
    let variantUpcharges = {};
    variants.forEach((variant) => {
        var _a;
        variantCodes.add(variant.variantCode);
        variantUpcharges = Object.assign(Object.assign({}, variantUpcharges), { [variant.variantCode]: (_a = variant.upcharge.amount) !== null && _a !== void 0 ? _a : 0 });
    });
    const allVariantsOutOfStock = yumProducts
        .flatMap((product) => product.variants)
        .filter((variant) => variantCodes.has(variant.variantCode))
        .every((variant) => variant.blackout);
    // map over each product and convert it to a deal builder recipe
    const recipes = yumProducts
        .map((yumProduct) => {
        // filter out the variants that are not allowed for the product
        const productVariants = yumProduct.variants.filter((variant) => variantCodes.has(variant.variantCode));
        const variantsWithBundlePrice = productVariants.map((variant) => (Object.assign(Object.assign({}, variant), { price: bundleChoicePrice !== null && bundleChoicePrice !== void 0 ? bundleChoicePrice : variant.price })));
        const filteredProduct = Object.assign(Object.assign({}, yumProduct), { variants: variantsWithBundlePrice });
        const { options } = filteredProduct;
        if (isMelt(options) || isPizza(options)) {
            return transformYumProductPizzasAndMelts(filteredProduct, allOptions, allSlots, removeBundleVariants, variantUpcharges, promotionDefinition);
        }
        else if (isWings(options)) {
            return transformYumProductWings(filteredProduct, allOptions, allSlots, removeBundleVariants, variantUpcharges, promotionDefinition);
        }
        else {
            return transformYumProduct(filteredProduct, allOptions, allSlots, removeBundleVariants, variantUpcharges, promotionDefinition);
        }
    })
        .map((product) => {
        // set the appropriate type to each product
        const optionIds = product.options.map((option) => option.id);
        return Object.assign(Object.assign({}, product), { customizationsIncluded: 0, isCYO: false, price: bundleChoicePrice === null || bundleChoicePrice === void 0 ? void 0 : bundleChoicePrice.amount, type: productType(optionIds) });
    });
    const step = {
        allergenDisclaimer: '',
        customizationsIncluded: 0,
        displayOrder: 0,
        displayText,
        id: choiceCode,
        name,
        outOfStock: recipes.every((recipe) => recipe.outOfStock) || allVariantsOutOfStock,
        recipes,
        required: minAllowedSelections >= 1,
        rules: [],
        type: 'STEP',
    };
    return step;
};
export default transformYumBundleChoiceToDealStep;
