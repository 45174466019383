import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { mobileStartBreakpoint } from '@/materialUi/theme';
import fontStyles from '@/common/fontStyles';
import colors from '@/common/colors';

export const cartNavBarButtonStyles = makeStyles((theme: Theme) => createStyles({
  cartGrid: {
    maxWidth: 'fit-content',
    padding: '12px 0px 12px 24px',
    marginRight: '0px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      padding: '0 0 0 8px'
    }
  },
  cartLink: {
    ...fontStyles.headerComponentLink,
    display: 'flex',
    textDecoration: 'none',
    paddingBottom: '4px',
    cursor: 'pointer',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      padding: '4px 0 4px 0',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  cartImage: {
    position: 'relative'
  },
  cartQuantity: {
    top: '-7px',
    left: '2px',
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
    color: colors.gray900,
    fontSize: '12px',
    fontWeight: 'bold',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      fontSize: '9px'
    }
  },
  cartQuantityRed: {
    color: colors.red
  },
  cartIcon: {
    height: '28px',
    width: '33px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      height: '20px',
      width: '24px'
    }
  },
  cartSubtotal: {
    ...fontStyles.headerMainText,
    fontFamily: 'PizzaHutFont',
    marginLeft: '8px',
    lineHeight: '30px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      fontSize: '12px',
      lineHeight: '12px',
      marginLeft: '0px'
    }
  },
  'sr-only': {
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px',
    height: '1px',
    margin: '-1px',
    padding: 0,
    overflow: 'hidden',
    clip: 'rect(1px, 1px, 1px, 1px)',
    border: 0
  },
  'sr-only-focusable': {
    '&:focus.sr-only': {
      width: 'auto',
      height: 'auto',
      margin: 0,
      overflow: 'auto',
      clip: 'auto'
    }
  }
}));

export const cartNavBarSkeletonStyles = makeStyles((theme: Theme) => createStyles({
  cartGrid: {
    maxWidth: 'fit-content',
    padding: '12px 0px 12px 24px',
    marginRight: '0px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      padding: '0 0 0 8px'
    },
    '& .MuiSkeleton-root.MuiSkeleton-circular': {
      width: '35px',
      height: '35px',
      [theme.breakpoints.down(mobileStartBreakpoint)]: {
        width: '20px',
        height: '20px'
      }
    },
    '& .MuiSkeleton-root.MuiSkeleton-text': {
      width: '40px',
      height: '30px',
      marginLeft: '8px',
      [theme.breakpoints.down(mobileStartBreakpoint)]: {
        width: '27px',
        height: '13px',
        marginLeft: 0
      }
    }
  },
  cartLink: {
    display: 'flex',
    padding: '8px 0 4px 0',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      padding: '4px 0 4px 0',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  cartImage: {
    position: 'relative'
  }
}));
