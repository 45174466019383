import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  mobileStartBreakpoint
} from '@/materialUi/theme';

const styles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.3)',
    padding: '12px',
    fontFamily: 'PizzaHutFont',
    fontSize: '48px',
    marginBottom: '0',
    width: 'auto',
    height: '65px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      fontSize: '30px',
      height: '48px',
      justifyContent: 'center'
    }
  },
  closeIcon: {
    minWidth: '40px'
  },
  label: {
    marginTop: '5px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      position: 'relative'
    }
  },
  title: {
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      alignItems: 'center'
    }
  },
  srOnly: {
    position: 'absolute',
    width: '1px',
    height: '1px',
    padding: '0',
    margin: '-1px',
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    whiteSpace: 'nowrap',
    border: '0'
  },
  closeIconItem: {
    zIndex: 1201
  }
}));

export default styles;
