/* eslint-disable @typescript-eslint/no-explicit-any */
import { configureStore } from '@reduxjs/toolkit';
import { CoreConfigReducer } from './slices/CoreConfigSlice';
import { customerApi } from './api/customer/CustomerApi';
import { CustomerReducer } from './slices/CustomerSlice';
import { DealBuilderReducer } from './slices/DealBuilderSlice';
import { menuApi } from './api/menu/MenuApi';
import { menuApiContentGateway } from './api/menu/MenuApiContentGateway';
import { MenuReducer } from './slices/MenuSlice';
import { orderApi, orderSessionApi } from './api/order/OrderApi';
import { OrderReducer } from './slices/OrderSlice';
import { storeApi, storeLocalizationApi } from './api/store/StoreApi';
import rtkQueryErrorLogger from './middleware/errorLogger';
import { paymentApi } from './api/order/PaymentApi';
import { PizzaBuilderReducer } from './slices/PizzaBuilderSlice';
import { WingsBuilderReducer } from './slices/WingBuilderSlice';
import { yumApi } from './api/customer/YumApi';
import { homepageApi } from './api/homepage/HomepageApi';
import { loyaltyApi } from './api/customer/LoyaltyApi';
export const ClientCoreReducer = {
    [customerApi.reducerPath]: customerApi.reducer,
    [homepageApi.reducerPath]: homepageApi.reducer,
    [loyaltyApi.reducerPath]: loyaltyApi.reducer,
    [menuApi.reducerPath]: menuApi.reducer,
    [menuApiContentGateway.reducerPath]: menuApiContentGateway.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [orderSessionApi.reducerPath]: orderSessionApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [storeApi.reducerPath]: storeApi.reducer,
    [storeLocalizationApi.reducerPath]: storeLocalizationApi.reducer,
    [yumApi.reducerPath]: yumApi.reducer,
    coreConfig: CoreConfigReducer,
    customer: CustomerReducer,
    dealBuilder: DealBuilderReducer,
    menu: MenuReducer,
    order: OrderReducer,
    pizzaBuilder: PizzaBuilderReducer,
    wingBuilder: WingsBuilderReducer,
};
export const defaultStoreConfig = {
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware()
            .concat(customerApi.middleware)
            .concat(homepageApi.middleware)
            .concat(loyaltyApi.middleware)
            .concat(menuApi.middleware)
            .concat(menuApiContentGateway.middleware)
            .concat(orderApi.middleware)
            .concat(orderSessionApi.middleware)
            .concat(paymentApi.middleware)
            .concat(rtkQueryErrorLogger)
            .concat(storeApi.middleware)
            .concat(storeLocalizationApi.middleware)
            .concat(yumApi.middleware);
    },
    reducer: ClientCoreReducer,
};
export const makeStore = () => configureStore({
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware()
            .concat(customerApi.middleware)
            .concat(homepageApi.middleware)
            .concat(loyaltyApi.middleware)
            .concat(menuApi.middleware)
            .concat(menuApiContentGateway.middleware)
            .concat(orderApi.middleware)
            .concat(orderSessionApi.middleware)
            .concat(paymentApi.middleware)
            .concat(rtkQueryErrorLogger)
            .concat(storeApi.middleware)
            .concat(storeLocalizationApi.middleware)
            .concat(yumApi.middleware);
    },
    reducer: ClientCoreReducer,
});
export const makeMockStore = (extraReducers, defaultMiddleware) => configureStore({
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware(defaultMiddleware || {})
            .concat(customerApi.middleware)
            .concat(homepageApi.middleware)
            .concat(loyaltyApi.middleware)
            .concat(menuApi.middleware)
            .concat(menuApiContentGateway.middleware)
            .concat(orderApi.middleware)
            .concat(orderSessionApi.middleware)
            .concat(paymentApi.middleware)
            .concat(rtkQueryErrorLogger)
            .concat(storeApi.middleware)
            .concat(storeLocalizationApi.middleware)
            .concat(yumApi.middleware);
    },
    reducer: Object.assign(Object.assign({}, ClientCoreReducer), extraReducers),
});
export let ReduxStore = makeStore();
export const getStore = () => ReduxStore;
export const setStore = (store) => {
    ReduxStore = store;
};
export default ReduxStore;
