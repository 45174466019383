/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState } from 'react';
import { Grid, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { connect, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import {
  searchCarryout,
  searchCarryoutByLatLongV2,
  searchCarryoutV2,
  searchDeliveryV2,
  switchToCarryout,
  switchToDelivery
} from '../actions';
import CarryoutIcon from '../icons/CarryoutIcon';
import DeliveryIcon from '../icons/DeliveryIcon';
import OccasionTab from './OccasionTab';
import { Occasion } from '../constants';
import DeliveryFormFields from './DeliveryFormFields';
import CarryoutFormFields from './CarryoutFormFields';
import SavedAddressPicker from './SavedAddressPicker';
import { mobileStartBreakpoint } from '../../materialUi/theme';
import {
  SearchFormAnalyticsActionType,
  storeSearchFormAnalytics
} from '../../dataAnalytics/dataAnalyticsHelper';
import { RootState } from '@/rootStateTypes';
import { selectors } from '@/account/profile/profile.slice';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';

const formUseStyles = makeStyles((theme: Theme) => createStyles({
  content: {
    paddingLeft: '24px',
    paddingRight: '24px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      paddingLeft: '16px',
      paddingRight: '16px'
    }
  },
  headerLabel: {
    fontSize: '20px',
    fontFamily: 'open_sans_bold',
    margin: '24px 0 24px 5px',
    letterSpacing: '0.4px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      fontSize: '18px'
    }
  },
  occasionTabs: {
    paddingBottom: '24px'
  },
  rightFlowSearch: {
    padding: '10px'
  }
}));

export function onSubmitHandler(event: any, search: () => void): void {
  event.preventDefault();
  search();
}

const carryoutDefaultParams: CarryoutSearchDetails = {
  city: undefined,
  state: undefined,
  zipcode: undefined
};

const deliveryDefaultParams: DeliveryAddress = {
  address: '',
  address2: '',
  city: '',
  state: '',
  zipcode: ''
};

interface OccasionFormProps {
  searchDeliveryV2: (options: DeliveryAddress) => void;
  searchCarryout: (options: CarryoutSearchByLocation) => void;
  searchCarryoutV2: (options: CarryoutSearchByLocation) => void;
  occasion?: Occasion;
  previousSearchDetails: CarryoutSearchDetails | DeliveryAddress;
  switchToDelivery: () => void;
  switchToCarryout: () => void;
  isSearchingIndicator: boolean;
  searchCarryoutByLatLongV2: (options: CarryoutSearch) => void;
  switchToFormFields: () => void;
  switchToSavedAddresses: () => void;
  useSavedAddresses: boolean;
}

function OccasionForm({
  searchCarryout,
  searchDeliveryV2,
  occasion,
  previousSearchDetails,
  switchToCarryout,
  switchToDelivery,
  isSearchingIndicator,
  switchToFormFields,
  useSavedAddresses,
  switchToSavedAddresses
}:
  OccasionFormProps) {
  const classes = formUseStyles();
  const analytics = useAnalytics();
  const initialCarryoutState = getInitialCarryoutState();
  const initialDeliveryState = getInitialDeliveryState();
  const savedAddresses = useSelector(selectors.addresses);
  const router = useRouter();
  const includeSavedAddressInAnalytics = useSavedAddresses && savedAddresses.length > 0;
  const [autoInput, setAutoInput] = useState(false);

  function getInitialCarryoutState() {
    return previousSearchDetails
      ? { ...carryoutDefaultParams, ...previousSearchDetails }
      : carryoutDefaultParams;
  }

  function getInitialDeliveryState() {
    return previousSearchDetails
      ? { ...deliveryDefaultParams, ...previousSearchDetails }
      : deliveryDefaultParams;
  }
  let formFields;
  if (useSavedAddresses) {
    formFields = (
      <SavedAddressPicker
        savedAddresses={savedAddresses}
        switchToFormFields={switchToFormFields}
        occasion={occasion}
        searchDelivery={searchDeliveryV2}
        searchCarryout={searchCarryout}
        isSearchingIndicator={isSearchingIndicator}
      />
    );
  } else if (occasion === Occasion.DELIVERY) {
    formFields = (
      <DeliveryFormFields
        initialDeliveryState={initialDeliveryState}
        searchDelivery={searchDeliveryV2}
        isSearchingIndicator={isSearchingIndicator}
        switchToSavedAddresses={switchToSavedAddresses}
        showUseSavedAddressButton={savedAddresses.length > 0}
      />
    );
  } else {
    formFields = (
      <CarryoutFormFields
        initialCarryoutState={initialCarryoutState}
        isSearchingIndicator={isSearchingIndicator}
        switchToSavedAddresses={switchToSavedAddresses}
        showUseSavedAddressButton={savedAddresses.length > 0}
      />
    );
  }

  const analyticsAction = (occasion === Occasion.DELIVERY
    ? SearchFormAnalyticsActionType.SWITCH_FROM_DELIVERY
    : SearchFormAnalyticsActionType.SWITCH_FROM_CARRYOUT);

  const onClickSwitchToCarryout = () => {
    switchToCarryout();
    if (savedAddresses.length >= 1) {
      switchToFormFields();
    }
    analytics.push(() => storeSearchFormAnalytics('Carryout', analyticsAction, includeSavedAddressInAnalytics));
  };

  const onClickSwitchToDelivery = () => {
    switchToDelivery();
    if (savedAddresses.length >= 1) {
      switchToSavedAddresses();
    }
    analytics.push(() => storeSearchFormAnalytics('Delivery', analyticsAction, includeSavedAddressInAnalytics));
  };

  if(router.query?.local === 'delivery' && !autoInput) {
    onClickSwitchToDelivery();
    setAutoInput(true)
  }
  return (
    <Grid className={classes.content}>
      <Grid container justifyContent="center">
        <Grid container item xs={12}>
          <div className={classes.headerLabel}>Find your store to see local deals</div>
          <Grid className={classes.occasionTabs} container item spacing={1}>
            <Grid item data-testid="carryout-occasion-tab" xs={6}>
              <OccasionTab
                icon={<CarryoutIcon aria-hidden />}
                label="CARRYOUT"
                selected={occasion === Occasion.CARRYOUT}
                onClick={onClickSwitchToCarryout}
              />
            </Grid>
            <Grid item data-testid="delivery-occasion-tab" xs={6}>
              <OccasionTab
                icon={<DeliveryIcon aria-hidden />}
                label="DELIVERY"
                selected={occasion === Occasion.DELIVERY}
                onClick={onClickSwitchToDelivery}
              />
            </Grid>
          </Grid>
          {formFields}
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state: RootState) => ({
  previousSearchDetails: state.presentational.localization.previousSearchDetails,
  occasion: state.presentational.localization.occasion,
  isSearchingIndicator: state.presentational.localization.isSearchingIndicator,
  savedAddresses: state.presentational.localization.savedAddresses
});

const mapDispatchToProps = {
  searchCarryout,
  searchCarryoutV2,
  searchDeliveryV2,
  switchToDelivery,
  switchToCarryout,
  searchCarryoutByLatLongV2
};

export default connect(mapStateToProps, mapDispatchToProps)(OccasionForm);
