import { SlotKeys } from '../types';
import computeNutrition from './computeNutrition';
const transformCartItemToPizzaBuilderState = (cartItem, pizzaBuilder) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { cartItemId, modifiers = [], price = 0, quantity = 1, specialInstructions = '', variantCode = '', } = cartItem;
    const { cheeses, glutenFree, sizesAndCrusts, sauces, toppings } = pizzaBuilder;
    const allToppings = toppings.reduce((acc, group) => {
        var _a;
        return acc.concat((_a = group.modifiers) !== null && _a !== void 0 ? _a : []);
    }, []);
    const cartCheese = (_b = (_a = modifiers.find((modifier) => modifier.id === SlotKeys.pizzaCheese || modifier.id === SlotKeys.meltCheese)) === null || _a === void 0 ? void 0 : _a.modifiers) === null || _b === void 0 ? void 0 : _b[0];
    const cartSauce = (_d = (_c = modifiers.find((modifier) => modifier.id === SlotKeys.pizzaSauce || modifier.id === SlotKeys.meltSauce)) === null || _c === void 0 ? void 0 : _c.modifiers) === null || _d === void 0 ? void 0 : _d[0];
    const cartToppings = (_e = modifiers.find((modifier) => modifier.id === SlotKeys.pizzaToppings)) === null || _e === void 0 ? void 0 : _e.modifiers;
    const selectedCheese = cheeses.reduce((acc, cheese) => {
        var _a;
        (_a = cheese.modifiers) === null || _a === void 0 ? void 0 : _a.forEach((modifier) => {
            if (modifier.weightCode === (cartCheese === null || cartCheese === void 0 ? void 0 : cartCheese.weightCode) && !modifier.outOfStock) {
                acc = modifier;
            }
        });
        return acc;
    }, null);
    const selectedSauce = sauces.reduce((acc, sauce) => {
        var _a;
        (_a = sauce.modifiers) === null || _a === void 0 ? void 0 : _a.forEach((modifier) => {
            if (modifier.id === (cartSauce === null || cartSauce === void 0 ? void 0 : cartSauce.id) && !modifier.outOfStock) {
                acc = modifier;
            }
        });
        return acc;
    }, null);
    const selectedToppings = (_f = cartToppings === null || cartToppings === void 0 ? void 0 : cartToppings.reduce((acc, cartTopping) => {
        const topping = allToppings.find((modifier) => modifier.id === cartTopping.id);
        if (topping && !topping.outOfStock) {
            acc.push(Object.assign(Object.assign({}, topping), { placement: cartTopping.placement, portion: cartTopping.portion, weightCode: cartTopping.weightCode }));
        }
        return acc;
    }, [])) !== null && _f !== void 0 ? _f : [];
    const [selectedCrust, selectedSize] = sizesAndCrusts.reduce((acc, size) => {
        var _a;
        (_a = size.modifiers) === null || _a === void 0 ? void 0 : _a.forEach((crust) => {
            if (crust.variantCode === variantCode) {
                acc.push(crust);
                acc.push(size);
            }
        });
        return acc;
    }, []);
    const maxToppingsAllowed = (_g = selectedCrust.maxAllowed) !== null && _g !== void 0 ? _g : null;
    const nutrition = computeNutrition(selectedSize, selectedCrust, selectedCheese, selectedSauce, selectedToppings);
    return {
        builder: pizzaBuilder,
        cartItemId,
        cheeses,
        glutenFree,
        isCartLoading: false,
        isPriceLoading: false,
        maxToppingsAllowed,
        nutrition,
        price,
        quantity,
        sauces,
        selectedCheese,
        selectedCrust,
        selectedSauce,
        selectedSize,
        selectedToppings,
        sizesAndCrusts,
        specialInstructions,
        toppings,
        type: 'PIZZA',
    };
};
export default transformCartItemToPizzaBuilderState;
