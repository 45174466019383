import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { mobileStartBreakpoint } from '@/materialUi/theme';

export const railStyles = makeStyles((theme: Theme) => createStyles({
  paper: {
    width: 'calc(100vw - 48px)',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      width: '446px'
    }
  }
}));
