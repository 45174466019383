import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, SvgIcon, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useDispatch } from 'react-redux';
import { CtaButton } from '../../coreComponents/buttons/CtaButton/CtaButton';
import { Content } from './slices/Toast.slice';
import { QTY } from './constants';
import { toggleCartRail } from '@/clientCore/redux/rail/CartRailSlice';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    '& button': {
      width: '100%'
    }
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: '20px'
  },
  heading: {
    color: '#131313',
    margin: '0 0 0 8px'
  },
  checkmarkIcon: {
    color: '#228618',
    width: '19px'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '23px'
  },
  topContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  productHeading: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#131313'
  }
}));

interface CartToastContentProps {
  onGoToCart: () => void;
  content?:Content;
}

const CartToastContent = ({
  onGoToCart,
  content
}: CartToastContentProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleGoToCartButtonClick = () => {
    onGoToCart();
    dispatch(toggleCartRail());
  };

  return (
    <Grid className={classes.root} data-testid="cart-toast-content-container">
      <Grid className={classes.content} data-testid="cart-toast-content">
        <Grid className={classes.headerContainer}>
          <SvgIcon
            component={CheckCircleIcon}
            className={classes.checkmarkIcon}
          />
          <h3 className={classes.heading} aria-hidden>
            {content?.heading ? content.heading : 'Added to order!'}
          </h3>
        </Grid>
        {content ? (
          <Grid container className={classes.topContent}>
            <Grid item>
              <Typography
                className={classes.productHeading}
                data-testid="cart-toast-product-title"
              >
                {content.productName}
              </Typography>
            </Grid>
            <Grid item>
              <Typography data-testid="cart-toast-product-price">
                {content.productPrice}
              </Typography>
            </Grid>
          </Grid>
        ) : null}

        {content?.productQuantity ? (
          <Typography data-testid="cart-toast-product-quantity">
            {QTY}: {content?.productQuantity}
          </Typography>
        ) : null}
      </Grid>
      {content?.productPrice
        && (
        <CtaButton
          color="primary"
          clickHandler={handleGoToCartButtonClick}
          labelText="Go to cart"
          size="large"
          ariaLabel="Go to cart"
        />
        )}
    </Grid>
  );
};

export default CartToastContent;
