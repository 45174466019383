import React from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import { CartItem as CartItemType } from '@pizza-hut-us-development/client-core';
import styles from './styles';
import LinkButton from '@/common/LinkButton';
import { useCartItem } from './useCartItem';
import SpecialInstructions from './components/SpecialInstructions';
import QuantityPickerWrapper from './components/QuantityPickerWrapper';
import ItemDescription from './components/ItemDescription';
import DealItems from './components/DealItems';
import { EDIT_ITEM_TEST_ID, WARNING_CONTENT_TITLE_PRICE_TC } from '@/clientCore/cart/constants';
import InfoIcon from '@/localization/icons/InfoIcon';

interface CartItemProps {
  readonly item: CartItemType;
  readonly index: number;
  readonly isLastItem: boolean;
}

const CartItem = ({ item, index, isLastItem }: CartItemProps): JSX.Element => {
  const classes = styles.cartItem();

  const [data, handler] = useCartItem({ item });

  const {
    description,
    name,
    isDeal,
    shouldShowQuantityPicker,
    priceTextContent,
    quantity,
    shouldShowEditButton,
    specialInstructions,
    increaseQuantityButtonDisabled,
    legalText
  } = data;

  const {
    getVluPrefix,
    handleRemoveItem,
    handleIncreaseItemQuantity,
    handleDecreaseItemQuantity,
    handleEditCartItemClick,
    handleOpenWarningInfoClick
  } = handler;

  const itemHeader: JSX.Element = getVluPrefix ? (
    <Grid container direction="column" className={classes.itemContainer}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.headerItemContainer}
      >
        <Grid item className={classes.titleContainer}>
          <Typography variant="h4" className={classes.title}>
            {getVluPrefix() + name}
            {legalText && (
              <IconButton
                aria-label={WARNING_CONTENT_TITLE_PRICE_TC}
                disableFocusRipple
                disableRipple
                className={classes.infoIcon}
                onClick={handleOpenWarningInfoClick?.(legalText)}
                size="large"
              >
                <InfoIcon />
              </IconButton>
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.price}>{priceTextContent}</Typography>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <></>
  );

  const editAndRemoveButtons: JSX.Element = handleRemoveItem && handleEditCartItemClick ? (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.container}
    >
      {shouldShowEditButton && (
        <>
          <LinkButton
            testId={EDIT_ITEM_TEST_ID(index)}
            onClick={handleEditCartItemClick}
          >
            Edit
          </LinkButton>
          <Typography className={classes.spacing}>|</Typography>
        </>
      )}
      <LinkButton
        testId="cart-rail-remove-item"
        onClick={handleRemoveItem}
      >
        Remove
      </LinkButton>
    </Grid>
  ) : (
    <></>
  );

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      data-testid={`cart-item-${index}`}
      classes={{ root: classes.root }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        classes={{ root: isLastItem ? classes.lastItem : classes.item }}
      >
        {itemHeader}
        {!isDeal && description ? (
          <ItemDescription description={description} />
        ) : null}
        {isDeal ? <DealItems item={item} /> : null}
        {specialInstructions ? (
          <SpecialInstructions specialInstructions={specialInstructions} />
        ) : null}
        <Grid container justifyContent="space-between">
          {editAndRemoveButtons}
          <QuantityPickerWrapper
            quantity={quantity}
            shouldShowQuantityPicker={shouldShowQuantityPicker}
            handleIncreaseItemQuantity={handleIncreaseItemQuantity}
            handleDecreaseItemQuantity={handleDecreaseItemQuantity}
            increaseQuantityButtonDisabled={increaseQuantityButtonDisabled}
            dealName={name}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CartItem;
