import { useGetDealsQuery, useGetDealsWithQuickAddPayloadQuery, DiningOccasion } from '@pizza-hut-us-development/client-core';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useDecision } from '@optimizely/react-sdk';
import { selectOccasion } from '@/localization/selectors';
import { OccasionApi } from '@/localization/constants';
import { DealMenu } from '@/menu/deals/types';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { transformGetDealsData } from './transformGetDealsData';
import { debugModeSelectors } from '@/clientCore/redux/selectors/debugSelectors';
import { transformationDebugLogging } from '@/clientCore/debuggerLogging';
import { DebugModeOptions } from '@/clientCore/redux/debug/types';

declare type CCDealsResponse = {
  data?: DealMenu | null;
  isQueryLoading?: boolean | null;
  isError?: boolean | null;
  storeID?: string | null;
};

declare type Props = { skip?: boolean };

declare type UseGetDealsQuery = (arg0: Props) => CCDealsResponse;

const useCCGetDealsMenu: UseGetDealsQuery = ({ skip }) => {
  const [{ enabled: isDealsWithQuickAddPayloadEnabled }] = useDecision('dtg-1510-deals-quick-add-payload');
  const debugEnabled = useSelector(debugModeSelectors.enabled);
  const debugMode = useSelector(debugModeSelectors.mode);
  const [storeNumber, setStoreNumber] = useState('');
  const [storeTimezone, setStoreTimeZone] = useState('');
  const storeDetails = useSelector(localizationSelectors.storeDetails);
  const storeOccasion = useSelector(selectOccasion) as keyof typeof OccasionApi;

  const occasion = OccasionApi[storeOccasion] as OccasionApi;

  useEffect(() => {
    if (storeDetails?.storeNumber) setStoreNumber(storeDetails.storeNumber);
    if (storeDetails?.storeTimezone) setStoreTimeZone(storeDetails.storeTimezone);
  }, [storeDetails]);

  const {
    data: dataUseGetDealsQuery,
    isLoading: isLoadingUseGetDealsQuery,
    isFetching: isFetchingUseGetDealsQuery,
    isError: isErrorUseGetDealsQuery
  } = useGetDealsQuery(
    {
      occasion: DiningOccasion[occasion as keyof typeof DiningOccasion],
      storeNumber
    },
    {
      skip: skip || storeNumber.length === 0 || isDealsWithQuickAddPayloadEnabled
    }
  );

  const {
    data: dataUseGetDealsWithQuickAddPayload,
    isLoading: isLoadingUseGetDealsWithQuickAddPayload,
    isFetching: isFetchingUseGetDealsWithQuickAddPayload,
    isError: isErrorUseGetDealsWithQuickAddPayload
  } = useGetDealsWithQuickAddPayloadQuery(
    {
      storeNumber,
      occasion: DiningOccasion[occasion as keyof typeof DiningOccasion]
    },
    {
      skip: skip || storeNumber.length === 0 || !isDealsWithQuickAddPayloadEnabled
    }
  );

  const data = isDealsWithQuickAddPayloadEnabled ? dataUseGetDealsWithQuickAddPayload : dataUseGetDealsQuery;
  const isLoading = isDealsWithQuickAddPayloadEnabled ? isLoadingUseGetDealsWithQuickAddPayload : isLoadingUseGetDealsQuery;
  const isFetching = isDealsWithQuickAddPayloadEnabled ? isFetchingUseGetDealsWithQuickAddPayload : isFetchingUseGetDealsQuery;
  const isError = isDealsWithQuickAddPayloadEnabled ? isErrorUseGetDealsWithQuickAddPayload : isErrorUseGetDealsQuery;

  const isQueryLoading = isLoading || isFetching;

  const loadingOrSkip = skip || isQueryLoading || storeNumber.length === 0;

  // TEMPORARY - FILTER OUT REWARD ITEMS
  const filteredData = {
    ...data,
    products: data?.products?.filter((product) => !product?.name?.toLowerCase().includes('reward'))
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const transformedData = data && storeTimezone ? transformGetDealsData(filteredData, occasion, storeTimezone || '') : undefined;

  if (!transformedData || loadingOrSkip) {
    return {
      data: null,
      isQueryLoading: true,
      isError: null,
      storeID: null
    };
  }

  if (isError) {
    return {
      data: undefined,
      isQueryLoading,
      isError,
      storeID: storeNumber
    };
  }

  if (debugEnabled) {
    transformationDebugLogging('useCCGetDealsMenu', 'useGetDealsQuery', data, transformedData, debugMode, [
      DebugModeOptions.MENU,
      DebugModeOptions.TRANSFORMATIONS,
      DebugModeOptions.DEALS
    ]);
  }

  return {
    data: transformedData,
    isQueryLoading,
    isError,
    storeID: storeNumber
  };
};

export default useCCGetDealsMenu;
