import React from 'react';
import { Grid } from '@mui/material';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import QuantityPicker from './QuantityPicker';
import styles from '../styles';

const BASE_ANALYTICS_EVENT = {
  event: 'FFE-QuantityPicker',
  event_category: 'Feature Flag Experiment',
  beacon_id: '99.07.02',
  event_action: 'Treated_QuantityPicker'
};

// all these props are optional because the data returned from useCartItem can be undefined. ideally we should not have to do this.
interface QuantityPickerWrapperProps {
  quantity?: number;
  shouldShowQuantityPicker?: boolean;
  increaseQuantityButtonDisabled?: boolean;
  handleIncreaseItemQuantity?(): Promise<void>;
  handleDecreaseItemQuantity?(): Promise<void>;
  dealName?: string;
}

const QuantityPickerWrapper = ({
  quantity,
  shouldShowQuantityPicker,
  handleIncreaseItemQuantity,
  handleDecreaseItemQuantity,
  increaseQuantityButtonDisabled,
  dealName
}: QuantityPickerWrapperProps): JSX.Element | null => {
  const analytics = useAnalytics();
  const classes = styles.cartItem();
  const isQuantityPickerRenderable = handleIncreaseItemQuantity && handleDecreaseItemQuantity;

  if (!shouldShowQuantityPicker || !isQuantityPickerRenderable) {
    return null;
  }

  const onDecreaseItemQuantity = () => {
    analytics.push(() => ({
      ...BASE_ANALYTICS_EVENT,
      event_interaction: `quantity decrease - ${dealName}`
    }));
    return handleDecreaseItemQuantity();
  };

  const onIncreaseItemQuantity = () => {
    if (increaseQuantityButtonDisabled) {
      return Promise.resolve();
    }
    analytics.push(() => ({
      ...BASE_ANALYTICS_EVENT,
      event_interaction: `quantity increase - ${dealName}`
    }));
    return handleIncreaseItemQuantity();
  }

  return (
    <Grid item xs={4} sm={7} className={classes.quantityItem}>
      <QuantityPicker
        quantity={quantity || 0}
        increaseItemQuantity={onIncreaseItemQuantity}
        decreaseItemQuantity={onDecreaseItemQuantity}
      />
    </Grid>
  );
};

export default QuantityPickerWrapper;
