export const sortByDisplayOrder = (a, b) => {
    const aOrder = a.displayOrder ? a.displayOrder : 0;
    const bOrder = b.displayOrder ? b.displayOrder : 0;
    if (aOrder > bOrder) {
        return 1;
    }
    else if (aOrder < bOrder) {
        return -1;
    }
    return 0;
};
export const sortByOutOfStock = (arr) => {
    if (!Array.isArray(arr)) {
        return [];
    }
    const notOos = arr.filter((item) => !item.outOfStock);
    const oos = arr.filter((item) => item.outOfStock);
    return notOos.concat(oos);
};
