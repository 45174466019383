import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import colors from '@/common/colors';

const useOneClickUpsellCartRailListStyles = makeStyles(() => createStyles({
  root: {
    padding: '19px 0 5px 0'
  },
  divider: {
    height: '1px',
    backgroundColor: colors.gray400,
    margin: '.5em'
  },
  headerItemContainer: {
    flexWrap: 'nowrap',
    alignItems: 'baseline',
    gap: '24px',
    flexDirection: 'column'
  },
  titleContainer: {
    display: 'flex',
    margin: '0 16px 0 16px'
  },
  title: {
    textTransform: 'none',
    letterSpacing: '0px',
    lineHeight: '21px',
    fontSize: '18px',
    fontWeight: 'bolder',
    fontFamily: 'open_sans_semi'
  },
  item: {
    padding: '0px'
  },
  upsellsContainer: {
    listStyle: 'none',
    padding: '0',
    '&.MuiGrid-container': {
      width: 'calc(100% - 24px)'
    }
  }
}));

export default useOneClickUpsellCartRailListStyles;
