import { utcToZonedTime } from 'date-fns-tz';
import { getISODay } from 'date-fns';

import { Product } from '@pizza-hut-us-development/client-core';
import { Item } from '../types/PizzaBuilder';
import { OccasionApi } from '@/localization/constants';
import { DealItem } from '@/graphql/types/Deal';
import { DealRecipe } from '@/builders/deals/slice/dealTypes';
import { MenuRecipeV3 } from '@/graphql/types/PizzaMenu';
import AvailabilityFragment from '@/graphql/types/fragment/Availability';
import { ItemWithModifier } from '@/graphql/types/Category';
import { Availability, MenuRecipe } from '@/menu/pizza/pizzaMenuTypes';
import logger from '@/common/logger';
import { DealBuilderWithAvailability } from '@/clientCore/temporaryTransformationalHooks/useCCGetDealBuilderQuery/transformGetDealBuilderData';
import { PARENT_DEAL } from '@/deals/constants';

export type ItemTypes = Item | DealItem | DealRecipe | MenuRecipe | MenuRecipeV3 | ItemWithModifier | Product | DealBuilderWithAvailability;

export type AvailabilityItem<T extends ItemTypes> = T & {
  available: boolean;
  availableInOtherOccasion: boolean;
};

const otherOccasion = {
  [OccasionApi.C]: OccasionApi.D,
  [OccasionApi.D]: OccasionApi.C
};

const checkAvailability = <T extends ItemTypes>(
  item: T,
  occasion: OccasionApi,
  storeTimezone: string
): AvailabilityItem<T> => {
  // Master Deals are always available, On Yum Menu, no availability === always available
  if ((item as DealItem)?.type === PARENT_DEAL || !item.availability?.length) {
    return { ...item, available: true, availableInOtherOccasion: true };
  }

  const storeLocalTime: Date | Error = localTimeAt(storeTimezone);

  if (storeLocalTime instanceof Error) {
    logger.error(
      new Error(
        "Unable to get store's local time. Treating item as if available."
      ),
      {
        itemId: item.id,
        storeTimezone
      }
    );
    return { ...item, available: true, availableInOtherOccasion: true };
  }

  const dayWithOffset = getISODay(storeLocalTime);

  let availableInOtherOccasion = false;

  const available = !!item?.availability?.some(
    (availability: AvailabilityFragment | Availability) => {
      const isAvailableToday = availability.days.includes(dayWithOffset);
      if (occasion === availability.occasion && isAvailableToday) {
        return true;
      }

      if (otherOccasion[occasion as keyof typeof otherOccasion] === availability.occasion && isAvailableToday) {
        availableInOtherOccasion = true;
      }

      return false;
    }
  );
  return { ...item, available, availableInOtherOccasion };
};

export default checkAvailability;

function localTimeAt(storeTimezone: string): Date | Error {
  if (storeTimezone === '') {
    return new Error('Unable to get local time');
  }
  const utcTime = new Date();
  const zoned = utcToZonedTime(utcTime, storeTimezone);

  if (isValid(zoned)) {
    return zoned;
  }
  return new Error('Unable to get local time');
}

function isValid(date: Date): boolean {
  return date instanceof Date && !Number.isNaN(date.valueOf());
}
