export const transformStepsWithBlackout = (bundle, bundleChoices, products) => {
    const { choices } = bundle;
    // restructure bundleChoices to have minAllowedSelections, outOfStock, and required values for checking out of stock scenarios
    const steps = choices.reduce((acc, choice) => {
        const choiceData = bundleChoices.find((bChoice) => bChoice.choiceCode === choice.choiceCode);
        if (choiceData) {
            acc.push(Object.assign(Object.assign({}, choiceData), { minAllowedSelections: choice.minAllowedSelections, outOfStock: false, required: choice.minAllowedSelections >= 1 }));
        }
        return acc;
    }, []);
    const stepsWithBlackout = steps.reduce((acc, step) => {
        const variants = step.variants;
        // get all variant codes and all products
        const variantCodes = variants.map((variant) => variant.variantCode);
        const allVariants = products.flatMap((product) => product.variants);
        // get included products on the deal step to capture blackout value
        const includedProducts = products.reduce((ac, product) => {
            const { variants: productVariants } = product;
            const productVariantCodes = productVariants.map((variant) => variant.variantCode);
            const includeProduct = productVariantCodes.some((variantCode) => variantCodes.includes(variantCode));
            if (includeProduct) {
                ac.push(product);
            }
            return ac;
        }, []);
        // for each step's variants, find the variant from all variants and map its blackout value like so
        // {some_variant_code: false}
        const getStepVariants = variantCodes.reduce((accum, variantCode) => {
            const matchedVariant = allVariants.find((variant) => variant.variantCode === variantCode);
            const matchedVariantCode = matchedVariant === null || matchedVariant === void 0 ? void 0 : matchedVariant.variantCode;
            if (matchedVariant && matchedVariantCode) {
                accum[matchedVariantCode] = !!matchedVariant.blackout;
            }
            return accum;
        }, {});
        // for each variant, clone the step variant and add the blackout value from the product variant
        const variantsWithBlackout = variants.map((variant) => (Object.assign(Object.assign({}, variant), { blackout: getStepVariants[variant.variantCode] })));
        // clone the step with the outOfStock value automatically false if all variants are out of stock
        acc.push(Object.assign(Object.assign({}, step), { outOfStock: variantsWithBlackout.every((variant) => variant.blackout) ||
                includedProducts.every((product) => product.blackout), variants: variantsWithBlackout }));
        return acc;
    }, []);
    return stepsWithBlackout;
};
