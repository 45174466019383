import React from 'react';
import { Button } from '@mui/material';

const RemoveLocalizationButton = (): JSX.Element => {
  const handleDeleteLocalizationCookie = async () => {
    const fetchDeleteLocalizationEndpoint = await fetch(
      '/api/delete-localization-cookie',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    );

    const fetchDeleteLocalizationEndpointResponse = await fetchDeleteLocalizationEndpoint.json();

    if (fetchDeleteLocalizationEndpointResponse.success) {
      document.cookie = "user_state='';max-age=-99999;domain=.pizzahut.com;path=/";
    }
  };

  return (
    <Button
      color="secondary"
      variant="contained"
      onClick={handleDeleteLocalizationCookie}
    >
      Remove Localization
    </Button>
  );
};

export default RemoveLocalizationButton;
