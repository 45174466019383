import transformPizzaBuilderToYumCartItem from './transformPizzaBuilderToYumCartItem';
import transformProductToCartLineItemInput from './transformProductToCartLineItemInput';
import transformWingBuilderToYumCartItem from './transformWingBuilderToYumCartItem';
export const transformDealBuilderToYumCartLineItemUpdate = (lineItemId, state) => {
    const { stepSelections, steps } = state;
    const dealItem = {
        configuredChoices: steps.reduce((acc, step, index) => {
            var _a, _b, _c;
            const selection = stepSelections[index]; // recipe
            let configuredProduct;
            switch (selection.type) {
                case 'MELT':
                case 'PIZZA': {
                    const configuredPizza = transformPizzaBuilderToYumCartItem(selection).items[0]
                        .configuredProduct;
                    configuredProduct = configuredPizza;
                    break;
                }
                case 'WINGS': {
                    const configuredWings = transformWingBuilderToYumCartItem(selection).configuredProduct;
                    configuredProduct = configuredWings;
                    break;
                }
                default: {
                    configuredProduct = (_b = (_a = transformProductToCartLineItemInput(selection).products) === null || _a === void 0 ? void 0 : _a[0].configuredProduct) !== null && _b !== void 0 ? _b : {
                        productCode: '',
                        selectedModifiers: [],
                        variantCode: '',
                    };
                }
            }
            const configuredChoiceInput = {
                choiceID: step.id,
                configuredProduct,
                quantity: 1,
                specialInstructions: (_c = step.specialInstructions) !== null && _c !== void 0 ? _c : '',
            };
            acc.push(configuredChoiceInput);
            return acc;
        }, []),
        lineItemId,
        quantity: 1,
        specialInstructions: '',
    };
    const yumCartItem = {
        bundles: [dealItem],
        raiseOnConflict: true,
    };
    return yumCartItem;
};
export default transformDealBuilderToYumCartLineItemUpdate;
