import React, { ReactNode } from 'react';
import { useDecision } from "@optimizely/react-sdk";

import Header from "@/header";
import Footer from "@/footer";

type LayoutProps = {
    children: ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
    const [{ enabled: relocateHeaderFooterEnabled }] = useDecision('fr-web-4220-relocate_header_footer');

    if (!relocateHeaderFooterEnabled) {
        return children;
    }

    return (
        <>
            <Header visible />
            {children}
            <Footer visible />
        </>
    )
}
