import React from "react";
import { useDecision } from "@optimizely/react-sdk";

import Footer from './Footer';

type ConditionalFooterProps = {
    visible?: boolean;
}

// TODO: When retiring fr-web-4220-relocate_header_footer remove <Footer /> component from individual pages
// and only render in app/Layout.tsx
const ConditionalFooter = ({ visible }: ConditionalFooterProps) => {
  const [{ enabled: relocateHeaderFooterEnabled }] = useDecision('fr-web-4220-relocate_header_footer');

  if (relocateHeaderFooterEnabled && !visible) return null;

  return <Footer />
}

export default ConditionalFooter;
