var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { orderApiCart } from '../api/order/OrderApiCart';
import { SizeKeys, } from '../types';
import { applyWingBuilderIncludeRules, computeBuilderQuantity, computeDipsNutrition, computeWingNutrition, computeWingPrice, decrement, increment, removeDipsExcludedByVariant, transformWingBuilderToCartItem, transformWingBuilderToCartLineItemUpdate, transformWingBuilderToYumCartItem, } from '../utils';
import { OrderActions } from './OrderSlice';
const initialState = {
    builder: { dips: [], wings: [] },
    dips: [],
    dipsNutrition: [],
    isCartLoading: false,
    isPriceLoading: false,
    nutrition: '',
    price: 0,
    quantity: 1,
    selectedAdditionalOption: null,
    selectedDips: [],
    selectedSauce: null,
    selectedSize: null,
    selectedWings: null,
    type: 'WINGS',
    wings: [],
};
const addDipsToCart = createAsyncThunk('wingBuilder/addDipsToCart', (_, { dispatch, rejectWithValue, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { selectedDips } = state.wingBuilder;
    try {
        let responses = [];
        for (const dip of selectedDips) {
            const modifiers = dip.options.reduce((options, option) => {
                var _a;
                if (((_a = option.modifiers) === null || _a === void 0 ? void 0 : _a.length) === 1) {
                    const { name, id, type } = option.modifiers[0];
                    return [
                        ...options,
                        Object.assign(Object.assign({}, option), { id, modifiers: [], name, quantities: [1], type }),
                    ];
                }
                return options;
            }, []);
            const dipItem = Object.assign(Object.assign({}, dip), { modifiers });
            const response = (yield dispatch(orderApiCart.endpoints.addCartItem.initiate({ item: dipItem })));
            responses.push(response);
        }
        // return final cart response which should have all the dips
        return responses[responses.length - 1];
    }
    catch ({ message }) {
        return rejectWithValue(message);
    }
}));
export const addWingsToCart = createAsyncThunk('wingBuilder/addWingsToCart', (_, { dispatch, rejectWithValue, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const { coreConfig: { isYumEcomm }, order: { cart }, wingBuilder, } = getState();
    const cartPayload = isYumEcomm
        ? transformWingBuilderToYumCartItem(wingBuilder)
        : transformWingBuilderToCartItem(wingBuilder);
    try {
        const data = yield dispatch(orderApiCart.endpoints.addBuilderItemToCart.initiate({
            cartItem: { item: cartPayload },
        }));
        if ('error' in data)
            return rejectWithValue(data.error);
        const oldCartIds = (cart === null || cart === void 0 ? void 0 : cart.items.map((item) => item.cartItemId)) || [];
        const [newCartItem] = data.data.items.filter((item) => !oldCartIds.includes(item.cartItemId));
        let cartItemId = newCartItem === null || newCartItem === void 0 ? void 0 : newCartItem.cartItemId;
        let quantity = wingBuilder.quantity;
        if (!cartItemId) {
            const { cartItemId: id, quantity: quant } = computeBuilderQuantity(cart === null || cart === void 0 ? void 0 : cart.items, data.data.items);
            cartItemId = id;
            quantity = quant;
        }
        dispatch(OrderActions.setWingHistory({
            cartItemId,
            state: Object.assign(Object.assign({}, wingBuilder), { isCartLoading: false, isPriceLoading: false, quantity }),
        }));
        return isYumEcomm ? null : yield dispatch(addDipsToCart());
    }
    catch ({ message }) {
        return rejectWithValue(message);
    }
}));
export const editWings = createAsyncThunk('wingBuilder/editWings', (cartItem, { getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { order: { wingHistory }, } = state;
    const { cartItemId } = cartItem;
    const history = wingHistory[cartItem.cartItemId];
    return Object.assign(Object.assign({}, history), { cartItemId });
}));
export const priceWings = createAsyncThunk('wingBuilder/priceWings', (_, { dispatch, rejectWithValue, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const { coreConfig: { isYumEcomm }, order: { cartId }, wingBuilder, } = getState();
    if (isYumEcomm) {
        return computeWingPrice(wingBuilder);
    }
    else {
        if (!wingBuilder.selectedWings || !wingBuilder.selectedSize || !wingBuilder.selectedSauce) {
            return wingBuilder.price;
        }
        try {
            const cartPayload = transformWingBuilderToCartItem(wingBuilder);
            const data = (yield dispatch(orderApiCart.endpoints.priceCart.initiate({ cartId, items: [cartPayload] })));
            return data.data.price;
        }
        catch (err) {
            return rejectWithValue(err.message);
        }
    }
}));
export const updateWings = createAsyncThunk('wingBuilder/updateWings', (itemId, { dispatch, rejectWithValue, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const { coreConfig: { isYumEcomm }, wingBuilder, } = getState();
    let requestBody;
    if (isYumEcomm) {
        requestBody = transformWingBuilderToCartLineItemUpdate(itemId, wingBuilder);
    }
    else {
        const cartPayload = transformWingBuilderToCartItem(wingBuilder);
        requestBody = {
            cartItem: { item: cartPayload },
            itemId,
        };
    }
    try {
        const data = yield dispatch(orderApiCart.endpoints.updateCartItem.initiate(requestBody));
        dispatch(OrderActions.setWingHistory({
            cartItemId: itemId,
            state: Object.assign(Object.assign({}, wingBuilder), { isCartLoading: false, isPriceLoading: false }),
        }));
        return data;
    }
    catch (err) {
        return rejectWithValue(err.message);
    }
}));
export const filterAdditionalOptions = (variantCode, options) => {
    const additionalOptions = options.filter((option) => {
        var _a;
        const { excludedVariants = {}, weightCode = '' } = option;
        const isIncluded = !((_a = excludedVariants[weightCode]) === null || _a === void 0 ? void 0 : _a.includes(variantCode));
        return isIncluded;
    });
    return additionalOptions;
};
export const wingsBuilderSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(addWingsToCart.fulfilled, () => {
            return initialState;
        });
        builder.addCase(addWingsToCart.pending, (state) => {
            state.isCartLoading = true;
        });
        builder.addCase(addWingsToCart.rejected, (state) => {
            state.isCartLoading = false;
        });
        builder.addCase(updateWings.fulfilled, () => {
            return initialState;
        });
        builder.addCase(updateWings.pending, (state) => {
            state.isCartLoading = true;
        });
        builder.addCase(updateWings.rejected, (state) => {
            state.isCartLoading = false;
        });
        builder.addCase(priceWings.fulfilled, (state, action) => {
            state.isPriceLoading = false;
            state.price = action.payload;
        });
        builder.addCase(priceWings.pending, (state) => {
            state.isPriceLoading = true;
        });
        builder.addCase(priceWings.rejected, (state) => {
            state.isPriceLoading = false;
        });
        builder.addCase(editWings.fulfilled, (_, action) => {
            return action.payload;
        });
        builder.addCase(editWings.pending, (state) => {
            state.isCartLoading = true;
        });
        builder.addCase(editWings.rejected, (state) => {
            state.isCartLoading = false;
        });
    },
    initialState,
    name: 'wingBuilder',
    reducers: {
        addDip: (state, action) => {
            const payloadWithQuantity = action.payload.quantity ? action.payload : Object.assign(Object.assign({}, action.payload), { quantity: 1 });
            const dipFromSelectedIndex = state.selectedDips.findIndex((dip) => dip.id === payloadWithQuantity.id);
            let newSelectedDips = [...state.selectedDips];
            if (dipFromSelectedIndex >= 0) {
                newSelectedDips[dipFromSelectedIndex] = payloadWithQuantity;
                state.selectedDips = newSelectedDips;
            }
            else {
                newSelectedDips = [...newSelectedDips, payloadWithQuantity];
            }
            state.selectedDips = newSelectedDips;
        },
        clearBuilder: () => initialState,
        decrementDipQuantity: (state, action) => {
            const dipToBeUpdated = state.selectedDips.find((dip) => dip.id === action.payload.id);
            if ((dipToBeUpdated === null || dipToBeUpdated === void 0 ? void 0 : dipToBeUpdated.quantity) === 1) {
                state.selectedDips = state.selectedDips.filter(({ id }) => id !== action.payload.id);
            }
            else {
                state.selectedDips = state.selectedDips.map((dip) => {
                    if (dip.id === (dipToBeUpdated === null || dipToBeUpdated === void 0 ? void 0 : dipToBeUpdated.id) && dip.quantity) {
                        return Object.assign(Object.assign({}, dip), { quantity: decrement(dip.quantity) });
                    }
                    return dip;
                });
            }
        },
        decrementQuantity: (state) => {
            state.quantity = decrement(state.quantity);
        },
        editDealWings: (_state, action) => {
            const { cartItemId, state } = action.payload;
            return Object.assign(Object.assign({}, state), { cartItemId });
        },
        incrementDipQuantity: (state, action) => {
            state.selectedDips = state.selectedDips.map((dip) => {
                var _a;
                if (dip.id === ((_a = action.payload) === null || _a === void 0 ? void 0 : _a.id) && dip.quantity) {
                    return Object.assign(Object.assign({}, dip), { quantity: increment(dip.quantity) });
                }
                return dip;
            });
        },
        incrementQuantity: (state) => {
            state.quantity = increment(state.quantity);
        },
        removeDip: (state, action) => {
            state.selectedDips = state.selectedDips.filter(({ id }) => id !== action.payload.id);
        },
        setAdditionalOption: (state, action) => {
            state.selectedAdditionalOption = action.payload;
        },
        setSauce: (state, action) => {
            state.selectedSauce = action.payload;
            state.nutrition = computeWingNutrition(action.payload);
        },
        setSize: (state, action) => {
            var _a, _b, _c;
            state.selectedSize = action.payload;
            if (state.selectedSauce) {
                // find the current selectedSauce id to get the correct incoming sauce with the new size
                // we get the nutrition object from that incoming size in order to update the serving count for nutrition
                let selectedSauce;
                // if we are on YHD, the id has modifiers ini it
                if (state.selectedSauce.id.includes('/modifiers')) {
                    const sauceId = ((_a = state.selectedSauce.id) === null || _a === void 0 ? void 0 : _a.split('/modifiers/')[1]) || '';
                    selectedSauce = (_b = action.payload.modifiers) === null || _b === void 0 ? void 0 : _b.find((sauce) => sauce.id.includes(sauceId));
                }
                else {
                    selectedSauce = (_c = action.payload.modifiers) === null || _c === void 0 ? void 0 : _c.find((sauce) => { var _a; return sauce.id === ((_a = state.selectedSauce) === null || _a === void 0 ? void 0 : _a.id); });
                }
                const nutrition = selectedSauce ? computeWingNutrition(selectedSauce) : '';
                if (selectedSauce) {
                    state.selectedSauce = Object.assign({}, selectedSauce);
                }
                state.nutrition = nutrition;
            }
        },
        setWingBuilder: (state, action) => {
            var _a, _b, _c, _d, _e, _f;
            const { includeRules, itemId } = action.payload;
            let { wingBuilder: builder } = action.payload;
            const dipsNutrition = builder.dips ? computeDipsNutrition(builder.dips) : [];
            // apply INCLUDE rules if we have them
            if (includeRules) {
                builder = applyWingBuilderIncludeRules(builder, includeRules);
            }
            const defaultWings = itemId ? builder.wings.find((w) => w.id === itemId) : builder.wings[0];
            // Find selected size
            const defaultSize = defaultWings === null || defaultWings === void 0 ? void 0 : defaultWings.selectedOptions.find((option) => option.type === 'SIZE');
            // find the selected sauce for the selected size
            const defaultSauce = defaultWings === null || defaultWings === void 0 ? void 0 : defaultWings.selectedOptions.find((modifier) => modifier.type === 'MODIFIER' && modifier.selected);
            // get the variant code fo the default wings
            const defaultVariantCode = (_b = (_a = defaultSize === null || defaultSize === void 0 ? void 0 : defaultSize.variantCode) !== null && _a !== void 0 ? _a : defaultSauce === null || defaultSauce === void 0 ? void 0 : defaultSauce.variantCode) !== null && _b !== void 0 ? _b : '';
            // check to see if we have an additional option on the wing product, if we do these are
            // baked wings and we need the additionalOptions available on the product
            const additionalOption = defaultWings === null || defaultWings === void 0 ? void 0 : defaultWings.options.find((option) => !SizeKeys.has(option.id));
            if (additionalOption) {
                const additionalOptions = filterAdditionalOptions(defaultVariantCode, (_c = additionalOption.modifiers) !== null && _c !== void 0 ? _c : []);
                state.additionalOptions = additionalOptions;
            }
            // if the selectedWings has an additional option for dip cups, we need to remove any dips
            // that may be excluded by the variant
            // by default, use the dips in the builder
            let dips = builder.dips;
            const options = defaultWings === null || defaultWings === void 0 ? void 0 : defaultWings.options.find((option) => !SizeKeys.has(option.id));
            if (options) {
                dips = removeDipsExcludedByVariant((_d = defaultSauce === null || defaultSauce === void 0 ? void 0 : defaultSauce.variantCode) !== null && _d !== void 0 ? _d : '', builder.dips, (_e = options.modifiers) !== null && _e !== void 0 ? _e : []);
            }
            // Set all of the wing builder state
            state.builder = builder;
            state.dips = dips;
            state.dipsNutrition = dipsNutrition;
            state.selectedWings = defaultWings !== null && defaultWings !== void 0 ? defaultWings : null;
            state.selectedSize = defaultSize !== null && defaultSize !== void 0 ? defaultSize : null;
            state.selectedSauce = defaultSauce !== null && defaultSauce !== void 0 ? defaultSauce : null;
            state.wings = builder.wings;
            state.price = (_f = defaultWings === null || defaultWings === void 0 ? void 0 : defaultWings.price) !== null && _f !== void 0 ? _f : 0;
        },
        setWings: (state, action) => {
            var _a, _b, _c;
            if (action.payload.id !== ((_a = state.selectedWings) === null || _a === void 0 ? void 0 : _a.id)) {
                state.selectedWings = action.payload;
                // Find selected size
                const defaultSize = (_b = action.payload) === null || _b === void 0 ? void 0 : _b.selectedOptions.find((option) => option.type === 'SIZE');
                // find the selected sauce for the selected size
                const defaultSauce = (_c = action.payload) === null || _c === void 0 ? void 0 : _c.selectedOptions.find((modifier) => modifier.type === 'MODIFIER' && modifier.selected);
                state.selectedSize = defaultSize !== null && defaultSize !== void 0 ? defaultSize : null;
                state.selectedSauce = defaultSauce !== null && defaultSauce !== void 0 ? defaultSauce : null;
            }
        },
    },
});
const { addDip, clearBuilder, decrementDipQuantity, decrementQuantity, editDealWings, incrementDipQuantity, incrementQuantity, removeDip, setAdditionalOption, setSauce, setSize, setWingBuilder, setWings, } = wingsBuilderSlice.actions;
export const WingBuilderActions = {
    addDip,
    clearBuilder,
    decrementDipQuantity,
    decrementQuantity,
    editDealWings,
    incrementDipQuantity,
    incrementQuantity,
    removeDip,
    setAdditionalOption,
    setSauce,
    setSize,
    setWingBuilder,
    setWings,
};
export const WingsBuilderReducer = wingsBuilderSlice.reducer;
