import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  computeComponentKey,
  getJoinedText
} from '../../common/string-formatter';
import { getLinkContent } from '../../graphql/gqlDataManipulation';
import FooterSectionHeader from './FooterSectionHeader';
import AnchorLink from '../../common/AnchorLink';
import getLinkLangAttribute from '../../common/getLinkLangAttribute';
import telemetry from '../../telemetry';

interface CompactViewProps {
  sectionName?: string;
  links: [Links];
  classes: { [key: string]: string };
}

const CompactView = ({ sectionName, links, classes }: CompactViewProps) => (
  <Accordion square classes={{ expanded: classes.accordion }} role="navigation">
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      classes={{
        root: classes.expansionSummary,
        content: classes.expansionSummaryContent
      }}
    >
      <FooterSectionHeader
        // @ts-expect-error - existing code
        sectionName={sectionName}
        className={classes.compactSectionHeader}
      />
    </AccordionSummary>
    <AccordionDetails className={classes.expansionDetails}>
      <ul className={classes.navigationList}>
      {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
      {links.map((links: Links, index: number) => {
          const {
            link,
            linkDisplayText,
            linkTitle,
            linkType,
            external,
            onClick,
            tabIndex,
            preventHref
          } = getLinkContent(links) || {};
          return (
            <li
              className={classes.root}
              // @ts-expect-error - existing code
              key={computeComponentKey(linkDisplayText, index)}
            >
              <Grid
                className={classes.accordionLink}
                onClick={telemetry.addCustomEventOnClick(
                  'footer-navigation-link-click'
                )}
              >
                <AnchorLink
                  link={link}
                  linkTitle={linkTitle}
                  linkDisplayText={linkDisplayText}
                  preventHref={preventHref}
                  linkType={linkType}
                  external={external}
                  onClick={onClick}
                  className={classes.linkText}
                  testId={`footer-${getJoinedText(linkDisplayText)}-link`}
                  dataAnalyticsCategory="global_footer"
                  dataAnalyticsAction={linkDisplayText}
                  tabIndex={tabIndex || 0}
                  lang={getLinkLangAttribute(linkDisplayText)}
                  ariaHaspopup={linkTitle?.toLowerCase() === 'sign in'}
                >
                  <>
                    {linkDisplayText}
                    <ArrowForwardIosIcon
                      fontSize="small"
                      className={classes.headerIcon}
                    />
                  </>
                </AnchorLink>
              </Grid>
            </li>
          );
        })}
      </ul>
    </AccordionDetails>
  </Accordion>
);

export default CompactView;
