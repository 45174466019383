import React, { LegacyRef } from 'react';
import Link from 'next/link';
import { useAnchorLink } from '@/common/AnchorLink/hooks/useAnchorLink';

export interface AnchorLinkProps extends LinkContent {
  className?: string;
  children?: JSX.Element;
  testId?: string;
  dataAnalyticsCategory?: string;
  dataAnalyticsAction?: string;
  tabIndex?: number;
  linkRef?: LegacyRef<HTMLAnchorElement> | undefined;
  lang?: string | undefined;
  preventHref?: boolean;
  handleAfterLocalizing?: (localizationToken: string) => void;
  ariaHaspopup?: boolean;
}

function additionalProps(external: boolean) {
  return external ? { target: '_blank', rel: 'noopener' } : {};
}

function dealLinkProps(preventHref: boolean, href?: string) {
  return preventHref ? { role: 'button' } : { href };
}

const AnchorLink = ({
  link,
  linkTitle,
  linkType,
  linkDisplayText,
  external,
  className,
  children,
  testId,
  tabIndex,
  dataAnalyticsCategory,
  dataAnalyticsAction,
  linkRef,
  lang,
  onClick,
  preventHref = false,
  handleAfterLocalizing,
  ariaHaspopup = false
}: AnchorLinkProps): JSX.Element => {
  const {
    linkContent,
    isRelativeLink,
    relativeLink,
    href,
    handleOnAction,
    handleOnKeyDown
  } = useAnchorLink({
    children,
    linkDisplayText,
    linkType,
    link,
    dataAnalyticsAction,
    onClick,
    handleAfterLocalizing,
    preventHref
  });

  return isRelativeLink ? (
    <Link href={relativeLink ?? ''} legacyBehavior>
      <a
        ref={linkRef}
        data-testid={`link-${testId}`}
        title={linkTitle}
        className={className}
        tabIndex={tabIndex}
        data-analytics-category={dataAnalyticsCategory}
        data-analytics-action={dataAnalyticsAction}
        onClick={handleOnAction}
        onKeyDown={handleOnKeyDown}
        role="button"
        lang={lang}
        {...additionalProps(external)}
        aria-haspopup={ariaHaspopup ? 'dialog' : false}
      >
        {linkContent}
      </a>
    </Link>
  ) : (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <a
      ref={linkRef}
      data-testid={testId}
      title={linkTitle}
      className={className}
      tabIndex={tabIndex}
      data-analytics-category={dataAnalyticsCategory}
      data-analytics-action={dataAnalyticsAction}
      onClick={handleOnAction}
      onKeyDown={handleOnKeyDown}
      lang={lang}
      {...additionalProps(external)}
      {...dealLinkProps(preventHref, href)}
      aria-haspopup={ariaHaspopup ? 'dialog' : false}
    >
      {linkContent}
    </a>
  );
};

export default AnchorLink;
