import { GiftCardTypes } from '@/domain/cart/types';

export enum HutRewardsSignedUp {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  NOT_ENROLLED = 'NOT_ENROLLED'
}

export type CreditInfo = {
  nameOnCard: string;
  cardNumber: string;
  cvvCode: string;
  expiration: string;
  billingZip: string;
};

export type SavedCardInfo = {
  paymentId: string;
  billingZip: string;
  name: string;
  cvvCode: string;
};

export type ScheduleForLaterInfo = {
  date: string;
  approximateDateTime: string | Date;
};

export type ContactlessCurbsideInfo = {
  foodPlacementLocation: string;
  vehicleColor: string;
  vehicleDescription: string;
};

interface HutRewardsPassword {
  password: string;
  confirmPassword: string;
}

export type CustomerCheckoutInfo = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  promotionConsent?: boolean;
  creditDebitInfo?: CreditInfo;
  scheduleForLater?: ScheduleForLaterInfo;
  contactlessCurbside?: ContactlessCurbsideInfo;
  contactlessDelivery?: boolean;
  windowPickup?: boolean;
  deliveryAddress?: DeliveryAddress;
  deliveryInstructions?: string;
  smsOrderUpdate?: string;
  hutRewardsPassword?: HutRewardsPassword;
  hutRewardsRecaptcha?: boolean;
};

export enum PaymentTypes {
  ApplePay = 'Pay',
  CreditDebit = 'Credit/Debit',
  SavedCard = 'SavedCreditcard',
  Cash = 'Cash',
  GiftCard = 'GiftCard',
  NotImplemented = 'NotImplemented'
}

export interface GiftCardBalance {
  balance: number;
}

export interface ApplyableTPGC extends ApplyableBaseGiftCard {
  cvv: string;
  expirationDate?: string;
}

export interface ApplyableLegacyGC extends ApplyableBaseGiftCard {
  pin?: string;
}

export interface ApplyableBaseGiftCard {
  token?: string;
  cardNumber: string;
  balance: number;
  appliedAmount: number;
  savedInfo?: {
    paymentId: string;
    type: GiftCardTypes.Legacy | GiftCardTypes.ThirdParty;
  };
}

export type ApplyableGiftCard = ApplyableTPGC | ApplyableLegacyGC;

export interface ApplePayResult {
  token: string;
  postalCode: string;
}
