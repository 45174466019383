const transformWingBuilderToYumCartItem = (wingBuilder) => {
    var _a, _b;
    const { selectedWings, selectedSauce, selectedAdditionalOption, selectedDips, selectedSize, quantity } = wingBuilder;
    const selectedModifiers = selectedDips.map((dip) => {
        return {
            modifierCode: dip.id,
            modifierWeightCode: 'NONE',
            slotCode: dip.slotCode || '',
        };
    });
    if (selectedAdditionalOption) {
        selectedModifiers.push({
            modifierCode: selectedAdditionalOption.id,
            modifierWeightCode: (_a = selectedAdditionalOption.weightCode) !== null && _a !== void 0 ? _a : 'NONE',
            slotCode: (_b = selectedAdditionalOption.slotCode) !== null && _b !== void 0 ? _b : '',
        });
    }
    const yumProduct = {
        configuredProduct: {
            productCode: (selectedWings === null || selectedWings === void 0 ? void 0 : selectedWings.id) || '',
            selectedModifiers,
            variantCode: (selectedSize === null || selectedSize === void 0 ? void 0 : selectedSize.variantCode) || (selectedSauce === null || selectedSauce === void 0 ? void 0 : selectedSauce.variantCode) || '',
        },
        quantity: quantity || 1,
        raiseOnConflict: true,
    };
    return yumProduct;
};
export default transformWingBuilderToYumCartItem;
