import React, { ReactElement } from 'react';
import { Button, CircularProgress, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import colors from '@/common/colors';
import fontStyles from '@/common/fontStyles';
import { smallMobileStartBreakpoint } from '@/materialUi/theme';
import { formatTestId } from '@/common/string-formatter';
import { ColorOption, CtaButtonProps, VariantOption } from '@/coreComponents/buttons/CtaButton/types';

const getFontStyleKey = (
  color: ColorOption, label: string
): keyof typeof fontStyles => `${color}${label}` as keyof typeof fontStyles;

const useStyles = (
  fullWidth: boolean, color: ColorOption
) => makeStyles((theme: Theme) => createStyles({
  label: {
    fontFamily: 'open_sans_semi'
  },
  containedBtn: {
    borderRadius: '8px'
  },
  lowercaseBtn: {
    textTransform: 'inherit'
  },
  containedBtnPrimary: {
    backgroundColor: colors.red
  },
  additionalStyles: {
    '& .MuiCircularProgress-colorPrimary': {
      color: colors.red
    },
    border: '1px solid red !important'
  },
  containedBtnSecondary: {
    borderRadius: 8,
    backgroundColor: colors.white,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  },
  containedBtnTertiary: {
    backgroundColor: colors.black,
    '&:hover, &:active': {
      backgroundColor: colors.black
    }
  },
  small: {
    ...fontStyles[getFontStyleKey(color, 'SmallCtaLabel')],
    width: 128,
    height: 32
  },
  medium: {
    ...fontStyles[getFontStyleKey(color, 'CtaLabel')],
    width: 192,
    height: 40
  },
  large: {
    ...fontStyles[getFontStyleKey(color, 'CtaLabel')],
    width: fullWidth ? '100%' : 280,
    height: 40,
    paddingRight: '1.4rem',
    paddingLeft: '1.4rem',
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      width: '100%'
    }
  },
  loadingPrimary: {
    backgroundColor: `${colors.red} !important`
  },
  loadingSecondary: {
    backgroundColor: `${colors.white} !important`
  },
  loadingTertiary: {
    backgroundColor: `${colors.black} !important`
  }
}));

export const CtaButton = ({
  labelText,
  size = 'medium',
  clickHandler = () => { },
  autoFocus = false,
  ariaLabel = '',
  type = 'button',
  fullWidth,
  disabled = false,
  loading = false,
  color = 'primary',
  capitalize = true,
  tabIndex = 0,
  ariaHidden = false,
  buttonBaseTag = 'button',
  startIcon,
  endIcon
}: CtaButtonProps): ReactElement => {
  const classes = useStyles(fullWidth || false, color)();
  const sizeStyle = ((sizeChoice) => {
    switch (sizeChoice) {
      case 'small':
        return { sizeSmall: classes.small };
      case 'large':
        return { sizeLarge: classes.large };
      default:
        return { root: classes.medium };
    }
  })(size);

  const colorStyle = ((selectedColor: ColorOption) => {
    switch (selectedColor) {
      case 'secondary':
        return classes.containedBtnSecondary;
      case 'tertiary':
        return classes.containedBtnTertiary;
      default:
        return classes.containedBtnPrimary;
    }
  })(color);

  const variantStyle = ((selectedColor: ColorOption): VariantOption => {
    switch (selectedColor) {
      case 'secondary':
        return 'outlined';
      default:
        return 'contained';
    }
  })(color);

  const loadingStyle = ((selectedColor: ColorOption) => {
    switch (selectedColor) {
      case 'secondary':
        return classes.loadingSecondary;
      case 'tertiary':
        return classes.loadingTertiary;
      default:
        return classes.loadingPrimary;
    }
  })(color);

  return (
    <Button
      type={type}
      aria-label={ariaLabel}
      autoFocus={autoFocus}
      tabIndex={tabIndex}
      onClick={clickHandler}
      color="primary"
      variant={variantStyle}
      classes={{
        contained: clsx(classes.containedBtn, colorStyle, !capitalize && classes.lowercaseBtn),
        outlined: clsx(color === 'secondary' && !disabled && classes.additionalStyles),
        disabled: clsx(loading && loadingStyle),
        ...sizeStyle
      }}
      data-testid={`${color}-cta-button-${formatTestId(labelText)}`}
      size={size}
      disabled={disabled || loading}
      disableRipple
      aria-hidden={ariaHidden}
      component={buttonBaseTag}
      role={buttonBaseTag === 'a' ? 'link' : 'button'}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {loading && <CircularProgress aria-live="assertive" aria-label="Loading" size={24} />}
      {!loading && labelText}
    </Button>
  );
};
