import getConfig from "next/config";

import { YUM_IDP_URL_PROD, YUM_IDP_URL_STG } from "@noqo/constants";

const { publicRuntimeConfig } = getConfig();
const { GRAPHQL_ENVIRONMENT } = publicRuntimeConfig;

let token: string | null = null;

// TODO: NOQO - Temporary API authentication. Replace with fetching existing user access token.
export async function getAuthToken(): Promise<string> {
  if (token) return token;

  const body = GRAPHQL_ENVIRONMENT !== 'production'
    ? new URLSearchParams({
        client_id: 'ph_us_web',
        grant_type: 'guest',
      })
    : JSON.stringify({
        client_id: 'ph_us_web',
      });

  const response = await fetch(GRAPHQL_ENVIRONMENT === 'production' ? YUM_IDP_URL_PROD : YUM_IDP_URL_STG, {
    method: 'POST',
    headers: {
      'Content-Type': GRAPHQL_ENVIRONMENT !== 'production' ? 'application/x-www-form-urlencoded' : 'application/json',
    },
    body
  });

  if (!response.ok) {
    throw new Error('Failed to get auth token');
  }

  const data = await response.json();
  token = data.access_token as string;

  return token
}
