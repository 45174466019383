import { DisplayableMenuItem, DisplayableProductWithSubcategory, isPizzaProduct } from '@/domain/product/types';
import {
  BaseAnalytics,
  BaseProductsAnalytics,
  BeaconIds,
  DealBaseAnalytics,
  ItemAnalytics,
  LineupProductsAnalytics,
  MenuProductAnalytics,
  NationalItemAnalytics,
  PageViewWithItemsAnalytics,
  PageViewWithNationalItemsAnalytics
} from '@/dataAnalytics/analyticsTypes';
import {
  commonAddToDealOrCartAnalytics,
  commonLocalizedCategory,
  commonMenuBaseAnalytics,
  commonUpsellAnalytics,
  getItemsWithoutSizeAndModifiers
} from '@/dataAnalytics/dataAnalyticsHelper';
import ProductId from '@/common/ProductId';
import { NationalMenuTileData } from '@/common/pageSetup/categories/nationalMenuData/types';
import { LINEUP, UPSELL } from '@/deals/constants';
import {
  onDealLoversAddToCartSuccess,
  onLineupLocalizedProductTileAddToCartSuccessFromInfoRail,
  onLineupSelectSizeOrAdditionalOption
} from '@/dataAnalytics/lineup/lineupAnalytics';
import { upsellProductAddedToCartSuccess } from '@/dataAnalytics/upsell/upsellAnalytics';
import { DisplayableProductHookItem } from './useDisplayableProduct/types';

export interface ProductTileCommonEventsParam {
  category: string;
  item: ItemAnalytics;
  size: string;
  modifier: string;
  quantity: number;
  price: number;
  dealId?: string;
  dealName?: string;
}

export const parsePrice = (
  product_price: (number | null | undefined) = null
) : number | null => (Number.isNaN(product_price) ? null : product_price);

export const onNonPizzaMenuPageLoad = (
  label: string,
  items: ItemAnalytics[],
  dealId?: string,
  dealName?: string
): Partial<PageViewWithItemsAnalytics> => ({
  ...commonMenuBaseAnalytics(label, true, dealName, dealId),
  event: 'screen_view',
  beacon_id: BeaconIds.MENU.INITIAL_PAGE_LOAD,
  items: [...getItemsWithoutSizeAndModifiers(items)]
});

export const onNonPizzaMenuPageLoadInDeal = (
  items: ItemAnalytics[],
  deal_id = '',
  deal_name = ''
): Partial<PageViewWithItemsAnalytics> => ({
  event: 'screen_view',
  beacon_id: BeaconIds.DEAL.NON_PIZZA_MENU_PAGE_LOAD,
  screen_name: 'Builder > Deal Builder > Product Builder',
  screen_level1: 'Builder',
  screen_level2: 'Builder > Deal Builder',
  screen_level3: 'Builder > Deal Builder > Product Builder',
  screen_level4: 'Builder > Deal Builder > Product Builder',
  level2_breadcrumb: 'Deals > Product Builder',
  level3_aggregation: 'Product Builder',
  deal_id: new ProductId(deal_id).globalId,
  deal_name,
  items: [...getItemsWithoutSizeAndModifiers(items)]
});

export const onNationalNonDealMenuPageLoad = (
  label: string, items: NationalItemAnalytics[]
): Partial<PageViewWithNationalItemsAnalytics> => {
  const modifiedItems = items.map(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ item_modifier, item_size, ...restAnalyticsItems }) => restAnalyticsItems
  );
  return {
    ...commonMenuBaseAnalytics(label, false),
    event: 'screen_view',
    beacon_id: BeaconIds.MENU.INITIAL_PAGE_LOAD,
    items: [...modifiedItems]
  };
};

export const onNationalMenuTileClick = (category: string, tileName: string): BaseAnalytics => {
  const pageLocation = `National Menu > ${category}`;
  return ({
    beacon_id: BeaconIds.MENU.NATIONAL_TILE_CLICK,
    event: 'button_click',
    screen_name: pageLocation,
    event_category: pageLocation,
    event_action: `${tileName}`,
    event_label: `${pageLocation} > ${tileName}`,
    screen_level1: 'National Menu',
    screen_level2: pageLocation,
    screen_level3: pageLocation,
    screen_level4: pageLocation,
    level2_breadcrumb: pageLocation,
    level3_aggregation: pageLocation
  });
};

export const onNationalClickSeeMore = (category: string): Partial<BaseAnalytics> => ({
  ...commonMenuBaseAnalytics(category, false),
  event: 'button_click',
  event_category: `National Menu > ${category}`,
  event_action: 'See More',
  event_label: `National Menu > ${category} > See More`,
  beacon_id: BeaconIds.MENU.NATIONAL_SEE_MORE
});

export const createSingleItemAnalytics = (
  categoryDisplayName: string,
  product: DisplayableProductHookItem,
  index: number
): ItemAnalytics => ({
  item_list: 'subcategory' in product
    ? `${categoryDisplayName} > ${product.subcategory}`
    : categoryDisplayName,
  item_index: index,
  item_id: product.id,
  item_name: product.name,
  item_category: categoryDisplayName,
  item_position: `${index + 1}`,
  item_stock: `${product.isOutOfStock ? 'Out of Stock' : 'In Stock'}`,
  item_price: product.price,
  item_size: product.selectedSize?.name ?? '',
  item_modifier: product.selectedAdditionalOption?.name ?? ''
});

export const createItemAnalytics = (
  sortedProducts: (DisplayableProductWithSubcategory | DisplayableMenuItem)[],
  categoryDisplayName: string
): ItemAnalytics[] => sortedProducts.map(
  (item, index) => ({
    item_list: 'subcategory' in item ? `${categoryDisplayName} > ${item.subcategory}` : categoryDisplayName,
    item_index: index,
    item_id: item.id,
    item_name: item.name,
    item_category: categoryDisplayName,
    item_position: `${index + 1}`,
    item_stock: `${item.isOutOfStock ? 'Out of Stock' : 'In Stock'}`,
    ...(isPizzaProduct(item) ? {
      item_price: (item.price ?? 0) / 100
    } : {
      // @ts-expect-error - existing code
      item_price: (item.sizes[0]?.price ?? 0) / 100,
      // @ts-expect-error - existing code
      item_size: item.sizes[0]?.name ?? null,
      // @ts-expect-error - existing code
      item_modifier: item?.additionalOptions[item?.sizes[0]?.name]?.[0]?.name ?? null
    })
  })
);

export const createItemAnalyticsForNationalProduct = (
  products: NationalMenuTileData[],
  category: string
): NationalItemAnalytics[] => (products.map((product, index) => ({
  item_list: category,
  item_index: index,
  item_price: null,
  item_name: product.name,
  item_category: category,
  item_position: `${index + 1}`,
  item_stock: '',
  item_id: ''
})));

function beaconPayloadOnMenuCategoryInfoButtonClick(
  category: string,
  eventCategory: string,
  productName: string,
  item?: ItemAnalytics,
  dealId?: string,
  dealName?: string,
  quantity?: number
): BaseProductsAnalytics<MenuProductAnalytics> | BaseAnalytics {
  const isUpsell = category === UPSELL;
  return {
    ...(isUpsell
      ? commonUpsellAnalytics(undefined, undefined)
      : commonMenuBaseAnalytics(category, true)),
    beacon_id: isUpsell ? '21.06.02' : BeaconIds.MENU.LOCALIZED_INFO_BUTTON.CLICK,
    event: 'button_click',
    event_action: 'Info',
    event_category: eventCategory,
    event_label: `${eventCategory} > Info`,
    product_name_hit: productName,
    ...(isUpsell && ({
      products: [
        {
          product_index: 0,
          product_action_list: dealId ? 'Deals' : item?.item_list,
          product_quantity: quantity,
          product_id: item?.item_id ?? '',
          product_name: item?.item_name,
          product_category: dealId ? 'Deals' : category,
          product_price: parsePrice(item?.item_price),
          product_position: item?.item_position,
          product_size: item?.item_size,
          product_modifier: item?.item_modifier,
          product_added_location: UPSELL,
          ...(dealId && {
            product_deal_id: new ProductId(dealId).globalId,
            product_deal_name: dealName || ''
          })
        }
      ]
    }))
  };
}

function beaconPayloadLineupInfoButtonClick(
  productName:string
): DealBaseAnalytics {
  return {
    event: 'button_click',
    screen_name: 'Builder > Deal Builder > Lineup',
    event_category: 'Builder > Deal Builder > Lineup',
    event_action: 'Info',
    event_label: 'Builder > Deal Builder > Lineup > Info',
    screen_level1: 'Builder',
    screen_level2: 'Builder > Deal Builder',
    screen_level3: 'Builder > Deal Builder > Lineup',
    screen_level4: 'Builder > Deal Builder > Lineup',
    level2_breadcrumb: 'Deal Builder > Lineup',
    level3_aggregation: LINEUP,
    beacon_id: BeaconIds.LINEUP.INFO_RAIL_CLICK,
    deal_id: LINEUP,
    deal_name: LINEUP,
    product_name_hit: productName
  };
}
function beaconPayloadOnDealInfoButtonClick(
  dealId: string,
  dealName: string,
  productName: string
): DealBaseAnalytics {
  return {
    beacon_id: BeaconIds.DEAL.LOCALIZED_INFO_BUTTON.CLICK,
    deal_id: new ProductId(dealId).globalId,
    deal_name: dealName,
    event: 'button_click',
    event_action: 'Info',
    event_category: 'Builder > Deal Builder > Product Builder',
    event_label: 'Builder > Deal Builder > Product Builder > Info',
    screen_name: 'Builder > Deal Builder > Product Builder',
    screen_level1: 'Builder',
    screen_level2: 'Builder > Deal Builder',
    screen_level3: 'Builder > Deal Builder > Product Builder',
    screen_level4: `Builder > Deal Builder > Product Builder > ${dealName}`,
    level2_breadcrumb: 'Deals > Product Builder',
    level3_aggregation: 'Product Builder',
    product_name_hit: productName
  };
}

export const onLocalizedProductTileInfoButtonClick = (
  category: string,
  productName: string,
  dealId?: string,
  dealName?: string,
  item?: ItemAnalytics,
  quantity?: number
): DealBaseAnalytics | BaseAnalytics => {
  const isUpsell = category === UPSELL;
  const eventCategory = isUpsell
    ? UPSELL
    : commonLocalizedCategory(category, dealName);
  if (!!dealName && !!dealId) {
    return beaconPayloadOnDealInfoButtonClick(dealId, dealName, productName);
  }
  if (category === 'Lineup') {
    return beaconPayloadLineupInfoButtonClick(productName);
  }
  return beaconPayloadOnMenuCategoryInfoButtonClick(
    category,
    eventCategory,
    productName,
    item,
    dealId,
    dealName,
    quantity
  );
};

const beaconPayloadOnLineupViewProductSlideOut = (item: ItemAnalytics) => ({
  event: 'screen_view',
  screen_name: 'Builder > Deal Builder > Lineup',
  screen_level1: 'Builder',
  screen_level2: 'Builder > Deal Builder',
  screen_level3: 'Builder > Deal Builder > Lineup',
  screen_level4: 'Builder > Deal Builder > Lineup',
  level2_breadcrumb: 'Deals > Deal Builder',
  level3_aggregation: 'Lineup',
  beacon_id: '15.06.02',
  deal_id: LINEUP,
  deal_name: LINEUP,
  product_name_hit: item.item_name,
  items: [item]
});

export const onViewProductInfoSlideOut = (
  category: string,
  item: ItemAnalytics,
  dealId?: string,
  dealName?: string
): PageViewWithItemsAnalytics => {
  const eventCategory = commonLocalizedCategory(category, dealName);

  if (category === LINEUP) {
    return beaconPayloadOnLineupViewProductSlideOut(item);
  }

  return {
    ...commonMenuBaseAnalytics(category, true, dealName, dealId),
    beacon_id: BeaconIds.MENU.LOCALIZE_RAIL_VIEW,
    event: 'screen_view',
    screen_level3: `${eventCategory}${!dealId ? ' > Product Info Slide' : ''}`,
    screen_level4: `${eventCategory} > Product Info Slide`,
    items: [item],
    product_name_hit: item.item_name
  };
};

export const onLocalizedProductTileAddToCartClickFromMenuTile = (
  category: string,
  item: ItemAnalytics,
  quantity: number,
  userAction = 'Add to Order',
  dealId?: string,
  dealName?: string
): BaseProductsAnalytics<MenuProductAnalytics> => {
  const categoryName = dealId ? 'Menu' : category;
  const eventCategory = commonLocalizedCategory(categoryName, dealName);

  return {
    ...commonMenuBaseAnalytics(categoryName, true, dealName, dealId),
    beacon_id: BeaconIds.MENU.ADD_TO_CART.TILE_CLICK,
    event: 'product_click',
    event_action: dealId ? `Menu > ${userAction}` : 'Add to Order',
    event_category: eventCategory,
    event_label: `${eventCategory} > Add to Order`,
    product_name_hit: item.item_name,
    product_action: 'click',
    products: [
      {
        product_index: 0,
        product_id: item.item_id ?? '',
        product_name: item.item_name,
        product_category: dealId ? 'Deals' : category,
        product_quantity: quantity,
        product_price: parsePrice(item.item_price),
        product_position: item.item_position,
        product_action_list: dealId ? 'Deals' : item.item_list,
        product_size: item.item_size ?? '',
        product_modifier: item.item_modifier ?? '',
        ...(dealId && {
          product_deal_id: new ProductId(dealId).globalId,
          product_deal_name: dealName || ''
        })
      }
    ],
    ...(dealName ? commonAddToDealOrCartAnalytics(dealName) : {})
  };
};

export const onLocalizedProductTileAddToCartClickFromProductInfoRail = (
  category: string,
  item: ItemAnalytics,
  quantity: number,
  userAction: string
): BaseProductsAnalytics<MenuProductAnalytics> => ({
  ...commonMenuBaseAnalytics(category, true),
  beacon_id: BeaconIds.MENU.ADD_TO_CART.INFO_RAIL_CLICK,
  event: 'product_click',
  event_action: 'Add to Order',
  event_category: `Menu > ${category} > Product Info Slide`,
  event_label: `Menu > ${category} > Product Info Slide > ${userAction}`,
  screen_level3: `Menu > ${category} > Product Info Slide`,
  screen_level4: `Menu > ${category} > Product Info Slide`,
  product_name_hit: item.item_name,
  product_action: 'click',
  products: [
    {
      product_index: 0,
      product_id: item.item_id ?? '',
      product_name: item.item_name,
      product_category: category,
      product_quantity: quantity,
      product_price: parsePrice(item.item_price),
      product_position: item.item_position,
      product_action_list: `${item.item_list} > Product Info Slide`,
      product_size: item.item_size ?? '',
      product_modifier: item.item_modifier ?? ''
    }
  ]
});

export const onLocalizedProductTileAddToCartClickFromProductInfoRailInsideDeal = (
  item: ItemAnalytics,
  quantity: number,
  userAction: string,
  dealId: string,
  dealName: string
): BaseProductsAnalytics<MenuProductAnalytics> => ({
  beacon_id: BeaconIds.MENU.ADD_TO_CART.INFO_RAIL_CLICK,
  event: 'product_click',
  event_action: `Product Info Slide > ${userAction}`,
  event_category: 'Builder > Deal Builder',
  event_label: `Builder > Deal Builder > Product Info Slide > ${userAction}`,
  screen_level1: 'Builder',
  screen_level2: 'Builder > Deal Builder',
  screen_level3: 'Builder > Deal Builder',
  screen_level4: 'Builder > Deal Builder',
  screen_name: 'Builder > Deal Builder',
  level2_breadcrumb: 'Deals > Deal Builder',
  level3_aggregation: 'Deal Builder',
  deal_id: new ProductId(dealId).globalId,
  deal_name: dealName,
  product_name_hit: item.item_name,
  product_action: 'click',
  products: [
    {
      product_index: 0,
      product_id: item.item_id ?? '',
      product_name: item.item_name,
      product_category: 'Deals',
      product_quantity: quantity,
      product_price: parsePrice(item.item_price),
      product_position: item.item_position,
      product_action_list: 'Deals',
      product_size: item.item_size ?? '',
      product_modifier: item.item_modifier ?? '',
      product_deal_id: new ProductId(dealId).globalId,
      product_deal_name: dealName
    }
  ]
});

export const onLocalizedProductTileAddToCartSuccessFromMenuTile = (
  category: string,
  item: ItemAnalytics,
  quantity: number,
  userAction: string,
  dealId?: string,
  dealName?: string
): BaseProductsAnalytics<MenuProductAnalytics> => {
  const categoryName = dealId ? 'Menu' : category;
  const eventCategory = commonLocalizedCategory(categoryName, dealName);

  if (category === LINEUP) {
    return onDealLoversAddToCartSuccess(item, quantity);
  }

  if (category === UPSELL) {
    return upsellProductAddedToCartSuccess(item, quantity);
  }

  return {
    ...commonMenuBaseAnalytics(categoryName, true, dealName, dealId),
    beacon_id: BeaconIds.MENU.ADD_TO_CART.TILE_SUCCESS,
    event: 'cart_add',
    event_action: dealId ? `Menu > ${userAction}` : 'Add to Order',
    event_category: eventCategory,
    event_label: `${eventCategory} > ${userAction}`,
    product_name_hit: item.item_name,
    product_action: 'Add',
    products: [
      {
        product_index: 0,
        product_id: item.item_id ?? '',
        product_name: item.item_name,
        product_category: dealId ? 'Deals' : category,
        product_quantity: quantity,
        product_price: parsePrice(item.item_price),
        product_position: item.item_position,
        product_action_list: dealId ? 'Deals' : item.item_list,
        product_added_location: eventCategory,
        product_size: item.item_size ?? '',
        product_modifier: item.item_modifier ?? '',
        ...(dealId && {
          product_deal_id: new ProductId(dealId).globalId,
          product_deal_name: dealName || ''
        })
      }
    ],
    ...(dealName ? commonAddToDealOrCartAnalytics(dealName) : {})
  };
};

export const onLocalizedProductTileAddToCartSuccessFromProductInfoRail = (
  category: string,
  item: ItemAnalytics,
  quantity: number,
  userAction: string,
  dealId?: string,
  dealName?: string
): BaseProductsAnalytics<MenuProductAnalytics> | LineupProductsAnalytics<MenuProductAnalytics> => {
  if (category === LINEUP) {
    return onLineupLocalizedProductTileAddToCartSuccessFromInfoRail(item, quantity);
  }

  const eventCategory = commonLocalizedCategory(category, dealName);

  return {
    ...commonMenuBaseAnalytics(category, true, dealName, dealId),
    beacon_id: BeaconIds.MENU.ADD_TO_CART.INFO_RAIL_SUCCESS,
    event: 'cart_add',
    event_action: 'Add to Order',
    event_category: `${eventCategory} > Product Info Slide`,
    event_label: `${eventCategory} > Product Info Slide > ${userAction}`,
    screen_level3: `${eventCategory} > Product Info Slide`,
    screen_level4: `${eventCategory} > Product Info Slide`,
    product_name_hit: item.item_name,
    product_action: 'Add',
    products: [
      {
        product_index: 0,
        product_id: item.item_id ?? '',
        product_name: item.item_name,
        product_category: dealId ? `Deal Builder > ${category}` : category,
        product_quantity: quantity,
        product_price: parsePrice(item.item_price),
        product_position: item.item_position,
        product_action_list: `${item.item_list} > Product Info Slide`,
        product_added_location: `${eventCategory} > Product Info Slide`,
        product_size: item.item_size ?? '',
        product_modifier: item.item_modifier ?? '',
        ...(dealId && {
          product_deal_id: new ProductId(dealId).globalId,
          product_deal_name: dealName || ''
        })
      }
    ]
  };
};

export const onSelectSizeOrAdditionalOption = ({
  category,
  item,
  quantity,
  modifier,
  price,
  size,
  dealId,
  dealName
}: ProductTileCommonEventsParam): BaseProductsAnalytics<MenuProductAnalytics> => {
  if (category === LINEUP) {
    return onLineupSelectSizeOrAdditionalOption({
      category, item, quantity, modifier, price, size
    });
  }

  const eventCategory = commonLocalizedCategory(category, dealName);

  return {
    ...commonMenuBaseAnalytics(category, true, dealName, dealId),
    beacon_id: BeaconIds.MENU.MODIFIER_CLICK,
    event: 'button_click',
    event_action: modifier,
    screen_name: dealId ? 'Builder > Deal Builder > Menu' : `Menu > ${category}`,
    event_category: eventCategory,
    event_label: `${eventCategory} > ${modifier}`,
    product_name_hit: item.item_name,
    level2_breadcrumb: dealId ? 'Deals > Deal Builder' : `Menu > ${category}`,
    level3_aggregation: dealId ? 'Menu > Deal Builder' : `Menu > ${category}`,
    screen_level3: dealId ? 'Builder > Deal Builder > Menu' : `Menu > ${category}`,
    screen_level4: dealId ? `Builder > Deal Builder > Menu ${dealName ? `> ${dealName}` : ''}` : `Menu > ${category}`,
    products: [
      {
        product_index: 0,
        product_id: item.item_id ?? '',
        product_name: item.item_name,
        product_category: category,
        product_quantity: quantity,
        product_price: parsePrice(price),
        product_position: item.item_position,
        product_action_list: item.item_list,
        product_added_location: `Menu > ${category}`,
        product_size: size,
        product_modifier: modifier ?? '',
        ...(dealId && {
          product_action_list: 'Deals',
          product_category: 'Deals',
          product_deal_id: new ProductId(dealId).globalId,
          product_deal_name: dealName,
          product_added_location: 'Deals'

        })
      }
    ]
  };
};

export const onSelectSizeOrAdditionalOptionFromProductInfoRail = ({
  category,
  item,
  quantity,
  modifier,
  price,
  size,
  dealId,
  dealName
}: ProductTileCommonEventsParam): BaseProductsAnalytics<MenuProductAnalytics> => {
  if (category === LINEUP) {
    return onLineupSelectSizeOrAdditionalOption({
      category, item, quantity, modifier, price, size
    }, true);
  }

  const eventCategory = commonLocalizedCategory(category, dealName);

  return {
    ...commonMenuBaseAnalytics(category, true, dealName, dealId),
    beacon_id: BeaconIds.MENU.INFO_RAIL_MODIFIER_CLICK,
    event: 'button_click',
    event_action: modifier,
    event_category: `${eventCategory} > Product Info Slide`,
    event_label: `${eventCategory} > Product Info Slide > ${modifier}`,
    screen_level3: `${eventCategory} > Product Info Slide`,
    screen_level4: `${eventCategory} > Product Info Slide`,
    product_name_hit: item.item_name,
    product_action: 'click',
    products: [
      {
        product_index: 0,
        product_id: item.item_id ?? '',
        product_name: item.item_name,
        product_category: category,
        product_quantity: quantity,
        product_price: parsePrice(price),
        product_position: item.item_position,
        product_action_list: `${item.item_list} > Product Info Slide`,
        product_size: size,
        product_modifier: modifier ?? '',
        ...(dealId && {
          product_category: 'Deals',
          product_action_list: 'Deals > Product Info Slide',
          product_deal_id: new ProductId(dealId).globalId,
          product_deal_name: dealName
        })
      }
    ],
    ...(dealId && {
      screen_name: 'Builder > Deal Builder',
      level2_breadcrumb: 'Deals > Deal Builder',
      level3_aggregation: 'Deal Builder',
      screen_level3: 'Builder > Deal Builder',
      screen_level4: 'Builder > Deal Builder'
    })
  };
};
