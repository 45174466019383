import React from 'react';
import { Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CtaButton } from '@/coreComponents/buttons/CtaButton/CtaButton';
import styles from './CartInfoAndWarningView.styles';
import { BACK_TO_CART_BUTTON } from '@/cart/constants';
import { LinkButton } from '../../../common/LinkButton';
import { useCartInfoAndWarningView } from './hooks/useCartInfoAndWarningView';

const CartInfoAndWarningView = () => {
  const [{ warningContent }] = useCartInfoAndWarningView();
  const classes = styles.cartInfoAndWariningView();

  const {
    content, label, onClick, title, showBackToCartLink
  } = warningContent;

  return (
    <div className={classes.root}>
      {showBackToCartLink && (
        <LinkButton
          testId="back-to-cart"
          ariaLabel={BACK_TO_CART_BUTTON}
          className={classes.link}
          onClick={onClick}
        >
          <ArrowBackIcon /> &nbsp;{BACK_TO_CART_BUTTON}
        </LinkButton>
      )}
      <Typography variant="h3" className={classes.title}>
        {title}
      </Typography>
      <Typography className={classes.content}>{content}</Typography>
      <CtaButton
        color="primary"
        clickHandler={onClick}
        labelText={label}
        fullWidth
        size="large"
      />
    </div>
  );
};

export default CartInfoAndWarningView;
