import React from 'react';
import { Grid, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import ResponsiveImageRender from '../common/ResponsiveImageRender';
import { mobileStartBreakpoint } from '../materialUi/theme';
import telemetry from '../telemetry';
import AnchorLink from "../common/AnchorLink";

const useStyles = makeStyles((theme: Theme) => createStyles({
  logoWrapper: {
    flex: 1,
    maxWidth: 'fit-content',
    display: 'table'
  },
  logoLink: {
    display: 'block'
  },
  phLogo: {
    width: '59px',
    height: '47px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      width: '42px',
      height: '34px'
    }
  }
}));

const Logo = ({ logoImage }: any) => {
  const classes = useStyles();

  return (
    <Grid
      item
      className={classes.logoWrapper}
      data-testid="ph-logo-container"
      onClick={telemetry.addCustomEventOnClick('header-logo-click')}
      aria-label='Pizza Hut Logo. Click to navigate to home page'
    >
      <AnchorLink
        className={classes.logoLink}
        link=""
        tabIndex={0}
        linkTitle="Pizza Hut"
        testId="ph-logo"
        linkType="relativeLink"
        linkDisplayText=""
        dataAnalyticsCategory="global_header"
        dataAnalyticsAction="Logo"
        external={false}
      >
        <ResponsiveImageRender
          testId="ph-logo"
          {...logoImage}
          className={classes.phLogo}
        />
      </AnchorLink>
    </Grid>
  );
};

export default Logo;
