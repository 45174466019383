import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { mobileStartBreakpoint } from '../../materialUi/theme';

export const railHeaderHeight = '65px';
export const railHeaderHeightMobileView = '48px';

export const styles = makeStyles((theme: Theme) => createStyles({
  root: {
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.3)',
    padding: '12px',
    fontFamily: 'PizzaHutFont',
    fontSize: '48px',
    marginBottom: '0',
    width: 'auto',
    height: railHeaderHeight,
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      fontSize: '30px',
      height: railHeaderHeightMobileView,
      justifyContent: 'center'
    }
  },
  icon: {
    paddingRight: '15px'
  },
  closeIcon: {
    minWidth: '40px'
  },
  label: {
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      position: 'relative',
      top: -7
    }
  }
}));

export default styles;
