import React, { ReactElement } from 'react';
import { Button, CircularProgress } from '@mui/material';
import clsx from 'clsx';
import { formatTestId } from '@/common/string-formatter';
import {
  ColorOption,
  CtaButtonProps,
  VariantOption
} from '@/clientCore/components/CtaButton/types';
import { useStyles } from '@/clientCore/components/CtaButton/styles';

const CtaButton = ({
  labelText,
  size = 'medium',
  clickHandler = () => {},
  autoFocus = false,
  ariaLabel = '',
  type = 'button',
  fullWidth,
  disabled = false,
  loading = false,
  color = 'primary',
  capitalize = true,
  tabIndex = 0,
  ariaHidden = false,
  buttonBaseTag = 'button'
}: CtaButtonProps): ReactElement => {
  const classes = useStyles(fullWidth || false, color)();
  const sizeStyle = ((sizeChoice) => {
    switch (sizeChoice) {
      case 'small':
        return { sizeSmall: classes.small };
      case 'large':
        return { sizeLarge: classes.large };
      default:
        return { root: classes.medium };
    }
  })(size);

  const colorStyle = ((selectedColor: ColorOption) => {
    switch (selectedColor) {
      case 'secondary':
        return classes.containedBtnSecondary;
      case 'tertiary':
        return classes.containedBtnTertiary;
      default:
        return classes.containedBtnPrimary;
    }
  })(color);

  const variantStyle = ((selectedColor: ColorOption): VariantOption => {
    switch (selectedColor) {
      case 'secondary':
        return 'outlined';
      default:
        return 'contained';
    }
  })(color);

  const loadingStyle = ((selectedColor: ColorOption) => {
    switch (selectedColor) {
      case 'secondary':
        return classes.loadingSecondary;
      case 'tertiary':
        return classes.loadingTertiary;
      default:
        return classes.loadingPrimary;
    }
  })(color);

  return (
    <Button
      type={type}
      aria-label={ariaLabel}
      autoFocus={autoFocus}
      tabIndex={tabIndex}
      onClick={clickHandler}
      color="primary"
      variant={variantStyle}
      classes={{
        contained: clsx(
          classes.containedBtn,
          colorStyle,
          !capitalize && classes.lowercaseBtn
        ),
        outlined: clsx(
          color === 'secondary' && !disabled && classes.additionalStyles
        ),
        disabled: clsx(loading && loadingStyle),
        ...sizeStyle
      }}
      data-testid={`${color}-cta-button-${formatTestId(labelText)}`}
      size={size}
      disabled={disabled || loading}
      disableRipple
      aria-hidden={ariaHidden}
      component={buttonBaseTag}
      role={buttonBaseTag === 'a' ? 'link' : 'button'}
    >
      {loading && (
        <CircularProgress
          aria-live="assertive"
          aria-label="Loading"
          size={24}
        />
      )}
      {!loading && labelText}
    </Button>
  );
};

export default CtaButton;
