// The types and interfaces contained herein are used to model the
// request bodies and responses for the ordering gateway endpoints
export var CartWarningType;
(function (CartWarningType) {
    CartWarningType["CartPaymentsNotProvided"] = "CartPaymentsNotProvided";
    CartWarningType["LoyaltyOfferNotApplied"] = "LoyaltyOfferNotApplied";
    CartWarningType["PickupDeadlineApproaching"] = "PickupDeadlineApproaching";
    CartWarningType["PromotionNotApplied"] = "PromotionNotApplied";
    CartWarningType["TaxNotApplied"] = "TaxNotApplied";
})(CartWarningType || (CartWarningType = {}));
export var DeliveryProvider;
(function (DeliveryProvider) {
    DeliveryProvider["INTERNAL"] = "INTERNAL";
    DeliveryProvider["DOORDASH"] = "DOORDASH";
    DeliveryProvider["MOCK_DOORDASH"] = "MOCK_DOORDASH";
})(DeliveryProvider || (DeliveryProvider = {}));
export var FeeType;
(function (FeeType) {
    FeeType["DELIVERY_FEE"] = "DELIVERY_FEE";
    FeeType["SERVICE_FEE"] = "SERVICE_FEE";
    FeeType["REGULATORY_FEE"] = "REGULATORY_FEE";
})(FeeType || (FeeType = {}));
export var LoyaltyMetricType;
(function (LoyaltyMetricType) {
    LoyaltyMetricType["POINTS"] = "POINTS";
})(LoyaltyMetricType || (LoyaltyMetricType = {}));
export var LoyaltyNotAppliedReason;
(function (LoyaltyNotAppliedReason) {
    LoyaltyNotAppliedReason["OFFER_NOT_ACTIVE"] = "OFFER_NOT_ACTIVE";
    LoyaltyNotAppliedReason["OFFER_NOT_REDEEMABLE"] = "OFFER_NOT_REDEEMABLE";
    LoyaltyNotAppliedReason["PROMOTION_NOT_APPLIED"] = "PROMOTION_NOT_APPLIED";
})(LoyaltyNotAppliedReason || (LoyaltyNotAppliedReason = {}));
export var LoyaltyOfferOperation;
(function (LoyaltyOfferOperation) {
    LoyaltyOfferOperation["APPLY"] = "apply";
    LoyaltyOfferOperation["REMOVE"] = "remove";
})(LoyaltyOfferOperation || (LoyaltyOfferOperation = {}));
export var OrderPaymentMethod;
(function (OrderPaymentMethod) {
    // Handles credit cards and gift cards
    OrderPaymentMethod["CARD"] = "CARD";
    OrderPaymentMethod["CASH"] = "CASH";
    OrderPaymentMethod["UNKNOWN"] = "UNKNOWN";
})(OrderPaymentMethod || (OrderPaymentMethod = {}));
export var PickupDeadlineType;
(function (PickupDeadlineType) {
    PickupDeadlineType["BundleAvailabilityDeadline"] = "BundleAvailabilityDeadline";
    PickupDeadlineType["ModifierAvailabilityDeadline"] = "ModifierAvailabilityDeadline";
    PickupDeadlineType["ProductAvailabilityDeadline"] = "ProductAvailabilityDeadline";
    PickupDeadlineType["StoreClosedDeadline"] = "StoreClosedDeadline";
    PickupDeadlineType["VariantAvailabilityDeadline"] = "VariantAvailabilityDeadline";
})(PickupDeadlineType || (PickupDeadlineType = {}));
export var PromotionNotAppliedReasonType;
(function (PromotionNotAppliedReasonType) {
    PromotionNotAppliedReasonType["DiningOccasionRequirementNotMet"] = "DiningOccasionRequirementNotMet";
    PromotionNotAppliedReasonType["ItemExclusionRequirementNotMet"] = "ItemExclusionRequirementNotMet";
    PromotionNotAppliedReasonType["ItemPresenceRequirementNotMet"] = "ItemPresenceRequirementNotMet";
    PromotionNotAppliedReasonType["ItemQuantityRequirementNotMet"] = "ItemQuantityRequirementNotMet";
    PromotionNotAppliedReasonType["MaxTotalUsesPerCustomerPerDayRequirementExceeded"] = "MaxTotalUsesPerCustomerPerDayRequirementExceeded";
    PromotionNotAppliedReasonType["MaxTotalUsesPerCustomerRequirementNotMet"] = "MaxTotalUsesPerCustomerRequirementNotMet";
    PromotionNotAppliedReasonType["MaxTotalUsesPerRedemptionCodeRequirementNotMet"] = "MaxTotalUsesPerRedemptionCodeRequirementNotMet";
    PromotionNotAppliedReasonType["MaxTotalUsesRequirementNotMet"] = "MaxTotalUsesRequirementNotMet";
    PromotionNotAppliedReasonType["PromotionEffectTargetNotInCart"] = "PromotionEffectTargetNotInCart";
    PromotionNotAppliedReasonType["PromotionForLoyaltyOfferNotFound"] = "PromotionForLoyaltyOfferNotFound";
    PromotionNotAppliedReasonType["PromotionIsInvalid"] = "PromotionIsInvalid";
    PromotionNotAppliedReasonType["PromotionNotCombinable"] = "PromotionNotCombinable";
    PromotionNotAppliedReasonType["RedemptionCodeNotFound"] = "RedemptionCodeNotFound";
    PromotionNotAppliedReasonType["SalesChannelRequirementNotMet"] = "SalesChannelRequirementNotMet";
    PromotionNotAppliedReasonType["SubtotalNotDiscountable"] = "SubtotalNotDiscountable";
    PromotionNotAppliedReasonType["SubtotalRequirementNotMet"] = "SubtotalRequirementNotMet";
    PromotionNotAppliedReasonType["TimeFrameRequirementNotMet"] = "TimeFrameRequirementNotMet";
    PromotionNotAppliedReasonType["UnrecognizedPromotionReason"] = "UnrecognizedPromotionReason";
    PromotionNotAppliedReasonType["UserStatusRequirementNotMet"] = "UserStatusRequirementNotMet";
})(PromotionNotAppliedReasonType || (PromotionNotAppliedReasonType = {}));
export var SalesChannel;
(function (SalesChannel) {
    SalesChannel["ANDROID"] = "ANDROID";
    SalesChannel["DOORDASH"] = "DOORDASH";
    SalesChannel["GRUBHUB"] = "GRUBHUB";
    SalesChannel["IOS"] = "IOS";
    SalesChannel["KIOSK"] = "KIOSK";
    SalesChannel["POS"] = "POS";
    SalesChannel["UBEREATS"] = "UBEREATS";
    SalesChannel["WEB"] = "WEB";
})(SalesChannel || (SalesChannel = {}));
export var TaxNotAppliedReason;
(function (TaxNotAppliedReason) {
    TaxNotAppliedReason["TAX_RULE_LOGIC_EVALUATION_ERROR"] = "TAX_RULE_LOGIC_EVALUATION_ERROR";
})(TaxNotAppliedReason || (TaxNotAppliedReason = {}));
