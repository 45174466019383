import React, { ReactElement, useEffect } from 'react';
import { SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { RailUrlStatus } from '../constants';
import useYumSignIn from './hooks/useYumSignIn';
import useQueryParams from '@/rail/railContent/signInRail/useQueryParams';
import YumSignInIframeSkeleton from './YumSignInIframe.skeleton';

const useStyles = makeStyles(() => ({
  resetSuccess: {
    padding: '14px 26px 0px 26px'
  },
  iconMessageBlock: {
    display: 'flex',
    alignItems: 'center'
  },
  checkmarkIcon: {
    color: '#228618',
    width: '19px',
    margin: '0 10px 0 0'
  }
}));

interface YumSignInIframeProps {
  setRailTitle: (title: string) => void;
  title?: string;
}

const YumSignInIframe = (props: YumSignInIframeProps): ReactElement => {
  const { setRailTitle } = props;
  const { railStatus, loading } = useYumSignIn({ setRailTitle });
  const classes = useStyles();
  const { removeQueryParams } = useQueryParams();

  useEffect(() => {
    if (railStatus === RailUrlStatus.RESET) {
      setTimeout(() => {
        removeQueryParams(['railStatus', 'reset_code']);
      }, 5000);
    }
  }, [railStatus, removeQueryParams]);

  return (
    <>
      <div className={classes.resetSuccess}>
        {railStatus === RailUrlStatus.RESET && (
          <div className={classes.iconMessageBlock} id="rail-url-status">
            <SvgIcon
              component={CheckCircleIcon}
              className={classes.checkmarkIcon}
            />
            Password reset successful
          </div>
        )}
      </div>
      {loading ? <YumSignInIframeSkeleton /> : null}
      <div
        id="yum-sign-in-iframe-container"
        data-testid="yum-sign-in-iframe"
        style={{
          height: '100%',
          width: '100%',
          opacity: loading ? 0 : 1
        }}
      />
    </>
  );
};

export default YumSignInIframe;
