var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { OptionKeys, } from '../types';
import { removeBundleOnlyVariants } from './filterUtilities';
import { includeVariantForPromotionDefinition, metafieldsHaveSodiumWarning } from './menuUtilities';
import { sortByOutOfStock } from './sortUtilities';
import transformYumAvailability from './transformYumAvailabilty';
import transformYumNutrition from './transformYumNutrition';
export const transformYumProductWings = (yumProduct, allOptions, allSlots, removeBundleVariants = true, variantUpcharges = {}, promotionDefinition) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    // filter out bundlesOnly variants
    const product = removeBundleVariants
        ? Object.assign(Object.assign({}, yumProduct), { variants: removeBundleOnlyVariants(yumProduct.variants) }) : Object.assign({}, yumProduct);
    // destructure the Yum metadata for the CC product
    const { availability: productAvailability, blackout: outOfStock = false, defaultVariantCode, description, images, legalText, metafields, name, privateMetafields, productCode, variants, } = product;
    // get the option keys for this product
    const flavorKey = OptionKeys.wingFlavor;
    const sizeKey = OptionKeys.wingSize;
    // get all sizes and flavors from the options
    const allFlavors = ((_a = allOptions.find((option) => option.id === flavorKey)) === null || _a === void 0 ? void 0 : _a.modifiers) || [];
    const allSizes = ((_b = allOptions.find((option) => option.id === sizeKey)) === null || _b === void 0 ? void 0 : _b.modifiers) || [];
    // get all of the slot codes of the incoming slots
    const allSlotCodes = new Set(allSlots.map((slot) => slot.id));
    // storage for the ids of the flavors, sizes and slots that participate in the product's variants
    // the flavorIds will be used so that only flavors that participate in the products variants will be included
    // under the sizes for this product
    const flavorIds = new Set();
    // the sizeIds will be used so that only sizes that participate in the products variants will be included
    // under the options for this product
    const sizeIds = new Set();
    // the slotIds will be used so that only slots that participate in the products variants AND
    // only slots that have not been filtered out by private metafields will be included under the options for this product
    const slotIds = new Set();
    // storage for the modifier codes that are excluded from each of the product's variants
    // the excluded variants will be added to each modifier if its code is in the map
    const modifierWeightsExcludedByVariant = new Map();
    // storage for the option value codes for each of the product's variants
    // the option value codes are used when building the nutrition object for each modifier
    const variantOptionValues = new Map();
    // storage for the weight price ranges by slot
    // these will be added to the crusts later, and are be used by the computeProductPrice function
    const variantSlotPriceRanges = new Map();
    // store slot optionRequired boolean
    const variantSlotOptionRequired = new Map();
    // determine which variants to use for building the pizza product
    // if a promotion definition is present, the variants will be futher filtered
    const productVariants = promotionDefinition
        ? variants.filter((variant) => includeVariantForPromotionDefinition(productCode, promotionDefinition, variant))
        : variants;
    // iterate over the product's variants to populate all of the storage objects declared above
    productVariants.forEach((variant) => {
        var _a, _b, _c, _d;
        const { selectedOptionValues, slots, variantCode } = variant;
        // get the flavorId and sizeId from the option values on this variant
        const flavorId = (_b = (_a = selectedOptionValues.find((option) => option.optionTypeCode === flavorKey)) === null || _a === void 0 ? void 0 : _a.optionValueCode) !== null && _b !== void 0 ? _b : '';
        const sizeId = (_d = (_c = selectedOptionValues.find((option) => option.optionTypeCode === sizeKey)) === null || _c === void 0 ? void 0 : _c.optionValueCode) !== null && _d !== void 0 ? _d : '';
        // add the ids to the storage objects declared above
        sizeIds.add(sizeId);
        flavorIds.add(flavorId);
        // declare an object to store the weight price ranges for each slot on the variant
        let priceRanges = {};
        slots.forEach(({ minModifierWeight, modifiers, slotCode, weightPriceRanges }) => {
            // only process this slot if the slotCode is in the incoming slots
            // (they could have been filtered out by metafields)
            if (!variantSlotOptionRequired.get(slotCode) && slotCode) {
                variantSlotOptionRequired.set(slotCode, !!minModifierWeight);
            }
            if (allSlotCodes.has(slotCode)) {
                slotIds.add(slotCode);
                if (weightPriceRanges.length > 0) {
                    priceRanges = Object.assign(Object.assign({}, priceRanges), { [slotCode]: weightPriceRanges });
                }
                modifiers.forEach((modifier) => {
                    const { modifierCode } = modifier;
                    const excludedWeights = modifier.weights
                        .filter((weight) => weight.isExcludedFromVariant)
                        .map((weight) => weight.modifierWeightCode);
                    // for each modifier on this slot, if a weight is excluded from this variant,
                    // then add the variant code to the storage declared above
                    excludedWeights.forEach((weightCode) => {
                        var _a, _b;
                        const excludedVariantsByWeight = (_a = modifierWeightsExcludedByVariant.get(modifierCode)) !== null && _a !== void 0 ? _a : new Map();
                        const excludedVariants = (_b = excludedVariantsByWeight.get(weightCode)) !== null && _b !== void 0 ? _b : [];
                        excludedVariants.push(variantCode);
                        excludedVariantsByWeight.set(weightCode, excludedVariants);
                        modifierWeightsExcludedByVariant.set(modifierCode, excludedVariantsByWeight);
                    });
                });
            }
        });
        // add the price ranges for this variant's slots to the storage declared above
        variantSlotPriceRanges.set(variantCode, priceRanges);
        // add the option value codes for this variant to the storage declared above
        variantOptionValues.set(variantCode, selectedOptionValues.map((option) => option.optionValueCode));
    });
    // get the size modifier group from the incoming allOptions
    const sizeModifierGroup = allOptions.find((option) => option.id === sizeKey);
    // create the selectedOptions array it will hold all of the default modifiers for the default variant
    // and the selected flag will be set for each one
    const selectedOptions = [];
    // get the default variant for the product, if there is not one use the first variant
    // in the product's variants
    const defaultVariant = (_c = productVariants.find((variant) => variant.variantCode === defaultVariantCode)) !== null && _c !== void 0 ? _c : productVariants[0];
    // destructure the default modifiers and selected option values
    // and guard against the default variant being undefined
    const { defaultModifiers = [], selectedOptionValues = [] } = Object.assign({}, defaultVariant);
    // get all of the ids for the default modifiers
    const defaultModifierIds = defaultModifiers.map((modifier) => modifier.modifierCode);
    // get the default flavor and size for the default variant
    const defaultFlavorId = (_e = (_d = selectedOptionValues.find((option) => option.optionTypeCode === flavorKey)) === null || _d === void 0 ? void 0 : _d.optionValueCode) !== null && _e !== void 0 ? _e : '';
    const defaultSizeId = (_g = (_f = selectedOptionValues.find((option) => option.optionTypeCode === sizeKey)) === null || _f === void 0 ? void 0 : _f.optionValueCode) !== null && _g !== void 0 ? _g : '';
    // filter all of the flavors that participate in the variants from all flavors, using the storage declared above
    const flavors = sortByOutOfStock(allFlavors
        .filter((flavor) => flavorIds.has(flavor.id))
        .map((flavor) => {
        return Object.assign(Object.assign({}, flavor), { selected: flavor.id === defaultFlavorId, subtype: 'flavors' });
    }) || []);
    // filter all of the sizes that participate in the variants from all sizes, using the storage declared above
    // and create a temporary object containing the participating sizes indexed by the id of the size
    const sizes = allSizes
        .filter((modifier) => sizeIds.has(modifier.id))
        .reduce((acc, size) => {
        return Object.assign(Object.assign({}, acc), { [size.id]: Object.assign(Object.assign({}, size), { modifiers: [], selected: size.id === defaultSizeId, subtype: 'sizes' }) });
    }, {}) || {};
    // iterate over the variants again, adding the flavor for each variant to the modifiers array of the size
    productVariants.forEach((variant) => {
        var _a, _b, _c, _d, _e, _f;
        const { blackout = false, metafields: variantMetafields, nutritionInformation, price: { amount }, privateMetafields: variantPrivateMetafields, selectedOptionValues: variantSelectedOptionValues, slots, variantCode, } = variant;
        const flavorId = (_b = (_a = variantSelectedOptionValues.find((option) => option.optionTypeCode === flavorKey)) === null || _a === void 0 ? void 0 : _a.optionValueCode) !== null && _b !== void 0 ? _b : '';
        const sizeId = (_d = (_c = variantSelectedOptionValues.find((option) => option.optionTypeCode === sizeKey)) === null || _c === void 0 ? void 0 : _c.optionValueCode) !== null && _d !== void 0 ? _d : '';
        const flavor = flavors.find((modifier) => modifier.id === flavorId);
        const size = sizes[sizeId];
        const variantSodiumWarning = metafieldsHaveSodiumWarning(variantPrivateMetafields);
        // add the flavor to the size's modifiers and add the variant code to the flavor
        if (size && flavor) {
            const nutrition = nutritionInformation.map((n) => (Object.assign(Object.assign({}, n), { qualifiers: [flavorId, sizeId, variantCode] })));
            const upcharges = slots.filter((slot) => slotIds.has(slot.slotCode));
            (_e = size.modifiers) === null || _e === void 0 ? void 0 : _e.push(Object.assign(Object.assign(Object.assign(Object.assign({}, flavor), { metafields: variantMetafields, nutrition, outOfStock: blackout, price: amount, privateMetafields: variantPrivateMetafields, slotPriceRanges: variantSlotPriceRanges.get(variantCode) }), (variantSodiumWarning !== undefined && { sodiumWarning: variantSodiumWarning })), { subtype: 'flavors', upcharge: (_f = variantUpcharges[variantCode]) !== null && _f !== void 0 ? _f : 0, upcharges,
                variantCode }));
        }
    });
    // filter all of the slots that participate in the variants from all slots
    const filteredSlots = allSlots.filter((modifier) => slotIds.has(modifier.id)) || [];
    // map over the slots, building all of the modifier groups that will live under options for this product
    // the selected flag will be set to true for all of the default modifiers on the default variant
    const slots = filteredSlots.reduce((acc, slot) => {
        // get the id and modifiers from the slot
        const { modifiers: slotModifiers = [], slotCode = '' } = slot;
        // add nutrition to the modifiers
        const modifiers = slotModifiers.map((modifier) => {
            const nutrition = transformYumNutrition(modifier, variantOptionValues);
            const { nutritionInformation } = modifier, rest = __rest(modifier, ["nutritionInformation"]);
            return Object.assign(Object.assign({}, rest), { nutrition });
        });
        acc.push(Object.assign(Object.assign({}, slot), { modifiers: modifiers.map((modifier) => {
                var _a;
                // get the excluded variants map for this modifier
                const excludedVariants = Object.fromEntries((_a = modifierWeightsExcludedByVariant.get(modifier.id)) !== null && _a !== void 0 ? _a : new Map());
                const selected = defaultModifierIds.includes(modifier.id);
                const modifierToPush = Object.assign(Object.assign(Object.assign({}, modifier), (Object.keys(excludedVariants).length > 0 && { excludedVariants })), { selected });
                if (selected) {
                    selectedOptions.push(modifierToPush);
                }
                return modifierToPush;
            }), optionRequired: !!variantSlotOptionRequired.get(slotCode) }));
        return acc;
    }, []);
    // build the options array for the wings by concatenating the size group with the slots
    const wingSizes = Object.assign(Object.assign({}, sizeModifierGroup), { modifiers: Object.values(sizes) });
    const options = [wingSizes, ...slots];
    // set the default size and crust in the selected options array
    const selectedSize = wingSizes.modifiers.find((s) => s.id === defaultSizeId);
    const selectedFlavor = (_h = selectedSize === null || selectedSize === void 0 ? void 0 : selectedSize.modifiers) === null || _h === void 0 ? void 0 : _h.find((c) => c.id === defaultFlavorId);
    // transform the Yum availablility to CC availablility
    const availability = transformYumAvailability(productAvailability === null || productAvailability === void 0 ? void 0 : productAvailability.schedule);
    // check if this product has a sodium warning private metafield
    const sodiumWarning = metafieldsHaveSodiumWarning(privateMetafields);
    const wings = Object.assign(Object.assign({ availability, description: description !== null && description !== void 0 ? description : '', displayOrder: 0, id: productCode, legalText: legalText !== null && legalText !== void 0 ? legalText : '', metafields,
        name,
        options,
        outOfStock, price: (_j = defaultVariant === null || defaultVariant === void 0 ? void 0 : defaultVariant.price.amount) !== null && _j !== void 0 ? _j : 0, privateMetafields, qoId: productCode, selectedOptions: [selectedSize, selectedFlavor, ...selectedOptions] }, (sodiumWarning !== undefined && { sodiumWarning })), { type: 'PRODUCT' });
    // add images to the product
    images.forEach((image) => {
        if (image.key) {
            wings[image.key] = image.url;
        }
    });
    return wings;
};
export default transformYumProductWings;
