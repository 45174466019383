import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Grid, Typography } from '@mui/material';
import clsx from 'clsx';
import { useDecision } from '@optimizely/react-sdk';

import {
  CartAlert,
  setCartChangedAlert
} from '@/clientCore/redux/rail/CartRailSlice';
import { ITEMS_REMOVED_WARNING, ORDER_PRICE_CHANGE_WARNING } from '@/clientCore/cart/constants';
import { AlertIcon } from '@/clientCore/cart/components/CartRail/common/AlertIcon';
import { CloseIcon } from '@/clientCore/cart/components/CartRail/common/CloseIcon';
import { CartItemsRemoved } from '@/clientCore/cart/components/CartRail/components/CartChangedAlert/CartItemsRemoved/CartItemsRemoved';

import colors from '@/common/colors';
import formattedPrice from '@/common/formattedPrice';
import {
  ENTER_KEY_EVENT_CODE,
  KEYBOARD_ENTER_KEYCODE
} from '@/common/constants';

import styles from './styles';

interface CartChangedAlertProps {
  cartChangedAlert: CartAlert;
}

const CartChangedAlert = ({ cartChangedAlert }: CartChangedAlertProps): JSX.Element => {
  const {
    previousPrice, currentPrice, quantityChanged, itemsRemoved
  } = cartChangedAlert;
  const classes = styles();
  const hasPriceChanged = previousPrice !== currentPrice;
  const formattedPreviousPrice = formattedPrice(previousPrice);
  const formattedCurrentPrice = formattedPrice(currentPrice);
  const multiAlerts = !!(quantityChanged && hasPriceChanged);
  const dispatch = useDispatch();

  const [{ enabled: retainCartWithItemsRemovedWarningEnabled }] = useDecision('fr-web-4470-cart-items-removed-warning');

  const onClose = () => dispatch(setCartChangedAlert(null));

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === ENTER_KEY_EVENT_CODE || event.keyCode === KEYBOARD_ENTER_KEYCODE) {
      event.preventDefault();
      onClose();
    }
  };

  return (
    <Grid container className={classes.container}>
      <Grid container direction="row" className={clsx(classes.header, multiAlerts && classes.alertsContainer)} wrap="nowrap">
        <Grid item className={classes.icon}>
          <AlertIcon color={colors.black} />
        </Grid>
        <Grid item className={classes.textContainer}>
          <Grid container className={clsx(multiAlerts && classes.textGrid)}>
            {!retainCartWithItemsRemovedWarningEnabled && hasPriceChanged && (
            <Typography
              component="span"
              className={clsx(classes.span, multiAlerts && classes.priceChanged)}
            >
              {ORDER_PRICE_CHANGE_WARNING(formattedPreviousPrice, formattedCurrentPrice)}
            </Typography>
            )}
            {quantityChanged && (!retainCartWithItemsRemovedWarningEnabled || !itemsRemoved?.length) && (
              <Typography
                component="span"
              >
                {ITEMS_REMOVED_WARNING}
              </Typography>
            )}
            {retainCartWithItemsRemovedWarningEnabled && itemsRemoved?.length ? (
              <Grid container direction="row">
                <CartItemsRemoved title={ITEMS_REMOVED_WARNING} content={itemsRemoved.join(', ')} />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid item>
          <Button
            data-testid="close-alert"
            aria-label="Discard message"
            onClick={onClose}
            onKeyDown={onKeyDown}
            className={clsx(classes.closeIcon, multiAlerts && classes.multiAlertsCloseIcon)}
          >
            <CloseIcon />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CartChangedAlert;
