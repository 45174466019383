export const isBundleAvailableForTimeAndOccasion = (bundle, time, occasion) => {
    var _a;
    const { availability } = bundle;
    // if the schedule is empty, the bundle is available for all occasions
    if ((availability === null || availability === void 0 ? void 0 : availability.schedule.length) === 0) {
        return true;
    }
    // at this point we have a schedule, check if the occasion is in the schedule
    // if it is not, the bundle is not available
    const [occasionTimeFrame] = (_a = availability === null || availability === void 0 ? void 0 : availability.schedule.filter((scheduleItem) => scheduleItem.occasion === occasion)) !== null && _a !== void 0 ? _a : [];
    if (!occasionTimeFrame) {
        return false;
    }
    // get the actual time frame from the occasion time frame
    const { timeFrame: { blackout, days, duration, endDate, intervalStartTime, startDate }, } = occasionTimeFrame;
    // if the blackout flag is true, the bundle is not available
    if (blackout) {
        return false;
    }
    // get the incoming day of the week and see if it is the time frame
    // for JavaScript Date first day of week is Sunday with value of 0
    // for ISO8601 first day of week is Monday with value of 1
    const day = time.getDay();
    const dayOfTheWeek = day === 0 ? 7 : day;
    const incomingDayIsInTimeFrame = days.includes(dayOfTheWeek);
    // use the intervaleStartTime and duration to see if the incoming time is within the time frame
    // get a copy of the incoming date/time so we have the same day
    const startTime = new Date(time);
    const endTime = new Date(time);
    // get the start time and duration hours/minutes/seconds as numbers so we can set them
    const [startHours, startMinutes, startSeconds] = intervalStartTime.split(':').map((t) => new Number(t).valueOf());
    const [durHours, durMinutes, durSeconds] = duration.split(':').map((t) => new Number(t).valueOf());
    // set the start time hours/minutes/seconds
    startTime.setHours(startHours);
    startTime.setMinutes(startMinutes);
    startTime.setSeconds(startSeconds);
    // add the duration hours/minutes/seconds to the start time and set them on the duration time
    // we need to propagate any overflow of seconds to minutes and minues to hours
    const rSeconds = (startSeconds + durSeconds) % 60;
    const oMinutes = (startSeconds + durSeconds) / 60;
    const rMinutes = (startMinutes + durMinutes + oMinutes) % 60;
    const oHours = (startMinutes + durMinutes + oMinutes) / 60;
    const rHours = startHours + durHours + oHours;
    endTime.setHours(rHours);
    endTime.setMinutes(rMinutes);
    endTime.setSeconds(rSeconds);
    // get the time stamps for incoming time, start time and durations
    // and computes if the incoming time is with the start time and duration
    const incomingTimestamp = time.getTime();
    let startTimestamp = startTime.getTime();
    let endTimestamp = endTime.getTime();
    const incomingTimeIsInTimeFrame = incomingTimestamp >= startTimestamp && incomingTimestamp <= endTimestamp;
    // available is true if the incoming day is valid and the incoming time is within the time frame
    let available = incomingDayIsInTimeFrame && incomingTimeIsInTimeFrame;
    // if we have a start date and end date, we need to check that the incoming date is within the start and end date
    if (!!endDate && !!startDate) {
        // create the start date and set the start time
        // trim off HH:MM:SS string (T00:00:00.000Z)
        const [startYear, startMonth, startDay] = startDate
            .split('T')[0]
            .split('-')
            .map((t) => new Number(t).valueOf());
        const start = new Date(startYear, startMonth - 1, startDay);
        start.setHours(startHours);
        start.setMinutes(startMinutes);
        start.setSeconds(startSeconds);
        // create the end date and set the end time
        // trim off HH:MM:SS string (T00:00:00.000Z)
        const [endYear, endMonth, endDay] = endDate
            .split('T')[0]
            .split('-')
            .map((t) => new Number(t).valueOf());
        const end = new Date(endYear, endMonth - 1, endDay);
        end.setHours(rHours);
        end.setMinutes(rMinutes);
        end.setSeconds(rSeconds);
        // get the start and end timestamps
        startTimestamp = start.getTime();
        endTimestamp = end.getTime();
        // check that the incoming timestamp is with the start and end
        const incomingDateIsInTimeFrame = incomingTimestamp >= startTimestamp && incomingTimestamp <= endTimestamp;
        available = available && incomingDateIsInTimeFrame;
    }
    return available;
};
export default isBundleAvailableForTimeAndOccasion;
