import React from 'react';
import { Grid, Typography } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CtaButton } from '@/coreComponents/buttons/CtaButton/CtaButton';
import styles from './CartCheckout.styles';
import { CHECKOUT_SUBMIT_LABEL } from '@/clientCore/cart/constants';
import { LinkButton } from '../../../common/LinkButton';
import { useCartCheckout } from './hooks/useCartCheckout';
import { CartCheckoutSkeleton } from './CartCheckout.skeleton';
import { FeeList } from './components/FeeList';
import { CartCheckoutProps } from './types';

const CartCheckout = (props: CartCheckoutProps) => {
  const checkoutClasses = styles.cartCheckout();
  const [
    {
      isCheckoutButtonDisabled,
      isCartLoading,
      summaryExpanded,
      fees,
      total,
      discountedAmount,
      subtotal,
      tax
    },
    { handleGoToCheckoutButtonClick, handleSummaryExpanded, handleFeeClick }
  ] = useCartCheckout(props);

  return (
    <div className={checkoutClasses.root}>
      {/* Header */}
      <div>
        <Grid
          container
          justifyContent="space-between"
          className={checkoutClasses.summaryRoot}
        >
          <Grid className={checkoutClasses.total}>
            <Typography variant="h2">Total</Typography>
            <LinkButton
              className={checkoutClasses.link}
              ariaLabel="taxes and fees"
              onClick={handleSummaryExpanded}
              isExpandable
              isExpanded={summaryExpanded}
              testId="expandable-button"
            >
              Taxes and fees incl.
              {summaryExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </LinkButton>
          </Grid>
          <Grid>
            {/* Loading */}
            {isCartLoading ? (
              <CartCheckoutSkeleton />
            ) : (
              <Typography className={checkoutClasses.displayableTotal}>
                {total}
              </Typography>
            )}
          </Grid>
        </Grid>
        {/* Expanded Summary */}
        {summaryExpanded && (
          <div className={checkoutClasses.container}>
            <Grid container justifyContent="space-between">
              <Grid>
                <Typography data-testid="subtotal">Subtotal</Typography>
              </Grid>
              <Grid>
                <Typography>{subtotal}</Typography>
              </Grid>
              {/* Discounted Amount */}
              {discountedAmount && (
              <Grid container justifyContent="space-between">
                <Grid>
                  <Typography data-testid="discount">Applied Promo</Typography>
                </Grid>
                <Grid>
                  <Typography className={checkoutClasses.appliedPromo}>{discountedAmount}</Typography>
                </Grid>
              </Grid>
              )}
              {/* Has Taxes */}
              {tax !== '$0.00' && (
                <Grid container justifyContent="space-between">
                  <Grid>
                    <Typography data-testid="tax">Tax</Typography>
                  </Grid>
                  <Grid>
                    <Typography>{tax}</Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {/* Fee List */}
            <FeeList handleFeeClick={handleFeeClick} fees={fees} />
          </div>
        )}
      </div>
      {/* Checkout Button */}
      <CtaButton
        disabled={isCheckoutButtonDisabled}
        color="primary"
        clickHandler={handleGoToCheckoutButtonClick}
        labelText={CHECKOUT_SUBMIT_LABEL}
        fullWidth
        size="large"
      />
    </div>
  );
};

export default CartCheckout;
