import transformModifierWeights from "./transformModifierWeights";
import { productType } from "./menuUtilities";
const transformBundleChoicesToCartItemInputModifiers = (choices, variantMap, menuModifiers) => choices.map((choice) => {
    var _a;
    // At this point in the code, we've already verified that the choice has only 1 variant, so we can grab the 0 index
    const { variantCode } = choice.variants[0];
    const product = variantMap[variantCode];
    const modifiers = product.defaultModifiers.map((modifier) => {
        var _a;
        return (Object.assign({ id: modifier.modifierCode, modifiers: [], name: (_a = menuModifiers.find((menuMod) => menuMod.modifierCode === modifier.modifierCode)) === null || _a === void 0 ? void 0 : _a.name, slotCode: modifier.slotCode, type: 'MODIFIER', weightCode: modifier.modifierWeightCode }, transformModifierWeights(modifier.modifierWeightCode)));
    });
    return {
        id: choice.choiceCode,
        name: choice.name,
        description: '',
        modifiers: [
            {
                id: product.productCode,
                name: product.name,
                description: (_a = product.description) !== null && _a !== void 0 ? _a : '',
                modifiers: modifiers !== null && modifiers !== void 0 ? modifiers : [],
                type: productType(product.options),
                quantity: 1,
                variantCode
            }
        ],
        type: 'STEP'
    };
});
export default transformBundleChoicesToCartItemInputModifiers;
