import gql from 'graphql-tag';
import { categoryIsNotLineup } from '@/graphql/helpers/filterCategories';
import { CategoriesQuery, CategoriesQueryResult, Category } from '../../types/Category';

const GET_CATEGORIES_NATIONAL = gql`
    query getCategories($envID: String!) {
        Homepage(envID: $envID) {
            categories {
                name
                mainImage {
                    altText
                    desktop
                    mobile
                }
                displayName
                isNational
            }
        }
    }
`;

export const parser = (data: CategoriesQueryResult): Category[] => {
  const categories = data?.Homepage?.categories || [];
  return categories
    .filter((category) => category?.isNational)
    .filter((category) => category.displayName !== null && categoryIsNotLineup(category.displayName));
};

// @ts-expect-error Expect error for the parser function
const query = (): CategoriesQuery => ({ query: GET_CATEGORIES_NATIONAL, parser });

export default query;
