import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { mobileStartBreakpoint } from '@/materialUi/theme';
import borders from '@/common/borders';

export const CartRailStyles = makeStyles((theme: Theme) => createStyles({
  localizationPromiseTime: {
    padding: '20px 16px 24px 16px',
    borderBottom: borders.gray400Border
  },
  paper: {
    width: 'calc(100vw - 48px)',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      width: '446px'
    }
  }
}));
