import DealType from '../../DealTypes';
import { Deal, DealData, DealSummary } from '../dealTypes';
import { selectCurrentStep, selectRecipesForCurrentStep } from './userSelectionsSelectors';

const selectDeal = (deal: Deal): Deal | null => deal;

const selectDealId = (deal: Deal): string | undefined  => deal?.data?.id;

const selectDealType = (deal: DealData): DealType | null => deal.type;

const selectDealSummary = (deal: DealData): DealSummary => ({
  id: deal.id,
  name: deal.name,
  image: deal.image
});

const selectCustomizationsIncluded = (deal: Deal): number | undefined => {
  const dealRecipes = selectRecipesForCurrentStep(deal);
  const currentStep = selectCurrentStep(deal);
  const selectedRecipeId = currentStep?.recipeId;
  const selectedRecipe = dealRecipes?.find(
    (recipe) => recipe.id === selectedRecipeId
  );

  return selectedRecipe?.customizationsIncluded;
};

export {
  selectDeal,
  selectDealId,
  selectDealType,
  selectDealSummary,
  selectCustomizationsIncluded
};
