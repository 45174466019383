import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getConfig from 'next/config';
import { HomepageComponentResponse, MerchBannerComponentData, HomepageComponent, MerchBannerComponent } from '@/homepage/MerchandisingBanner/hooks/types';

interface FetchContentfulQueryArgs {
  query: string;
  variables: {
    storeId?: string;
    authenticated: string;
    localized: string;
    occasion?: string;
  };
}

const { publicRuntimeConfig } = getConfig();
const { CONTENTFUL_SPACE_ID, CONTENTFUL_ENVIRONMENT, CONTENTFUL_ACCESS_TOKEN, CONTENTFUL_BASE_URL } = publicRuntimeConfig;

const baseQuery = fetchBaseQuery({
  baseUrl: `${CONTENTFUL_BASE_URL}/spaces/${CONTENTFUL_SPACE_ID}/environments/${CONTENTFUL_ENVIRONMENT}`,
  prepareHeaders: (headers) => {
    headers.set("Authorization", `Bearer ${CONTENTFUL_ACCESS_TOKEN}`);
    headers.set("Content-Type", "application/json");
    return headers;
  }
});

export const contentfulApi = createApi({
  reducerPath: "contentfulApi",
  baseQuery,
  endpoints: (builder) => ({
    fetchContentfulData: builder.query<MerchBannerComponentData | null, FetchContentfulQueryArgs>({
      query: ({ query, variables }) => ({
        url: '',
        method: "POST",
        body: JSON.stringify({ query, variables })
      }),
      transformResponse: (response: HomepageComponentResponse): MerchBannerComponentData | null => response?.data?.homepageContentCollection?.items
        ?.flatMap((item: HomepageComponent) =>
          item.merchandiseBannersCollection?.items.map((banner: MerchBannerComponent) => ({
            targetUrl: banner.component?.targetUrl ?? "",
            altText: banner.component?.altText ?? "",
            desktopImage: banner.component?.desktopImage?.url ?? "",
            mobileImage: banner.component?.mobileImage?.url ?? ""
          })) ?? []
        )
        ?.find(Boolean) || null
    })
  })
});

export const { useFetchContentfulDataQuery } = contentfulApi;
