import { filterItemByPrivateMetafield } from "./filterUtilities";
const doesModifierHaveOnlyOneWeight = (modifier) => (modifier === null || modifier === void 0 ? void 0 : modifier.weights.filter((weight) => !weight.isExcludedFromVariant).length) === 1;
const transformProductsWithNoRequiredSelectionsToVariantMap = (products) => products.reduce((acc, product) => {
    var _a;
    // Filter out any product or variant that has access-point-Web = N
    // filterItemByPrivateMetafield returns true if the item should show
    if (!filterItemByPrivateMetafield(product))
        return acc;
    // Loop through every variant
    // First, check that the variant should show by using filterItemByPrivateMetafield
    // Second, use .every to look through the variant's slots
    // Confirm that every slot has only 0 or 1 modifier(s)
    // If 1 modifier, Filter out the modfiers in each slot to only include modifiers that only have 1 weight included (taking into account isExcludedFromVariant)
    // If all conditions pass, add the variantCode to the map as the key and the value as the quick add product details
    (_a = product.variants) === null || _a === void 0 ? void 0 : _a.forEach((variant) => {
        if (filterItemByPrivateMetafield(variant) &&
            variant.slots.every((slot) => slot.modifiers.length === 0 || slot.modifiers.filter((mod) => doesModifierHaveOnlyOneWeight(mod)).length === 1)) {
            const { productCode, name, description, options } = product;
            acc[variant.variantCode] = {
                productCode,
                name,
                description: description !== null && description !== void 0 ? description : '',
                options,
                defaultModifiers: variant.defaultModifiers
            };
        }
    });
    return acc;
}, {});
export default transformProductsWithNoRequiredSelectionsToVariantMap;
