import { Cart, CartItem, DiningOccasion, orderApiCart } from '@pizza-hut-us-development/client-core';
import { Dispatch } from '@reduxjs/toolkit';

import { CartItem as LegacyCartItem } from '@/domain/cart/types';
import { AddableCartItemKinds } from '@/api/phdApiV2Client/request.types';
import formattedPrice from '@/common/formattedPrice';
import { Occasion } from '@/localization/constants';
import { CityStateAndZipCodeArgs } from '@/clientCore/cart/types';
import { CartAlert, setCartChangedAlert } from '@/clientCore/redux/rail/CartRailSlice';

export const cartItemToLegacy = (item: CartItem): LegacyCartItem => ({
  kind: AddableCartItemKinds.CART_ITEM,
  id: item.id,
  name: item.name,
  type: item.type,
  displayablePrice: formattedPrice(item.price),
  quantity: item.quantity ?? 1,
  modifiers: item.modifiers ?? [],
  description: item.description ?? '',
  specialInstructions: item.specialInstructions,
  cartItemId: item.cartItemId
});

export const cartItemsToLegacy = (items: CartItem[]): LegacyCartItem[] => items.map((item) => cartItemToLegacy(item)) ?? [];

export const getAlphanumericValue = (value: string): string => value.replace(/[^a-zA-Z0-9-]/ig, '');

export const occasionLegacyToCC = (occasion: Occasion): DiningOccasion => {
  if (occasion === Occasion.DELIVERY) {
    return DiningOccasion.DELIVERY;
  }
  return DiningOccasion.CARRYOUT;
};

export const getCityStateAndZipcode = (
  { city, state, postalCode }: CityStateAndZipCodeArgs
): string => `${city}, ${state} ${postalCode}`;

export const calculateCartQuantity = (items: CartItem[]) => items.reduce(
  (total: number, currentItem: CartItem): number => {
    if (currentItem.quantity) {
      return total + currentItem.quantity;
    }
    return total;
  },
  0
) ?? 0;

export const checkIfCartHasChanges = async (previousCartItems: CartItem[], newCart: Cart, dispatch: Dispatch) => {
  const cartItemIdsToRemove: string[] = [];
  newCart.failedItems?.data.forEach((item) => {
    if ('lineItemId' in item) {
      cartItemIdsToRemove.push(item.lineItemId ?? '');
    }
  });

  const itemsRemoved = previousCartItems.filter(item => cartItemIdsToRemove?.includes(item.cartItemId)) ?? [];
  
  // eslint-disable-next-line no-restricted-syntax
  for (const item of itemsRemoved) {
    try {
      // eslint-disable-next-line no-await-in-loop
      await dispatch<any>(orderApiCart.endpoints.deleteCartItem.initiate({
        cartItemId: item.cartItemId,
        cartId: newCart.cartId
      }));
    } catch (error) {
      console.error('error removing failed item from cart', { error });
    }
  }

  if (itemsRemoved.length) {
    const cartAlert: CartAlert = {
      displayAlert: true,
      quantityChanged: true,
      itemsRemoved: itemsRemoved.map(item => item.name)
    };
    dispatch(setCartChangedAlert(cartAlert));
  }
}
