import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import colors from '@/common/colors';

const styles = makeStyles(() => createStyles({
  container: {
    margin: '0 4px'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    border: 0,
    background: 'transparent',
    cursor: 'pointer',
    margin: 0,
    padding: 0
  },
  title: {
    color: colors.blue,
    fontWeight: 700
  },
  arrow: {
    color: colors.blue
  },
  content: {
    fontSize: 12
  }
}));

export default styles;
