import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import getConfig from 'next/config';
import { zIndex } from '@/localization/constants';
import colors from '../colors';
import { mobileStartBreakpoint } from '@/materialUi/theme';
import useUnload from '../../hooks/useUnload';
import { RootState } from '@/rootStateTypes';
import {
  hideLoadingBar,
  initializeLocalization,
  showLoadingBar as showLoadingBarAction,
  tokenHasExpired,
  tokenIssuedLongAgo
} from '@/localization/actions';
import clientSide from '../isClientSide/isClientSide';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { getLocalizationTokenDetail, LocalizationDetails } from '@/localization/localizationToken';

const { publicRuntimeConfig } = getConfig();
const { baseLocalizationIAT } = publicRuntimeConfig;
const barHeight = 7;
const barHeightMobile = barHeight - 2;
const useStyles = makeStyles((theme) => createStyles({
  container: {
    position: 'fixed',
    top: 0,
    width: '100%',
    height: `${barHeight}px`,
    zIndex: zIndex.HIGHEST,
    backgroundColor: colors.gray400,
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      height: `${barHeightMobile}px`
    }
  },
  bar: {
    position: 'absolute',
    width: '40%',
    height: `${barHeight}px`,
    backgroundColor: colors.red,
    animation: `$BarLoading 2.5s ${theme.transitions.easing.easeIn} infinite`,
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      height: `${barHeightMobile}px`
    }
  },
  '@keyframes BarLoading': {
    from: {
      transform: 'translateX(-100%)'
    },
    to: {
      transform: 'translateX(250%)'
    }
  }
}));

const PageLoading = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
  const router = useRouter();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const localizationToken = useSelector(localizationSelectors.localizationToken);

  const { showLoadingBar, hasLoadingQuery } = useSelector(
    (state: RootState) => state.presentational.localization
  );

  useUnload(() => {
    setIsRedirecting(true);
  });

  useEffect(() => {
    const startLoading = () => {
      if (clientSide() && localizationToken) {
        const {
          occasion,
          storeNumber,
          expirationDate,
          issuedDate
        } = getLocalizationTokenDetail(localizationToken) as LocalizationDetails;
        const source = 'state';
        const expired = tokenHasExpired(expirationDate, source);
        const outdatedIssuedDate = tokenIssuedLongAgo(issuedDate, baseLocalizationIAT, source);

        if (expired || outdatedIssuedDate) {
          dispatch(
            initializeLocalization(
              occasion,
              storeNumber,
              expirationDate,
              issuedDate,
              baseLocalizationIAT,
              localizationToken,
              source
            )
          );
        }
      }
      dispatch(showLoadingBarAction());
    };
    const stopLoading = () => {
      dispatch(hideLoadingBar());
    };

    router.events.on('routeChangeStart', startLoading);
    router.events.on('routeChangeComplete', stopLoading);
    router.events.on('routeChangeError', stopLoading);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', startLoading);
      router.events.off('routeChangeComplete', stopLoading);
      router.events.off('routeChangeError', stopLoading);
    };
  }, [router, dispatch, localizationToken]);

  const loadingBar = (
    <div className={classes.container} data-testid="page-loading-bar">
      <div className={classes.bar} />
    </div>
  );

  if (isRedirecting) {
    return loadingBar;
  }

  return (
    <>
      {(showLoadingBar || hasLoadingQuery) ? loadingBar : null}
      {children}
    </>
  );
};

export default PageLoading;
