export const transformWingBuilderToCartItem = (wingBuilder) => {
    var _a, _b, _c, _d;
    const { price, quantity, selectedAdditionalOption, selectedSauce, selectedSize, selectedWings } = wingBuilder;
    if (!selectedWings || !selectedSize) {
        throw new Error('No selected wing to add to cart');
    }
    const additionalOption = selectedAdditionalOption
        ? {
            displayName: selectedAdditionalOption.name,
            modifiers: [],
            name: selectedAdditionalOption.id,
            quantities: [1],
            type: (_a = selectedAdditionalOption.type) !== null && _a !== void 0 ? _a : 'MODIFIER',
        }
        : null;
    const sauce = selectedSauce
        ? {
            displayName: selectedSauce.name,
            modifiers: [],
            name: selectedSauce.id,
            quantities: [1],
            type: (_b = selectedSauce.type) !== null && _b !== void 0 ? _b : 'MODIFIER',
        }
        : {
            displayName: '',
            modifiers: [],
            name: '',
            quantities: [1],
            type: 'MODIFIER',
        };
    const size = {
        displayName: selectedSize.name,
        modifiers: [],
        name: selectedSize.id,
        quantities: [1],
        type: (_c = selectedSize.type) !== null && _c !== void 0 ? _c : 'SIZE',
    };
    const cartItemModifiers = [sauce, size];
    if (additionalOption) {
        cartItemModifiers.push(additionalOption);
    }
    return {
        displayName: selectedWings.name,
        modifiers: cartItemModifiers.flat(),
        name: selectedWings.id,
        price,
        quantity,
        type: (_d = selectedWings.type) !== null && _d !== void 0 ? _d : 'PRODUCT',
    };
};
export default transformWingBuilderToCartItem;
