export const transformYumAvailability = (occasionTimeFrames) => {
    if (!occasionTimeFrames) {
        return [];
    }
    const transformedAvailability = occasionTimeFrames.map((occasionTimeFrame) => {
        return {
            days: occasionTimeFrame.timeFrame.days,
            endTime: occasionTimeFrame.timeFrame.endDate || '',
            occasion: occasionTimeFrame.occasion,
            startTime: occasionTimeFrame.timeFrame.startDate || '',
            status: '',
        };
    });
    return transformedAvailability;
};
export default transformYumAvailability;
