import React from 'react';
import { Grid, Skeleton } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => createStyles({
  addButton: {
    marginLeft: '10px',
    marginRight: '10px'
  },
  image: {
    marginLeft: '5px',
    marginTop: '10px',
    marginRight: '10px'
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '10px'
  }
}));

export const OneClickUpsellCartRailRowSkeleton = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center">
      <Skeleton className={classes.addButton} variant="circular" width={25} height={25} />
      <Skeleton className={classes.image} variant="circular" width={75} height={75} />
      <Grid className={classes.text}>
        <Skeleton variant="text" width={175} height={30} />
        <Skeleton variant="text" width={50} height={30} />
      </Grid>
    </Grid>
  );
};
