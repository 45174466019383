import { QueryHookOptions } from '@apollo/client';
import useEnvQueryVariables from './useEnvQueryVariables';
import useProduct, { ProductDomain } from './useProductItem';
import useLocalizationGqlVariables from './useLocalizationQueryVariables';
import { CustomQueryOptions } from '../../types/QueryOptions';

interface QueryVariables {
  variables: QueryHookOptions;
  skip: boolean;
  storeID?: string;
}

const useQueryVariables = (
  itemId?: string,
  storeSpecific?: boolean,
  options?: CustomQueryOptions,
  hasNationalOverrides = false,
  productDomain: ProductDomain = 'items'
): QueryVariables => {
  const envQueryVariables = useEnvQueryVariables();
  const localizationVariables = useLocalizationGqlVariables(
    hasNationalOverrides, options?.newStoreFormat
  );
  const productItemVariables = useProduct(productDomain, itemId);
  const variables = {
    ...(storeSpecific ? localizationVariables.variables : {}),
    ...(envQueryVariables.variables || {}),
    ...(itemId ? productItemVariables.variables : {}),
    ...(options ? options.variables : {})
  };

  return {
    ...(storeSpecific && { storeID: localizationVariables.variables?.storeID }),
    variables: { variables },
    skip: options?.skip || false
  };
};

export default useQueryVariables;
