var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import { menuApi } from './MenuApi';
import { menuApiContentGatewayHub } from './MenuApiContentGatewayHub';
import { Categories, } from '../../types';
import { menuApiCategory } from './MenuApiCategory';
import generateProductsByIdQuery from './queries/generateProductsByIdQuery';
import { transformGqlDealItem, transformWingBuilder } from '../../utils';
import { transformYumWingsProductToWingBuilderState } from '../../utils/transformYumWingProductToWingBuilder';
export const menuApiWingBuilder = menuApi.injectEndpoints({
    endpoints: (build) => ({
        getDealWingBuilder: build.query({
            queryFn: (args, { dispatch, getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                var _a, _b, _c, _d;
                const { promotionDefinition, storeNumber } = args;
                const { coreConfig: { isYumEcomm: useContentGateway }, } = getState();
                let response;
                if (useContentGateway) {
                    const { data, error } = yield dispatch(menuApiContentGatewayHub.endpoints.getContentGatewayMenuHub.initiate({
                        promotionDefinition,
                        storeNumber,
                    }));
                    const dips = (_b = (_a = data === null || data === void 0 ? void 0 : data.categories.find((category) => category.id === Categories.DIPS)) === null || _a === void 0 ? void 0 : _a.products) !== null && _b !== void 0 ? _b : [];
                    const wings = (_d = (_c = data === null || data === void 0 ? void 0 : data.categories.find((category) => category.id === Categories.WINGS)) === null || _c === void 0 ? void 0 : _c.products) !== null && _d !== void 0 ? _d : [];
                    const builder = { dips, wings };
                    response = data ? { data: builder } : { error };
                }
                else {
                    // generate the query to get all of the recipes for the deal step as products
                    const query = generateProductsByIdQuery(args.step.recipes);
                    const { data, error } = yield fetchWithBQ({
                        data: {
                            operationName: 'getProductsById',
                            query,
                            variables: {
                                storeID: `stores/${args.storeNumber}`,
                            },
                        },
                        method: 'post',
                    });
                    const result = data;
                    const productGql = Object.values(result.data.store.menu);
                    const products = transformGqlDealItem(productGql);
                    const wingsCategory = {
                        displayOrder: 0,
                        featuredProducts: [],
                        id: Categories.WINGS,
                        name: 'Wings',
                        products,
                    };
                    const builder = transformWingBuilder(wingsCategory);
                    // Return the Wingbuilder or an error if an error occured
                    response = data ? { data: builder } : { error };
                }
                return response;
            }),
        }),
        getWingBuilder: build.query({
            queryFn: (args, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
                var _e, _f, _g, _h, _j;
                // Pull out options from the args if they exist, otherwise use what is in redux
                const { coreConfig: { isYumEcomm: useContentGateway }, order: { cart, store }, } = getState();
                const categoryId = Categories.WINGS;
                const dipCategoryId = Categories.DIPS;
                const environmentId = args ? args.environmentId : 'prod';
                const occasion = (args ? args.occasion : cart === null || cart === void 0 ? void 0 : cart.occasionId);
                const storeNumber = (_e = (args ? args.storeNumber : store === null || store === void 0 ? void 0 : store.storeNumber)) !== null && _e !== void 0 ? _e : '';
                const removeBundleVariants = args ? args.removeBundleVariants : true;
                const dealRecipe = args === null || args === void 0 ? void 0 : args.dealRecipe;
                const promotionDefinition = args === null || args === void 0 ? void 0 : args.promotionDefinition;
                let response;
                if (useContentGateway) {
                    const { data, error } = yield dispatch(menuApiContentGatewayHub.endpoints.getContentGatewayMenuHub.initiate({
                        promotionDefinition,
                        storeNumber,
                    }));
                    const dips = (_g = (_f = data === null || data === void 0 ? void 0 : data.categories.find((category) => category.id === Categories.DIPS)) === null || _f === void 0 ? void 0 : _f.products) !== null && _g !== void 0 ? _g : [];
                    const wings = (_j = (_h = data === null || data === void 0 ? void 0 : data.categories.find((category) => category.id === Categories.WINGS)) === null || _h === void 0 ? void 0 : _h.products) !== null && _j !== void 0 ? _j : [];
                    const builder = { dips, wings };
                    if (!!dealRecipe) {
                        response = { data: transformYumWingsProductToWingBuilderState(dealRecipe, dips) };
                    }
                    else {
                        response = data ? { data: builder } : { error };
                    }
                }
                else {
                    // Build the CategoryOptions for the getProductsByCategory endpoint
                    const options = {
                        categoryId,
                        environmentId,
                        includeNutrition: true,
                        occasion,
                        removeBundleVariants,
                        storeNumber,
                    };
                    // Initiate the endpoint
                    const { data: wingData, error } = yield dispatch(menuApiCategory.endpoints.getProductsByCategory.initiate(options));
                    const { data: dipData, error: dipError } = yield dispatch(menuApiCategory.endpoints.getProductsByCategory.initiate(Object.assign(Object.assign({}, options), { categoryId: dipCategoryId })));
                    // return undefined to default the parameter in transformWingBuilder
                    const dipIds = !dipError ? dipData === null || dipData === void 0 ? void 0 : dipData.products.map((product) => product.qoId) : undefined;
                    // set the response as the Wingbuilder or an error if an error occured
                    response = wingData ? { data: transformWingBuilder(wingData, dipIds) } : { error: error };
                }
                return response;
            }),
        }),
    }),
});
export const { useGetDealWingBuilderQuery, useGetWingBuilderQuery } = menuApiWingBuilder;
