import { CategoryData } from '@pizza-hut-us-development/client-core';
import CategoryFragment from '@/graphql/types/fragment/Category';
import { Category } from '@/graphql/types/Category';

export const transformCategoriesQuery = (
  data: CategoryData[] | undefined,
  isMainMenu = false
): CategoryFragment[] | Category[] => {
  if (!data) return [];
  const filteredData = [...data]
    .sort((a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0))
    .filter(({ link, displayName }) => link?.displayText !== 'Value Lineup' && displayName && !displayName.toLocaleLowerCase().includes('deal') && link);

  if (isMainMenu) {
    return filteredData.map((c) => ({
      isNational: c.isNational,
      displayName: c.displayName,
      name: c.name,
      mainImage: c.mainImage
    })) as Category[];
  }

  return filteredData.map((c) => {
    const { link } = c;
    return {
      isNational: c.isNational,
      index: `${c.displayOrder ?? 0}`,
      link: {
        link: link?.path ?? '',
        linkType: link?.type ?? '',
        linkDisplayText: link?.displayText ?? '',
        linkTitle: link?.title ?? '',
        external: link?.external ?? false
      }
    };
  });
};
