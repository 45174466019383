import React from "react";
import { useDecision } from "@optimizely/react-sdk";

import Header from './Header';

type ConditionalHeaderProps = {
    visible?: boolean;
}

// TODO: When retiring fr-web-4220-relocate_header_footer remove <Header /> component from individual pages
// and only render in app/Layout.tsx
const ConditionalHeader = ({ visible }: ConditionalHeaderProps) => {
  const [{ enabled: relocateHeaderFooterEnabled }] = useDecision('fr-web-4220-relocate_header_footer');

  if (relocateHeaderFooterEnabled && !visible) return null;

  return <Header />
}

export default ConditionalHeader;
