/* eslint-disable sort-keys */
export const Environments = {
    development: {
        baseUrlContentGateway: 'https://services-dev.digital.pizzahut.com/content-gateway',
        baseUrlCustomerGateway: 'https://services-staging.digital.pizzahut.com/customer-gateway/v1',
        baseUrlLoyaltyGateway: 'https://services-staging.digital.pizzahut.com/loyalty-gateway/v1',
        baseUrlV1: 'https://services-dev.digital.pizzahut.com/phdapi/v1',
        baseUrlV2: 'https://services-dev.digital.pizzahut.com/phdapi/v2',
        baseUrlV3: 'https://services-staging.digital.pizzahut.com/ordering-gateway',
        configBaseUrl: 'https://quikorder.pizzahut.com/AppResources/assets/core',
        contentGatewayKey: 'FDlXyrtSn2dzAPHGUUKpFQrDSAEH7uht',
        customerGatewayKey: '9tAp1M0Dqdin4S9JAMQUNeM9aucwUWpw',
        loyaltyGatewayKey: 'iAup7PmJLSEoMOicc3PLStA2kgV3MWPf',
        menuKey: 'yubkBO7G58TrEEfJlfZNYsLjl5bt75YS',
        menuUrl: 'https://services-dev.digital.pizzahut.com/consolidated-gql/query',
        payUrl: 'https://services-dev.digital.pizzahut.com/phpay/v1',
        yumAuthUrl: 'https://api.staging.yumconnect.dev/v1/oidc/oauth2/token',
        PizzaHut: {
            keyV1: 'RyAzYIiBQMB6ISN8CAOiulwmGHA68F4c',
            keyV2: 'lw9P49pgXJ27uM0EElItVDoThf1wOMWW',
            keyV3: 'LiCRAuZ8v7B65CPzgTT1pMcopVMXdidd',
        },
        ['PizzaHut-Staging']: {
            keyV1: 'A9PHUPQbR2GGV1LWIqSRWFrA5Muo5Ced',
            keyV2: '',
            keyV3: 'LiCRAuZ8v7B65CPzgTT1pMcopVMXdidd',
        },
        iOS: {
            keyV1: 'x61c4lN6nyNJZMpwyo228jCAGGCri7n0',
            keyV2: 'vB0OsQdYetQWcSDdSCCQFC6muvil2Kd8',
            keyV3: 'LiCRAuZ8v7B65CPzgTT1pMcopVMXdidd',
        },
        Android: {
            keyV1: '5SsW7CAOvzA8gKT0MqoL8n6LZMyp8Aqi',
            keyV2: 'Qs6AABXxp9AI6otaw4K4ok9bCNELjq8y',
            keyV3: 'LiCRAuZ8v7B65CPzgTT1pMcopVMXdidd',
        },
        HutHutWin: {
            keyV1: 'IbZikSUIukmOmS1qMXCwJjwoET3AADAt',
            keyV2: '',
            keyV3: 'LiCRAuZ8v7B65CPzgTT1pMcopVMXdidd',
        },
        alexa: {
            keyV1: 'dO790OfSmaNWyTUG0tXS6i7JvF0lcvUg',
            keyV2: 'GfW80RuA7wvsXiyUv0csiHGQZbuHLtbm',
            keyV3: 'LiCRAuZ8v7B65CPzgTT1pMcopVMXdidd',
        },
        ['google-assistant']: {
            keyV1: 'lWVrGyxa092MlaeCCI4q2LGzKcDrY9G9',
            keyV2: 't4jOCSygAEoX4VbEDkDG3TTswzvQzwRj',
            keyV3: 'LiCRAuZ8v7B65CPzgTT1pMcopVMXdidd',
        },
        synack: {
            keyV1: 'Ygpf5sYw6jgryejLWMwGZyHZThzvdqCm',
            keyV2: '7lpAbFX7fBIZocfJXoTQmukDB0NnHqd3',
            keyV3: 'LiCRAuZ8v7B65CPzgTT1pMcopVMXdidd',
        },
        WEB2: {
            keyV1: 'ApXqGyM6xPAp0AXTYIi3DSGGBGeRDXSS',
            keyV2: 'HC5acg0yUKDLojH6HPfU04msxq3w2plu',
            keyV3: 'LiCRAuZ8v7B65CPzgTT1pMcopVMXdidd',
        },
    },
    staging: {
        baseUrlContentGateway: 'https://services-staging.digital.pizzahut.com/content-gateway',
        baseUrlCustomerGateway: 'https://services-staging.digital.pizzahut.com/customer-gateway/v1',
        baseUrlLoyaltyGateway: 'https://services-staging.digital.pizzahut.com/loyalty-gateway/v1',
        baseUrlV1: 'https://services-staging.digital.pizzahut.com/phdapi/v1',
        baseUrlV2: 'https://services-staging.digital.pizzahut.com/phdapi/v2',
        baseUrlV3: 'https://services-staging.digital.pizzahut.com/ordering-gateway',
        configBaseUrl: 'https://quikorder.pizzahut.com/AppResources/assets/core',
        contentGatewayKey: 'FDlXyrtSn2dzAPHGUUKpFQrDSAEH7uht',
        customerGatewayKey: '9tAp1M0Dqdin4S9JAMQUNeM9aucwUWpw',
        loyaltyGatewayKey: 'iAup7PmJLSEoMOicc3PLStA2kgV3MWPf',
        menuKey: 'CAxPIsnVsf7SJPbAy8T8tCXo688LM5fu',
        menuUrl: 'https://services-staging.digital.pizzahut.com/consolidated-gql/query',
        payUrl: 'https://services-staging.digital.pizzahut.com/phpay/v1',
        yumAuthUrl: 'https://api.staging.yumconnect.dev/v1/oidc/oauth2/token',
        PizzaHut: {
            keyV1: 'RyAzYIiBQMB6ISN8CAOiulwmGHA68F4c',
            keyV2: 'lw9P49pgXJ27uM0EElItVDoThf1wOMWW',
            keyV3: 'LiCRAuZ8v7B65CPzgTT1pMcopVMXdidd',
        },
        ['PizzaHut-Staging']: {
            keyV1: 'A9PHUPQbR2GGV1LWIqSRWFrA5Muo5Ced',
            keyV2: '',
            keyV3: 'LiCRAuZ8v7B65CPzgTT1pMcopVMXdidd',
        },
        iOS: {
            keyV1: 'x61c4lN6nyNJZMpwyo228jCAGGCri7n0',
            keyV2: 'vB0OsQdYetQWcSDdSCCQFC6muvil2Kd8',
            keyV3: 'LiCRAuZ8v7B65CPzgTT1pMcopVMXdidd',
        },
        Android: {
            keyV1: '5SsW7CAOvzA8gKT0MqoL8n6LZMyp8Aqi',
            keyV2: 'Qs6AABXxp9AI6otaw4K4ok9bCNELjq8y',
            keyV3: 'LiCRAuZ8v7B65CPzgTT1pMcopVMXdidd',
        },
        HutHutWin: {
            keyV1: 'IbZikSUIukmOmS1qMXCwJjwoET3AADAt',
            keyV2: '',
            keyV3: 'LiCRAuZ8v7B65CPzgTT1pMcopVMXdidd',
        },
        alexa: {
            keyV1: 'dO790OfSmaNWyTUG0tXS6i7JvF0lcvUg',
            keyV2: 'GfW80RuA7wvsXiyUv0csiHGQZbuHLtbm',
            keyV3: 'LiCRAuZ8v7B65CPzgTT1pMcopVMXdidd',
        },
        ['google-assistant']: {
            keyV1: 'lWVrGyxa092MlaeCCI4q2LGzKcDrY9G9',
            keyV2: 't4jOCSygAEoX4VbEDkDG3TTswzvQzwRj',
            keyV3: 'LiCRAuZ8v7B65CPzgTT1pMcopVMXdidd',
        },
        synack: {
            keyV1: 'Ygpf5sYw6jgryejLWMwGZyHZThzvdqCm',
            keyV2: '7lpAbFX7fBIZocfJXoTQmukDB0NnHqd3',
            keyV3: 'LiCRAuZ8v7B65CPzgTT1pMcopVMXdidd',
        },
        WEB2: {
            keyV1: 'ApXqGyM6xPAp0AXTYIi3DSGGBGeRDXSS',
            keyV2: 'HC5acg0yUKDLojH6HPfU04msxq3w2plu',
            keyV3: 'LiCRAuZ8v7B65CPzgTT1pMcopVMXdidd',
        },
    },
    production: {
        baseUrlContentGateway: 'https://services.digital.pizzahut.com/content-gateway',
        baseUrlCustomerGateway: 'https://services.digital.pizzahut.com/customer-gateway/v1',
        baseUrlLoyaltyGateway: 'https://services.digital.pizzahut.com/loyalty-gateway/v1',
        baseUrlV1: 'https://services.digital.pizzahut.com/phdapi/v1',
        baseUrlV2: 'https://services.digital.pizzahut.com/phdapi/v2',
        baseUrlV3: 'https://services.digital.pizzahut.com/ordering-gateway',
        configBaseUrl: 'https://quikorder.pizzahut.com/AppResources/assets/core',
        contentGatewayKey: 'TGwHGN74nGuUjhpdFAvYqcCMALAlWRDA',
        customerGatewayKey: 'by58OxmOpcZDKgYcELEadHUHABb85RHs',
        loyaltyGatewayKey: '6QJWAa5dsV1KFNWSA5mSc4VDs2vfVAqd',
        menuKey: 'zrUXk5Ri1F9RKqNLKh5ie3CPGTBifOD5',
        menuUrl: 'https://services.digital.pizzahut.com/consolidated-gql/query',
        payUrl: 'https://services.digital.pizzahut.com/phpay/v1',
        yumAuthUrl: 'https://www.pizzahut.com/v1/oidc/oauth2/token',
        PizzaHut: {
            keyV1: 'RyAzYIiBQMB6ISN8CAOiulwmGHA68F4c',
            keyV2: 'z3Av4fjnaVcxsjWkT1QNvhGwFrXzGnXG',
            keyV3: 'KpnMjBhqAaMrI7aB7bPQ4PV8qU9Q09tR',
        },
        ['PizzaHut-Staging']: {
            keyV1: 'A9PHUPQbR2GGV1LWIqSRWFrA5Muo5Ced',
            keyV2: '',
            keyV3: 'KpnMjBhqAaMrI7aB7bPQ4PV8qU9Q09tR',
        },
        iOS: {
            keyV1: 'x61c4lN6nyNJZMpwyo228jCAGGCri7n0',
            keyV2: 'ojpxGnc1sS15fpFThtDymgA0kt07woaC',
            keyV3: 'KpnMjBhqAaMrI7aB7bPQ4PV8qU9Q09tR',
        },
        Android: {
            keyV1: '5SsW7CAOvzA8gKT0MqoL8n6LZMyp8Aqi',
            keyV2: 'ajNK2IGTjTBtLudTYf7G0cx2MheMyiFk',
            keyV3: 'KpnMjBhqAaMrI7aB7bPQ4PV8qU9Q09tR',
        },
        HutHutWin: {
            keyV1: 'IbZikSUIukmOmS1qMXCwJjwoET3AADAt',
            keyV2: '',
            keyV3: 'KpnMjBhqAaMrI7aB7bPQ4PV8qU9Q09tR',
        },
        alexa: {
            keyV1: 'dO790OfSmaNWyTUG0tXS6i7JvF0lcvUg',
            keyV2: 'AUvP0AjmU3gYVNO5KUnmEyoI5aISQOy3',
            keyV3: 'KpnMjBhqAaMrI7aB7bPQ4PV8qU9Q09tR',
        },
        ['google-assistant']: {
            keyV1: 'lWVrGyxa092MlaeCCI4q2LGzKcDrY9G9',
            keyV2: 'xHQxER7AAXKU7bc999nBDnZOEb7EUi4Y',
            keyV3: 'KpnMjBhqAaMrI7aB7bPQ4PV8qU9Q09tR',
        },
        synack: {
            keyV1: 'Ygpf5sYw6jgryejLWMwGZyHZThzvdqCm',
            keyV2: 'Vf9FifiIVHMGO36lImTszS2jyZWSe7AR',
            keyV3: 'KpnMjBhqAaMrI7aB7bPQ4PV8qU9Q09tR',
        },
        WEB2: {
            keyV1: 'ApXqGyM6xPAp0AXTYIi3DSGGBGeRDXSS',
            keyV2: '8CNl1ssOwYGrdFM924imkWYbXnJ5totq',
            keyV3: 'KpnMjBhqAaMrI7aB7bPQ4PV8qU9Q09tR',
        },
    },
    proxyStaging: {
        baseUrlContentGateway: 'http://localhost:3000/content-gateway',
        baseUrlCustomerGateway: 'http://localhost:3000/customer-gateway/v1',
        baseUrlLoyaltyGateway: 'http://localhost:3000/loyalty-gateway/v1',
        baseUrlV1: 'http://localhost:3000/phdapi/v1',
        baseUrlV2: 'http://localhost:3000/phdapi/v2',
        baseUrlV3: 'http://localhost:3000/ordering-gateway',
        configBaseUrl: 'http://localhost:3000/AppResources/assets/core',
        contentGatewayKey: 'FDlXyrtSn2dzAPHGUUKpFQrDSAEH7uht',
        customerGatewayKey: '9tAp1M0Dqdin4S9JAMQUNeM9aucwUWpw',
        loyaltyGatewayKey: 'iAup7PmJLSEoMOicc3PLStA2kgV3MWPf',
        menuKey: 'CAxPIsnVsf7SJPbAy8T8tCXo688LM5fu',
        menuUrl: 'http://localhost:3000/consolidated-gql/query',
        payUrl: 'http://localhost:3000/phpay/v1',
        yumAuthUrl: 'https://api.staging.yumconnect.dev/v1/oidc/oauth2/token',
        PizzaHut: {
            keyV1: 'RyAzYIiBQMB6ISN8CAOiulwmGHA68F4c',
            keyV2: 'lw9P49pgXJ27uM0EElItVDoThf1wOMWW',
            keyV3: 'LiCRAuZ8v7B65CPzgTT1pMcopVMXdidd',
        },
        ['PizzaHut-Staging']: {
            keyV1: 'A9PHUPQbR2GGV1LWIqSRWFrA5Muo5Ced',
            keyV2: '',
            keyV3: 'LiCRAuZ8v7B65CPzgTT1pMcopVMXdidd',
        },
        iOS: {
            keyV1: 'x61c4lN6nyNJZMpwyo228jCAGGCri7n0',
            keyV2: 'vB0OsQdYetQWcSDdSCCQFC6muvil2Kd8',
            keyV3: 'LiCRAuZ8v7B65CPzgTT1pMcopVMXdidd',
        },
        Android: {
            keyV1: '5SsW7CAOvzA8gKT0MqoL8n6LZMyp8Aqi',
            keyV2: 'Qs6AABXxp9AI6otaw4K4ok9bCNELjq8y',
            keyV3: 'LiCRAuZ8v7B65CPzgTT1pMcopVMXdidd',
        },
        HutHutWin: {
            keyV1: 'IbZikSUIukmOmS1qMXCwJjwoET3AADAt',
            keyV2: '',
            keyV3: 'LiCRAuZ8v7B65CPzgTT1pMcopVMXdidd',
        },
        alexa: {
            keyV1: 'dO790OfSmaNWyTUG0tXS6i7JvF0lcvUg',
            keyV2: 'GfW80RuA7wvsXiyUv0csiHGQZbuHLtbm',
            keyV3: 'LiCRAuZ8v7B65CPzgTT1pMcopVMXdidd',
        },
        ['google-assistant']: {
            keyV1: 'lWVrGyxa092MlaeCCI4q2LGzKcDrY9G9',
            keyV2: 't4jOCSygAEoX4VbEDkDG3TTswzvQzwRj',
            keyV3: 'LiCRAuZ8v7B65CPzgTT1pMcopVMXdidd',
        },
        synack: {
            keyV1: 'Ygpf5sYw6jgryejLWMwGZyHZThzvdqCm',
            keyV2: '7lpAbFX7fBIZocfJXoTQmukDB0NnHqd3',
            keyV3: 'LiCRAuZ8v7B65CPzgTT1pMcopVMXdidd',
        },
        WEB2: {
            keyV1: 'ApXqGyM6xPAp0AXTYIi3DSGGBGeRDXSS',
            keyV2: 'HC5acg0yUKDLojH6HPfU04msxq3w2plu',
            keyV3: 'LiCRAuZ8v7B65CPzgTT1pMcopVMXdidd',
        },
    },
    proxyProduction: {
        baseUrlContentGateway: 'http://localhost:3000/content-gateway',
        baseUrlCustomerGateway: 'http://localhost:3000/customer-gateway/v1',
        baseUrlLoyaltyGateway: 'http://localhost:3000/loyalty-gateway/v1',
        baseUrlV1: 'http://localhost:3000/phdapi/v1',
        baseUrlV2: 'http://localhost:3000/phdapi/v2',
        baseUrlV3: 'http://localhost:3000/ordering-gateway',
        configBaseUrl: 'http://localhost:3000/AppResources/assets/core',
        contentGatewayKey: 'TGwHGN74nGuUjhpdFAvYqcCMALAlWRDA',
        customerGatewayKey: 'by58OxmOpcZDKgYcELEadHUHABb85RHs',
        loyaltyGatewayKey: '6QJWAa5dsV1KFNWSA5mSc4VDs2vfVAqd',
        menuKey: 'zrUXk5Ri1F9RKqNLKh5ie3CPGTBifOD5',
        menuUrl: 'http://localhost:3000/consolidated-gql/query',
        payUrl: 'http://localhost:3000/phpay/v1',
        yumAuthUrl: 'https://www.pizzahut.com/v1/oidc/oauth2/token',
        PizzaHut: {
            keyV1: 'RyAzYIiBQMB6ISN8CAOiulwmGHA68F4c',
            keyV2: 'z3Av4fjnaVcxsjWkT1QNvhGwFrXzGnXG',
            keyV3: 'KpnMjBhqAaMrI7aB7bPQ4PV8qU9Q09tR',
        },
        ['PizzaHut-Staging']: {
            keyV1: 'A9PHUPQbR2GGV1LWIqSRWFrA5Muo5Ced',
            keyV2: '',
            keyV3: 'KpnMjBhqAaMrI7aB7bPQ4PV8qU9Q09tR',
        },
        iOS: {
            keyV1: 'x61c4lN6nyNJZMpwyo228jCAGGCri7n0',
            keyV2: 'ojpxGnc1sS15fpFThtDymgA0kt07woaC',
            keyV3: 'KpnMjBhqAaMrI7aB7bPQ4PV8qU9Q09tR',
        },
        Android: {
            keyV1: '5SsW7CAOvzA8gKT0MqoL8n6LZMyp8Aqi',
            keyV2: 'ajNK2IGTjTBtLudTYf7G0cx2MheMyiFk',
            keyV3: 'KpnMjBhqAaMrI7aB7bPQ4PV8qU9Q09tR',
        },
        HutHutWin: {
            keyV1: 'IbZikSUIukmOmS1qMXCwJjwoET3AADAt',
            keyV2: '',
            keyV3: 'KpnMjBhqAaMrI7aB7bPQ4PV8qU9Q09tR',
        },
        alexa: {
            keyV1: 'dO790OfSmaNWyTUG0tXS6i7JvF0lcvUg',
            keyV2: 'AUvP0AjmU3gYVNO5KUnmEyoI5aISQOy3',
            keyV3: 'KpnMjBhqAaMrI7aB7bPQ4PV8qU9Q09tR',
        },
        ['google-assistant']: {
            keyV1: 'lWVrGyxa092MlaeCCI4q2LGzKcDrY9G9',
            keyV2: 'xHQxER7AAXKU7bc999nBDnZOEb7EUi4Y',
            keyV3: 'KpnMjBhqAaMrI7aB7bPQ4PV8qU9Q09tR',
        },
        synack: {
            keyV1: 'Ygpf5sYw6jgryejLWMwGZyHZThzvdqCm',
            keyV2: 'Vf9FifiIVHMGO36lImTszS2jyZWSe7AR',
            keyV3: 'KpnMjBhqAaMrI7aB7bPQ4PV8qU9Q09tR',
        },
        WEB2: {
            keyV1: 'ApXqGyM6xPAp0AXTYIi3DSGGBGeRDXSS',
            keyV2: '8CNl1ssOwYGrdFM924imkWYbXnJ5totq',
            keyV3: 'KpnMjBhqAaMrI7aB7bPQ4PV8qU9Q09tR',
        },
    },
};
export const AkamaiHeader = {
    development: 'X6mgXChU4Ssu6rmF',
    production: '4TV36fraBzFJzpa5',
    proxyProduction: '4TV36fraBzFJzpa5',
    proxyStaging: 'X6mgXChU4Ssu6rmF',
    staging: 'X6mgXChU4Ssu6rmF',
};
