import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import fontStyles from '@/common/fontStyles';
import { largeStartBreakpoint } from '@/materialUi/theme';

type StyleProps = {
  isMenuTile: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  nutrition: {
    gridArea: 'nutrition',
    width: '100%',
    ...fontStyles.productNutrition,
    borderTop: '1px solid #CFCECC',
    padding: (props) => (props.isMenuTile ? '13px 16px 0' : '13px 0 0'),
    marginTop: '24px',
    alignSelf: 'end',
    '& p': {
      lineHeight: '18px'
    },
    [theme.breakpoints.up(largeStartBreakpoint)]: {
      padding: (props) => (props.isMenuTile ? '13px 24px 0' : '13px 0 0'),
      display: 'flex',
      flexWrap: 'wrap',
      '& p:not(:last-child):after': {
        content: (props) => (props.isMenuTile ? '"|"' : ''),
        margin: '0 5px'
      }
    }
  }
}));

export default useStyles;
