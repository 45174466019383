import { DealData } from '@/builders/deals/slice/dealTypes';
import { OccasionApi } from '@/localization/constants';
import {
  DealBuilderWithAvailability,
  transformGetDealBuilderData
} from '@/clientCore/temporaryTransformationalHooks/useCCGetDealBuilderQuery/transformGetDealBuilderData';

export const transformGetDiscountFromMarketingCodeData = (
  data: DealBuilderWithAvailability,
  occasion: OccasionApi,
  storeTimeZone: string
): DealData => transformGetDealBuilderData(data, occasion, storeTimeZone);
