var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { customerApi } from './CustomerApi';
import { camelToSnake, snakeToCamel } from '../../utils';
/**
 * Defines the endpoints related to customer programs and promotions. We use the
 * [injectEndpoints](https://redux-toolkit.js.org/rtk-query/usage/code-splitting) feature of RTK Query's `createApi`.
 */
export const customerApiPromotion = customerApi.injectEndpoints({
    endpoints: (build) => {
        return {
            addProgram: build.mutation({
                query: (program) => {
                    const data = camelToSnake(program);
                    return {
                        data,
                        method: 'post',
                        url: `/customer/programs`,
                    };
                },
            }),
            addPromotion: build.mutation({
                invalidatesTags: ['Customer', 'Promotions'],
                queryFn: (promotion, { getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    const { coreConfig: { isYumEcomm }, } = getState();
                    const data = camelToSnake(promotion);
                    const { error } = yield fetchWithBQ({
                        data,
                        isYum: isYumEcomm,
                        method: 'post',
                        url: '/customer/promotions',
                    });
                    if (error) {
                        return { error };
                    }
                    return { data: null };
                }),
            }),
            deleteProgram: build.mutation({
                query: (programId) => ({
                    method: 'delete',
                    url: `/customer/programs/${programId}`,
                }),
            }),
            deletePromotion: build.mutation({
                invalidatesTags: ['Customer', 'Promotions'],
                query: (promotionId) => ({
                    method: 'delete',
                    url: `/customer/promotions/${promotionId}`,
                }),
            }),
            promotions: build.query({
                providesTags: ['Promotions'],
                query: () => ({
                    method: 'get',
                    url: `/customer/promotions`,
                }),
                transformResponse: (response) => {
                    const data = snakeToCamel(response);
                    return data;
                },
            }),
        };
    },
});
export const { useAddProgramMutation, useAddPromotionMutation, useDeleteProgramMutation, useDeletePromotionMutation, usePromotionsQuery, } = customerApiPromotion;
