import React from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { breakPointsValues } from '../../materialUi/theme';
import useFallback from '../useFallback';
import useImageDimensionsOnLoad from '../useImageDimensionsOnLoad';

export interface ResponsiveImageRenderProps extends Image {
  pictureClassName?: string;
  className: string;
  testId: string;
  altText: string;
  ariaHidden?: boolean;
  fallbackImage?: string;
  loading?: boolean;
  loadingAnimation?: 'pulse' | 'wave' | false;
  onClick?: (e?: React.SyntheticEvent) => void;
}

const useStyles = makeStyles(() => createStyles({
  skeletonImage: {
    width: '100%',
    height: '100%'
  },
  hidden: {
    visibility: 'hidden',
    maxHeight: '0',
    overflow: 'hidden'
  },
  skeletonContainer: {
    overflow: 'hidden'
  },
  skeletonFlare: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    content: '""',
    animation: '$wave 1.6s linear 0.5s infinite',
    transform: 'translateX(-100%)',
    background: 'linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.04), transparent)'
  },
  '@keyframes wave': {
    '0%': {
      transform: 'translateX(-100%)'
    },
    '60%': {
      // +0.5s of delay between each loop
      transform: 'translateX(100%)'
    },
    '100%': {
      transform: 'translateX(100%)'
    }
  }
}));

const ResponsiveImageRender = (props: ResponsiveImageRenderProps) => {
  const {
    mobile,
    desktop,
    pictureClassName,
    className,
    testId,
    altText,
    fallbackImage = '',
    ariaHidden = false,
    loading,
    loadingAnimation,
    onClick
  } = props;
  const classes = useStyles();
  const { ref, onError, sourceRef } = useFallback(fallbackImage, { desktopImage: desktop, mobileImage: mobile }, loading);
  const { onLoad, imageLoaded } = useImageDimensionsOnLoad(ref);

  const loadingPropExists = typeof loading !== 'undefined';
  const stillWaiting = !imageLoaded || loading;
  const shouldShowSkeleton = loadingPropExists && stillWaiting;

  const classForSkeletonImage = loadingAnimation && shouldShowSkeleton && classes.skeletonImage;

  return (
    <div
      aria-hidden={ariaHidden}
      data-testid={`${testId}-wrapper`}
      className={loadingAnimation ? classes.skeletonContainer : undefined}
    >
      {loadingAnimation && shouldShowSkeleton && (
        <div
          data-testid={`${testId}-skeleton`}
          className={loadingAnimation && classes.skeletonFlare}
        >
          &nbsp;
        </div>
      )}
      <picture className={pictureClassName} onLoad={onLoad}>
        <source
          media={`(min-width:${breakPointsValues.md}px)`}
          data-testid={`${testId}-desktop`}
          srcSet={desktop}
          ref={sourceRef}
        />
        <img
          aria-hidden={ariaHidden}
          className={clsx(className, classForSkeletonImage)}
          src={mobile}
          alt={altText}
          data-testid={`${testId}-mobile`}
          ref={ref}
          loading="lazy"
          onClick={(e) => onClick?.(e)}
          onError={onError}
        />
      </picture>
    </div>
  );
};

export default ResponsiveImageRender;
