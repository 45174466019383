import { Grid, Typography } from '@mui/material';
import React from 'react';
import styles from '../styles';

interface ItemDescriptionProps {
  description: string;
}

const ItemDescription = ({ description }: ItemDescriptionProps) => {
  const classes = styles.cartItem();

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={10}>
        <Typography component="span" className={classes.span}>
          { description }
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography>&nbsp;</Typography>
      </Grid>
    </Grid>
  );
};

export default ItemDescription;
