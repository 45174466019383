import { isArray, isObject, isString } from 'remeda';

export type Nullable<T> = T | null;
export type Maybe<T> = Nullable<T> | undefined;
export type OrEmptyObj<T> = T | Record<string, never>;

// eslint-disable-next-line @typescript-eslint/ban-types
const isEmpty = <T extends Maybe<string | object | any[]>>(
  value: T
): value is never => {
  if (isString(value) || isArray(value)) {
    return value.length === 0;
  }
  if (isObject(value)) {
    return Object.keys(value).length === 0;
  }
  return true;
};

const negate = <T extends any[]>(fn: (...args: T) => boolean): (...args: T) => boolean => (...args) => !fn(...args);

const random = (max = 1): number => Math.random() * max;

const round = (value: number): number => Math.round(value);

let idCount = 0;
// eslint-disable-next-line no-plusplus
const uniqueSvgID = (id: string): string => (`${id}-${++idCount}`);

// eslint-disable-next-line no-control-regex
const asciiWordRegex = /[^\x00-\x2f\x3a-\x40\x5b-\x60\x7b-\x7f]+/g;
const asciiWords = (value: string) => value.match(asciiWordRegex);
const words = <T extends Maybe<string>>(value: T, pattern?: string): RegExpMatchArray | null => (pattern && value ? value.match(pattern) : value && asciiWords(value));

// aaa | A | Aaaa
const lowerToUpperBoundaryMatcher = /([a-z]+)|([A-Z]+[a-z]*)/g;
const mapAndJoin = <T extends Maybe<string>>(value: T, mapFn: (val: string) => string, delimiter: string): string => {
  const wordSplit = (words(value) || []).flatMap((str) => str.match(lowerToUpperBoundaryMatcher) || str);
  return wordSplit.map(mapFn).join(delimiter);
};

const capitalize = <T extends Maybe<string>>(value: T): string => (value ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() : '');

const lowerCase = <T extends Maybe<string>>(value: T): string => (value ? value.toLowerCase() : '');

const startCase = <T extends Maybe<string>>(value: T): string => mapAndJoin(value, capitalize, ' ');

const kebabCase = <T extends Maybe<string>>(value: T): string => mapAndJoin(value, lowerCase, '-');

export {
  kebabCase, startCase, random, round, uniqueSvgID, capitalize, negate, isEmpty
};
