import { useCallback } from 'react';
import {
  DeliveryProvider,
  DiningOccasion,
  LocalizeCustomerCartInput,
  useCreateCustomerCartMutation,
  useResetCustomerCartMutation
} from '@pizza-hut-us-development/client-core';
import { useDispatch, useSelector } from 'react-redux';
import { useDecision } from '@optimizely/react-sdk';

import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import { toggleCartLoadingStatus } from '@/clientCore/redux/cart/CartSlice';

import { localizationSelectors } from '@/localization/localizationSelectors';
import { occasionLegacyToCC } from '../helpers';
import { OccasionApi } from '@/localization/constants';
import { resetToNationalStore } from '@/localization/actions';
import { setCookie } from '@/cookies/handlers';
import { COOKIES } from '@/cookies/constants';

type CreateCartInputOptions = {
  shouldResetYumCart?: boolean;
};

export const useCreateCart = () => {
  const dispatch = useDispatch();

  const store = useSelector(orderSelectors.store);
  const occasion = useSelector(localizationSelectors.occasion);
  const localizedAddress = useSelector(
    localizationSelectors.currentlyLocalizedAddress
  );

  const [{ enabled: preventCartAddressStoreAddressFix }] = useDecision('fr-web-4054-prevent-cart-address-store-address');
  const [{ enabled: cartCallErrorOnConfirmationPageEnable }] = useDecision('fr-web-4597-cart-call-error-on-confirmation-page');

  const [createCartMutation] = useCreateCustomerCartMutation();
  const [resetCartMutation] = useResetCustomerCartMutation();

  const saveCartIdInCookie = useCallback(async (cartCCId: string) => {
    setCookie(COOKIES.CART_CC_ID, cartCCId);
  }, []);

  const createCart = useCallback(async (options?: CreateCartInputOptions) => {
    if (preventCartAddressStoreAddressFix && OccasionApi[occasion] === 'DELIVERY' && store?.address1 === localizedAddress?.address) {
      return;
    }

    if(cartCallErrorOnConfirmationPageEnable && !store?.storeNumber) {
      return;
    }

    try {
      dispatch(toggleCartLoadingStatus({ loadingState: true }));

      if (options?.shouldResetYumCart) {
        await resetCartMutation();
      }
      const response = await createCartMutation({
        storeNumber: store?.storeNumber,
        occasion: occasionLegacyToCC(occasion),
        ...(occasionLegacyToCC(occasion) !== DiningOccasion.CARRYOUT && {
          deliveryAddress: {
            address1: localizedAddress?.address ?? '',
            address2: localizedAddress?.address2 ?? '',
            city: localizedAddress?.city ?? '',
            state: localizedAddress?.state ?? '',
            postalCode: localizedAddress?.zipcode ?? '',
            countryCode: 'US',
            ...(localizedAddress?.lat && localizedAddress?.lng && {
              position: {
                coordinates: [
                  localizedAddress?.lng, localizedAddress?.lat
                ]
              }
            })
          },
          deliveryProvider: 'INTERNAL' as DeliveryProvider
        })
      } as LocalizeCustomerCartInput);

      if ('error' in response) throw response.error;
      await saveCartIdInCookie(response.data.cartId);
    } catch (error) {
      resetToNationalStore(dispatch);
    } finally {
      dispatch(toggleCartLoadingStatus({ loadingState: false }));
    }
  }, [
    createCartMutation,
    resetCartMutation,
    saveCartIdInCookie,
    dispatch,
    localizedAddress,
    occasion,
    store,
    preventCartAddressStoreAddressFix,
    cartCallErrorOnConfirmationPageEnable
  ]);

  return [createCart];
};
