import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { Implementation } from './getHiddenImplementation/getHiddenImplementation';

type BreakpointKeys = 'xsDown' | 'smDown' | 'mdDown' | 'lgDown' | 'xlDown' | 'xsUp' | 'smUp' | 'mdUp' | 'lgUp' | 'xlUp';

type Props = {
  children: React.ReactNode;
  implementation?: Implementation;
  xsDown?: boolean;
  smDown?: boolean;
  mdDown?: boolean;
  lgDown?: boolean;
  xlDown?: boolean;
  xsUp?: boolean;
  smUp?: boolean;
  mdUp?: boolean;
  lgUp?: boolean;
  xlUp?: boolean;
  [key: string]: any;
};

const ShownOnDevice = (props: Props): JSX.Element | null => {
  const { children, ...restProps } = props;
  const theme = useTheme();

  // Media queries for both up and down for all breakpoints
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const isXlDown = useMediaQuery(theme.breakpoints.down('xl'));

  const isXsUp = useMediaQuery(theme.breakpoints.up('xs'));
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const isXlUp = useMediaQuery(theme.breakpoints.up('xl'));

  // Determine visibility based on props and breakpoints
  const isVisible: Record<BreakpointKeys, boolean> = {
    xsDown: isXsDown,
    smDown: isSmDown,
    mdDown: isMdDown,
    lgDown: isLgDown,
    xlDown: isXlDown,
    xsUp: isXsUp,
    smUp: isSmUp,
    mdUp: isMdUp,
    lgUp: isLgUp,
    xlUp: isXlUp
  };

  // Visibility check: check for the specific condition
  const shouldRender = (Object.keys(isVisible) as BreakpointKeys[]).some(
    (key: BreakpointKeys) => restProps[key] && isVisible[key]
  );

  return shouldRender ? <>{children}</> : null;
};

export default ShownOnDevice;
