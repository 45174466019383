import { CurrencyCode } from '../types';
/*
this function will take a single dip product and create multiple dip
products using the modifiers defined in the slot on the dip variant

 Assumptions:
 1. The dip has a single variant
 2. That single variant has a single slot
*/
const transformDipToMultipleProducts = (dip, menuModifiers) => {
    // get the variant defined on the product
    const { variants } = dip;
    const [dipVariant] = variants;
    if (!dipVariant) {
        throw new Error('dip does not have a variant');
    }
    // get the slot defined on the variant
    const { slots } = dipVariant;
    const [dipSlot] = slots;
    if (!dipSlot) {
        throw new Error('dip variant does not have a slot');
    }
    // get the modifiers defined on the slot
    const { modifiers, slotCode } = dipSlot;
    if (!modifiers.length) {
        throw new Error('dip slot does not have any modifiers');
    }
    // build a new YumProduct based on each modifier on the slot
    const dips = modifiers.reduce((acc, modifier) => {
        var _a, _b, _c;
        const { modifierCode, weights } = modifier;
        const menuModifier = menuModifiers.find((m) => m.modifierCode === modifierCode);
        if (!menuModifier)
            return acc;
        const [weight] = weights;
        const { modifierWeightCode, price } = weight;
        const zeroPrice = { amount: 0, currencyCode: CurrencyCode.USD };
        const variant = Object.assign(Object.assign({}, dipVariant), { defaultModifiers: [{ modifierCode, modifierWeightCode, slotCode }], price: price !== null && price !== void 0 ? price : zeroPrice, slots: [
                Object.assign(Object.assign({}, dipSlot), { modifiers: [modifier] }),
            ] });
        const product = Object.assign(Object.assign({}, dip), { blackout: menuModifier === null || menuModifier === void 0 ? void 0 : menuModifier.blackout, description: (_a = menuModifier === null || menuModifier === void 0 ? void 0 : menuModifier.description) !== null && _a !== void 0 ? _a : '', images: (_b = menuModifier === null || menuModifier === void 0 ? void 0 : menuModifier.images) !== null && _b !== void 0 ? _b : [], name: (_c = menuModifier === null || menuModifier === void 0 ? void 0 : menuModifier.name) !== null && _c !== void 0 ? _c : modifierCode, variants: [variant] });
        acc.push(product);
        return acc;
    }, []);
    return dips;
};
export default transformDipToMultipleProducts;
