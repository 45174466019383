var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { Environments } from '../config';
import { defaultMenuHubConfig } from '../types';
const initialState = {
    authChannel: 'PizzaHut',
    axiosTimeout: 10000,
    clientId: 'ph_us_web',
    consolidatedGqlEnvironment: 'prod',
    conversationId: '',
    debugMode: false,
    enableCoreConfig: true,
    isPreviewMode: false,
    isYumEcomm: false,
    menuConfig: defaultMenuHubConfig,
    mockData: false,
    payUrl: Environments.staging.payUrl,
    phdApiEnvironment: 'staging',
    xServerEnv: 'live-va',
};
export const fetchCoreConfig = createAsyncThunk('coreConfig/fetchCoreConfig', (env, { rejectWithValue }) => __awaiter(void 0, void 0, void 0, function* () {
    const baseUrl = Environments[env].configBaseUrl;
    const url = `${baseUrl}/${env}/coreConfig.json`;
    try {
        const response = yield axios.get(url);
        return response.data;
    }
    catch (error) {
        console.log({ error });
        return rejectWithValue(error.message);
    }
}));
export const coreConfigSlice = createSlice({
    extraReducers: (builder) => {
        builder
            .addCase(fetchCoreConfig.fulfilled, (state, action) => {
            state.menuConfig = action.payload;
        })
            .addCase(fetchCoreConfig.rejected, (state, action) => {
            console.log('fetchCoreConfig.rejected', { action });
            state.menuConfig = defaultMenuHubConfig;
        });
    },
    initialState,
    name: 'coreConfig',
    reducers: {
        setAuthChannel: (state, action) => {
            state.authChannel = action.payload;
        },
        setAxiosTimeout: (state, action) => {
            state.axiosTimeout = action.payload;
        },
        setClientId: (state, action) => {
            state.clientId = action.payload;
        },
        setDebugMode: (state, action) => {
            state.debugMode = action.payload;
        },
        setDevelopmentEnv: (state) => {
            state.payUrl = Environments.development.payUrl;
            state.phdApiEnvironment = 'development';
        },
        setEnableCoreConfig: (state, action) => {
            state.enableCoreConfig = action.payload;
        },
        setIsPreviewMode: (state, action) => {
            state.isPreviewMode = action.payload;
        },
        setIsYumEcomm: (state, action) => {
            state.isYumEcomm = action.payload;
        },
        setMenuHubConfig: (state, action) => {
            state.menuConfig = action.payload;
        },
        setProductionEnv: (state) => {
            state.payUrl = Environments.production.payUrl;
            state.phdApiEnvironment = 'production';
        },
        setProxyProductionEnv: (state) => {
            state.payUrl = Environments.proxyProduction.payUrl;
            state.phdApiEnvironment = 'proxyProduction';
            state.xServerEnv = 'live-va';
        },
        setProxyStagingEnv: (state) => {
            state.payUrl = Environments.proxyStaging.payUrl;
            state.phdApiEnvironment = 'proxyStaging';
            state.xServerEnv = 'live-va';
        },
        setSalesChannel: (state, action) => {
            state.salesChannel = action.payload;
        },
        setStagingEnv: (state) => {
            state.payUrl = Environments.staging.payUrl;
            state.phdApiEnvironment = 'staging';
            state.xServerEnv = 'live-va';
        },
        setState: (state, action) => {
            state = Object.assign(Object.assign({}, state), action.payload);
        },
        setXOriginatingAppId: (state, action) => {
            state.xOriginatingAppId = action.payload;
        },
        setXServerEnv: (state, action) => {
            state.xServerEnv = action.payload;
        },
    },
});
const { setAuthChannel, setAxiosTimeout, setClientId, setDebugMode, setDevelopmentEnv, setEnableCoreConfig, setIsPreviewMode, setIsYumEcomm, setMenuHubConfig, setProductionEnv, setProxyProductionEnv, setProxyStagingEnv, setSalesChannel, setStagingEnv, setXOriginatingAppId, setXServerEnv, } = coreConfigSlice.actions;
export const CoreConfigActions = {
    setAuthChannel,
    setAxiosTimeout,
    setClientId,
    setDebugMode,
    setDevelopmentEnv,
    setEnableCoreConfig,
    setIsPreviewMode,
    setIsYumEcomm,
    setMenuHubConfig,
    setProductionEnv,
    setProxyProductionEnv,
    setProxyStagingEnv,
    setSalesChannel,
    setStagingEnv,
    setXOriginatingAppId,
    setXServerEnv,
};
export const CoreConfigReducer = coreConfigSlice.reducer;
