import {
  CUSTOMER_DETAILS_FAILURE,
  CUSTOMER_DETAILS_SUCCESS,
  LOGIN_STATUS_FALSE
} from './actionTypes';
import { AuthState } from '@/auth/userStates';

export const customerDetailsSuccess = (customerDetails: CustomerDetails) => ({
  type: CUSTOMER_DETAILS_SUCCESS,
  customerDetails
});

export const customerDetailsFailure = () => ({
  type: CUSTOMER_DETAILS_FAILURE
});

export const setLogInStatusToFalse = () => ({
  type: LOGIN_STATUS_FALSE
});

export const setAuthState = (authState: AuthState) => ({
  type: authState
});
