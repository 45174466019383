// This file declares all of the interfaces needed to model a Yum menu
import { Categories, LineupNameOverrides, Placement, Portion } from './menuTypes';
export var BundlePricingMode;
(function (BundlePricingMode) {
    BundlePricingMode["FIXED"] = "FIXED";
    BundlePricingMode["DISCOUNT_CHEAPEST_SELECTION_PERCENT_OFF"] = "DISCOUNT_CHEAPEST_SELECTION_PERCENT_OFF";
    BundlePricingMode["DISCOUNT_CHEAPEST_SELECTION_SET_FIXED"] = "DISCOUNT_CHEAPEST_SELECTION_SET_FIXED";
    BundlePricingMode["CONFIGURED_CHOICE_PRICING"] = "CONFIGURED_CHOICE_PRICING";
    BundlePricingMode["TIME_BASED_BUNDLE_PRICING"] = "TIME_BASED_BUNDLE_PRICING";
})(BundlePricingMode || (BundlePricingMode = {}));
export var ChoicePricingMode;
(function (ChoicePricingMode) {
    ChoicePricingMode["CHOICE_UPCHARGE"] = "CHOICE_UPCHARGE";
    ChoicePricingMode["VARIANT_MENU_PRICE"] = "VARIANT_MENU_PRICE";
})(ChoicePricingMode || (ChoicePricingMode = {}));
export var CurrencyCode;
(function (CurrencyCode) {
    CurrencyCode["USD"] = "USD";
    CurrencyCode["PEN"] = "PEN";
    CurrencyCode["MXN"] = "MXN";
    CurrencyCode["GBP"] = "GBP";
    CurrencyCode["EUR"] = "EUR";
    CurrencyCode["CAD"] = "CAD";
    CurrencyCode["INR"] = "INR";
})(CurrencyCode || (CurrencyCode = {}));
export var DiningOccasion;
(function (DiningOccasion) {
    DiningOccasion["DELIVERY"] = "DELIVERY";
    DiningOccasion["CARRYOUT"] = "CARRYOUT";
    DiningOccasion["DINE_IN"] = "DINE_IN";
    DiningOccasion["DRIVE_THRU"] = "DRIVE_THRU";
})(DiningOccasion || (DiningOccasion = {}));
export var MetafieldValueType;
(function (MetafieldValueType) {
    MetafieldValueType["STRING"] = "STRING";
    MetafieldValueType["INTEGER"] = "INTEGER";
    MetafieldValueType["JSON_STRING"] = "JSON_STRING";
})(MetafieldValueType || (MetafieldValueType = {}));
export var ProductVariantPricingMode;
(function (ProductVariantPricingMode) {
    ProductVariantPricingMode["FIXED"] = "FIXED";
    ProductVariantPricingMode["TIME_BASED_VARIANT_PRICING"] = "TIME_BASED_VARIANT_PRICING";
})(ProductVariantPricingMode || (ProductVariantPricingMode = {}));
export const CategoryMap = {
    ['App/Side']: Categories.SIDES,
    Dessert: Categories.DESSERTS,
    Dip: Categories.DIPS,
    Drink: Categories.DRINKS,
    ['Individual Eats']: Categories.PARTYOFONE,
    Melts: Categories.MELTS,
    ['Party of One']: Categories.PARTYOFONE,
    Pasta: Categories.PASTA,
    Pizza: Categories.PIZZA,
    Promo: Categories.DEALS,
    Salad: Categories.SALADS,
    Sandwich: Categories.SANDWICHES,
    Wings: Categories.WINGS,
};
export const DipProductCodes = new Set(['CLSS070A|PROD338XD', 'CLSS010WS|PROD033XD', 'CLSS070A|PROD339XS']);
export const GlutenFreeCodes = new Set(['CLSS001P|BASE701GF']);
export const LineupNameOverridesMap = {
    ['CLSS001P|PROD010B_CYO1T']: LineupNameOverrides.MEDIUM_CYO,
};
export const ModifierKeys = {
    classicMarinara: 'CLSS001P|TOPP721PS',
};
export const OptionKeys = {
    appetizerSize: 'option_appetizer_size',
    dessertSize: 'dessert_size',
    dipBase: 'wingstreet_base',
    dipSize: 'wingstreet_size',
    drinkSize: 'option_carryout_drinks_size',
    friesSeasoning: 'fries_seasoning',
    friesSize: 'wingstreet_size',
    meltCrust: 'option_pizza_melt_base',
    meltSize: 'option_pizza_melt_size',
    noOption: 'No Option',
    otherDrinkSize: 'option_no_tax_beverages_size',
    pastaSize: 'pasta_size',
    pizzaCrust: 'option_pizza_crust',
    pizzaSize: 'option_pizza_size',
    saladSize: 'option_salad_size',
    sandwichSize: 'option_sandwich_size',
    softDrinkSize: 'option_soft_drinks_size',
    wingFlavor: 'wing_flavor',
    wingSize: 'wing_size',
};
export const PlacementMap = {
    ['CLSS001P|TOPP200XC']: Placement.WHOLE,
    ['CLSS001P|TOPP206NC']: Placement.WHOLE,
    ['CLSS001P|TOPP547ZC']: Placement.WHOLE,
    ['CLSS001P|TOPP701EZ']: Placement.WHOLE,
    ['CLSS001P|TOPP702XS']: Placement.WHOLE,
    ['CLSS001P|TOPP710XB']: Placement.WHOLE,
    ['EXTRA_SAUCE']: Placement.WHOLE,
    ['LEFT_EXTRA']: Placement.LEFT,
    ['LEFT_REGULAR']: Placement.LEFT,
    ['LIGHT_SAUCE']: Placement.WHOLE,
    ['NONE']: Placement.WHOLE,
    ['RIGHT_EXTRA']: Placement.RIGHT,
    ['RIGHT_REGULAR']: Placement.RIGHT,
    ['WHOLE_EXTRA']: Placement.WHOLE,
    ['WHOLE_LIGHT']: Placement.WHOLE,
    ['WHOLE_REGULAR']: Placement.WHOLE,
};
export const PlacementMultipliers = {
    [Placement.LEFT]: 0.5,
    [Placement.RIGHT]: 0.5,
    [Placement.WHOLE]: 1.0,
};
export const PortionMap = {
    ['CLSS001P|TOPP200XC']: Portion.EXTRA,
    ['CLSS001P|TOPP206NC']: Portion.NONE,
    ['CLSS001P|TOPP547ZC']: Portion.LIGHT,
    ['CLSS001P|TOPP701EZ']: Portion.LIGHT,
    ['CLSS001P|TOPP702XS']: Portion.EXTRA,
    ['CLSS001P|TOPP710XB']: Portion.EXTRA,
    EXTRA_SAUCE: Portion.EXTRA,
    LEFT_EXTRA: Portion.EXTRA,
    LEFT_REGULAR: Portion.REGULAR,
    LIGHT_SAUCE: Portion.LIGHT,
    NONE: Portion.NONE,
    RIGHT_EXTRA: Portion.EXTRA,
    RIGHT_REGULAR: Portion.REGULAR,
    WHOLE_EXTRA: Portion.EXTRA,
    WHOLE_LIGHT: Portion.LIGHT,
    WHOLE_REGULAR: Portion.REGULAR,
};
export const PortionMultipliers = {
    [Portion.EXTRA]: 2.0,
    [Portion.LIGHT]: 0.5,
    [Portion.NONE]: 0,
    [Portion.REGULAR]: 1.0,
};
export const SizeKeys = new Set([
    OptionKeys.appetizerSize,
    OptionKeys.dessertSize,
    OptionKeys.dipSize,
    OptionKeys.drinkSize,
    OptionKeys.friesSize,
    OptionKeys.meltSize,
    OptionKeys.noOption,
    OptionKeys.otherDrinkSize,
    OptionKeys.pastaSize,
    OptionKeys.pizzaSize,
    OptionKeys.saladSize,
    OptionKeys.sandwichSize,
    OptionKeys.softDrinkSize,
    OptionKeys.wingSize,
]);
export const NonSizeKeys = new Set([
    OptionKeys.dipBase,
    OptionKeys.friesSeasoning,
    OptionKeys.meltCrust,
    OptionKeys.pizzaCrust,
    OptionKeys.wingFlavor,
]);
export const SlotKeys = {
    appetizerDip: 'slot_appetizer_dip_cup',
    appetizerToppings: 'slot_appetizer_toppings',
    extraDip: 'slot_wingstreet_extra_dip_sauce',
    meltCheese: 'slot_pizza_melt_cheese',
    meltDip: 'slot_pizza_melt_dip_cup',
    meltSauce: 'slot_pizza_melt_sauce',
    meltSeasoning: 'slot_pizza_melt_seasoning',
    pastaSide: 'slot_pasta_side_item',
    pizzaCheese: 'slot_pizza_cheese',
    pizzaDip: 'slot_pizza_dip_cup',
    pizzaPreferences: 'slot_pizza_preferences',
    pizzaSauce: 'slot_pizza_sauce',
    pizzaSeasoning: 'slot_pizza_seasoning',
    pizzaToppings: 'slot_pizza_toppings',
    wingDip: 'slot_wingstreet_wings_dip_cup',
};
export const WeightKeys = {
    extraCheese: 'CLSS001P|TOPP200XC',
    extraSauce: 'EXTRA_SAUCE',
    extraSauceMarinara: 'CLSS001P|TOPP702XS',
    lightCheese: 'CLSS001P|TOPP547ZC',
    lightSauce: 'LIGHT_SAUCE',
    lightSauceMarinara: 'CLSS001P|TOPP701EZ',
    noCheese: 'CLSS001P|TOPP206NC',
    none: 'NONE',
    regularCheese: 'WHOLE_REGULAR',
    regularSauce: 'WHOLE_REGULAR',
    wholeRegular: 'WHOLE_REGULAR',
};
export const CheeseDescriptions = {
    [WeightKeys.extraCheese]: 'Extra Cheese',
    [WeightKeys.lightCheese]: 'Light Cheese',
    [WeightKeys.noCheese]: 'No Cheese',
    [WeightKeys.regularCheese]: 'Regular Cheese',
};
export const WeightMap = {
    ['CLSS001P|TOPP200XC']: { placement: Placement.WHOLE, portion: Portion.EXTRA },
    ['CLSS001P|TOPP206NC']: { placement: Placement.WHOLE, portion: Portion.NONE },
    ['CLSS001P|TOPP547ZC']: { placement: Placement.WHOLE, portion: Portion.LIGHT },
    ['CLSS001P|TOPP701EZ']: { placement: Placement.WHOLE, portion: Portion.LIGHT },
    ['CLSS001P|TOPP702XS']: { placement: Placement.WHOLE, portion: Portion.EXTRA },
    EXTRA_SAUCE: { placement: Placement.WHOLE, portion: Portion.EXTRA },
    LIGHT_SAUCE: { placement: Placement.WHOLE, portion: Portion.LIGHT },
    NONE: { placement: Placement.WHOLE, portion: Portion.NONE },
    WHOLE_REGULAR: { placement: Placement.WHOLE, portion: Portion.REGULAR },
};
