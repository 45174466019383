import React, { useCallback, useState } from 'react';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

import styles from './styles';

type CartNotificationProps = {
    title: string;
    content: string;
}

export const CartItemsRemoved = ({ title, content }: CartNotificationProps) => {
    const classes = styles();

    const [isExpanded, setIsExpanded] = useState(true);

    const handleHeaderClick = useCallback(() => {
        setIsExpanded(value => !value);
    }, [])

    return (
        <div className={classes.container}>
            <button type="button" className={classes.header} onClick={handleHeaderClick}>
                <span className={classes.title}>{title}</span>
                {isExpanded ? <ArrowDropUp className={classes.arrow} /> : <ArrowDropDown className={classes.arrow} />}
            </button>
            {isExpanded ? <div className={classes.content}>{content}</div> : null}
        </div>
    )
}