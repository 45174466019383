import { useSelector } from 'react-redux';
import { useGetPointsQuery } from '@pizza-hut-us-development/client-core';

import { userDomainSelectors } from '@/header/userDomainSelectors';

type UseLoyaltyPoints = {
  currentPoints?: number;
};

export const useLoyaltyPoints = (): UseLoyaltyPoints => {
  const isLoggedIn = useSelector(userDomainSelectors.isAuthenticated);

  const {
    data
  } = useGetPointsQuery(undefined, { skip: !isLoggedIn });

  return {
    currentPoints: data?.currentValue
  };
};
