import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { AlertIcon } from '@/clientCore/cart/components/CartRail/common/AlertIcon';
import { useStyles } from '@/clientCore/cart/components/CartRail/components/Inline7DLWarning/styles';
import { DEAL_LOVERS_INLINE_WARNING } from '@/clientCore/cart/components/CartRail/components/Inline7DLWarning/constants';
import colors from '@/common/colors';
import Routes from '@/router/routes';
import { closeCartRail } from '@/clientCore/redux/rail/CartRailSlice';

const Inline7DLWarning = (): JSX.Element => {
  const classes = useStyles();
  const router = useRouter();
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(closeCartRail());
    if (router.pathname !== Routes.LINEUP) {
      router.push(Routes.LINEUP);
    }
  };

  return (
    <Grid
      container
      direction="row"
      className={classes.container}
      wrap="nowrap"
      data-testid="value-lineup-alert"
    >
      <Grid item className={classes.icon}>
        <AlertIcon color={colors.gray900} />
      </Grid>
      <Grid item className={classes.textContainer}>
        <Grid container className={classes.warningContainer}>
          <Typography component="span" variant="caption">
            {DEAL_LOVERS_INLINE_WARNING.content}
          </Typography>
          <Typography component="span" variant="caption" onClick={onClick}>
            <Typography
              component="span"
              variant="caption"
              className={classes.addItemSpan}
            >
              {DEAL_LOVERS_INLINE_WARNING.cta}
            </Typography>
            <ArrowForwardIosIcon className={classes.caratIcon} />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Inline7DLWarning;
