var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import { menuApi } from './MenuApi';
import { Categories, DiningOccasion, } from '../../types';
import GET_DEALS from './queries/getDeals';
import GET_MENU_HUB_NUTRITION from './queries/getMenuHubNutrition';
import { checkIsDealAvailable, filterItemByPrivateMetafield, isBundleAvailableForTimeAndOccasion, getQuickAddPayload, mergeNutrition, transformDealChoicesToMap, transformMenuCategories, transformMenuDeals, transformProductsWithNoRequiredSelectionsToVariantMap, transformYumBundleToDealProduct, } from '../../utils';
import { menuApiContentGatewayHub } from './MenuApiContentGatewayHub';
import { menuApiConsolidatedGqlHub } from './MenuApiConsolidatedGqlHub';
const KEEP_UNUSED_DATA_FOR_FIVE_MINUTES = 60 * 5;
export const menuApiHub = menuApi.injectEndpoints({
    endpoints: (build) => ({
        getDeals: build.query({
            queryFn: (options, { dispatch, getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                var _a;
                const { coreConfig: { isPreviewMode, isYumEcomm }, order: { cart }, } = getState();
                let response;
                if (isYumEcomm) {
                    response = yield dispatch(menuApiContentGatewayHub.endpoints.getMenu.initiate(options.storeNumber));
                    const menu = response.data;
                    let { bundles } = menu;
                    const { bundleChoices, categories } = menu;
                    const occasion = (_a = cart === null || cart === void 0 ? void 0 : cart.occasionId) !== null && _a !== void 0 ? _a : DiningOccasion.CARRYOUT;
                    // if we have the deals category, use it to build the CC deals otherwise fall back to bundles
                    const dealsCategory = categories.find((category) => category.categoryCode === Categories.DEALS);
                    if (dealsCategory) {
                        bundles = dealsCategory.items.map(({ bundleCode }) => bundles.find((bundle) => bundle.bundleCode === bundleCode));
                    }
                    // transform Yum bundles into client core deal products
                    // we need to know the current time because some bundles are restricted to a time/occasion
                    const now = new Date();
                    const dealProducts = bundles
                        .filter((bundle) => isBundleAvailableForTimeAndOccasion(bundle, now, occasion))
                        .filter((bundle) => (!isPreviewMode ? filterItemByPrivateMetafield(bundle) : true))
                        .map((bundle) => transformYumBundleToDealProduct(bundle, bundleChoices, menu));
                    const visibleDeals = dealProducts.filter((deal) => !deal.hidden);
                    const deals = {
                        displayOrder: 0,
                        featuredProducts: (visibleDeals === null || visibleDeals === void 0 ? void 0 : visibleDeals.slice(0, 2)) || [],
                        id: Categories.DEALS,
                        metafields: [],
                        name: 'Deals',
                        privateMetafields: [],
                        products: dealProducts,
                    };
                    response = response.data ? { data: deals } : { error: response.error };
                }
                else {
                    response = yield fetchWithBQ({
                        data: {
                            operationName: 'getDeals',
                            query: GET_DEALS,
                            variables: {
                                occasion: options.occasion,
                                storeID: `stores/${options.storeNumber}`,
                            },
                        },
                        method: 'post',
                    });
                    if (response.data) {
                        const deals = transformMenuDeals(response.data.data);
                        response = { data: deals };
                    }
                    else {
                        response = { error: response.error };
                    }
                }
                return (response === null || response === void 0 ? void 0 : response.data) ? { data: response.data } : { error: response.error };
            }),
            structuralSharing: false,
        }),
        getDealsWithQuickAddPayload: build.query({
            keepUnusedDataFor: KEEP_UNUSED_DATA_FOR_FIVE_MINUTES,
            queryFn: ({ storeNumber }, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
                var _b;
                const { coreConfig: { isPreviewMode }, order: { cart }, } = getState();
                let response = yield dispatch(menuApiContentGatewayHub.endpoints.getMenu.initiate(storeNumber));
                const menu = response.data;
                if (!menu) {
                    return {
                        data: {
                            products: []
                        }
                    };
                }
                let { bundles } = menu;
                const { categories } = menu;
                // if we have the deals category, use it to build the CC deals otherwise fall back to bundles
                const dealsCategory = categories.find((category) => category.categoryCode === Categories.DEALS);
                if (dealsCategory) {
                    bundles = dealsCategory.items.map(({ bundleCode }) => bundles.find((bundle) => bundle.bundleCode === bundleCode));
                }
                const occasion = (_b = cart === null || cart === void 0 ? void 0 : cart.occasionId) !== null && _b !== void 0 ? _b : DiningOccasion.CARRYOUT;
                const dealChoicesMap = transformDealChoicesToMap(menu.bundleChoices);
                const variantFromProducts = transformProductsWithNoRequiredSelectionsToVariantMap(menu.products);
                const now = new Date();
                return {
                    data: {
                        products: bundles
                            .filter((deal) => checkIsDealAvailable({ deal, isPreviewMode, currentOccasion: occasion, now }))
                            .map((deal) => {
                            const dealChoices = deal.choices.map((choice) => dealChoicesMap[choice.choiceCode]);
                            return Object.assign(Object.assign({}, transformYumBundleToDealProduct(deal, dealChoices, menu)), { quickAddPayload: getQuickAddPayload(deal, dealChoices, variantFromProducts, menu.modifiers) });
                        })
                    }
                };
            })
        }),
        getMenuHub: build.query({
            queryFn: (options, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
                const { coreConfig: { isYumEcomm }, } = getState();
                let response;
                if (isYumEcomm) {
                    response = yield dispatch(menuApiContentGatewayHub.endpoints.getContentGatewayMenuHub.initiate(options));
                }
                else {
                    response = yield dispatch(menuApiConsolidatedGqlHub.endpoints.getConsolidatedGqlMenu.initiate(options));
                }
                return response.data ? { data: response.data } : { error: response.error };
            }),
            structuralSharing: false,
        }),
        getMenuHubNutrition: build.query({
            onQueryStarted: (options, { dispatch, queryFulfilled }) => __awaiter(void 0, void 0, void 0, function* () {
                const { data: nutrition } = yield queryFulfilled;
                dispatch(menuApiHub.util.updateQueryData('getMenuHub', options, (cachedState) => {
                    cachedState.categories.forEach((category, index) => {
                        // get the corresponding category from the nutrition
                        const categoryNutrition = nutrition.categories.find((element) => element.id === category.id);
                        // merge in nutrition data
                        if (categoryNutrition) {
                            cachedState.categories[index] = mergeNutrition(category, categoryNutrition);
                        }
                    });
                }));
            }),
            query: (options) => ({
                data: {
                    operationName: 'getMenuHubNutrition',
                    query: GET_MENU_HUB_NUTRITION,
                    variables: {
                        envID: options.environmentId,
                        occasions: [options.occasion],
                        storeID: options.storeNumber,
                    },
                },
                method: 'post',
            }),
            structuralSharing: false,
            transformResponse: (response) => ({
                categories: transformMenuCategories(response.data),
                featuredProducts: [],
            }),
        }),
    }),
});
export const { useGetDealsQuery, useGetDealsWithQuickAddPayloadQuery, useGetMenuHubNutritionQuery, useGetMenuHubQuery, useLazyGetDealsQuery, useLazyGetMenuHubNutritionQuery, useLazyGetMenuHubQuery, } = menuApiHub;
