import transformBundleChoicesToCartItemInputModifiers from './transformBundleChoicesToCartItemInputModifiers';
const getQuickAddPayload = (deal, dealChoices, variantMap, menuModifiers) => {
    const isSingleVariantAndModifierForEveryChoice = dealChoices.every((choice) => { var _a; return choice.variants.length === 1 && !!variantMap[(_a = choice.variants[0]) === null || _a === void 0 ? void 0 : _a.variantCode]; });
    if (!isSingleVariantAndModifierForEveryChoice) {
        return null;
    }
    return {
        id: deal.bundleCode,
        name: deal.name,
        type: 'DISCOUNT',
        quantity: 1,
        modifiers: transformBundleChoicesToCartItemInputModifiers(dealChoices, variantMap, menuModifiers)
    };
};
export default getQuickAddPayload;
