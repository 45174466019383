import React from 'react';
import {
  AppBar, Button, Grid
} from '@mui/material';
import CloseIcon from '../icons/CloseIcon';
import {
  SearchFormAnalyticsActionType,
  storeResultsViewButtonAnalytics,
  storeSearchFormAnalytics
} from '../../dataAnalytics/dataAnalyticsHelper';
import styles from './RailHeader.styles';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';

export interface RailHeaderProps {
  onClickClose: () => void;
  label: string;
  testId: string;
  icon: React.ReactNode;
  includeSavedAddressInAnalytics: boolean;
}

export default function RailHeader({
  onClickClose, icon, label, testId, includeSavedAddressInAnalytics
}: RailHeaderProps) {
  const classes = styles();
  const analytics = useAnalytics();

  const onClick = () => {
    onClickClose();
    if (label !== 'Start here') {
      analytics.push(() => storeResultsViewButtonAnalytics(label, 'close'));
    } else {
      const occasion = (testId === 'carryout') ? 'Carryout' : 'Delivery';
      analytics.push(() => storeSearchFormAnalytics(
          occasion,
          SearchFormAnalyticsActionType.CLOSE,
          includeSavedAddressInAnalytics
        ));
    }
  };

  return (
    <AppBar color="inherit" position="sticky" classes={{ root: classes.root }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <span data-testid={`rail-header-${testId}-icon`} className={classes.icon}>
            {icon}
          </span>
          <span data-testid={`rail-header-${testId}-label`} className={classes.label}>
            {label}
          </span>
        </Grid>
        <Grid item>
          <Button
            data-testid="rail-close"
            aria-label="Close store selection"
            onClick={onClick}
            classes={{ root: classes.closeIcon }}
          >
            <CloseIcon />
          </Button>
        </Grid>
      </Grid>
    </AppBar>
  );
}
