import { BundleChoiceVariant, CartItemInput } from '@pizza-hut-us-development/client-core';

export enum IsCyo {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
  UNKNOWN = 'UNKNOWN'
}

enum DayOfWeek {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7
}

export interface Availability {
  days: DayOfWeek[];
  startTime: string;
  endTime: string;
  occasion: string;
  status?: string;
}

export interface RecipeItem {
  id: string;
  name: string;
  imageURL: string | null;
  priority: number;
  outOfStock: boolean;
  isCYO?: IsCyo;
}

export interface DealSteps {
  recipes: {
    outOfStock: boolean;
  }[];
}

export interface MenuRecipe extends RecipeItem {
  imageAltText?: string;
  staticLink?: string;
  availability: Availability[];
  available?:boolean;
  hidden: boolean;
  exists?: boolean;
  vegetarian?: boolean;
  price?: number;
  steps?: DealSteps[];
  allergenDisclaimer?: string;
  productDescription?: string;
  fullId?: string;
  upcharges?: BundleChoiceVariant[];
  type?: string;
  sodiumWarning?: boolean;
  quickAddPayload?: CartItemInput | null;
}

export interface PizzaMenu {
  menu: {
    items: MenuRecipe[];
  };
}
