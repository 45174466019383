import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useSelector } from 'react-redux';
import telemetry from '../../telemetry';
import logger from '@/common/logger';
import { localizationSelectors } from '@/localization/localizationSelectors';

/* Adapted from below github issue detailing issues with img.onError and SSR
 * https://github.com/facebook/react/issues/15446#issuecomment-484709466
 */

interface FallbackImageRefs {
  // Main ref to add to image tag
  ref: MutableRefObject<any>;

  // Optionally add this to the source element of picture tag to enable fallback image replacement
  sourceRef: MutableRefObject<any>;

  // Main error handler to add to image tag
  onError: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void;
}

const hasFallbackImage = (fallbackImage?: string): boolean => Boolean(fallbackImage);
const originalImageFailed = (imgElement: HTMLImageElement, fallbackImage: string): boolean => !fallbackImageFailed(imgElement, fallbackImage);
const fallbackImageFailed = (imgElement: HTMLImageElement, fallbackImage: string): boolean => imgElement.src.includes(fallbackImage);

const replaceOriginalImageWithFallback = (imgElement: HTMLImageElement, fallbackImage: string, sourceElement?: HTMLSourceElement) => {
  imgElement.src = fallbackImage;
  imgElement.srcset = ''; // Fallback images are simple, so eliminate the options for browser to choose from

  if (sourceElement) {
    // Fallback images are not responsive, so just get rid of the <source /> and rely on <img />
    sourceElement.srcset = '';
  }
};
const hideImage = (imgElement: HTMLImageElement) => {
  imgElement.style.display = 'none';
};

const noticeError = (message: string, context: ContextWithLocalization) => {
  logger.withoutTelemetry.error(message, context);
  telemetry.addNoticeError(new Error(message), context);
};

/* eslint-disable no-param-reassign */
const doFallback = (fallbackImage: string, context: ContextWithLocalization, imgElement: HTMLImageElement, sourceElement?: HTMLSourceElement): boolean => {
  if (!imgElement) {
    return false;
  }

  if (hasFallbackImage(fallbackImage) && originalImageFailed(imgElement, fallbackImage)) {
    noticeError('Original image failed to load - showing fallback image', context);
    replaceOriginalImageWithFallback(imgElement, fallbackImage, sourceElement);
    return false;
  }

  if (!hasFallbackImage(fallbackImage)) {
    noticeError('Image failed to load - no fallback image provided', context);
    hideImage(imgElement);
    return true;
  }

  if (hasFallbackImage(fallbackImage) && fallbackImageFailed(imgElement, fallbackImage)) {
    noticeError('Original and fallback images both failed to load', context);
    hideImage(imgElement);
    return true;
  }

  noticeError('Unexpected fallback condition', context);
  return true;
};

interface Context extends Record<string, unknown> {
  desktopImage: string;
  mobileImage: string;
}
interface ContextWithLocalization extends Context, DeliveryAddress {
  storeId: string;
}

export default function useFallback(
  fallbackImage: string, context: Context, loading?: boolean
): FallbackImageRefs {
  const ref = useRef<HTMLImageElement>();
  const sourceRef = useRef<HTMLSourceElement | undefined>();
  const [errored, setErrored] = useState(false);
  const currentlyLocalizedAddress = useSelector(localizationSelectors.currentlyLocalizedAddress);
  const storeId = useSelector(localizationSelectors.storeIdEarly);
  const contextWithStoreDetail = { ...context, ...currentlyLocalizedAddress, storeId };

  // Error happened before hydration, caught after hydration
  useEffect(() => {
    if (ref && ref.current) {
      const { complete, naturalHeight } = ref.current;
      const imageHasFailedToLoad = complete && naturalHeight === 0;

      if (imageHasFailedToLoad && !loading) {
        const sourceTag = sourceRef && sourceRef.current;
        const imgTag = ref && ref.current;
        setErrored(doFallback(fallbackImage, contextWithStoreDetail, imgTag, sourceTag));
      }
    }
  }, [fallbackImage]);

  // Error happened after hydration
  // @ts-expect-error - existing code
  const onError = useCallback((e) => {
    const sourceTag = sourceRef && sourceRef.current;
    setErrored(doFallback(fallbackImage, contextWithStoreDetail, e.target, sourceTag));
  }, [fallbackImage, errored]);

  return {
    ref,
    sourceRef,
    onError
  };
}
