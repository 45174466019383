import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import colors from '@/common/colors';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    background: colors.gray100,
    padding: '12px',
    borderBottom: `1px solid ${colors.gray400}`,
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start'
    }
  },
  icon: {
    paddingRight: '8px',
    display: 'flex'
  },
  addItemSpan: {
    fontSize: '12px',
    width: '100%',
    color: colors.red,
    fontWeight: 'bold',
    paddingLeft: '3px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  caratIcon: {
    color: colors.red,
    fontSize: '10px',
    '&:hover': {
      cursor: 'pointer'
    },
    marginLeft: '4px',
    verticalAlign: 'middle',
    marginBottom: '2px'
  },
  textContainer: {
    flexGrow: 1,
    fontSize: 12
  },
  warningContainer: {
    display: 'block'
  }
}));
