import { useDecision } from "@optimizely/react-sdk";
import { useGetNewProductsQuery } from "@/api/reduxToolkit/newProducts";

export const useGetNewProducts = (): Record<string, boolean> => {
  const [{ enabled: isNewBadgeEnabled }] = useDecision('fr-dtg-1485-new-badge-display-logic');
  const { data: newProducts, isLoading } = useGetNewProductsQuery(undefined, { skip: !isNewBadgeEnabled });

  if (!isNewBadgeEnabled || isLoading || !newProducts) {
    return {};
  }

  return newProducts;
}

export const useGetNewProduct = (productIDs: string[]): boolean => {
  const [{ enabled: isNewBadgeEnabled }] = useDecision('fr-dtg-1485-new-badge-display-logic');
  const newProducts = useGetNewProducts();

  if (!isNewBadgeEnabled) return false;

  return productIDs.some((id) => newProducts[id]);
}