import { CartItem } from '@/domain/cart/types';
import { MenuProductAnalytics } from '@/dataAnalytics/analyticsTypes';
import { isCoupon } from '@/cart/cartRail/cartRailHelpers';
import { getGlobalId } from '@/cart/helpers/getGlobalId';

export const finalPromiseTime = (isDelivery: boolean, promiseTime: string) => (isDelivery ? {
  user_promiseTime_delivery_final:
  promiseTime
} : {
  user_promiseTime_carryout_final: promiseTime
});

export const getDealsInfo = (items?: CartItem[]) => {
  const deals = items?.filter((item) => item?.type === 'DISCOUNT');
  const dealsIds = deals?.map((item) => (item?.id));
  const dealsNames = deals?.map((item) => (item?.name));

  return {
    ...(deals?.length && {
      deal_id: dealsIds?.join(','),
      deal_name: dealsNames?.join(',')
    })
  };
};

enum AnalyticsValue {
  YES = 'Yes',
  NO = 'No'
}

export const getProductsInfo = (items?: CartItem[]):MenuProductAnalytics[] | undefined => items?.map(
  (item, index) => {
    const itemDealInfo = getDealsInfo([item]);
    return {
      product_index: index,
      product_action_list: 'Checkout My Order',
      product_quantity: Number(item.quantity),
      product_id: item.id,
      product_name: item.name,
      product_category: item.type,
      product_price: Number(item.displayablePrice?.replace(/[^0-9.,]+/g, '')),
      product_deal_id: itemDealInfo?.deal_id,
      product_deal_name: itemDealInfo?.deal_name,
      product_size: item?.pizzaModifiers?.size,
      product_sauce: item?.pizzaModifiers?.sauce,
      product_cheese: item?.pizzaModifiers?.cheese,
      product_crust: item?.pizzaModifiers?.crust,
      product_finisher: item?.pizzaModifiers?.finisher,
      product_veggies: item?.pizzaModifiers?.veggies,
      product_meats: item?.pizzaModifiers?.meats,
      product_modifier: null,
      product_redeemed_as_reward: item.rewardsRedemptionCode ? AnalyticsValue.YES : AnalyticsValue.NO
    };
  }
);

const couponArrayToString = (arr: string[]): string => {
  let str = '';
  arr.forEach((value, index) => {
    str += value;
    if (index < arr.length - 1) {
      str += ', ';
    }
  });
  return str;
};

export const getCouponInformation = (items?: CartItem[]) => {
  const couponList = items?.filter((item) => isCoupon(item));
  const names : string[] = [];
  const discounts : string[] = [];
  const codes : string[] = [];
  couponList?.forEach((coupon) => {
    names.push(coupon.name);
    discounts.push(coupon.displayablePrice);
    codes.push(coupon.id);
  });
  return {
    order_coupon_name: couponArrayToString(names),
    order_coupon_code: getGlobalId(couponArrayToString(codes)),
    order_coupon_discount: couponArrayToString(discounts)
  };
};
