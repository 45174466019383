import { CartItemInput } from '@pizza-hut-us-development/client-core';
import { legacyProductTransformer } from './helpers/legacyProductTransformer';
import { AddableCartItem } from '@/api/phdApiV2Client/request.types';
import { Product } from '@/domain/product/types';
import { legacyPizzaTransformer } from './helpers/legacyPizzaTransformer';
import { BuiltPizza } from '@/builders/pizza/dataTransformers/builderTypes';
import { legacyDealTransformer } from './helpers/legacyDealTransformer';
import { Deal } from '@/builders/deals/slice/dealTypes';

export const useCCTransformLegacyCartItem = () => {
  const handleLegacyCartItemTransformer = (legacyItem: AddableCartItem): CartItemInput => {
    const transformDecision = 'userSelections' in legacyItem ? 'DEAL' : legacyItem.type;

    switch (transformDecision) {
      case 'DEAL':
        return legacyDealTransformer(legacyItem as Deal);
      case 'PIZZA':
        return legacyPizzaTransformer(legacyItem as BuiltPizza);
      default:
        return legacyProductTransformer(legacyItem as Product);
    }
  };

  return { handleLegacyCartItemTransformer };
};
