import React from 'react';
import DialogActions from '@mui/material/DialogActions';
import { Grid, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { mobileStartBreakpoint, smallMobileStartBreakpoint } from '../../../materialUi/theme';
import { CtaButton } from '../../buttons/CtaButton/CtaButton';
import { modalErrorPopupClickAnalytics } from '../../../dataAnalytics/dataAnalyticsHelper';
import useMappedRoute from '../../../dataAnalytics/hooks/useMappedRoute';
import { selectors } from '../../../localization/actions';
import useAnalytics, { Analytics } from '@/dataAnalytics/hooks/useAnalytics';

const pushAnalytics = (
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  modalDetails: any,
  screenName: string,
  actionKey: string,
  analytics: Analytics
) => {
  const { data: modalData } = modalDetails;
  const { analyticsModalData } = modalData;

  analytics.push(() => modalErrorPopupClickAnalytics(
    analyticsModalData?.title || modalData?.title,
    analyticsModalData?.body || modalData?.body,
    modalData[actionKey].text,
    analyticsModalData?.screenName || screenName,
    analyticsModalData?.occasion,
    analyticsModalData?.isSavedAddress,
    analyticsModalData?.storeSearchStage
  ));
};

const styles = makeStyles((theme: Theme) => createStyles({
  root: {
    justifyContent: 'center',
    padding: 0
  },
  primaryBtn: {
    justifyContent: 'center',
    margin: 0,
    width: '100%'
  },
  bothCtaGrid: {
    justifyContent: 'space-between',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      height: '96px',
      alignContent: 'space-between',
      justifyContent: 'center'
    }
  },
  btnGrid: {
    maxWidth: '280px',
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      maxWidth: '100%'
    },
    '&:first-child': {
      paddingLeft: 0
    },
    '&:last-child': {
      paddingRight: 0
    },
    '&.MuiGrid-item': {
      paddingTop: 0,
      paddingBottom: 0,
      [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
        maxWidth: '100%',
        padding: 0
      }
    }
  },
  reorderSecondaryBtn: {
    order: 1,
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      order: 2
    }
  },
  reorderPrimaryBtn: {
    order: 2,
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      order: 1
    }
  }
}));

interface DialogActionBtnProps {
  primary: ModalCta;
  secondary?: ModalCta;
  closeModal: () => void;
}

const DialogActionButtons = (
  { primary, secondary, closeModal }: DialogActionBtnProps
): JSX.Element => {
  const classes = styles();
  const modalDetails = useSelector(selectors.selectModalDetails);
  const analytics = useAnalytics();
  const screenName = useMappedRoute(useRouter());

  const primaryExists = primary && !!primary.text;
  const secondaryBtnExists = secondary && !!secondary.text;
  const mdSpacing = secondaryBtnExists ? 6 : 12;

  // todo: clear me up a little bit
  const gridForBoth = primaryExists && secondaryBtnExists ? classes.bothCtaGrid : '';
  const primaryReorder = primary?.reverseButtonsOrder ? classes.reorderPrimaryBtn : '';
  const secondaryReorder = secondary?.reverseButtonsOrder ? classes.reorderSecondaryBtn : '';

  const handlePrimaryBtnClick = () => {
    pushAnalytics(modalDetails, screenName, 'cta', analytics);
    closeModal();
    if (primary.callback) primary.callback();
  };

  const handleSecondaryBtnClick = () => {
    pushAnalytics(modalDetails, screenName, 'altCta', analytics);
    closeModal();
    if (secondary?.callback) secondary.callback();
  };

  return (
    <DialogActions classes={{ root: classes.root }}>
      <Grid
        container
        className={`${classes.primaryBtn} ${gridForBoth}`}
        data-testid="modal-action-buttons"
      >
        {primaryExists && (
          <Grid item xs={12} md={mdSpacing} className={`${classes.btnGrid} ${primaryReorder}`}>
            <CtaButton
              color="primary"
              labelText={primary.text}
              clickHandler={handlePrimaryBtnClick}
              size="large"
              autoFocus
            />
          </Grid>
        )}
        {secondaryBtnExists && (
        <Grid item xs={12} md={6} className={`${classes.btnGrid} ${secondaryReorder}`}>
          <CtaButton
            color="secondary"
            labelText={secondary?.text}
            clickHandler={handleSecondaryBtnClick}
            size="large"
            autoFocus={!primaryExists}
          />
        </Grid>
        )}
      </Grid>
    </DialogActions>
  );
};

export default DialogActionButtons;
