import { Categories, CategoryMap, } from '../types';
import { filterDealLoversVariants, filterVariantsSlotsModifiersByAccessPointWeb, removeBundleOnlyVariants, } from './filterUtilities';
import isBundleAvailableForTimeAndOccasion from './isBundleAvailableForTimeAndOccasion';
import { includeBundleForPromotionDefinition, includeProductForPromotionDefinition, isDip, productType, } from './menuUtilities';
import transformDipToMultipleProducts from './transformDipToMultipleProducts';
import transformYumBundleToDealProduct from './transformYumBundleToDealProduct';
import transformYumCategories from './transformYumCategories';
import transformYumOptionTypesToModifierArray from './transformYumOptionTypesToModifierArray';
import transformYumProduct from './transformYumProduct';
import transformYumProductPizzasAndMelts from './transformYumProductPizzasAndMelts';
import transformYumProductWings from './transformYumProductWings';
import transformYumSlotsToModifierArray from './transformYumSlotsToModifierArray';
export const transformYumMenu = (menu, occasion, removeBundleVariants = true, skipFilterByAccessPointWeb = false, promotionDefinition) => {
    // destructure the parts of the Yum menu we need during the transform
    const { categories: yumCategories, modifiers, optionTypes, optionValues, products: allYumProducts, slots: yumSlots, bundleChoices, } = menu;
    let { bundles } = menu;
    // filter out slots and variants by the access-point-Web private metafield
    let { products: yumProducts, slots: slotsFilteredByPrivateMetafield, modifiers: modifiersFilteredByPrivateMetafield, } = filterVariantsSlotsModifiersByAccessPointWeb(allYumProducts, yumSlots, modifiers, skipFilterByAccessPointWeb);
    if (promotionDefinition) {
        yumProducts = yumProducts.filter(({ productCode }) => includeProductForPromotionDefinition(productCode, promotionDefinition));
    }
    // store all of the item codes and variant codes in each category and the type of code
    const categoryVariantCodes = new Map();
    const categoryItemCodes = yumCategories.reduce((acc, category) => {
        var _a;
        const id = (_a = CategoryMap[category.categoryCode]) !== null && _a !== void 0 ? _a : category.categoryCode;
        // create an empty array in the category variant codes map
        categoryVariantCodes.set(id, []);
        const itemCodes = category.items.map(({ bundleCode = '', productCode = '', variantCode = '' }) => {
            var _a;
            if (bundleCode.length) {
                return { code: bundleCode, type: 'bundle' };
            }
            else if (productCode.length) {
                return { code: productCode, type: 'product' };
            }
            else {
                (_a = categoryVariantCodes.get(id)) === null || _a === void 0 ? void 0 : _a.push(variantCode);
                return { code: variantCode, type: 'variant' };
            }
        });
        acc.set(id, itemCodes);
        return acc;
    }, new Map());
    // transform all of the Yum slots and modifiers into client core Modifier groups
    const allSlots = transformYumSlotsToModifierArray(slotsFilteredByPrivateMetafield, modifiersFilteredByPrivateMetafield);
    // transform all of the Yum option types and option values as client core Modifier groups
    const allOptions = transformYumOptionTypesToModifierArray(optionTypes, optionValues);
    // transform Yum bundles into client core deal products
    // first thing we need to do is determine the source of the bundles
    // if we have the deals category in categories, we use that
    // otherwise fall back to the bundles section of the menu
    const dealsCategory = yumCategories.find((category) => category.categoryCode === Categories.DEALS);
    if (dealsCategory) {
        bundles = dealsCategory.items.map(({ bundleCode }) => bundles.find((bundle) => bundle.bundleCode === bundleCode));
    }
    // if we have a promotion, we need to only inlcude bundles defined in the promotion
    if (promotionDefinition) {
        bundles = bundles.filter(({ bundleCode }) => includeBundleForPromotionDefinition(bundleCode, promotionDefinition));
    }
    // we need to know the current time because some bundles are restricted to a time/occasion
    const now = new Date();
    const dealProducts = bundles
        .filter((bundle) => isBundleAvailableForTimeAndOccasion(bundle, now, occasion))
        .map((bundle) => transformYumBundleToDealProduct(bundle, bundleChoices, menu));
    const visibleDeals = dealProducts.filter((deal) => !deal.hidden);
    const deals = {
        displayOrder: 0,
        featuredProducts: (visibleDeals === null || visibleDeals === void 0 ? void 0 : visibleDeals.slice(0, 2)) || [],
        id: 'deals',
        metafields: [],
        name: 'Deals',
        privateMetafields: [],
        products: dealProducts,
    };
    // transform the Yum categories into client core categories, then reduce each category
    // we are also filtering out the deals category if it is present, it has already been processed above
    const transformedCategories = transformYumCategories(yumCategories.filter((category) => category.categoryCode !== Categories.DEALS));
    const productCategories = transformedCategories.reduce((acc, category) => {
        var _a;
        // get the array of items in this category
        const categoryItems = (_a = categoryItemCodes.get(category.id)) !== null && _a !== void 0 ? _a : [];
        // determine if this is the lineup category
        const isLineup = category.id === Categories.LINEUP;
        // create an empty array to store the products for this category
        const products = [];
        // iterate over each item in the category and run it through the proper transforms
        categoryItems.forEach(({ code, type: codeType }) => {
            // function to transform a Yum product based on the item type
            const transformYumProductByItemType = (yumProduct) => {
                const itemType = productType(yumProduct.options);
                switch (itemType) {
                    case 'MELT':
                    case 'PIZZA': {
                        const product = transformYumProductPizzasAndMelts(yumProduct, allOptions, allSlots, removeBundleVariants, {}, promotionDefinition);
                        return product;
                    }
                    case 'WINGS': {
                        const product = transformYumProductWings(yumProduct, allOptions, allSlots, removeBundleVariants, {}, promotionDefinition);
                        return product;
                    }
                    default: {
                        const product = transformYumProduct(yumProduct, allOptions, allSlots, removeBundleVariants, {}, promotionDefinition);
                        return product;
                    }
                }
            };
            // depending on the type of the item, we need to get the yum product in a different way
            switch (codeType) {
                // when the type is a bundle, bundles have already been transformed to deal products up above,
                // we just need to extract the deal using the bundle code na dpush it into the products array for this category
                case 'bundle': {
                    const deal = dealProducts.find((d) => d.id === code);
                    if (!!deal)
                        products.push(deal);
                    break;
                }
                // when the type is product, we just need to find the product by its productCode
                case 'product': {
                    const transformProduct = (yumProduct) => {
                        // filter out variants based on bundlesOnly and dealLovers private metafields
                        // if after we have variants left, transform the Yum products and then add it to the
                        // products array for this category
                        if (removeBundleVariants) {
                            const { variants = [] } = yumProduct;
                            const filteredVariants = filterDealLoversVariants(removeBundleOnlyVariants(variants), isLineup);
                            if (filteredVariants.length > 0) {
                                const product = transformYumProductByItemType(Object.assign(Object.assign({}, yumProduct), { variants: filteredVariants }));
                                products.push(product);
                            }
                        }
                        else {
                            const { variants = [] } = yumProduct;
                            const filteredVariants = filterDealLoversVariants(variants, isLineup);
                            if (filteredVariants.length > 0) {
                                const product = transformYumProductByItemType(Object.assign(Object.assign({}, yumProduct), { variants: filteredVariants }));
                                products.push(product);
                            }
                        }
                    };
                    const yumProduct = yumProducts.find((product) => product.productCode === code);
                    // if we found a Yum product
                    if (!!yumProduct) {
                        if (isDip(yumProduct.productCode)) {
                            const dips = transformDipToMultipleProducts(yumProduct, modifiersFilteredByPrivateMetafield);
                            dips.forEach((dip) => transformProduct(dip));
                        }
                        else {
                            transformProduct(yumProduct);
                        }
                    }
                    break;
                }
                // when the type is variant, wee need to look at each variant on each product,
                // if the variant is on the product, we found it
                case 'variant': {
                    const yumProduct = yumProducts.find((product) => {
                        const { variants } = product;
                        return variants.some((variant) => variant.variantCode === code);
                    });
                    // if we found a Yum product
                    if (!!yumProduct) {
                        // filter out variants based on bundlesOnly and dealLovers private metafields
                        // and then further filter by only including that variants identified by the variant codes
                        // if after we have variants left, transform the Yum product and then add it to the
                        // products array for this category
                        if (removeBundleVariants) {
                            const { variants = [] } = yumProduct;
                            const filteredVariants = filterDealLoversVariants(removeBundleOnlyVariants(variants), isLineup).filter((v) => { var _a; return (_a = categoryVariantCodes.get(category.id)) === null || _a === void 0 ? void 0 : _a.includes(v.variantCode); });
                            if (filteredVariants.length > 0) {
                                const product = transformYumProductByItemType(Object.assign(Object.assign({}, yumProduct), { variants: filteredVariants }));
                                products.push(product);
                            }
                        }
                        else {
                            const { variants = [] } = yumProduct;
                            const filteredVariants = filterDealLoversVariants(variants, isLineup).filter((v) => { var _a; return (_a = categoryVariantCodes.get(category.id)) === null || _a === void 0 ? void 0 : _a.includes(v.variantCode); });
                            if (filteredVariants.length > 0) {
                                const product = transformYumProductByItemType(Object.assign(Object.assign({}, yumProduct), { variants: filteredVariants }));
                                products.push(product);
                            }
                        }
                    }
                    break;
                }
            }
        });
        // add the category along with its products to the category list
        acc.push(Object.assign(Object.assign({}, category), { products }));
        return acc;
    }, []);
    const categories = [deals].concat(productCategories);
    return {
        categories,
        featuredProducts: [],
    };
};
export default transformYumMenu;
